import { Box, Card, Container, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import NotificationsList from '../../domain/notifications/NotificationsList';
import MainContainer from '../../ui/MainContainer';
import Header from '../layout/Header/Header';

const NotificationsPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Header />
      <MainContainer>
        <Container
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            pt: 2,
          }}
        >
          <Card>
            <Box width={590} pt={5} pb={3}>
              <Typography textAlign="center" variant="h4">
                {t('notifications.notifications')}
              </Typography>
              <NotificationsList />
            </Box>
          </Card>
        </Container>
      </MainContainer>
    </>
  );
};

export default NotificationsPage;
