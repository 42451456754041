import { MpSortRule, MpTable, MpTableDataArguments } from '@mp-react/table';
import isEqual from 'lodash/isEqual';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import PathEnum from '../../infrastructure/constants/PathEnum';
import TableLimit from '../../infrastructure/constants/TableLimit';
import { FilterTypeEnum, OrderEnum } from '../../infrastructure/types/IFilterInput';
import useStudiesQuery from './graphql/useStudiesQuery';
import useStudiesColumns from './hooks/useStudiesColumns';
import IStudy from './types/IStudy';
import { customRenderersMap } from './customRenderers/CustomRenderers';

const StudiesTable: React.FC = () => {
  const navigate = useNavigate();

  const [cursor, setCursor] = useState(0);
  const [sort, setSort] = useState<MpSortRule>();
  const [filter, setFilter] = useState<any>();

  const { loading, data, fetchMore } = useStudiesQuery({
    variables: {
      filters: {
        limit: TableLimit.studiesLimit,
        offset: cursor,
        filters: filter
          ? [{ field: filter.id, type: FilterTypeEnum.ILIKE, args: [filter.value] }]
          : undefined,
        order: sort
          ? [
              {
                field: sort.key,
                order: sort.desc ? OrderEnum.DESC : OrderEnum.ASC,
              },
            ]
          : undefined,
      },
    },
    fetchPolicy: 'network-only',
  });

  const [studies, setStudies] = useState<IStudy[]>([]);
  const [count, setCount] = useState(0);

  useEffect(() => {
    setStudies(data?.studies || []);
    setCount(data?.studiesCount || 0);
  }, [data]);

  const overridables = useMemo(
    () => ({
      renderers: customRenderersMap,
    }),
    [],
  );

  const columns = useStudiesColumns();

  const onRowClick = (row) => {
    navigate(generatePath(PathEnum.Study, { studyId: row.id }));
  };

  const handleGetData = useCallback(
    (variables: MpTableDataArguments) => {
      if (
        (variables.cursor !== undefined && cursor !== variables.cursor) ||
        !isEqual(sort, variables.sort?.[0]) ||
        !isEqual(filter, variables.filters?.[0])
      ) {
        const newCursor = variables.cursor || 0;
        setCursor(newCursor);

        const newSort = variables.sort?.[0];
        setSort(newSort);

        const newFilter = variables.filters?.[0];
        setFilter(newFilter);

        fetchMore({
          variables: {
            filters: {
              limit: TableLimit.studiesLimit,
              offset: newCursor,
              filters: newFilter
                ? [{ field: newFilter.id, type: FilterTypeEnum.ILIKE, args: [newFilter.value] }]
                : undefined,
              order: newSort
                ? [
                    {
                      field: newSort.key,
                      order: newSort.desc ? OrderEnum.DESC : OrderEnum.ASC,
                    },
                  ]
                : undefined,
            },
          },
        }).then(({ data: fetchData }) => {
          setStudies(fetchData?.studies || []);
          setCount(fetchData?.studiesCount || 0);
        });
      }
    },
    [cursor, sort, filter, fetchMore],
  );

  return (
    <MpTable
      columns={columns}
      rowIdKey="id"
      onGetData={handleGetData}
      onRowClick={onRowClick}
      loading={loading}
      dataCount={count}
      pageSize={TableLimit.studiesLimit}
      data={studies}
      overridables={overridables}
    />
  );
};

export default StudiesTable;
