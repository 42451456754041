import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import IStudy from '../../studies/types/IStudy';

const STUDY = gql`
  query StudyFoldersStructure($id: String!, $filters: FilterInput) {
    study(id: $id) {
      id
      name
      folderStructure(filters: $filters) {
        id
        name
        fileCount
        subFolders {
          id
          name
          fileCount
          subFolders {
            id
            name
            fileCount
            subFolders {
              id
              name
              fileCount
              subFolders {
                id
                name
                fileCount
                subFolders {
                  id
                  name
                  fileCount
                  subFolders {
                    id
                    name
                    fileCount
                    subFolders {
                      id
                      name
                      fileCount
                      subFolders {
                        id
                        name
                        fileCount
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default (options: QueryHookOptions) => useQuery<{ study: IStudy }>(STUDY, options);
