import NotificationsIcon from '@mui/icons-material/Notifications';
import { Box, Button, IconButton, List, Popover, Typography } from '@mui/material';
import Badge from '@mui/material/Badge';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Colors from '../../../infrastructure/constants/Colors';
import PathEnum from '../../../infrastructure/constants/PathEnum';
import { useNotifications } from '../../../infrastructure/context/NotificationsContext';
import NotificationItem from './NotificationItem';

const NotificationsPopover: React.FC = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const { t } = useTranslation();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { newNotifications, newNotificationsCount, markAllAsRead } = useNotifications();

  const navigate = useNavigate();

  const handleClickSeeAll = () => {
    handleClose();
    if (newNotificationsCount) {
      markAllAsRead();
    }
    navigate(PathEnum.Notifications);
  };

  return (
    <>
      <IconButton
        size="large"
        aria-label="notifications"
        aria-controls="notifications-popover"
        aria-haspopup="true"
        onClick={handleClick}
        color="inherit"
      >
        <Badge badgeContent={newNotificationsCount} color="primary">
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Popover
        id="notifications-popover"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box py={2}>
          <Box width={246}>
            {newNotificationsCount < 1 && (
              <Box py={2}>
                <Typography color={Colors.gray2} textAlign="center">
                  {t('notifications.noNewNotifications')}
                </Typography>
              </Box>
            )}
            {newNotifications.map && (
              <List disablePadding>
                {newNotifications.map((notification) => (
                  <NotificationItem key={notification.id} notification={notification} />
                ))}
              </List>
            )}
          </Box>
          <Button sx={{ display: 'flex', mx: 'auto', mt: 1 }} onClick={handleClickSeeAll}>
            {t('button.seeAll')}
          </Button>
        </Box>
      </Popover>
    </>
  );
};

export default NotificationsPopover;
