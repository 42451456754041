import { Button } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import NewAnalysisModal from './NewAnalysisModal';

interface Props {
  isCnv: boolean;
}

const NewAnalysis: React.FC<Props> = ({ isCnv }) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button variant="contained" onClick={handleOpen}>
        {t('study.newAnalysis')}
      </Button>
      {open && <NewAnalysisModal handleClose={handleClose} isCnv={isCnv} />}
    </>
  );
};

export default NewAnalysis;
