import {
  backdropClasses,
  createTheme,
  menuItemClasses,
  outlinedInputClasses,
  tableCellClasses,
  tableRowClasses,
} from '@mui/material';
import { getBorder } from '../utils/Common';
import Colors from './constants/Colors';

const theme = createTheme({
  palette: {
    background: {
      default: Colors.default,
    },
    primary: {
      main: Colors.primary,
    },
    secondary: {
      main: Colors.secondary,
    },
    error: {
      main: Colors.error,
    },
  },
  components: {
    MuiToggleButton: {
      styleOverrides: {
        root: {
          border: 0,
          color: Colors.textPrimary,
          textTransform: 'none',

          '&.Mui-disabled': {
            border: 0,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontSize: '1rem',
          '.Mui-disabled': {
            backgroundColor: Colors.disable,
          },
        },
        text: {
          ':hover': {
            backgroundColor: 'transparent',
          },
        },
        contained: {
          minWidth: '168px',
          boxShadow: 'none',
          ':hover': {
            boxShadow: 'none',
          },
        },
        containedPrimary: {
          padding: '8px 20px',
          ':hover': {
            backgroundColor: Colors.lightPrimary,
          },
        },
        containedSecondary: {
          padding: '10px 20px',
          fontSize: '14px',
          lineHeight: '22px',
          color: Colors.primary,
          ':hover': {
            backgroundColor: Colors.lightSecondary,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: Colors.gray,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          [`&:not(.Mui-focused):not(.Mui-error):hover .${outlinedInputClasses.notchedOutline}`]: {
            border: getBorder(Colors.gray),
          },
          [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
            borderWidth: 1,
          },
          [`&.Mui-disabled .${outlinedInputClasses.notchedOutline}`]: {
            border: getBorder(Colors.disable),
          },
        },
        input: {
          height: 'auto',
          padding: '10px 16px',
          fontSize: '16px',
          lineHeight: '24px',
        },
        notchedOutline: {
          borderColor: Colors.disable,
          '& legend': {
            fontSize: '10px',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '16px',
        },
        shrink: {
          transform: 'translate(16px, -6px) scale(0.625)', // transform from 16px to 10px
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          [`& .${backdropClasses.root}`]: {
            backgroundColor: Colors.backdropBg,
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          [`& .${backdropClasses.root}`]: {
            backgroundColor: 'unset',
          },
          [`& .${menuItemClasses.root}:hover`]: {
            background: Colors.secondary,
          },
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        root: {
          [`& .${backdropClasses.root}`]: {
            backgroundColor: 'unset',
          },
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {},
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          [`& .${tableRowClasses.root}`]: {
            transition: 'background-color 300ms',
            '&:hover': {
              backgroundColor: Colors.secondary,
              boxShadow:
                '0px 6px 8px rgba(0, 0, 0, 0.04), 0px 1px 16px rgba(0, 0, 0, 0.04), 0px 3px 4px rgba(0, 0, 0, 0.04)',
            },
            '&:nth-last-of-type(-n+1)': {
              // last row without border-bottom
              [`& .${tableCellClasses.root}`]: {
                borderBottom: 'unset',
              },
            },
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          color: Colors.gray,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: Colors.gray,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: Colors.boxShadow,
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        root: {
          color: Colors.black,
          fontWeight: 600,
          '& .MuiBadge-badge': {
            right: 3,
            top: 4,
            fontWeight: 600,
            fontSize: '10px',
            minWidth: '16px',
            height: '16px',
            padding: '0 4px',
          },
        },
        colorPrimary: {
          backgroundColor: Colors.alert,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: Colors.divider,
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        divider: {
          borderColor: Colors.divider,
        },
      },
    },
  },
});

export default theme;
