// const grid = 8;

import Colors from '../../../../infrastructure/constants/Colors';

const getItemStyle = (
  isDragging,
  draggableStyle,
  isDroppingOver = false,
  isDroppable = false,
  isSingleItem = false,
) => {
  return {
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    height: '120px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
    position: 'relative',

    // change background colour if dragging
    boxShadow: isDragging ? Colors.draggableBoxShadow : 'none',

    // styles we need to apply on draggables
    ...draggableStyle,

    ...(isDroppingOver && {
      transform: 'none!important',
    }),

    ...(isSingleItem && {
      transform: 'none',
      transition: 'unset',
    }),

    ...(isDroppable && {
      height: '170px',
      marginTop: '-50px',
      paddingTop: '50px',
    }),
  };
};

export default getItemStyle;
