import { NavigateBefore } from '@mui/icons-material';
import { Box, Button, Tooltip } from '@mui/material';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, Link, useParams } from 'react-router-dom';
import PathEnum from '../../infrastructure/constants/PathEnum';
import { useUploadContext } from './context/UploadContext';
import IStepEnum from './types/IStepEnum';
import UploadFileTypeEnum from './types/UploadFileEnum';

const BackToStudyButton: React.FC = () => {
  const { t } = useTranslation();
  const { studyId } = useParams();

  const { step, uploaded, uploadType } = useUploadContext();

  const disable = useMemo(() => {
    return (
      step === IStepEnum.UPLOAD_FILES_PROGRESS &&
      !uploaded &&
      UploadFileTypeEnum.DROPBOX !== uploadType
    );
  }, [step, uploaded]);

  return (
    <Box position="absolute" left={0}>
      <Tooltip title={disable ? t('analysis.backToStudyTooltip') : ''}>
        <span>
          <Button
            disabled={disable}
            component={Link}
            to={generatePath(PathEnum.Study, { studyId })}
          >
            <NavigateBefore />
            {t('analysis.backToStudy')}
          </Button>
        </span>
      </Tooltip>
    </Box>
  );
};

export default BackToStudyButton;
