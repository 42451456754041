import { colorToRGBA } from 'q-floodfill';
import convertToBlack from '../ImageEditor/helpers/convertToBlack';
import dataURItoBlob from '../ImageEditor/helpers/dataURItoBlob';
import replaceColors from '../ImageEditor/helpers/replaceColors';

const convertToBlackAndWhiteBlob = (canvas: HTMLCanvasElement, color: string) => {
  convertToBlack(canvas);
  const dataURL = canvas.toDataURL();
  const blob = dataURItoBlob(dataURL);
  replaceColors(canvas, [
    {
      colorToReplace: { r: 1, g: 1, b: 1, a: 255 },
      colorToReplaceWith: colorToRGBA(color),
    },
    {
      colorToReplace: { r: 0, g: 0, b: 0, a: 255 },
      colorToReplaceWith: { r: 0, g: 0, b: 0, a: 0 },
    },
  ]);
  return blob;
};

export default convertToBlackAndWhiteBlob;
