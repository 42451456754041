import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import IUser from '../../../infrastructure/types/IUser';

interface IGetUsers {
  users: IUser[];
}

const GET_USERS = gql`
  query users($filters: FilterInput) {
    users(filters: $filters) {
      id
      firstName
      lastName
      email
      status
    }
  }
`;

export default (options?: QueryHookOptions) => useQuery<IGetUsers>(GET_USERS, options);
