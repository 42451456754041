import { NavigateBefore, NavigateNext } from '@mui/icons-material';
import { Box, Button, Divider, Typography } from '@mui/material';
import { t } from 'i18next';
import React, { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import Colors from '../../infrastructure/constants/Colors';
import useStore from '../../infrastructure/store/useStore';
import AnalysisInterfaceEnum from '../../infrastructure/types/AnalysisInterfaceEnum';
import IFileRoleEnum from '../../infrastructure/types/IFileRoleEnum';
import IFileSubRoleEnum from '../../infrastructure/types/IFileSubRoleEnum';
import { FilterTypeEnum } from '../../infrastructure/types/IFilterInput';
import FullScreenLoader from '../../ui/FullScreenLoader';
import Image from '../../ui/Image';
import { getBorder } from '../../utils/Common';
import useFiles from './graphql/useFiles';
import useMaskChanges from './hooks/useMaskChanges';
import useMaskSaveDiscard from './hooks/useMaskSaveDiscard';
import IFile from './types/IFile';
import UnsavedChangesModal from './UnsavedChangesModal';

interface IProps {
  interfaceModel: AnalysisInterfaceEnum;
}

const ImageSelector: React.FC<IProps> = ({ interfaceModel }) => {
  const [selectedImage, selectedVIPImage, setSelectedImage, setSelectedVIPImage] = useStore(
    (state) => [
      state.selectedImage,
      state.selectedVIPImage,
      state.setSelectedImage,
      state.setSelectedVIPImage,
    ],
  );

  const { analysisId } = useParams();
  const filters = useMemo(() => {
    const genericFields = [
      { field: 'analysisId', type: FilterTypeEnum.IN, args: [analysisId] },
      { field: 'role', type: FilterTypeEnum.IN, args: [IFileRoleEnum.ANALYSIS_RESULT] },
    ];
    if (interfaceModel === AnalysisInterfaceEnum.MODEL_4) {
      return {
        filters: [
          ...genericFields,
          { field: 'subRole', type: FilterTypeEnum.IN, args: [IFileSubRoleEnum.OCT_VIP] },
        ],
      };
    }
    return {
      filters: genericFields,
    };
  }, [interfaceModel]);

  const { data: filesData, loading: filesLoading } = useFiles({
    variables: {
      filters,
    },
    skip: !analysisId,
  });

  const files = useMemo(() => filesData?.files || [], [filesData]);

  const [open, setOpen] = useState(false);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);
  const { onSave, saveLoading, onDiscard } = useMaskSaveDiscard(handleClose);
  const { hasChanges } = useMaskChanges();

  const onImageClick = useCallback(
    (file: IFile) => () => {
      if (hasChanges) {
        setOpen(true);
        return;
      }

      if (file.signedUrl) {
        if (interfaceModel === AnalysisInterfaceEnum.MODEL_4) {
          setSelectedVIPImage({
            id: file.id,
            index: file.index,
            signedUrl: file.signedUrl,
            objectId: file.objectId,
            name: file.name,
            width: 1000,
            height: 1000,
            mask: file.masks?.[0] || null,
            associatedFileId: file.associatedFileId,
          });
          return;
        }
        setSelectedImage(
          {
            id: file.id,
            index: file.index,
            signedUrl: file.signedUrl,
            objectId: file.objectId,
            name: file.name,
            isVIPImage: false,
          },
          file.masks || [],
        );
      }
    },
    [hasChanges, setSelectedImage, setSelectedVIPImage, interfaceModel],
  );

  const isSelected = useCallback(
    (file: IFile) => {
      if (interfaceModel === AnalysisInterfaceEnum.MODEL_4) {
        return selectedVIPImage?.id === file.id;
      }
      return selectedImage?.id === file.id;
    },
    [interfaceModel, selectedImage, selectedVIPImage],
  );

  const onPrev = useCallback(() => {
    if (hasChanges) {
      setOpen(true);
      return;
    }
    const selectedFileIndex = files.findIndex(
      (file) => file.id === selectedImage?.id || file.id === selectedVIPImage?.id,
    );
    if (selectedFileIndex === -1) return;

    const prevIndex = selectedFileIndex - 1;

    if (files && files[prevIndex]) {
      const file = files[prevIndex];
      const image = document.getElementById(file.id)?.firstChild as HTMLImageElement;
      if (!image) return;
      image.scrollIntoView({ block: 'start' });
      onImageClick(file)();
    }
  }, [hasChanges, files, selectedVIPImage, selectedImage, onImageClick]);

  const onNext = useCallback(() => {
    if (hasChanges) {
      setOpen(true);
      return;
    }
    const selectedFileIndex = files.findIndex(
      (file) => file.id === selectedImage?.id || file.id === selectedVIPImage?.id,
    );
    if (selectedFileIndex === -1) return;

    const nextIndex = selectedFileIndex + 1;

    if (files && files[nextIndex]) {
      const file = files[nextIndex];
      const image = document.getElementById(file.id)?.firstChild as HTMLImageElement;
      if (!image) return;
      image.scrollIntoView({ block: 'start' });
      onImageClick(file)();
    }
  }, [hasChanges, files, selectedVIPImage, selectedImage, onImageClick]);

  const disablePrevNextButtons = useMemo(
    () => files.length < 2 || (!selectedImage && !selectedVIPImage),
    [files, selectedImage, selectedVIPImage],
  );

  const isPrevDisabled = useMemo(() => {
    const selectedFileIndex = files.findIndex(
      (file) => file.id === selectedImage?.id || file.id === selectedVIPImage?.id,
    );
    return selectedFileIndex === 0;
  }, [files, selectedImage, selectedVIPImage]);

  const isNextDisabled = useMemo(() => {
    const selectedFileIndex = files.findIndex(
      (file) => file.id === selectedImage?.id || file.id === selectedVIPImage?.id,
    );
    return selectedFileIndex === files.length - 1;
  }, [files, selectedImage, selectedVIPImage]);

  if (filesLoading) {
    return <FullScreenLoader />;
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      position="relative"
      height="100%"
      borderRight={getBorder(Colors.divider)}
      sx={{ flexGrow: 1, overflowY: 'auto' }}
    >
      <Typography px={2} py={2} fontWeight={500}>
        {t('analysis.images')}
      </Typography>
      <Divider />
      <Box display="flex" flexWrap="wrap" overflow="auto" maxHeight="100%" pb={10}>
        {files.map((file) => (
          <Image
            key={file.id}
            id={file.id}
            name={file.name}
            onImageClick={onImageClick(file)}
            src={file.thumbnails.s115x115}
            width="100%"
            selected={isSelected(file)}
            showName
            fallbackSrc={file?.signedUrl}
          />
        ))}
      </Box>
      <Box mt="auto" display="flex" gap={1} py={1} justifyContent="center" width="100%">
        <Button size="small" onClick={onPrev} disabled={disablePrevNextButtons || isPrevDisabled}>
          <NavigateBefore />
          <Box fontSize="14px">{t('button.previous')}</Box>
        </Button>
        <Button size="small" onClick={onNext} disabled={disablePrevNextButtons || isNextDisabled}>
          <Box fontSize="14px">{t('button.next')}</Box>
          <NavigateNext />
        </Button>
      </Box>
      <UnsavedChangesModal
        handleClose={handleClose}
        open={open}
        onDiscard={onDiscard}
        onSave={onSave}
        saveLoading={saveLoading}
      />
    </Box>
  );
};

export default ImageSelector;
