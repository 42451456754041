import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useMemo } from 'react';
import TableLimit from '../constants/TableLimit';
import markAllNotificationsAsReadMutation from '../persistence/mutations/markAllNotificationsAsReadMutation';
import markNotificationAsReadMutation from '../persistence/mutations/markNotificationAsReadMutation';
import {
  NOTIFICATION_CREATED_SUBSCRIPTION,
  NOTIFICATION_UPDATED_SUBSCRIPTION,
} from '../persistence/queries/notificationSubscription';
import useNotificationsQuery from '../persistence/queries/useNotificationsQuery';
import INotification from '../types/INotification';
import { useAuth } from './AuthContext';

interface INotificationsContext {
  notifications: INotification[];
  newNotifications: INotification[];
  notificationsCount: number;
  newNotificationsCount: number;
  markAsRead: (ids: string[]) => void;
  markAllAsRead: () => void;
  seeMore: () => void;
}

export const NotificationsContext = React.createContext<INotificationsContext>({
  notifications: [],
  newNotifications: [],
  notificationsCount: 0,
  newNotificationsCount: 0,
  markAllAsRead: () => null,
  markAsRead: () => null,
  seeMore: () => null,
});

export const NotificationsProvider: React.FC = ({ children }) => {
  const { isAuthenticated } = useAuth();

  const { enqueueSnackbar } = useSnackbar();

  const { subscribeToMore, data, fetchMore } = useNotificationsQuery({
    skip: !isAuthenticated,
    variables: {
      filters: {
        offset: 0,
        limit: TableLimit.notificationsLimit,
      },
    },
  });

  const seeMore = useCallback(() => {
    fetchMore({
      variables: {
        filters: {
          offset: data?.notifications.length,
          limit: TableLimit.notificationsLimit,
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return {
          ...prev,
          notifications: [...prev.notifications, ...fetchMoreResult.notifications],
        };
      },
    });
  }, [fetchMore, data]);

  useEffect(() => {
    const notificationErrorCache = {};
    if (isAuthenticated) {
      subscribeToMore({
        document: NOTIFICATION_CREATED_SUBSCRIPTION,
        variables: {},
        updateQuery: (prev, { subscriptionData }) => {
          const newItem = subscriptionData.data?.notificationCreated;
          if (!newItem) return prev;

          enqueueSnackbar(newItem.message, {
            variant: 'info',
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          });

          return {
            ...prev,
            notifications: [newItem, ...prev.notifications],
          };
        },
      });
      subscribeToMore({
        document: NOTIFICATION_UPDATED_SUBSCRIPTION,
        variables: {},
        updateQuery: (prev, { subscriptionData }) => {
          const newItem = subscriptionData.data?.notificationUpdated;
          if (!newItem) return prev;

          const updatedNotifications = {
            ...prev,
            notifications: prev.notifications.map((item) => {
              if (item.id === newItem.id) {
                return newItem;
              }
              return item;
            }),
          };
          if (newItem.type === 'ANALYSIS_PROCESSED') {
            return updatedNotifications;
          }
          const lastError = newItem.errors.at(-1);
          const errorKey = `${newItem.id}:${lastError?.sentFile}`;
          if (lastError && !notificationErrorCache[errorKey]) {
            notificationErrorCache[errorKey] = true;
            enqueueSnackbar(`[Error] ${lastError?.message} (${lastError?.sentFile})`, {
              variant: 'error',
              anchorOrigin: { vertical: 'top', horizontal: 'right' },
              preventDuplicate: true,
            });
            return updatedNotifications;
          }
          if (newItem.currentProgress === newItem.expectedProgress) {
            enqueueSnackbar(newItem.message, {
              variant: 'info',
              anchorOrigin: { vertical: 'top', horizontal: 'right' },
            });
          }
          return updatedNotifications;
        },
      });
    }
  }, [isAuthenticated]);

  const [mutateMarkAsRead] = markNotificationAsReadMutation({});

  const [mutateMarkAllAsRead] = markAllNotificationsAsReadMutation({});

  const markAllAsRead = () => {
    mutateMarkAllAsRead();
  };

  const markAsRead = (ids: string[]) => {
    const variables = ids.map((id) => ({
      notificationId: id,
      read: true,
    }));
    mutateMarkAsRead({
      variables: {
        input: variables,
      },
    });
  };

  const newNotifications = useMemo(
    () => data?.notifications.filter((el) => el.read === false) || [],
    [data],
  );

  const value = useMemo(
    () => ({
      notifications: data?.notifications || [],
      notificationsCount: data?.notificationsCount || 0,
      newNotifications: newNotifications.slice(0, 8),
      newNotificationsCount: newNotifications.length || 0,
      markAsRead,
      markAllAsRead,
      seeMore,
    }),
    [data, markAsRead, markAllAsRead, newNotifications, seeMore],
  );

  return <NotificationsContext.Provider value={value}>{children}</NotificationsContext.Provider>;
};

export const useNotifications = () => React.useContext(NotificationsContext);
