import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import IFilterInput from '../../../infrastructure/types/IFilterInput';
import IAIModel from '../types/IAIModel';

interface IGetAIModelsQueryData {
  aiModels: IAIModel[];
}

export const GET_AI_MODELS = gql`
  query aiModels($filters: FilterInput) {
    aiModels(filters: $filters) {
      id
      model
      cnvModelId
      interface
      modelId
      creationDate
      cnvModelId
    }
  }
`;

export default (options?: QueryHookOptions<IGetAIModelsQueryData, { filters: IFilterInput }>) => {
  return useQuery<IGetAIModelsQueryData, { filters: IFilterInput }>(GET_AI_MODELS, options);
};
