import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import IStudy from '../types/IStudy';
import IStudyDetails from '../types/IStudyDetails';

interface ICreateStudyInput {
  input: IStudyDetails;
}

interface ICreateStudyResponse {
  createStudy: IStudy;
}

const CREATE_STUDY = gql`
  mutation createStudy($input: CreateStudyInput!) {
    createStudy(input: $input) {
      id
      name
      status
      creationDate
      lastModificationDate
      dueDate
      department
      studyDirectorId
      studyDirector {
        id
        firstName
        lastName
      }
      analysesCount
    }
  }
`;

export default (options: MutationHookOptions<ICreateStudyResponse, ICreateStudyInput>) => {
  return useMutation<ICreateStudyResponse, ICreateStudyInput>(CREATE_STUDY, options);
};
