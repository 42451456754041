import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import IFile from '../../analysis/types/IFile';

interface IAddFilesToAnalysisInput {
  input: {
    analysisId: string;
    fileIds: string[];
  };
}

interface IAddFilesToAnalysisResponse {
  addFilesToAnalysis: IFile;
}

const ADD_FILES_TO_ANALYSIS = gql`
  mutation addFilesToAnalysis($input: AddFilesToAnalysisInput!) {
    addFilesToAnalysis(input: $input) {
      id
      analysisId
    }
  }
`;

export default (
  options: MutationHookOptions<IAddFilesToAnalysisResponse, IAddFilesToAnalysisInput>,
) => {
  return useMutation<IAddFilesToAnalysisResponse, IAddFilesToAnalysisInput>(
    ADD_FILES_TO_ANALYSIS,
    options,
  );
};
