import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import FolderIcon from '@mui/icons-material/Folder';
import { ListItem, ListItemButton, ListItemText } from '@mui/material';
import React from 'react';
import Colors from '../../infrastructure/constants/Colors';
import IFolderItem from './types/IFolderItem';

interface FolderTreeItemProps {
  index: number;
  open: boolean;
  item: IFolderItem;
  isSelected: boolean;
  handleClick: ({ key, item }: { key: number; item?: IFolderItem }) => void;
}

const FolderTreeListItem: React.FC<FolderTreeItemProps> = ({
  index,
  isSelected,
  open,
  item,
  handleClick,
}) => {
  const ref = React.useRef<HTMLLIElement>(null);
  ref.current?.scrollIntoView({ block: 'center' });
  return (
    <ListItem
      ref={ref}
      disablePadding
      divider
      sx={{
        backgroundColor: isSelected ? Colors.secondary : undefined,
      }}
    >
      <ListItemButton onClick={() => handleClick({ key: index, item })}>
        {!!item?.subFolders?.length && (open ? <ExpandLess /> : <ExpandMore />)}
        <FolderIcon sx={{ mx: 1, width: 20 }} />
        <ListItemText primary={item.name} />
      </ListItemButton>
    </ListItem>
  );
};

export default React.memo(FolderTreeListItem);
