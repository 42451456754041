import { useRendererOptions } from '@mp-react/table';
import { Typography } from '@mui/material';
import prettyBytes from 'pretty-bytes';
import React from 'react';
import { CellProps } from 'react-table';

const PrettyBytes: React.FC<CellProps<{}>> = (props) => {
  const { value } = useRendererOptions(props);

  return <Typography variant="body2">{prettyBytes(value)}</Typography>;
};

export default PrettyBytes;
