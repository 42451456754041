import { Box, Button, Link, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import GenericModal from '../../ui/GenericModal';

interface IProps {
  open: boolean;
  handleClose: () => void;
}

const SearchInstructionsModal: React.FC<IProps> = ({ open, handleClose }) => {
  const { t } = useTranslation();
  return (
    <GenericModal open={open} onClose={handleClose}>
      <Box sx={{ textAlign: 'center' }}>
        <Typography id="modal-modal-title" variant="h5" component="h2">
          {t('upload.searchInstructionsModal.title')}
        </Typography>
        <Typography
          component="div"
          id="modal-modal-description"
          sx={{
            mt: 1,
            mb: 2,
            maxWidth: 350,
            mx: 'auto',
            textAlign: 'left',
          }}
          variant="body2"
        >
          <ul>
            <li>{t('upload.searchInstructionsModal.instruction1')}</li>
            <li>{t('upload.searchInstructionsModal.instruction2')}</li>
            <li>{t('upload.searchInstructionsModal.instruction3')}</li>
          </ul>
          <Link
            href="https://help.dropbox.com/view-edit/search"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('upload.searchInstructionsModal.link')}
          </Link>
        </Typography>

        <Box display="flex" justifyContent="center" mt={2}>
          <Button onClick={handleClose} variant="contained">
            {t('button.ok')}
          </Button>
        </Box>
      </Box>
    </GenericModal>
  );
};

export default SearchInstructionsModal;
