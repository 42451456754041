import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import IFile from '../../analysis/types/IFile';

const FILES = gql`
  query ProjectFiles($filters: FilterInput) {
    files(filters: $filters) {
      id
      name
      metadata
      signedUrl
      index
      analysisId
      studyId
    }
  }
`;

const useProjectFiles = (options?: QueryHookOptions) => {
  return useQuery<{ files: IFile[] }>(FILES, {
    fetchPolicy: 'network-only',
    ...options,
  });
};

export default useProjectFiles;
