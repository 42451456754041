import moment from 'moment';
import { useRendererOptions } from '@mp-react/table';
import React from 'react';
import { CellProps } from 'react-table';
import { Typography } from '@mui/material';
import { StudyStatusEnum } from '../../types/IStudy';

const DueDate: React.FC<CellProps<{}>> = (props) => {
  const options = useRendererOptions(props);
  const { value } = options;
  const { row } = props;

  if (row.values.status === StudyStatusEnum.ACTIVE && moment().diff(value) > 0) {
    return (
      <Typography color="error" fontSize="0.875rem">
        {value}
      </Typography>
    );
  }

  return value;
};

export default DueDate;
