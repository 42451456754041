import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import IFile from '../types/IFile';

export const FILES = gql`
  query Files($filters: FilterInput) {
    files(filters: $filters) {
      id
      name
      metadata
      signedUrl
      objectId
      index
      role
      subRole
      associatedFileId
      path
      masks {
        signedUrl
        id
        index
        label
      }
      labels {
        id
        name
        fileId
        selected
      }
      thumbnails {
        s100x100
        s115x115
        s150x150
        s200x200
      }
      illnessSpots {
        id
        name
        coordinateX
        coordinateY
        index
        cnvGrade
        probability
        fileId
      }
    }
  }
`;

export default (options: QueryHookOptions) => useQuery<{ files: IFile[] }>(FILES, options);
