import { MpFilterProps, useFilterOptions } from '@mp-react/table';
import { Box, Button, Divider, TextField, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Colors from '../../../../infrastructure/constants/Colors';

const SearchIncludeExclude: React.FC<MpFilterProps> = ({
  value,
  setValue,
  column: { mpColumn },
  doFiltering,
}) => {
  const filter = useFilterOptions(mpColumn);

  const { t } = useTranslation();

  return (
    <Box display="flex" flexDirection="column">
      <Box p={2} display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="body2" sx={{ fontWeight: 500 }}>
          {filter?.label}
        </Typography>
        <Button
          onClick={() => doFiltering(null)}
          sx={{
            fontSize: '16px',
            lineHeight: 1,
            textDecoration: 'underline',
            color: Colors.gray,
            fontWeight: 400,
            '&:hover': {
              textDecoration: 'underline',
            },
          }}
          size="small"
        >
          {t('button.clear')}
        </Button>
      </Box>
      <Divider />
      <Box display="flex" flexDirection="column" p={2} gap={2}>
        <TextField
          autoFocus
          sx={{ mt: 1 }}
          size="small"
          label={t('search.search')}
          value={value?.include || ''}
          onChange={(event) => setValue((val) => ({ ...val, include: event.target.value }))}
        />
        <TextField
          size="small"
          label={t('search.exclude')}
          value={value?.exclude || ''}
          onChange={(event) => setValue((val) => ({ ...val, exclude: event.target.value }))}
        />
      </Box>
    </Box>
  );
};

export default SearchIncludeExclude;
