import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import IUser from '../../types/IUser';

interface IGetCurrentUser {
  userProfile: IUser;
}

const GET_CURRENT_USER = gql`
  query userProfile {
    userProfile {
      id
      firstName
      lastName
      email
      root
    }
  }
`;

export default (options?: QueryHookOptions) => useQuery<IGetCurrentUser>(GET_CURRENT_USER, options);
