import LoadingButton from '@mui/lab/LoadingButton';
import { Alert, Box, Button, TextField, Typography } from '@mui/material';
import React, { ChangeEvent, FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PathEnum from '../../infrastructure/constants/PathEnum';
import { useAuth } from '../../infrastructure/context/AuthContext';
import useStateParams from '../../infrastructure/hooks/useStateParams';
import PasswordField from '../../ui/PasswordField';
import ForgotPasswordModal from './ForgotPasswordModal';
import useLoginMutation from './graphql/useLoginMutation';
import { Logo } from '../../infrastructure/assets/images';

interface IStateParams {
  message?: string;
}

const LoginForm: React.FC = () => {
  const { t } = useTranslation();
  const { setToken } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [open, setOpen] = useState(false);
  const state = useStateParams<IStateParams>();
  const navigate = useNavigate();
  const [login, { loading, error }] = useLoginMutation({
    onCompleted: (data) => {
      setToken(data.login);
      navigate(PathEnum.Dashboard);
    },
    onError: () => {},
  });

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    login({ variables: { input: { email, password } } });
  };

  const onEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const onPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleForgotPassword = () => {
    setOpen(true);
  };

  return (
    <>
      <Box component="img" src={Logo} alt="Logo" display="block" mx="auto" width={200} />
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 6 }} autoComplete="on">
        {state && state.message && (
          <Alert severity="success" sx={{ mt: 1 }}>
            {state.message}
          </Alert>
        )}
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          type="email"
          label={t('auth.emailLabel')}
          name="email"
          autoComplete="email"
          inputMode="email"
          autoFocus
          size="small"
          placeholder={t('auth.emailPlaceholder')}
          onChange={onEmailChange}
          value={email}
          error={!!error}
        />
        <PasswordField
          margin="normal"
          required
          fullWidth
          name="password"
          label={t('auth.passwordLabel')}
          placeholder={t('auth.passwordPlaceholder')}
          id="password"
          size="small"
          autoComplete="current-password"
          onChange={onPasswordChange}
          value={password}
          error={!!error}
        />
        {error && (
          <Typography variant="body1" color="error.main" mt={1}>
            {t('auth.wrongPassword')}
          </Typography>
        )}
        <LoadingButton loading={loading} type="submit" fullWidth variant="contained" sx={{ my: 2 }} color="primary">
          {t('auth.loginButtonLabel')}
        </LoadingButton>
        <Box component="div" mx="auto" mt={2} display="block" width="fit-content">
          <Button component="button" onClick={handleForgotPassword}>
            {t('auth.forgotPassword')}
          </Button>
        </Box>
      </Box>
      {open && <ForgotPasswordModal handleClose={handleClose} />}
    </>
  );
};

export default LoginForm;
