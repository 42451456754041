import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import GenericModal from '../../../ui/GenericModal';

interface IProps {
  open: boolean;
  handleClose: () => void;
}

const EditorToolbarInstructionsModal: React.FC<IProps> = ({ open, handleClose }) => {
  const { t } = useTranslation();
  return (
    <GenericModal open={open} onClose={handleClose}>
      <Box sx={{ textAlign: 'center' }}>
        <Typography id="modal-modal-title" variant="h5" component="h2">
          {t('analysis.tollbarIntructionsModal.title')}
        </Typography>
        <Typography
          component="div"
          id="modal-modal-description"
          sx={{
            mt: 1,
            mb: 2,
            maxWidth: 350,
            mx: 'auto',
            textAlign: 'left',
          }}
          variant="body2"
        >
          <ul>
            <li>{t('analysis.tollbarIntructionsModal.instruction1')}</li>
            <li>{t('analysis.tollbarIntructionsModal.instruction2')}</li>
            <li>{t('analysis.tollbarIntructionsModal.instruction3')}</li>
            <li>{t('analysis.tollbarIntructionsModal.instruction4')}</li>
            <li>{t('analysis.tollbarIntructionsModal.instruction5')}</li>
            <li>{t('analysis.tollbarIntructionsModal.instruction6')}</li>
            <li>{t('analysis.tollbarIntructionsModal.instruction7')}</li>
            <li>{t('analysis.tollbarIntructionsModal.instruction8')}</li>
          </ul>
        </Typography>

        <Box display="flex" justifyContent="center" mt={2}>
          <Button onClick={handleClose} variant="contained">
            {t('button.ok')}
          </Button>
        </Box>
      </Box>
    </GenericModal>
  );
};

export default EditorToolbarInstructionsModal;
