import { LoadingButton } from '@mui/lab';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Colors from '../../infrastructure/constants/Colors';
import GenericModal from '../../ui/GenericModal';

interface IProps {
  open: boolean;
  loading?: boolean;
  onDelete: () => void;
  handleClose: () => void;
}

const DeleteAnalysisModal: React.FC<IProps> = ({ open, loading, onDelete, handleClose }) => {
  const { t } = useTranslation();

  return (
    <GenericModal open={open} onClose={handleClose}>
      <Box sx={{ textAlign: 'center' }}>
        <Typography id="modal-modal-title" variant="h5" component="h2">
          {t('study.deleteAnalysisModal.title')}
        </Typography>
        <Typography
          id="modal-modal-description"
          sx={{ mt: 1, color: Colors.textSecondary, maxWidth: 350, mx: 'auto' }}
          variant="body2"
        >
          {t('study.deleteAnalysisModal.description')}
        </Typography>

        <Box display="flex" justifyContent="space-between" mt={2}>
          <LoadingButton onClick={handleClose} variant="contained" color="secondary">
            {t('button.cancel')}
          </LoadingButton>
          <LoadingButton
            disabled={loading}
            loading={loading}
            onClick={onDelete}
            variant="contained"
          >
            {t('button.delete')}
          </LoadingButton>
        </Box>
      </Box>
    </GenericModal>
  );
};

export default DeleteAnalysisModal;
