import { gql, useMutation } from '@apollo/client';
import IAnalysis from '../../studies/types/IAnalysis';
import IAnalysisDetails from '../types/IAnalysisDetails';

interface ICreateAnalysisInput {
  input: IAnalysisDetails;
}

interface ICreateAnalysisResponse {
  createAnalysis: IAnalysis;
}

const CREATE_ANALYSIS = gql`
  mutation createAnalysis($input: CreateAnalysisInput!) {
    createAnalysis(input: $input) {
      id
      interface
    }
  }
`;

export default (options: { onError: (apolloError) => void; onCompleted: (response) => void }) => {
  return useMutation<ICreateAnalysisResponse, ICreateAnalysisInput>(CREATE_ANALYSIS, options);
};
