import ImageEditorConfig from '../constants/ImageEditor.config';
import ICoordinates from '../types/ICoordinates';

const centerCoords = (coords: ICoordinates, height: number) => {
  const lineHeight = height / ImageEditorConfig.VIP_IMAGES_COUNT;
  const startLineY = coords.y - (coords.y % lineHeight);
  const startLineX = coords.x - (coords.x % lineHeight);
  const centerY = startLineY + lineHeight / 2;
  const centerX = startLineX + lineHeight / 2;
  return { x: centerX, y: centerY };
};

export default centerCoords;
