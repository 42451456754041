import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import BackToStudyButton from '../../domain/upload/BackToStudyButton';
import { UploadProvider, UploadTypeEnum } from '../../domain/upload/context/UploadContext';
import UploadInner from '../../domain/upload/UploadInner';
import MainContainer from '../../ui/MainContainer';
import Header from '../layout/Header/Header';

const UploadPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Header />
      <MainContainer>
        <UploadProvider type={UploadTypeEnum.STUDY}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Box position="relative" display="flex" justifyContent="center" alignItems="center">
                <BackToStudyButton />
                <Typography textAlign="center" variant="h4">
                  {t('study.uploadFiles')}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <UploadInner />
            </Grid>
          </Grid>
        </UploadProvider>
      </MainContainer>
    </>
  );
};

export default UploadPage;
