import { Box, Button, Divider, Tooltip } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { CheckedIcon, UncheckedIcon } from '../../infrastructure/assets/icons';
import Colors from '../../infrastructure/constants/Colors';
import AnalysisInterfaceEnum from '../../infrastructure/types/AnalysisInterfaceEnum';
import IFileRoleEnum from '../../infrastructure/types/IFileRoleEnum';
import IFileSubRoleEnum from '../../infrastructure/types/IFileSubRoleEnum';
import { FilterTypeEnum } from '../../infrastructure/types/IFilterInput';
import FullScreenLoader from '../../ui/FullScreenLoader';
import Image from '../../ui/Image';
import { getBorder } from '../../utils/Common';
import useFiles from '../analysis/graphql/useFiles';

interface Props {
  selectedImagesIds: string[];
  setSelectedImagesIds: (ids: string[]) => void;
  interfaceModel: AnalysisInterfaceEnum;
}

const ImagesSelector: React.FC<Props> = ({
  selectedImagesIds,
  setSelectedImagesIds,
  interfaceModel,
}) => {
  const { t } = useTranslation();
  const [lastSelectedImageId, setLastSelectedImageId] = useState<string | null>(null);

  const { analysisId } = useParams();

  const { data: filesData, loading: filesLoading } = useFiles({
    variables: {
      filters: {
        filters: [
          { field: 'analysisId', type: FilterTypeEnum.IN, args: [analysisId] },
          {
            field: 'role',
            type: FilterTypeEnum.IN,
            args: [IFileRoleEnum.ANALYSIS_RESULT],
          },
          ...(interfaceModel === AnalysisInterfaceEnum.MODEL_4
            ? [{ field: 'subRole', type: FilterTypeEnum.IN, args: [IFileSubRoleEnum.OCT_VIP] }]
            : []),
        ],
      },
    },
  });

  const files = useMemo(() => filesData?.files, [filesData?.files]);

  const filterUnique = (value: string, index: number, self: string[]) => {
    return self.indexOf(value) === index;
  };

  const handleClick = useCallback(
    (id: string) => (e: React.MouseEvent<HTMLDivElement>) => {
      const index = selectedImagesIds.indexOf(id);
      if (index !== -1) {
        setSelectedImagesIds([...selectedImagesIds].filter((el) => el !== id));
        setLastSelectedImageId(null);
        return;
      }
      if (e.shiftKey && files) {
        const lastSelectedIndex = files.findIndex((el) => el.id === lastSelectedImageId);
        const currentSelectedIndex = files.findIndex((el) => el.id === id);
        const min = Math.min(lastSelectedIndex, currentSelectedIndex);
        const max = Math.max(lastSelectedIndex, currentSelectedIndex);
        const selected = files.slice(min, max + 1).map((el) => el.id);
        setSelectedImagesIds([...selectedImagesIds, ...selected].filter(filterUnique));
        setLastSelectedImageId(id);
        return;
      }
      setLastSelectedImageId(id);
      setSelectedImagesIds([...selectedImagesIds, id].filter(filterUnique));
    },
    [selectedImagesIds, files, setLastSelectedImageId, lastSelectedImageId, setSelectedImagesIds],
  );

  const selectAllImages = useCallback(() => {
    const selectedAll = files?.map((el) => el.id) || [];
    setSelectedImagesIds([...selectedAll]);
  }, [files]);

  const deselectAllImages = useCallback(() => {
    setSelectedImagesIds([]);
  }, []);

  if (filesLoading || !files) {
    return <FullScreenLoader />;
  }

  return (
    <>
      <Toolbar>
        <Box width="100%" display="flex" justifyContent="space-between">
          <Typography variant="h5">{t('reports.selectImagesToAnalyse')}</Typography>
          <Box>
            <Button onClick={selectAllImages}>{t('reports.selectAllImages')}</Button>
            <Button onClick={deselectAllImages}>{t('button.discard')}</Button>
          </Box>
        </Box>
      </Toolbar>
      <Divider />
      <Box p={3} sx={{ flexGrow: 1, overflowY: 'auto' }}>
        {/*images here*/}
        <Box display="grid" gridTemplateColumns="1fr 1fr 1fr 1fr 1fr" gap={2}>
          {files.map((file, key) => (
            <Box
              key={file.id}
              position="relative"
              mb={1}
              pb={3}
              sx={{ cursor: 'pointer' }}
              onClick={handleClick(file.id)}
            >
              <Box
                position="relative"
                width="100%"
                height={130}
                pb={4}
                border={getBorder(Colors.default)}
              >
                <Image
                  height={130}
                  width="100%"
                  src={file.signedUrl}
                  alt={file.name}
                  style={{
                    ...(!selectedImagesIds.includes(file.id) && {
                      opacity: 0.8,
                    }),
                  }}
                />
                <Box position="absolute" top="4px" left="4px">
                  {selectedImagesIds.includes(file.id) ? <CheckedIcon /> : <UncheckedIcon />}
                </Box>
              </Box>
              <Box width="100%" position="absolute" bottom={0}>
                <Tooltip title={`${key + 1}. ${file.name}`}>
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: '12px',
                      lineHeight: '18px',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    }}
                  >
                    {`${key + 1}. ${file.name}`}
                  </Typography>
                </Tooltip>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </>
  );
};

export default ImagesSelector;
