import IFolderStructure from '../../types/IFolderStructure';

const findNextPathFromFolderStructure = (
  data: IFolderStructure[],
  path: string,
): IFolderStructure | undefined => {
  let current;
  let next;

  function iter(a: IFolderStructure) {
    if (a.fileCount > 0) {
      if (current) {
        // set next path after the current path is found
        next = a;
        return true;
      }
      if (a.objectId === path) {
        current = a;
      }
    }
    return Array.isArray(a.subFolders) && a.subFolders.some(iter);
  }

  data.some(iter);
  return next;
};

export default findNextPathFromFolderStructure;
