import { MpColumn, MpColumnFormat, MpColumnRender, MpFilterType } from '@mp-react/table';
import { useTranslation } from 'react-i18next';

const useAnalysisColumns = (): MpColumn[] => {
  const { t } = useTranslation();

  return [
    {
      label: t('study.analysisName'),
      key: 'name',
      filter: {
        type: MpFilterType.select,
        label: t('study.analysisName'),
      },
    },
    {
      label: t('studies.model'),
      key: 'aiModel.model',
      filter: {
        type: MpFilterType.select,
        label: t('studies.model'),
      },
    },
    {
      label: t('studies.status'),
      key: 'statusTranslation',
      filter: {
        type: MpFilterType.select,
        label: t('studies.status'),
      },
    },
    {
      label: t('studies.creationDate'),
      key: 'creationDate',
      format: {
        type: MpColumnFormat.dateTime,
        modifier: 'HH:mm',
      },
    },
    {
      label: '',
      key: 'id',
      sort: false,
      render: MpColumnRender.button,
      width: 70,
      action: {
        slug: 'deleteAnalysis',
        color: 'error',
        label: 'delete',
        icon: 'delete',
      },
    },
  ];
};

export default useAnalysisColumns;
