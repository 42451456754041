import AnalysisInterfaceEnum from '../../../infrastructure/types/AnalysisInterfaceEnum';

const imageEditorCases = [
  AnalysisInterfaceEnum.MODEL_1,
  AnalysisInterfaceEnum.MODEL_2,
  AnalysisInterfaceEnum.MODEL_3,
  AnalysisInterfaceEnum.MODEL_4,
];
const sliceSelectorCases = [AnalysisInterfaceEnum.MODEL_4];

export const shouldShowImageEditor = (caseNumber: AnalysisInterfaceEnum): boolean => {
  return imageEditorCases.includes(caseNumber);
};

export const shouldShowSliceSelector = (caseNumber: AnalysisInterfaceEnum): boolean => {
  return sliceSelectorCases.includes(caseNumber);
};

export const shouldShowRearrangeImages = (caseNumber: AnalysisInterfaceEnum): boolean => {
  return caseNumber === AnalysisInterfaceEnum.MODEL_5;
};

export const shouldShowUseCaseSix = (caseNumber: AnalysisInterfaceEnum): boolean => {
  return caseNumber === AnalysisInterfaceEnum.MODEL_6;
};

export const shouldShowImageLabelling = (caseNumber: AnalysisInterfaceEnum): boolean => {
  return caseNumber === AnalysisInterfaceEnum.MODEL_7;
};

export default { shouldShowImageEditor, shouldShowImageLabelling };
