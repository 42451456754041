import { useMemo } from 'react';
import useStore from '../../../../infrastructure/store/useStore';
import useEditLabelSelection from '../../graphql/useEditLabelSelection';
import { FILES } from '../../graphql/useFiles';
import ILabel from '../../types/ILabel';

const useLabelsSaveDiscard = (handleClose: () => void) => {
  const [selectedLabels, selectedImageFile, setSelectedLabels, setSelectedImageFile] = useStore(
    (state) => [
      state.selectedLabels,
      state.selectedImageFile,
      state.setSelectedLabels,
      state.setSelectedImageFile,
    ],
  );
  const [mutateLabels, { loading: saveLoading }] = useEditLabelSelection({
    refetchQueries: [FILES],
  });

  const onSave = () => {
    if (!selectedImageFile) return null;
    mutateLabels({
      variables: {
        input: selectedImageFile.labels.map((label) => ({
          id: label.id,
          selected: selectedLabels.includes(label.id),
        })),
        fileId: selectedImageFile.id,
      },
      onCompleted: (data) => {
        const labels: ILabel[] = data.editLabelSelection;
        setSelectedImageFile({
          ...selectedImageFile,
          labels: labels.sort((a, b) => a.name.localeCompare(b.name)),
        });
        handleClose();
      },
    });
  };

  const onDiscard = () => {
    if (!selectedImageFile) return;
    const labels = selectedImageFile.labels
      .filter((label) => label.selected)
      .map((label) => label.id);
    setSelectedLabels(labels);
    handleClose();
  };

  const hasChanges = useMemo(() => {
    if (!selectedImageFile) return false;
    const labels = selectedImageFile.labels
      .filter((label) => label.selected)
      .map((label) => label.id);
    if (labels.length !== selectedLabels.length) return true;
    return labels.some((label) => !selectedLabels.includes(label));
  }, [selectedImageFile, selectedLabels]);

  return { onSave, onDiscard, saveLoading, hasChanges };
};

export default useLabelsSaveDiscard;
