import { DeleteOutline, Download, ExpandLess, ExpandMore } from '@mui/icons-material';
import SummarizeIcon from '@mui/icons-material/Summarize';
import {
  Box,
  CircularProgress,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItemButton,
  Typography,
} from '@mui/material';
import moment from 'moment';
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { FileIcon } from '../../infrastructure/assets/icons';
import Colors from '../../infrastructure/constants/Colors';
import { FilterTypeEnum } from '../../infrastructure/types/IFilterInput';
import FullScreenLoader from '../../ui/FullScreenLoader';
import useReports from '../reports/graphql/useReports';
import useDeleteReport from '../reports/graphql/useDeleteReport';
import DeleteReportModal from '../reports/DeleteReportModal';
import { ReportStatusEnum } from '../reports/types/IReport';
import { REPORT_FILES_SUBSCRIPTION } from '../reports/graphql/useReportFilesSubscription';
import updateReportsQuery from '../reports/helpers/updateReportsQuery';

const ReportsTable: React.FC = () => {
  const { studyId } = useParams<{ studyId: string }>();
  const { t } = useTranslation();
  const [deleteModalId, setDeleteModalId] = useState<string | null>(null);

  const { data, loading, refetch, subscribeToMore } = useReports({
    variables: {
      filters: {
        filters: [{ field: 'studyId', type: FilterTypeEnum.IN, args: [studyId] }],
      },
    },
    fetchPolicy: 'network-only',
  });

  const [deleteReport, { loading: deleteLoading, client }] = useDeleteReport({
    onCompleted: () => {
      client.resetStore();
      setDeleteModalId(null);
      refetch();
    },
    onError: () => {},
  });

  const reports = useMemo(() => data?.analysisReports || [], [data]);

  const lastAddedInProgressReportId = useMemo(
    () => reports.find((report) => report.status === ReportStatusEnum.IN_PROGRESS)?.id,
    [reports],
  );

  useEffect(() => {
    if (lastAddedInProgressReportId) {
      subscribeToMore({
        document: REPORT_FILES_SUBSCRIPTION,
        variables: {
          reportId: lastAddedInProgressReportId,
        },
        updateQuery: updateReportsQuery(lastAddedInProgressReportId),
      });
    }
  }, [lastAddedInProgressReportId]);

  const [open, setOpen] = useState<string[]>([]);

  const handleOpen = useCallback(
    (id: string) => {
      const index = open.indexOf(id);
      if (index !== -1) {
        const aux = [...open];
        aux.splice(index, 1);
        setOpen([...aux]);
      } else {
        setOpen([...open, id]);
      }
    },
    [open],
  );

  const openDeleteModal = useCallback(
    (id: string) => (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      setDeleteModalId(id);
    },
    [setDeleteModalId],
  );

  const closeDeleteModal = useCallback(() => {
    setDeleteModalId(null);
  }, [setDeleteModalId]);

  const onDelete = useCallback(() => {
    if (!deleteModalId) {
      return;
    }
    deleteReport({ variables: { id: deleteModalId } });
  }, [deleteModalId]);

  if (loading) {
    return <FullScreenLoader />;
  }

  if (reports.length === 0) {
    return (
      <Typography color={Colors.gray} sx={{ mt: 10 }} textAlign="center">
        {t('study.noReportsFound')}
      </Typography>
    );
  }

  return (
    <>
      <DeleteReportModal
        open={Boolean(deleteModalId)}
        loading={deleteLoading}
        handleClose={closeDeleteModal}
        onDelete={onDelete}
      />
      <Box>
        <Box pl={4} py={2} display="flex" alignItems="center">
          <Typography variant="body1">{t('upload.filesName')}</Typography>
        </Box>
        <Divider />
        <List disablePadding>
          {reports.map((report) => (
            <Fragment key={report.id}>
              <ListItemButton divider onClick={() => handleOpen(report.id)}>
                {!!report.files?.length &&
                  (open.includes(report.id) ? <ExpandLess /> : <ExpandMore />)}
                {!report.files?.length && <Box width={25} />}
                <Box display="flex" width="100%" pl={1}>
                  <SummarizeIcon fontSize="large" />
                  <Box ml={1}>
                    <Typography variant="body2" sx={{ wordBreak: 'break-word', pr: 1 }}>
                      {report.name}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: '12px',
                        lineHeight: '18px',
                        color: Colors.textPrimary,
                      }}
                    >
                      {moment(report.creationDate).format('YYYY-MM-DD')}
                    </Typography>
                  </Box>
                </Box>

                {report.status === ReportStatusEnum.IN_PROGRESS && (
                  <Box sx={{ mr: 1 }}>
                    <CircularProgress size={24} />
                  </Box>
                )}
                {report.status !== ReportStatusEnum.IN_PROGRESS && (
                  <IconButton onClick={openDeleteModal(report.id)}>
                    <DeleteOutline />
                  </IconButton>
                )}
              </ListItemButton>
              <Collapse timeout="auto" in={!!report.files?.length && open.includes(report.id)}>
                <List component="div" disablePadding>
                  {report.files?.map((file) => (
                    <ListItemButton divider disableGutters sx={{ pl: 2, ml: 4 }} key={file.id}>
                      <Box display="flex" alignItems="center" width="100%">
                        <FileIcon />
                        <Box ml={1} flexGrow={1}>
                          <Typography variant="body2">{file.name}</Typography>
                        </Box>
                        <IconButton
                          aria-label="download"
                          onClick={() => {
                            if (file.signedUrl) {
                              window.location.assign(file.signedUrl);
                            }
                          }}
                          sx={{ mr: 1 }}
                        >
                          <Download />
                        </IconButton>
                      </Box>
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
            </Fragment>
          ))}
        </List>
      </Box>
    </>
  );
};

export default ReportsTable;
