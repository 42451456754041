import IAnalysisReorderInput from '../types/IAnalysisReorderInput';
import IFile from '../types/IFile';

interface IAnalysisRearrangeImages {
  items: (IFile[] | undefined)[];
  parsedFiles: (IFile[] | undefined)[];
  setItems: (items: (IFile[] | undefined)[]) => void;
  setParsedFiles: (items: (IFile[] | undefined)[]) => void;
  hasChanges: boolean;
  setHasChanges: (val: boolean) => void;
  onNextPath: () => void;
  onPrevPath: () => void;
  rearrangedItems: IAnalysisReorderInput[];
  setRearrangedItems: (
    newState: (prevValue: IAnalysisReorderInput[]) => IAnalysisReorderInput[],
  ) => void;
  resetRearrangeItems: () => void;
  folderPath: string | undefined;
  folderPathPrev: string | undefined;
  folderPathNext: string | undefined;
  setFolderPath: (params: { path?: string; pathPrev?: string; pathNext?: string }) => void;
}

const initialState = {
  items: [],
  parsedFiles: [],
  hasChanges: false,
  rearrangedItems: [],
  folderPath: undefined,
  folderPathPrev: undefined,
  folderPathNext: undefined,
};

const createAnalysisRearrangeImages = (set, get): IAnalysisRearrangeImages => ({
  ...initialState,
  setItems: (items) => set({ items }),
  setParsedFiles: (parsedFiles) => set({ parsedFiles }),
  setHasChanges: (val) => set({ hasChanges: val }),
  setRearrangedItems: (fn) => set({ rearrangedItems: fn(get().rearrangedItems) }),
  setFolderPath: ({ path, pathNext, pathPrev }) => {
    set({
      folderPath: path,
      folderPathNext: pathNext,
      folderPathPrev: pathPrev,
    });
  },
  onNextPath: () => {
    if (get().folderPathNext) {
      set({ folderPath: get().folderPathNext });
    }
  },
  onPrevPath: () => {
    if (get().folderPathPrev) {
      set({ folderPath: get().folderPathPrev });
    }
  },
  resetRearrangeItems: () => {
    set(initialState);
  },
});

export default createAnalysisRearrangeImages;
