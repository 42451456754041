// eslint-disable-next-line import/prefer-default-export
import NameAndPath from './NameAndPath/NameAndPath';
import PrettyBytes from './PrettyBytes/PrettyBytes';

export enum CustomRendererType {
  nameAndPath = 'nameAndPath',
  prettyBytes = 'prettyBytes',
}

export const customRenderersMap = {
  [CustomRendererType.nameAndPath]: NameAndPath,
  [CustomRendererType.prettyBytes]: PrettyBytes,
};
