import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Typography } from '@mui/material';
import moment from 'moment';
import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PathEnum from '../../infrastructure/constants/PathEnum';
import IInternalError from '../../infrastructure/types/IInternalError';
import isServerError from '../../infrastructure/utils/isServerError';
import PasswordField from '../../ui/PasswordField';
import useResetPassword from './graphql/useResetPassword';

interface Props {
  isNewUser?: boolean;
}

const ResetPasswordForm: React.FC<Props> = ({ isNewUser }) => {
  const { t } = useTranslation();
  const [newPassword, setNewPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const resetToken = searchParams.get('resetToken');
  const [error, setError] = useState<null | IInternalError>(null);
  const [resetPassword, { loading }] = useResetPassword({
    onCompleted: (success) => {
      if (success) {
        navigate(PathEnum.Login, {
          state: {
            message: isNewUser ? t('auth.createPasswordSuccess') : t('auth.resetPasswordSuccess'),
          },
        });
      }
    },
    onError: ({ networkError }) => {
      if (networkError) {
        if (isServerError(networkError)) {
          setError({
            date: moment().format('YYYY-MM-DD HH:mm'),
            statusCode: networkError.statusCode,
          });
          return;
        }
        setError({
          date: moment().format('YYYY-MM-DD HH:mm'),
          context: 'dateOnly',
        });
      }
    },
  });

  useEffect(() => {
    if (!resetToken) {
      navigate(PathEnum.Login);
    }
  }, [resetToken]);

  const passwordsDontMatch = Boolean(newPassword) && newPassword !== repeatPassword;

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    setError(null);
    if (passwordsDontMatch) {
      return;
    }
    resetPassword({ variables: { input: { resetToken, newPassword } } });
  };

  const onNewPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNewPassword(e.target.value);
  };

  const onRepeatPassword = (e: ChangeEvent<HTMLInputElement>) => {
    setRepeatPassword(e.target.value);
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      noValidate
      sx={{ mt: '14px', textAlign: 'center' }}
    >
      <PasswordField
        margin="normal"
        required
        fullWidth
        name="new-password"
        label={t('auth.newPasswordLabel')}
        placeholder={t('auth.newPasswordPlaceholder')}
        id="new-password"
        size="small"
        onChange={onNewPasswordChange}
        value={newPassword}
      />
      <PasswordField
        margin="normal"
        required
        fullWidth
        error={passwordsDontMatch}
        helperText={passwordsDontMatch && t('auth.passwordsDoNotMatch')}
        name="repeat-password"
        label={t('auth.repeatPasswordLabel')}
        placeholder={t('auth.repeatPasswordPlaceholder')}
        id="repeat-password"
        size="small"
        onChange={onRepeatPassword}
        value={repeatPassword}
      />
      {error && (
        <Typography variant="body1" color="error.main" mt={1}>
          {t('general.unknownError', {
            date: error.date,
            statusCode: error.statusCode,
            context: error.context,
          })}
        </Typography>
      )}
      <LoadingButton loading={loading} type="submit" variant="contained" sx={{ mt: '22px' }}>
        {isNewUser ? t('auth.createPasswordButtonLabel') : t('auth.resetPasswordButtonLabel')}
      </LoadingButton>
    </Box>
  );
};

export default ResetPasswordForm;
