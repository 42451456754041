import { NavigateBefore } from '@mui/icons-material';
import { Box, Button, Grid, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, Link, useParams } from 'react-router-dom';
import ReportsInner from '../../domain/reports/ReportsInner';
import PathEnum from '../../infrastructure/constants/PathEnum';
import MainContainer from '../../ui/MainContainer';
import Header from '../layout/Header/Header';
import { UploadTypeEnum, UploadProvider } from '../../domain/upload/context/UploadContext';

const ReportsPage: React.FC = () => {
  const { t } = useTranslation();
  const { analysisId } = useParams();

  return (
    <>
      <Header />
      <MainContainer>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Box position="relative" display="flex" justifyContent="center" alignItems="center">
              <Box position="absolute" left={0}>
                <Button component={Link} to={generatePath(PathEnum.Analysis, { analysisId })}>
                  <NavigateBefore />
                  {t('analysis.backToAnalysis')}
                </Button>
              </Box>
              <Typography textAlign="center" variant="h4">
                {t('analysis.generateReport')}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <UploadProvider type={UploadTypeEnum.STUDY}>
              <ReportsInner />
            </UploadProvider>
          </Grid>
        </Grid>
      </MainContainer>
    </>
  );
};

export default ReportsPage;
