import { LoadingButton } from '@mui/lab';
import { Box, Checkbox, FormControlLabel, TextField, Typography } from '@mui/material';
import moment from 'moment';
import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckedIcon } from '../../infrastructure/assets/icons';
import Colors from '../../infrastructure/constants/Colors';
import IInternalError from '../../infrastructure/types/IInternalError';
import IUser from '../../infrastructure/types/IUser';
import isServerError from '../../infrastructure/utils/isServerError';
import useUpdateUserProfileMutation from './graphql/useUpdateUserProfileMutation';
import useUserProfileQuery from './graphql/useUserProfileQuery';

const ProfileForm: React.FC = () => {
  const { t } = useTranslation();

  const [submitted, setSubmitted] = useState(false);
  const [wasChange, setWasChange] = useState(false);
  const [mutateError, setError] = useState<null | IInternalError>(null);
  const [user, setUser] = useState<IUser>({
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    emailNotifications: false,
  });

  const [mutateUser, { loading: mutateLoading }] = useUpdateUserProfileMutation({
    onCompleted: () => {
      setSubmitted(true);
      setWasChange(false);
    },
    onError: ({ networkError }) => {
      if (networkError) {
        if (isServerError(networkError)) {
          setError({
            date: moment().format('YYYY-MM-DD HH:mm'),
            statusCode: networkError.statusCode,
          });
          return;
        }
        setError({
          date: moment().format('YYYY-MM-DD HH:mm'),
          context: 'dateOnly',
        });
      }
    },
  });

  const handleSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      setSubmitted(false);
      setError(null);
      mutateUser({
        variables: {
          firstName: user.firstName,
          lastName: user.lastName,
          emailNotifications: user.emailNotifications,
        },
      });
    },
    [user, mutateUser],
  );

  const { loading, error, data } = useUserProfileQuery();

  useEffect(() => {
    if (!loading && !error && data) {
      setUser(data.userProfile);
    }
  }, [loading, error, data]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!user.id) {
        return;
      }
      setWasChange(true);
      const { name, value } = event.target;

      setUser({
        ...user,
        [name]: value,
      });
    },
    [user],
  );

  const handleToggleEmailNotifications = useCallback(() => {
    if (!user.id) {
      return;
    }
    setWasChange(true);
    setUser({
      ...user,
      emailNotifications: !user.emailNotifications,
    });
  }, [user]);

  const { emailNotifications, email, firstName, lastName } = user;

  return (
    <>
      <Box width={360} display="flex" flexDirection="column" alignItems="center">
        <Typography variant="h4">{t('profile.accountSettings')}</Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 6 }}>
          <TextField
            margin="normal"
            fullWidth
            id="firstName"
            type="text"
            label={t('profile.firstName')}
            name="firstName"
            size="small"
            onChange={handleChange}
            value={firstName}
          />
          <TextField
            margin="normal"
            fullWidth
            id="lastName"
            type="text"
            label={t('profile.lastName')}
            name="lastName"
            size="small"
            onChange={handleChange}
            value={lastName}
          />
          <TextField
            margin="normal"
            fullWidth
            id="email"
            type="email"
            label={t('profile.email')}
            name="email"
            size="small"
            onChange={handleChange}
            value={email}
            disabled
          />
          <FormControlLabel
            sx={{
              mt: 1,
            }}
            name="emailNotifications"
            checked={emailNotifications}
            onChange={handleToggleEmailNotifications}
            control={<Checkbox />}
            label={t('profile.receiveEmailNotifications')}
          />
          {mutateError && (
            <Typography variant="body1" color="error.main" mt={1}>
              {t('general.unknownError', {
                date: mutateError.date,
                statusCode: mutateError.statusCode,
                context: mutateError.context,
              })}
            </Typography>
          )}
          <LoadingButton
            loading={mutateLoading}
            disabled={!wasChange}
            type="submit"
            fullWidth
            variant="contained"
            sx={{ my: 2 }}
            color="primary"
          >
            {t('profile.saveChanges')}
          </LoadingButton>
        </Box>
      </Box>
      {submitted && (
        <Box position="relative" width="100%" display="flex" justifyContent="center">
          <Box position="absolute" textAlign="center" display="flex" mt={2}>
            <CheckedIcon />
            <Typography component="span" color={Colors.green}>
              <Box ml={1} component="span" fontWeight={500}>
                {t('profile.changesSaved')}
              </Box>
              {'. '}
              {t('profile.yourProfileSuccessfullyUpdated')}
            </Typography>
          </Box>
        </Box>
      )}
    </>
  );
};

export default ProfileForm;
