import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import IReport from '../types/IReport';

const CREATE_REPORT = gql`
  mutation createReport($input: CreateAnalysisReportInput!) {
    createReport(input: $input) {
      id
      name
      status
      error
      creationDate
      files {
        id
        name
        path
        signedUrl
      }
    }
  }
`;

export default (options: MutationHookOptions) => {
  return useMutation<{ createReport: IReport }>(CREATE_REPORT, options);
};
