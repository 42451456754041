import { Box, Card, Collapse, Typography } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Colors from '../../infrastructure/constants/Colors';
import PathEnum from '../../infrastructure/constants/PathEnum';
import useGetAnalysesLazyQuery from './hooks/useGetAnalysesLazyQuery';
import useGetStudiesLazyQuery from './hooks/useGetStudiesLazyQuery';
import SearchIcon from './SearchIcon';
import SearchInput from './SearchInput';
import SearchResults from './SearchResults';

const SearchBox: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { performSearchStudies, studies, loading: loadingStudies } = useGetStudiesLazyQuery();
  const { performSearchAnalyses, analyses, loading: loadingAnalyses } = useGetAnalysesLazyQuery();

  const [open, setOpen] = useState(false);

  const [value, setValue] = useState('');

  useEffect(() => {
    performSearchStudies(value);
    performSearchAnalyses(value);
  }, [value]);

  const handleChange = (val: string) => {
    setValue(val);
  };

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setValue('');
    setOpen(false);
  };

  const handleClickStudy = useCallback(
    (id: string) => {
      navigate(`${PathEnum.Studies}/${id}`);
    },
    [navigate],
  );

  const handleClickAnalysis = useCallback(
    (id: string) => {
      // todo: use path from PathEnum
      navigate(`/analysis/${id}`);
    },
    [navigate],
  );

  const showResults = useMemo(
    () => open && !!value && !loadingStudies && !loadingAnalyses && !!studies && !!analyses,
    [loadingStudies, studies, value, open, loadingAnalyses, analyses],
  );

  const notFound = useMemo(
    () => !(analyses.length > 0 || studies.length > 0),
    [analyses.length, studies.length],
  );

  return (
    <Box display="flex" alignItems="center" position="relative">
      <SearchIcon show={!open} handleClick={handleClick} />
      <SearchInput open={open} value={value} onChange={handleChange} onClose={handleClose} />
      <Collapse in={showResults} timeout={200} unmountOnExit>
        <Box position="absolute" right={0} top={24} width="300px" zIndex={10}>
          <Card>
            <Box py={2} maxHeight={500} overflow="auto">
              <SearchResults
                name={t('search.study')}
                items={studies}
                handleClick={handleClickStudy}
              />
              <SearchResults
                name={t('search.analysis')}
                items={analyses}
                handleClick={handleClickAnalysis}
              />
              {notFound && (
                <Typography color={Colors.textSecondary} textAlign="center" variant="body2">
                  {t('search.nothingFound')}
                </Typography>
              )}
            </Box>
          </Card>
        </Box>
      </Collapse>
    </Box>
  );
};

export default SearchBox;
