import { ServerError, ServerParseError } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Box, TextField, Typography } from '@mui/material';
import { GraphQLError } from 'graphql';
import moment from 'moment';
import React, { FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import IInternalError from '../../infrastructure/types/IInternalError';
import isServerError from '../../infrastructure/utils/isServerError';
import validateEmail from '../../infrastructure/utils/valideEmail';
import GenericModal from '../../ui/GenericModal';
import useCreateUserMutation from './graphql/useCreateUserMutation';
import { GET_USERS } from './graphql/useUsersQuery';

interface IProps {
  handleClose: () => void;
}

interface IGraphQLError extends GraphQLError {
  subCode?: number;
  values?: Record<string, string>;
}

const NewUserModal: React.FC<IProps> = ({ handleClose }) => {
  const { t } = useTranslation();
  const [submitted, setSubmitted] = useState(false);

  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState<null | IInternalError>(null);

  const [emailValidator, setEmailValidator] = useState<string>();

  const [mutateUser, { loading }] = useCreateUserMutation({
    onCompleted: () => {
      handleClose();
    },
    onError: ({
      graphQLErrors,
      networkError,
    }: {
      graphQLErrors: ReadonlyArray<IGraphQLError>;
      networkError: Error | ServerParseError | ServerError | null;
    }) => {
      const emailError = graphQLErrors.find((err) => {
        return Object.keys(err.values || {}).includes('email');
      });
      if (emailError) {
        setEmailValidator(emailError.message);
        return;
      }
      if (networkError) {
        if (isServerError(networkError)) {
          setError({
            date: moment().format('YYYY-MM-DD HH:mm'),
            statusCode: networkError.statusCode,
          });
          return;
        }
        setError({
          date: moment().format('YYYY-MM-DD HH:mm'),
          context: 'dateOnly',
        });
      }
    },
  });

  const onFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(e.target.value);
  };

  const onLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(e.target.value);
  };

  const onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    setError(null);
    setSubmitted(true);
    if (!validateEmail(email)) {
      setEmailValidator('Email is not valid');
    }
    if (!firstName || !lastName || !email) {
      return false;
    }
    mutateUser({
      variables: {
        input: {
          firstName,
          lastName,
          email,
        },
      },
      refetchQueries: [GET_USERS],
    });
  };

  return (
    <GenericModal open onClose={handleClose}>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography id="modal-modal-title" variant="h5" component="h2">
          {t('users.createUser')}
        </Typography>
        <Box width="100%" component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 2 }}>
          <TextField
            margin="normal"
            fullWidth
            id="firstName"
            type="text"
            label={t('profile.firstName')}
            name="firstName"
            inputMode="text"
            size="small"
            onChange={onFirstNameChange}
            value={firstName}
            error={submitted && !firstName}
          />
          <TextField
            margin="normal"
            fullWidth
            id="lastName"
            type="text"
            label={t('profile.lastName')}
            name="lastName"
            inputMode="text"
            size="small"
            onChange={onLastNameChange}
            value={lastName}
            error={submitted && !lastName}
          />
          <TextField
            margin="normal"
            fullWidth
            id="email"
            type="text"
            label={t('profile.email')}
            name="email"
            inputMode="text"
            size="small"
            onChange={onEmailChange}
            value={email}
            error={submitted && (!email || !!emailValidator)}
            helperText={emailValidator}
          />
          {error && !emailValidator && (
            <Typography variant="body1" color="error.main" mt={1}>
              {t('general.unknownError', {
                date: error.date,
                statusCode: error.statusCode,
                context: error.context,
              })}
            </Typography>
          )}
          <Box mt={3} textAlign="center">
            <LoadingButton
              loading={loading}
              type="submit"
              variant="contained"
              disabled={!email || !firstName || !lastName}
            >
              {t('button.create')}
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </GenericModal>
  );
};

export default NewUserModal;
