const getColumnStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  height: 'fit-content',
  minHeight: '100px',
  margin: '2px',
  border: '1px solid #dacccc',
  padding: 0,
  borderRadius: 4,

  // change background colour if dragging
  background: isDragging ? 'rgba(0, 0, 0, 0.12)' : 'white',

  // styles we need to apply on draggables
  ...draggableStyle,
});

export default getColumnStyle;
