import useUserQuery from '../persistence/queries/useUserQuery';
import IUser from '../types/IUser';

interface IUseCurrentUser {
  user: IUser;
}

const useCurrentUser = (): IUseCurrentUser => {
  const { data } = useUserQuery();
  const user = <IUser>data?.userProfile;

  return { user };
};

export default useCurrentUser;
