import { MpRowActionParameters, MpTable } from '@mp-react/table';
import { useSnackbar } from 'notistack';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { UserStatusEnum } from '../../infrastructure/types/IUser';
import useSendEmailNotification from './graphql/useSendEmailNotification';
import useUpdateUserMutation from './graphql/useUpdateUserMutation';
import useUsersQuery from './graphql/useUsersQuery';
import useUsersColumns from './hooks/useUsersColumns';

const UsersTable: React.FC = () => {
  const { loading, data } = useUsersQuery({});

  const [mutateUser, { loading: mutateLoading }] = useUpdateUserMutation({});
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [sendEmailNotification] = useSendEmailNotification({
    onCompleted: () => {
      enqueueSnackbar(t('profile.resetPasswordSent'), {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const columns = useUsersColumns();

  const users = useMemo(() => {
    return (
      data?.users.map((user) => ({
        ...user,
        isActive: user.status === UserStatusEnum.ACTIVE,
      })) || []
    );
  }, [data]);

  const toggleStatus = useCallback(
    (current: MpRowActionParameters) => {
      const { value, rowId } = current;
      if (!rowId || typeof rowId !== 'string') {
        return;
      }
      mutateUser({
        variables: {
          input: {
            status: value ? UserStatusEnum.ACTIVE : UserStatusEnum.INACTIVE,
          },
          id: rowId,
        },
      });
    },
    [mutateUser],
  );

  return (
    <MpTable
      data={users}
      columns={columns}
      rowIdKey="id"
      onGetData={() => {}}
      loading={loading || mutateLoading}
      localFiltering
      localSorting
      disablePagination
      rowMethods={{
        toggleStatus,
        resendEmail: ({ row }) => {
          sendEmailNotification({ variables: { userId: row.id } });
        },
      }}
    />
  );
};

export default UsersTable;
