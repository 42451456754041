import { AppBar, Box, Container, Toolbar } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import SearchBox from '../../../domain/search/SearchBox';
import { Logo } from '../../../infrastructure/assets/images';
import PathEnum from '../../../infrastructure/constants/PathEnum';
import NotificationsPopover from './NotificationsPopover';
import ProfileMenu from './ProfileMenu';

const Header: React.FC = () => {
  return (
    <Box>
      <AppBar position="static" color="default">
        <Container maxWidth="lg">
          <Toolbar disableGutters>
            <Link to={PathEnum.Dashboard}>
              <Box component="img" src={Logo} alt="Logo" display="block" mx="auto" width={200} />
            </Link>
            <Box sx={{ flexGrow: 1 }} />
            <SearchBox />
            <NotificationsPopover />
            <ProfileMenu />
          </Toolbar>
        </Container>
      </AppBar>
    </Box>
  );
};

export default Header;
