import { InputAdornment, MenuItem, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { StudyStatusEnum } from '../studies/types/IStudy';
import useUpdateStudyMutation from './graphql/useUpdateStudyMutation';
import Colors from '../../infrastructure/constants/Colors';

const StatusChangeInput: React.FC<{ status?: StudyStatusEnum }> = ({ status }) => {
  const { studyId } = useParams<{ studyId: string }>();

  const { t } = useTranslation();

  const [value, setValue] = useState<StudyStatusEnum>();

  const [mutateStudy, { loading }] = useUpdateStudyMutation({});

  useEffect(() => {
    if (status) {
      setValue(status);
    }
  }, [status]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (loading) {
      return;
    }
    const nextValue = event.target.value as StudyStatusEnum;
    setValue(nextValue);
    mutateStudy({
      variables: {
        id: studyId,
        input: {
          status: nextValue,
        },
      },
    });
  };

  return (
    <TextField
      color="secondary"
      select
      variant="outlined"
      InputProps={{
        startAdornment: (
          <InputAdornment disableTypography position="start">{`${t('studies.status')}:`}</InputAdornment>
        ),
      }}
      value={value || ''}
      sx={{
        backgroundColor: Colors.secondary,
        borderRadius: '4px',
        width: 194,
        '& .MuiInputAdornment-root': {
          color: Colors.primary,
        },
        '& .MuiSelect-select': {
          color: Colors.primary,
        },
        '& .MuiSelect-icon': {
          color: Colors.primary,
        },
        '& fieldset': {
          border: 'unset!important',
        },
      }}
      onChange={handleChange}
    >
      <MenuItem value={StudyStatusEnum.ACTIVE}>{t('study.active')}</MenuItem>
      <MenuItem value={StudyStatusEnum.INACTIVE}>{t('study.inactive')}</MenuItem>
    </TextField>
  );
};

export default StatusChangeInput;
