import { List, ListItem, ListItemText, Typography } from '@mui/material';
import React from 'react';
import Colors from '../../infrastructure/constants/Colors';
import ISearchItem from './types/ISearchItem';

interface IProps {
  name: string;
  items: ISearchItem[];
  handleClick: (id: string) => void;
}

const SearchResults: React.FC<IProps> = React.memo((props: IProps) => {
  const { name, items, handleClick } = props;

  return items.length > 0 ? (
    <>
      <Typography
        variant="body2"
        sx={{
          fontSize: '12px',
          lineHeight: '24px',
          textTransform: 'uppercase',
          color: Colors.textSecondary,
          pl: 2,
          pt: 1,
        }}
      >
        {name}
      </Typography>
      <List disablePadding>
        {items.map((item) => (
          <ListItem key={item.id} button onClick={() => handleClick(item.id)}>
            <ListItemText
              primaryTypographyProps={{
                fontSize: '12px',
              }}
            >
              <>
                {item.name}
                {item.study && ` (${item.study.name})`}
              </>
            </ListItemText>
          </ListItem>
        ))}
      </List>
    </>
  ) : null;
});

export default SearchResults;
