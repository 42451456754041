import { gql, QueryHookOptions, useLazyQuery } from '@apollo/client';
import IDropboxFile from '../types/IDropboxFile';

export const SEARCH_DROPBOX = gql`
  query searchDropboxFiles($input: SearchDropBoxInput!) {
    searchDropboxFiles(input: $input) {
      cursor
      items {
        id
        name
        type
        size
        fileType
        fileCount
        path
      }
    }
  }
`;

export default (options?: QueryHookOptions) => {
  return useLazyQuery<{
    searchDropboxFiles: {
      items: IDropboxFile[];
      cursor: string;
    };
  }>(SEARCH_DROPBOX, options);
};
