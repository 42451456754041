import { MpTable } from '@mp-react/table';
import { Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Colors from '../../infrastructure/constants/Colors';
import TableLimit from '../../infrastructure/constants/TableLimit';
import { customFilterFunctionMap, customFilterMap } from './customFilters/CustomFilters';
import CustomPaginatorWithoutButtons from './customPaginator/CustomPaginatorWithoutButtons';
import { customRenderersMap } from './customRenderers/CustomRenderers';
import useDropboxColumns from './hooks/useDropboxColumns';
import IDropboxFile from './types/IDropboxFile';

interface Props {
  files: IDropboxFile[];
  onRowsSelected: (selectedRows: any) => void;
  loading: boolean;
}

const SearchDropboxFilesTable: React.FC<Props> = ({ loading, files, onRowsSelected }) => {
  const { t } = useTranslation();

  const columns = useDropboxColumns({ loading });

  const overridables = useMemo(
    () => ({
      renderers: customRenderersMap,
      filters: customFilterMap,
      filterFunctions: customFilterFunctionMap,
      paginator: CustomPaginatorWithoutButtons,
    }),
    [],
  );

  return (
    <MpTable
      loading={loading}
      selection="multiple"
      data={loading ? files.slice(0, TableLimit.dropboxFilesLoadingLimit) : files}
      dataCount={files.length}
      columns={columns}
      rowIdKey="id"
      onGetData={() => {}}
      localSorting
      localFiltering
      emptyChildren={
        <Typography color={Colors.gray} sx={{ my: 5 }} textAlign="center">
          {t('upload.noImagesFound')}
        </Typography>
      }
      resetSelectedRows={false}
      onRowsSelected={onRowsSelected}
      showOnlyFilteredTotals
      overridables={overridables}
    />
  );
};

export default React.memo(SearchDropboxFilesTable);
