import { gql, MutationHookOptions, useMutation } from '@apollo/client';

interface ISendResetPasswordResponse {
  id: string;
  resetPasswordLink: String;
}

interface ISendResetPasswordEmail {
  sendResetPasswordEmail: ISendResetPasswordResponse;
}

const SEND_RESET_PASSWORD_EMAIL = gql`
  mutation sendResetPasswordEmail($input: SendPasswordResetEmailInput!) {
    sendResetPasswordEmail(input: $input) {
      id
      resetPasswordLink
    }
  }
`;

export default (options: MutationHookOptions) => {
  return useMutation<ISendResetPasswordEmail>(SEND_RESET_PASSWORD_EMAIL, options);
};
