import { MpColumn, MpColumnFormat, MpFilterType } from '@mp-react/table';
import { useTranslation } from 'react-i18next';
import { CustomRendererType } from '../customRenderers/CustomRenderers';

const useStudiesColumns = (): MpColumn[] => {
  const { t } = useTranslation();

  return [
    {
      label: t('studies.studyName'),
      key: 'name',
      filter: {
        type: MpFilterType.text,
        label: t('studies.studyName'),
      },
    },
    {
      label: t('studies.status'),
      key: 'status',
    },
    {
      label: t('studies.model'),
      key: 'cnvModelPathology',
    },
    {
      label: t('studies.creationDate'),
      key: 'creationDate',
      format: {
        type: MpColumnFormat.dateTime,
        modifier: 'HH:mm',
      },
    },
    {
      label: t('studies.department'),
      key: 'department',
    },
    {
      label: t('studies.dueDate'),
      key: 'dueDate',
      format: MpColumnFormat.date,
      render: CustomRendererType.dueDate as any,
    },
    {
      label: t('studies.studyDirector'),
      key: 'studyDirectorFullName',
    },
  ];
};

export default useStudiesColumns;
