import { DatePicker, LoadingButton, LocalizationProvider } from '@mui/lab';
import AdapterMoment from '@mui/lab/AdapterMoment';
import { Box, MenuItem, TextField, Typography } from '@mui/material';
import moment, { Moment } from 'moment';
import React, { FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import IInternalError from '../../infrastructure/types/IInternalError';
import { UserStatusEnum } from '../../infrastructure/types/IUser';
import isServerError from '../../infrastructure/utils/isServerError';
import FullScreenLoader from '../../ui/FullScreenLoader';
import useDepartmentsQuery from '../studies/graphql/useDepartmentsQuery';
import { GET_STUDIES } from '../studies/graphql/useStudiesQuery';
import useUsersQuery from '../studies/graphql/useUsersQuery';
import useStudyQuery from './graphql/useStudyQuery';
import useUpdateStudyMutation from './graphql/useUpdateStudyMutation';

const StudySettings: React.FC = () => {
  const { studyId } = useParams<{ studyId: string }>();

  const [submitted, setSubmitted] = useState(false);
  const [wasChange, setWasChange] = useState(false);

  const { t } = useTranslation();

  const [manager, setManager] = useState<string>('');
  const [department, setDepartment] = useState<string>('');
  const [dueDate, setDueDate] = useState<Moment | null>(null);
  const [error, setError] = useState<null | IInternalError>(null);
  const { data: departmentsData, loading: loadingDepartments } = useDepartmentsQuery();

  useStudyQuery({
    variables: {
      id: studyId,
    },
    skip: !studyId,
    onCompleted: (data) => {
      if (data.study) {
        setManager(data.study.studyDirectorId);
        setDepartment(data.study.department);
        setDueDate(moment(data.study.dueDate));
      }
    },
  });

  const { data: usersData, loading: loadingUsers } = useUsersQuery({
    variables: {},
  });

  const [mutateStudy, { loading: mutateLoading }] = useUpdateStudyMutation({
    onCompleted: () => {
      setSubmitted(true);
      setWasChange(false);
    },
    onError: ({ networkError }) => {
      if (networkError) {
        if (isServerError(networkError)) {
          setError({
            date: moment().format('YYYY-MM-DD HH:mm'),
            statusCode: networkError.statusCode,
          });
          return;
        }
        setError({
          date: moment().format('YYYY-MM-DD HH:mm'),
          context: 'dateOnly',
        });
      }
    },
  });

  const onManagerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setWasChange(true);
    setManager(e.target.value);
  };

  const onDepartmentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setWasChange(true);
    setDepartment(e.target.value);
  };

  const onDueDateChange = (newValue: Moment | null) => {
    setWasChange(true);
    setDueDate(newValue);
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    setSubmitted(true);
    setError(null);
    if (!manager || !dueDate || !department) {
      return false;
    }
    mutateStudy({
      variables: {
        id: studyId,
        input: {
          studyDirectorId: manager,
          dueDate: dueDate.toISOString(),
          department,
        },
      },
      refetchQueries: [GET_STUDIES],
    });
  };

  const sortedUsers = usersData?.users
    ?.filter((i) => {
      if (i.status === UserStatusEnum.INACTIVE) {
        return i.id === manager;
      }
      return true;
    })
    .sort((a, b) => a.firstName.localeCompare(b.firstName));

  if (loadingDepartments) {
    return <FullScreenLoader />;
  }

  return (
    <Box component="form" onSubmit={handleSubmit} noValidate width={360} mt={8}>
      {departmentsData?.departments.length ? (
        <TextField
          margin="normal"
          fullWidth
          select
          id="department"
          name="department"
          size="small"
          label={t('studies.departmentLabel')}
          placeholder={t('studies.departmentPlaceholder')}
          onChange={onDepartmentChange}
          value={department}
          error={submitted && !department}
        >
          {departmentsData?.departments.map((departmentObj) => (
            <MenuItem key={departmentObj.id} value={departmentObj.name}>
              {departmentObj.name}
            </MenuItem>
          ))}
        </TextField>
      ) : (
        // Use free text input if there aren't any departments
        <TextField
          margin="normal"
          fullWidth
          id="department"
          type="text"
          label={t('studies.departmentLabel')}
          name="department"
          inputMode="text"
          size="small"
          placeholder={t('studies.departmentPlaceholder')}
          onChange={onDepartmentChange}
          value={department}
          error={submitted && !department}
        />
      )}
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          label={t('studies.dueDateLabel')}
          value={dueDate}
          onChange={onDueDateChange}
          renderInput={(params) => (
            <TextField
              {...params}
              error={submitted && !dueDate}
              fullWidth
              name="dueDate"
              size="small"
              inputMode="text"
              margin="normal"
            />
          )}
        />
      </LocalizationProvider>
      {!loadingUsers && (
        <TextField
          select
          margin="normal"
          fullWidth
          id="manager"
          label={t('studies.managerLabel')}
          name="manager"
          size="small"
          placeholder={t('studies.managerPlaceholder')}
          onChange={onManagerChange}
          value={manager}
          error={submitted && !manager}
        >
          {sortedUsers?.map((user) => (
            <MenuItem
              disabled={user.status === UserStatusEnum.INACTIVE}
              key={user.id}
              value={user.id}
            >
              {`${user.firstName} ${user.lastName}`}
            </MenuItem>
          ))}
        </TextField>
      )}
      {error && (
        <Typography variant="body1" color="error.main" mt={1}>
          {t('general.unknownError', {
            date: error.date,
            statusCode: error.statusCode,
            context: error.context,
          })}
        </Typography>
      )}
      <LoadingButton
        loading={mutateLoading}
        disabled={!wasChange}
        type="submit"
        variant="contained"
        sx={{ my: 2 }}
        color="primary"
      >
        {t('profile.saveChanges')}
      </LoadingButton>
    </Box>
  );
};

export default StudySettings;
