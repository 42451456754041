import { gql, MutationHookOptions, useMutation } from '@apollo/client';

const MARK_NOTIFICATION_AS_READ = gql`
  mutation markNotificationsAsRead($input: [MarkNotificationAsReadInput!]!) {
    markNotificationsAsRead(input: $input) {
      id
      read
    }
  }
`;

export default (options: MutationHookOptions) => useMutation(MARK_NOTIFICATION_AS_READ, options);
