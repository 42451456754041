import { gql, MutationHookOptions, useMutation } from '@apollo/client';

const SEND_RESET_PASSWORD_EMAIL_AS_ADMIN = gql`
  mutation sendResetPasswordEmailAsAdmin($userId: String!) {
    sendResetPasswordEmailAsAdmin(userId: $userId) {
      id
      resetPasswordLink
    }
  }
`;

export default (options: MutationHookOptions) => {
  return useMutation(SEND_RESET_PASSWORD_EMAIL_AS_ADMIN, options);
};
