import { DeleteOutline, ExpandLess, ExpandMore } from '@mui/icons-material';
import SummarizeIcon from '@mui/icons-material/Summarize';
import { Box, CircularProgress, Collapse, IconButton, List, ListItemButton } from '@mui/material';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { FileIcon } from '../../infrastructure/assets/icons';
import Colors from '../../infrastructure/constants/Colors';
import { FilterTypeEnum } from '../../infrastructure/types/IFilterInput';
import FullScreenLoader from '../../ui/FullScreenLoader';
import { REPORT_FILES_SUBSCRIPTION } from './graphql/useReportFilesSubscription';
import useReports from './graphql/useReports';
import updateReportsQuery from './helpers/updateReportsQuery';
import { ReportStatusEnum } from './types/IReport';
import DeleteReportModal from './DeleteReportModal';
import useDeleteReport from './graphql/useDeleteReport';

const ReportsList: React.FC = () => {
  const { t } = useTranslation();
  const { analysisId } = useParams();
  const [deleteModalId, setDeleteModalId] = useState<string | null>(null);

  const { data, loading, subscribeToMore, refetch } = useReports({
    variables: {
      filters: {
        filters: [{ field: 'analysisId', type: FilterTypeEnum.IN, args: [analysisId] }],
      },
    },
  });

  const [deleteReport, { loading: deleteLoading, client }] = useDeleteReport({
    onCompleted: () => {
      client.resetStore();
      setDeleteModalId(null);
      refetch();
    },
    onError: () => {},
  });

  const reports = useMemo(() => data?.analysisReports || [], [data]);

  const lastAddedInProgressReportId = useMemo(
    () => reports.find((report) => report.status === ReportStatusEnum.IN_PROGRESS)?.id,
    [reports],
  );

  useEffect(() => {
    if (lastAddedInProgressReportId) {
      subscribeToMore({
        document: REPORT_FILES_SUBSCRIPTION,
        variables: {
          reportId: lastAddedInProgressReportId,
        },
        updateQuery: updateReportsQuery(lastAddedInProgressReportId),
      });
    }
  }, [lastAddedInProgressReportId]);

  const [open, setOpen] = useState<string[]>([]);

  const handleOpen = useCallback(
    (id: string) => {
      const index = open.indexOf(id);
      if (index !== -1) {
        const aux = [...open];
        aux.splice(index, 1);
        setOpen([...aux]);
      } else {
        setOpen([...open, id]);
      }
    },
    [open],
  );

  const openDeleteModal = useCallback(
    (id: string) => (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      setDeleteModalId(id);
    },
    [setDeleteModalId],
  );

  const closeDeleteModal = useCallback(() => {
    setDeleteModalId(null);
  }, [setDeleteModalId]);

  const onDelete = useCallback(() => {
    if (!deleteModalId) {
      return;
    }
    deleteReport({ variables: { id: deleteModalId } });
  }, [deleteModalId]);

  if (loading) {
    return <FullScreenLoader />;
  }

  return (
    <>
      <DeleteReportModal
        open={Boolean(deleteModalId)}
        loading={deleteLoading}
        handleClose={closeDeleteModal}
        onDelete={onDelete}
      />
      <Typography variant="h5" sx={{ mb: 1 }}>
        {t('reports.reports')}
      </Typography>
      <List>
        {reports.map((report) => (
          <Fragment key={report.id}>
            <ListItemButton disableGutters onClick={() => handleOpen(report.id)}>
              <Box display="flex" width="100%">
                <SummarizeIcon fontSize="large" />
                <Box ml={1}>
                  <Typography variant="body2" sx={{ wordBreak: 'break-word', pr: 1 }}>
                    {report.name}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: '12px',
                      lineHeight: '18px',
                      color: Colors.textPrimary,
                    }}
                  >
                    {moment(report.creationDate).format('YYYY-MM-DD')}
                  </Typography>
                </Box>
              </Box>
              {report.status === ReportStatusEnum.IN_PROGRESS && (
                <Box>
                  <CircularProgress size={24} />
                </Box>
              )}
              {report.status !== ReportStatusEnum.IN_PROGRESS && (
                <IconButton onClick={openDeleteModal(report.id)}>
                  <DeleteOutline />
                </IconButton>
              )}
              {!!report.files?.length &&
                (open.includes(report.id) ? <ExpandLess /> : <ExpandMore />)}
            </ListItemButton>
            <Collapse timeout="auto" in={!!report.files?.length && open.includes(report.id)}>
              <List component="div" sx={{ pt: 0 }}>
                {report.files?.map((file) => (
                  <ListItemButton
                    disableGutters
                    sx={{ pl: 2 }}
                    key={file.id}
                    onClick={() => {
                      if (file.signedUrl) {
                        window.location.assign(file.signedUrl);
                      }
                    }}
                  >
                    <Box display="flex">
                      <FileIcon />
                      <Box ml={1}>
                        <Typography variant="body2">{file.name}</Typography>
                      </Box>
                    </Box>
                  </ListItemButton>
                ))}
              </List>
            </Collapse>
          </Fragment>
        ))}
      </List>
    </>
  );
};

export default ReportsList;
