import { gql, useSubscription } from '@apollo/client';
import { BaseSubscriptionOptions } from '@apollo/client/react/types/types';

export const DROPBOX_PROGRESS_SUBSCRIPTION = gql`
  subscription dropboxCopyProgressUpdate($id: String!) {
    dropboxCopyProgressUpdate(id: $id) {
      id
      scanned
      uploaded
      failed
      progress
      errors {
        fileId
        fileName
        error
      }
    }
  }
`;

export default (options?: BaseSubscriptionOptions) => {
  return useSubscription(DROPBOX_PROGRESS_SUBSCRIPTION, options);
};
