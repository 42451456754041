import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Tooltip,
} from '@mui/material';
import Divider from '@mui/material/Divider';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, Link, useParams } from 'react-router-dom';
import PathEnum from '../../infrastructure/constants/PathEnum';
import BorderLinearProgress from './BorderLinearProgress';
import { UploadTypeEnum, useUploadContext } from './context/UploadContext';
import UploadFileTypeEnum from './types/UploadFileEnum';

const UploadFilesDrawer: React.FC = () => {
  const { t } = useTranslation();
  const { studyId } = useParams();
  const {
    filesCount,
    uploadedFileCount,
    selectedDropboxFileCount,
    uploaded,
    percentage,
    type,
    dropboxFileUploaded,
    dropboxFileProgress,
    abortUpload,
    aborted,
    dropboxFileErrors,
    fileUploadErrors,
    uploadType,
  } = useUploadContext();

  const disabled = !uploaded && !aborted && UploadFileTypeEnum.DROPBOX !== uploadType;

  const finished = useMemo(() => {
    if (selectedDropboxFileCount > 0) {
      return dropboxFileProgress === 100;
    }
    return uploaded;
  }, [dropboxFileProgress, uploaded, selectedDropboxFileCount]);

  const errors = useMemo(() => {
    if (uploadType === UploadFileTypeEnum.DROPBOX) {
      return dropboxFileErrors;
    }
    return fileUploadErrors;
  }, [dropboxFileErrors, fileUploadErrors, uploadType]);

  return (
    <Card>
      <Box position="relative" width="100%" height={600}>
        <Box>
          <Toolbar sx={{ background: 'white' }}>
            <Box px={2}>
              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
              >
                <Typography key="1" color="text.primary">
                  {t('upload.imageSelect')}
                </Typography>
                <Typography key="2" color="text.primary">
                  {t('upload.imageUpload')}
                </Typography>
              </Breadcrumbs>
            </Box>
          </Toolbar>
          <Divider />
        </Box>

        <Box
          component="div"
          sx={{ py: 8, overflowY: 'auto' }}
          width={504}
          mx="auto"
          textAlign="center"
        >
          {type === UploadTypeEnum.STUDY &&
            selectedDropboxFileCount > 0 &&
            (dropboxFileProgress > 0 ? (
              <>
                <Box display="flex" justifyContent="space-between" mb={1}>
                  <Typography>
                    {t('upload.uploadedFilesDropbox', {
                      count: dropboxFileUploaded,
                    })}
                  </Typography>
                  <Typography>{`${dropboxFileProgress}%`}</Typography>
                </Box>

                <BorderLinearProgress variant="determinate" value={dropboxFileProgress} />
              </>
            ) : (
              !aborted && (
                <>
                  <Typography pb={2}>{t('upload.startingUpload')}</Typography>
                  <CircularProgress />
                </>
              )
            ))}
          {type === UploadTypeEnum.STUDY &&
            filesCount > 0 &&
            (percentage > 0 ? (
              <>
                <Box display="flex" justifyContent="space-between" mb={1}>
                  {uploaded && filesCount === uploadedFileCount && (
                    <Typography>
                      {t('upload.uploadedFiles', {
                        count: filesCount,
                      })}
                    </Typography>
                  )}
                  {filesCount !== uploadedFileCount && (
                    <Typography>
                      {t('upload.uploadedFilesTotal', {
                        total: filesCount,
                        uploaded: uploadedFileCount,
                      })}
                    </Typography>
                  )}
                  <Box display="flex" alignSelf="flex-end" flexDirection="row">
                    {!uploaded &&
                    filesCount !== uploadedFileCount &&
                    !aborted &&
                    !fileUploadErrors.length ? (
                      <CircularProgress size={20} />
                    ) : null}
                    <Typography paddingLeft={2}>{`${percentage.toFixed(2)}%`}</Typography>
                  </Box>
                </Box>
                <BorderLinearProgress variant="determinate" value={percentage} />
              </>
            ) : (
              !aborted && (
                <>
                  <Typography pb={2}>{t('upload.startingUpload')}</Typography>
                  <CircularProgress />
                </>
              )
            ))}
          {type === UploadTypeEnum.ANALYSIS &&
            (uploaded ? (
              <Typography>{t('upload.filesSuccessfullyAssigned')}</Typography>
            ) : (
              <CircularProgress />
            ))}
          <Box mt={3}>
            {disabled && !fileUploadErrors.length && (
              <Typography>{t('analysis.backToStudyTooltip')}</Typography>
            )}
            {fileUploadErrors.length > 0 && <Typography>{t('analysis.uploadFailed')}</Typography>}
            {aborted && <Typography>{t('analysis.uploadWasCancelled')}</Typography>}
            <Tooltip title={disabled ? t('analysis.backToStudyTooltip') : ''}>
              <span>
                <Button
                  sx={{ mt: 3 }}
                  component={Link}
                  to={generatePath(PathEnum.Study, { studyId })}
                  disabled={disabled && !fileUploadErrors.length}
                  variant="contained"
                >
                  {t('upload.toProject')}
                </Button>
              </span>
            </Tooltip>
          </Box>
          {!aborted && !finished && (
            <Button sx={{ mt: 2 }} onClick={abortUpload} variant="outlined" color="error">
              {t('upload.cancelUpload')}
            </Button>
          )}
          <Box
            mt={5}
            maxHeight={350}
            overflow="auto"
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            {errors.length > 0 && <Typography>{t('upload.errors')}</Typography>}
            {errors.length > 0 &&
              errors.map((i) => (
                <List key={i.fileId} disablePadding>
                  <ListItem disableGutters disablePadding>
                    <ListItemText primary={i.error} secondary={i.fileName} />
                  </ListItem>
                </List>
              ))}
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

export default UploadFilesDrawer;
