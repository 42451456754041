import Box from '@mui/material/Box';
import React, { useCallback, useMemo } from 'react';
import { Button, Checkbox, Divider, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { DeleteOutline } from '@mui/icons-material';
import IFileRoleEnum from '../../infrastructure/types/IFileRoleEnum';
import { FilterTypeEnum } from '../../infrastructure/types/IFilterInput';
import FullScreenLoader from '../../ui/FullScreenLoader';
import useStudyFolderStructure from './graphql/useStudyFolderStructure';
import ProjectFilesTree from '../upload/ProjectFilesTree';
import IFolderStructure from '../analysis/types/IFolderStructure';
import FileTypeEnum from '../upload/types/FileTypeEnum';
import IProjectFolder from '../upload/types/IProjectFolder';
import useStore from '../../infrastructure/store/useStore';
import { useUploadContext } from '../upload/context/UploadContext';
import Colors from '../../infrastructure/constants/Colors';
import DeleteFilesModal from './DeleteFilesModal';
import useDeleteRawFiles from './graphql/useDeleteRawFiles';

const FilesList: React.FC = () => {
  const { t } = useTranslation();
  const { studyId } = useParams();
  const { setSelectedFileIds, selectedFilesIds } = useUploadContext();
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const projectFiles = useStore((state) => state.projectFiles);

  const { data, loading, refetch } = useStudyFolderStructure({
    variables: {
      id: studyId,
      filters: {
        filters: [{ field: 'role', type: FilterTypeEnum.IN, args: [IFileRoleEnum.RAW] }],
      },
    },
    fetchPolicy: 'network-only',
  });

  const [deleteRawFiles, { loading: deleteLoading, client }] = useDeleteRawFiles({
    onCompleted: () => {
      client.resetStore();
      setModalOpen(false);
      refetch();
      setSelectedFileIds([]);
    },
    onError: () => {},
  });

  const mapFolders = useCallback((el: IFolderStructure) => {
    const folder: IProjectFolder = {
      id: el.id,
      name: el.name,
      fileCount: el.fileCount,
      subFolders: el.subFolders?.map(mapFolders) || [],
      type: FileTypeEnum.FOLDER,
    };
    return folder;
  }, []);

  const items = useMemo(() => {
    return data?.study?.folderStructure?.map(mapFolders) || [];
  }, [data]);

  const checked = useMemo(() => {
    const childrenIds = projectFiles.root?.filter((el) => el.name !== '').map((el) => el.id) || [];
    return childrenIds.length > 0 && childrenIds.every((id) => selectedFilesIds.includes(id));
  }, [projectFiles, selectedFilesIds]);

  const handleOnCheck = useCallback(() => {
    const childrenIds = projectFiles.root?.map((el) => el.id) || [];
    if (childrenIds.length === 0) return;
    if (!checked) {
      setSelectedFileIds(childrenIds);
      return;
    }
    setSelectedFileIds([]);
  }, [checked, projectFiles, setSelectedFileIds]);

  const openDeleteModal = () => {
    setModalOpen(true);
  };

  const deleteFiles = () => {
    deleteRawFiles({ variables: { ids: selectedFilesIds } });
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  if (loading) {
    return <FullScreenLoader />;
  }

  if (items.length === 0) {
    return (
      <Typography color={Colors.gray} sx={{ mt: 10 }} textAlign="center">
        {t('study.noFilesFound')}
      </Typography>
    );
  }

  return (
    <Box>
      <DeleteFilesModal
        loading={deleteLoading}
        open={modalOpen}
        handleClose={handleModalClose}
        onDelete={deleteFiles}
      />
      <Box display="flex" justifyContent="end" mt={1}>
        <Button
          onClick={openDeleteModal}
          variant="outlined"
          size="small"
          disabled={selectedFilesIds.length === 0}
        >
          <DeleteOutline sx={{ mr: 1 }} />
          {t('study.deleteSelected')}
        </Button>
      </Box>
      <Box py={1} pl={4} pr={2} display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <Typography variant="body1">{t('upload.filesName')}</Typography>
        </Box>

        <Checkbox checked={checked} onChange={handleOnCheck} />
      </Box>
      <Divider />
      <ProjectFilesTree path="" folders={items} parentFolderIds={['root']} />
    </Box>
  );
};

export default FilesList;
