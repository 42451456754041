import {
  Checkbox,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { Fragment, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { FilesIcon } from '../../infrastructure/assets/icons';
import Colors from '../../infrastructure/constants/Colors';
import { BorderStyleEnum, getBorder } from '../../utils/Common';
import { IFile, useUploadContext } from './context/UploadContext';

const ComputerSelectFiles: React.FC = () => {
  const { t } = useTranslation();

  const { files, toggleFile, isDragOver, onDragLeave, setFiles } = useUploadContext();

  const onDrop = useCallback(
    (acceptedFiles) => {
      setFiles([
        ...files,
        ...acceptedFiles.map((file) => ({
          id: uuidv4(),
          file,
          selected: true,
        })),
      ]);
    },
    [files, setFiles],
  );

  const { getRootProps, getInputProps } = useDropzone({
    multiple: true,
    noClick: true,
    noKeyboard: true,
    onDrop,
  });

  const filesList = files.map((file: IFile, key: number) => (
    <Fragment key={file.id}>
      {key === 0 && <Divider />}
      <ListItem disablePadding>
        <ListItemButton onClick={() => toggleFile(key)}>
          <ListItemIcon>
            <Checkbox edge="start" tabIndex={-1} disableRipple checked={file.selected} />
          </ListItemIcon>
          <ListItemText primary={file.file.path} />
        </ListItemButton>
      </ListItem>
      <Divider />
    </Fragment>
  ));

  return (
    <section className="container">
      <div {...getRootProps({ className: 'dropzone' })}>
        <Box
          {...(isDragOver && {
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            zIndex: 9999,
            userSelect: 'none',
            border: getBorder(Colors.primary, BorderStyleEnum.DASHED),
            borderRadius: 1,
            backgroundColor: Colors.draggableTransparentBackground,
          })}
          onDrop={onDragLeave}
          onDragLeave={onDragLeave}
        >
          <input {...getInputProps()} />
        </Box>
      </div>
      {/* @ts-ignore */}
      {/* <input {...getInputProps()} directory="" webkitdirectory="" /> */}
      <Box
        pl={2}
        pr={5}
        height="100px"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        borderRadius={1}
        border={getBorder(Colors.textSecondary, BorderStyleEnum.DASHED)}
      >
        <Box display="flex" gap={2}>
          <FilesIcon />
          <Box>
            <Typography>{t('upload.dragAndDropFilesHere')}</Typography>
          </Box>
        </Box>
      </Box>
      <Box mt={5}>
        <Box pl={5} display="flex" alignItems="center">
          <Typography variant="body2">{t('upload.filesName')}</Typography>
        </Box>
        <List>{filesList}</List>
      </Box>
    </section>
  );
};

export default ComputerSelectFiles;
