import { colorToRGBA, getColorAtPixel, setColorAtPixel } from 'q-floodfill';
import IBounding from '../types/IBounding';
import ICoordinates from '../types/ICoordinates';
import addPaddingToBoundaries from './addBoundariesPadding';
import floodFill from './floodFill';
import getEmptyPlots from './getEmptyPlots';
import isBlack from './isBlack';

const backgroundColor = 'rgba(0, 0, 0, 0)';

const autoFillShapes = (
  canvas: HTMLCanvasElement,
  bounding: IBounding,
): [boolean, ICoordinates[]] => {
  const ctx = canvas.getContext('2d');
  if (!ctx) return [false, []];
  const padding = 20;
  const { top, left, right, bottom } = addPaddingToBoundaries(
    bounding,
    padding,
    canvas.width,
    canvas.height,
  );
  const width = right - left - 1;
  const height = bottom - top - 1;
  if (top === Infinity || left === Infinity) return [false, []];
  const imgData = ctx.getImageData(left, top, width, height);
  const plots = getEmptyPlots(imgData, width, height);
  plots.forEach(({ x, y }) => {
    floodFill(imgData, '#000', x, y);
  });

  let foundEmpty = false;
  const coords: ICoordinates[] = [];
  for (let j = 0; j < height; j += 1) {
    for (let i = 0; i < width; i += 1) {
      const currentColor = getColorAtPixel(imgData, i, j);
      const isCurrentBlack = isBlack(currentColor);
      const nextColor = isCurrentBlack ? colorToRGBA(backgroundColor) : currentColor;
      if (currentColor.a === 0) {
        foundEmpty = true;
        coords.push({ x: i + left, y: j + top });
      }
      setColorAtPixel(imgData, nextColor, i, j);
    }
  }
  ctx.putImageData(imgData, left, top, 0, 0, width, height);
  return [foundEmpty, coords];
};

export default autoFillShapes;
