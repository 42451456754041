import { useCallback } from 'react';
import useStore from '../../../../infrastructure/store/useStore';
import EditorColors from '../../ImageEditor/constants/EditorColors';
import ImageEditorConfig from '../../ImageEditor/constants/ImageEditor.config';
import ITopViewCoordinateRow from '../../ImageEditor/types/ITopViewCoordinateRow';

const useTopView = () => {
  const [selectedSliceIndex, topViewRef] = useStore((state) => [
    state.selectedSliceIndex,
    state.topViewRef,
  ]);

  const copyToTopView = useCallback(
    (canvas: HTMLCanvasElement) => {
      const topViewCanvas = topViewRef.current;
      const topViewCtx = topViewCanvas?.getContext('2d');
      const ctx = canvas.getContext('2d');
      if (!topViewCanvas || !topViewCtx || !ctx) return;
      const imgData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      topViewCtx.putImageData(imgData, 0, 0);
    },
    [topViewRef],
  );

  const updateTopView = useCallback(
    (coordinates: ITopViewCoordinateRow) => {
      const topViewCanvas = topViewRef?.current;
      const topViewCtx = topViewCanvas?.getContext('2d');
      if (!topViewCtx || !topViewCanvas || selectedSliceIndex === null) return;
      const lineHeight = topViewCanvas.height / ImageEditorConfig.VIP_IMAGES_COUNT;
      topViewCtx.clearRect(0, selectedSliceIndex * lineHeight, topViewCanvas.width, lineHeight);
      coordinates.forEach((section) => {
        topViewCtx.fillStyle = EditorColors.topViewDataColor;
        topViewCtx.fillRect(
          section.from,
          selectedSliceIndex * lineHeight,
          section.to - section.from + 1,
          lineHeight,
        );
      });
    },
    [selectedSliceIndex, topViewRef],
  );

  return { updateTopView, copyToTopView };
};

export default useTopView;
