import InfoIcon from '@mui/icons-material/Info';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const CollageSeparatorLine: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Box position="absolute" top={0} left={(124) * 7 - 2}>
      <Box width={0} height={300} border="2px solid #FE1717" />
      <Box
        display="flex"
        alignItems="center"
        position="absolute"
        bottom={-20}
        right={12}
        width={236}
      >
        <InfoIcon />
        <Typography variant="body2" ml={1}>
          {t('analysis.imagesPastThisLine')}
        </Typography>
      </Box>
    </Box>
  );
};

export default CollageSeparatorLine;
