import { getColorAtPixel } from 'q-floodfill';
import { RefObject } from 'react';
import ITopViewCoordinateRow from '../types/ITopViewCoordinateRow';

// TODO: Need to check all canvas data

const findMaskIndexWithFilledCoordinate = (
  imageDataList: ImageData[],
  i: number,
  height: number,
) => {
  let foundIndex = -1;
  for (let j = 0; j < height; j += 1) {
    const index = imageDataList.findIndex((imageData) => {
      return getColorAtPixel(imageData, i, j).a !== 0;
    });
    if (index !== -1) {
      foundIndex = index;
      break;
    }
  }
  return foundIndex;
};

export const getFromToCoordinatesForTopView = (maskRefs: RefObject<HTMLCanvasElement>[]) => {
  const imageDataList = maskRefs
    .map((maskRef) => {
      const maskCanvas = maskRef.current;
      const maskContext = maskCanvas?.getContext('2d');
      if (!maskCanvas || !maskContext) return null;
      return maskContext.getImageData(0, 0, maskCanvas.width, maskCanvas.height);
    })
    .filter((imageData) => imageData !== null) as ImageData[];
  const { width, height } = imageDataList[0];
  const coordinates: ITopViewCoordinateRow = [];
  let startX: number | null = null;
  let stopX: number | null = null;
  let lastMaskIndex: number | null = null;
  for (let i = 0; i < width; i += 1) {
    const maskIndex = findMaskIndexWithFilledCoordinate(imageDataList, i, height);
    if (
      maskIndex !== -1 &&
      lastMaskIndex !== null &&
      maskIndex !== lastMaskIndex &&
      startX !== null &&
      stopX !== null
    ) {
      coordinates.push({
        maskId: lastMaskIndex,
        from: startX,
        to: stopX,
      });
      startX = i;
      stopX = i;
      lastMaskIndex = maskIndex;
    } else if (maskIndex !== -1) {
      if (startX === null) {
        startX = i;
        lastMaskIndex = maskIndex;
      }
      stopX = i;
    } else if (maskIndex === -1) {
      if (startX !== null && stopX !== null && lastMaskIndex !== null) {
        coordinates.push({
          maskId: lastMaskIndex,
          from: startX,
          to: stopX,
        });
        startX = null;
        stopX = null;
        lastMaskIndex = null;
      }
    }
  }
  return coordinates;
};

const getTopViewFilledCoordinates = (canvas: HTMLCanvasElement) => {
  const coordinates: number[][] = [];
  const context = canvas.getContext('2d');
  const imgData = context?.getImageData(0, 0, canvas.width, canvas.height);
  if (!imgData) return [];
  const { width, height } = imgData;
  let startX: number | null = null;
  let stopX: number | null = null;
  for (let i = 0; i < width; i += 1) {
    for (let j = 0; j < height; j += 1) {
      const { a } = getColorAtPixel(imgData, i, j);
      if (a !== 0) {
        if (!startX) {
          startX = i;
        }
        stopX = i;
        break;
      }
      if (a === 0 && j === height - 1) {
        if (startX && stopX) {
          coordinates.push([startX, stopX]);
          startX = null;
          stopX = null;
        }
      }
    }
  }
  return coordinates;
};

export default getTopViewFilledCoordinates;
