import { colorToRGBA, getColorAtPixel, setColorAtPixel } from 'q-floodfill';
import EditorColors from '../constants/EditorColors';
import dataURItoBlob from './dataURItoBlob';
import isBlack from './isBlack';

interface IProps {
  imgSrc: string;
  canvas: HTMLCanvasElement;
  coordinatesToErase: number[];
}

const removePixelsFromImage = ({ canvas, imgSrc, coordinatesToErase }: IProps): Promise<Blob> => {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => {
      const ctx = canvas.getContext('2d');
      if (!ctx) return;
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(img, 0, 0);
      const imgData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      const { height } = imgData;
      const blackColor = colorToRGBA(EditorColors.black);
      coordinatesToErase.forEach((x) => {
        for (let j = 0; j < height; j += 1) {
          const currentColor = getColorAtPixel(imgData, x, j);
          if (!isBlack(currentColor)) {
            setColorAtPixel(imgData, blackColor, x, j);
          }
        }
      });
      ctx.putImageData(imgData, 0, 0);
      const dataURL = canvas.toDataURL();
      const blob = dataURItoBlob(dataURL);
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      resolve(blob);
    };
    img.src = imgSrc;
    img.crossOrigin = 'anonymous';
  });
};

export default removePixelsFromImage;
