import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import INotification from '../../types/INotification';

interface IGetNotifications {
  notifications: INotification[];
  notificationCreated?: INotification;
  notificationUpdated?: INotification;
  notificationsCount: number;
}

const GET_NOTIFICATIONS = gql`
  query notifications($filters: FilterInput) {
    notifications(filters: $filters) {
      id
      type
      message
      analysisId
      studyId
      creationDate
      read
      errors {
        code
        message
        sentFile
      }
    }
    notificationsCount
  }
`;

// eslint-disable-next-line max-len
export default (options?: QueryHookOptions) => useQuery<IGetNotifications>(GET_NOTIFICATIONS, options);
