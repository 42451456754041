import create from 'zustand';
import createAnalysisRearrangeImages from '../../domain/analysis/store/createAnalysisRearrangeImages';
import createAnalysisSlice from '../../domain/analysis/store/createAnalysisSlice';
import createFilesSlice from '../../domain/study/store/createFilesSlice';

const useStore = create((set, get) => ({
  ...createAnalysisSlice(set),
  ...createAnalysisRearrangeImages(set, get),
  ...createFilesSlice(set),
}));

export default useStore;
