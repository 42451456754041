import { Box, Container } from '@mui/material';
import React from 'react';

const MainContainer: React.FC = ({ children }) => {
  return (
    <Container maxWidth="lg">
      <Box my={4} pb={4}>
        {children}
      </Box>
    </Container>
  );
};

export default MainContainer;
