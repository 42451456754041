import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import PathEnum from '../../infrastructure/constants/PathEnum';
import AnalysisPage from '../pages/AnalysisPage';
import AnalysisUploadPage from '../pages/AnalysisUploadPage';
import LoginPage from '../pages/LoginPage';
import NotificationsPage from '../pages/NotificationsPage';
import ReportsPage from '../pages/ReportsPage';
import ResetPasswordPage from '../pages/ResetPasswordPage';
import StudiesPage from '../pages/StudiesPage';
import StudyPage from '../pages/StudyPage';
import UploadPage from '../pages/UploadPage';
import UserProfilePage from '../pages/UserProfilePage';
import UsersPage from '../pages/UsersPage';
import ProtectedRoute from './ProtectedRoute';
import ProtectedRootRoute from './ProtectedRootRoute';

const Router: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path={PathEnum.UserProfile}
          element={
            <ProtectedRoute>
              <UserProfilePage />
            </ProtectedRoute>
          }
        />
        <Route
          path={PathEnum.Dashboard}
          element={
            <ProtectedRoute>
              <StudiesPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={PathEnum.Study}
          element={
            <ProtectedRoute>
              <StudyPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={PathEnum.Analysis}
          element={
            <ProtectedRoute>
              <AnalysisPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={PathEnum.Upload}
          element={
            <ProtectedRoute>
              <UploadPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={PathEnum.AnalysisUpload}
          element={
            <ProtectedRoute>
              <AnalysisUploadPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={PathEnum.Reports}
          element={
            <ProtectedRoute>
              <ReportsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={PathEnum.Notifications}
          element={
            <ProtectedRoute>
              <NotificationsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={PathEnum.Users}
          element={
            <ProtectedRootRoute>
              <UsersPage />
            </ProtectedRootRoute>
          }
        />
        <Route path={PathEnum.Login} element={<LoginPage />} />
        <Route path={PathEnum.ResetPassword} element={<ResetPasswordPage />} />
        <Route path={PathEnum.CreatePassword} element={<ResetPasswordPage isNewUser />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
