import INotificationError from '../../domain/notifications/types/INotificationError';

export enum NotificationType {
  STUDY_FILES_UPLOADED = 'STUDY_FILES_UPLOADED',
  ANALYSIS_FILES_UPLOADED = 'ANALYSIS_FILES_UPLOADED',
  ANALYSIS_PROCESSED = 'ANALYSIS_PROCESSED',
  ANALYSIS_REPORT_GENERATED = 'ANALYSIS_REPORT_GENERATED',
}

export default interface INotification {
  currentProgress: number;
  id: string;
  type: NotificationType;
  message: string;
  analysisId: string;
  studyId: string;
  read: boolean;
  creationDate: string;
  errors: INotificationError[];
  expectedProgress: number;
}
