import { getColorAtPixel } from 'q-floodfill';
import ICoordinates from '../types/ICoordinates';

const getEmptyPlots = (imgData: ImageData, width: number, height: number) => {
  const borders = [
    // top border
    {
      from: { x: 0, y: 0 },
      to: { x: width - 1, y: 0 },
    },
    // right border
    {
      from: { x: width - 1, y: 0 },
      to: { x: width - 1, y: height - 1 },
    },
    // bottom border
    {
      from: { x: 0, y: height - 1 },
      to: { x: width - 1, y: height - 1 },
    },
    // left border
    {
      from: { x: 0, y: 0 },
      to: { x: 0, y: height - 1 },
    },
  ];
  const coordinates: ICoordinates[] = [];
  let intersection = true;
  borders.forEach(({ from, to }) => {
    for (let i = from.x; i <= to.x; i += 1) {
      for (let j = from.y; j <= to.y; j += 1) {
        const color = getColorAtPixel(imgData, i, j);
        if (color.a === 0 && intersection) {
          coordinates.push({ x: i, y: j });
          intersection = false;
        }
        if (color.a === 255 && !intersection) {
          intersection = true;
        }
      }
    }
  });
  return coordinates;
};

export default getEmptyPlots;
