import { colorToRGBA, getColorAtPixel } from 'q-floodfill';
import ICoordinates from '../types/ICoordinates';
import colorsMatch from './colorsMatch';

const backgroundColor = 'rgba(0, 0, 0, 0)';

const clearCanvas = (canvas: HTMLCanvasElement, coordinates: ICoordinates[]) => {
  const ctx = canvas.getContext('2d');
  if (!ctx) return false;
  const { width, height } = canvas;
  const imgData = ctx.getImageData(0, 0, width, height);
  let cleared = false;
  const { lineWidth } = ctx;
  ctx.lineWidth = 8;
  ctx.globalCompositeOperation = 'destination-out';
  coordinates.forEach(({ x, y }) => {
    if (!colorsMatch(getColorAtPixel(imgData, x, y), colorToRGBA(backgroundColor))) {
      cleared = true;
      ctx.beginPath();
      ctx.moveTo(x, y);
      ctx.lineTo(x === canvas.width - 1 ? x - 1 : x + 1, y === canvas.height - 1 ? y - 1 : y + 1);
      ctx.stroke();
    }
  });
  ctx.lineWidth = lineWidth;
  ctx.globalCompositeOperation = 'source-over';
  return cleared;
};

export default clearCanvas;
