import IFolderStructure from '../../types/IFolderStructure';

const findPrevPathFromFolderStructure = (
  data: IFolderStructure[],
  path: string,
): IFolderStructure | undefined => {
  let result;

  function iter(a: IFolderStructure) {
    if (a.fileCount > 0) {
      if (a.objectId === path) {
        return true;
      }
      result = a;
    }
    return Array.isArray(a.subFolders) && a.subFolders.some(iter);
  }

  data.some(iter);
  return result;
};

export default findPrevPathFromFolderStructure;
