async function* asyncGenerator<ListItem, PromiseResponse>(
  list: ListItem[],
  promiseCallback: (item: ListItem) => Promise<PromiseResponse>,
): AsyncGenerator<PromiseResponse> {
  // eslint-disable-next-line no-restricted-syntax
  for (const item of list) {
    yield promiseCallback(item);
  }
}

export default asyncGenerator;
