import { useRendererOptions } from '@mp-react/table';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { CellProps } from 'react-table';
import Colors from '../../../../infrastructure/constants/Colors';

const NameAndPath: React.FC<CellProps<{}>> = (props) => {
  const { value } = useRendererOptions(props);
  const { name = '', path = '' } = value;

  return (
    <Box>
      <Typography maxWidth="400px" variant="body2" sx={{ overflowWrap: 'break-word' }}>
        {name}
      </Typography>
      <Typography
        maxWidth="400px"
        variant="body2"
        sx={{
          fontSize: '12px',
          lineHeight: 1,
          color: Colors.textSecondary,
          overflowWrap: 'break-word',
        }}
      >
        {path}
      </Typography>
    </Box>
  );
};

export default NameAndPath;
