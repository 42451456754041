import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import IFile from '../../analysis/types/IFile';
import IReport from '../types/IReport';

interface IGetReports {
  analysisReports: IReport[];
  filesCreated?: IFile[];
}

export const REPORTS = gql`
  query Reports($filters: FilterInput) {
    analysisReports(filters: $filters) {
      id
      name
      status
      error
      creationDate
      files {
        id
        name
        path
        signedUrl
      }
    }
  }
`;

export default (options: QueryHookOptions) => {
  return useQuery<IGetReports>(REPORTS, options);
};
