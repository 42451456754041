import { Box, Card } from '@mui/material';
import React from 'react';

const TableWrapper: React.FC = ({ children }) => {
  return (
    <Card>
      <Box px={3} pt={1} pb={3}>
        {children}
      </Box>
    </Card>
  );
};

export default TableWrapper;
