import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import IAnalysis from '../types/IAnalysis';

const ANALYSIS = gql`
  query Analysis($id: String!, $filters: FilterInput) {
    analysis(id: $id) {
      name
      studyId
      interface
      reportCategories {
        id
        type
        reportId
        aiModelId
      }
      folderStructure(filters: $filters) {
        id
        name
        fileCount
        objectId
        subFolders {
          id
          name
          fileCount
          objectId
          subFolders {
            id
            name
            fileCount
            objectId
            subFolders {
              id
              name
              fileCount
              objectId
              subFolders {
                id
                name
                fileCount
                objectId
                subFolders {
                  id
                  name
                  fileCount
                  objectId
                  subFolders {
                    id
                    name
                    fileCount
                    objectId
                    subFolders {
                      id
                      name
                      fileCount
                      objectId
                      subFolders {
                        id
                        name
                        fileCount
                        objectId
                        subFolders {
                          id
                          name
                          fileCount
                          objectId
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default (options: QueryHookOptions) => useQuery<IAnalysis>(ANALYSIS, options);
