import { Box, CircularProgress, List } from '@mui/material';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import useStore from '../../infrastructure/store/useStore';
import IFileRoleEnum from '../../infrastructure/types/IFileRoleEnum';
import { FilterTypeEnum } from '../../infrastructure/types/IFilterInput';
import useProjectFiles from './graphql/useProjectFiles';
import ProjectFileListItem from './ProjectFileListItem';
import ProjectFolderListItem from './ProjectFolderListItem';
import FileTypeEnum from './types/FileTypeEnum';
import IProjectFile from './types/IProjectFile';
import IProjectFolder from './types/IProjectFolder';

interface IProps {
  path: string;
  folders: IProjectFolder[];
  parentFolderIds: string[];
  roleType?: IFileRoleEnum;
}

const ProjectFilesTree: React.FC<IProps> = ({
  path,
  folders,
  parentFolderIds,
  roleType = IFileRoleEnum.RAW,
}) => {
  const { studyId } = useParams();
  const [projectFiles, setProjectFiles] = useStore((state) => [
    state.projectFiles,
    state.setProjectFiles,
  ]);

  const { data, loading } = useProjectFiles({
    variables: {
      filters: {
        filters: [
          { field: 'path', type: FilterTypeEnum.IN, args: [path] },
          { field: 'studyId', type: FilterTypeEnum.IN, args: [studyId] },
          {
            field: 'role',
            type: FilterTypeEnum.IN,
            args: [roleType],
          },
        ],
      },
    },
    onCompleted: () => {
      const files: IProjectFile[] =
        data?.files.map((el) => {
          const projectFile: IProjectFile = {
            id: el.id,
            name: el.name,
            type: FileTypeEnum.FILE,
          };
          return projectFile;
        }) || [];
      if (parentFolderIds.length > 0) {
        setProjectFiles({
          ...projectFiles,
          [parentFolderIds[parentFolderIds.length - 1]]: [...files, ...folders],
        });
        return;
      }
      setProjectFiles({
        ...projectFiles,
        root: [...files, ...folders],
      });
    },
  });

  const files = useMemo(() => {
    if (data?.files) {
      return data.files.map((el) => {
        const projectFile: IProjectFile = {
          id: el.id,
          name: el.name,
          type: FileTypeEnum.FILE,
        };
        return projectFile;
      });
    }
    return [];
  }, [data]);

  if (loading) {
    return (
      <Box mt={2}>
        <CircularProgress size={24} />
      </Box>
    );
  }

  return (
    <List>
      {folders.map((folder: IProjectFolder) => {
        if (!folder.name) return;
        return (
          <ProjectFolderListItem
            key={folder.id}
            id={folder.id}
            name={folder.name}
            parentFolderIds={parentFolderIds}
            folder={folder}
          >
            <Box pl={4}>
              <ProjectFilesTree
                path={[path, folder.name].filter((el) => el).join('/')}
                folders={folder.subFolders}
                parentFolderIds={[...parentFolderIds, folder.id]}
                roleType={roleType}
              />
            </Box>
          </ProjectFolderListItem>
        );
      })}
      {files.map((file: IProjectFile) => {
        return (
          <ProjectFileListItem
            key={file.id}
            id={file.id}
            name={file.name}
            parentFolderIds={parentFolderIds}
          />
        );
      })}
    </List>
  );
};

export default React.memo(ProjectFilesTree);
