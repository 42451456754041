import { Box, Container } from '@mui/material';
import React from 'react';
import LoginForm from '../../domain/auth/LoginForm';

const LoginPage: React.FC = () => {
  return (
    <Container
      maxWidth="xs"
      sx={{
        height: '100%',
        flexDirection: 'column',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        width={590}
        py={8}
        display="flex"
        justifyContent="center"
        borderRadius={3}
        sx={{ backgroundColor: 'common.white' }}
      >
        <Box
          width={360}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <LoginForm />
        </Box>
      </Box>
    </Container>
  );
};

export default LoginPage;
