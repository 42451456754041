import { gql, useMutation } from '@apollo/client';

interface IDeleteAnalysisInput {
  id: string;
}

interface IDeleteAnalysisResponse {
  deleteAnalysis: {
    id: string;
  };
}

const DELETE_ANALYSIS = gql`
  mutation deleteAnalysis($id: String!) {
    deleteAnalysis(id: $id) {
      id
    }
  }
`;

export default (options: { onError: (apolloError) => void; onCompleted: (response) => void }) => {
  return useMutation<IDeleteAnalysisResponse, IDeleteAnalysisInput>(DELETE_ANALYSIS, options);
};
