enum AnalysisInterfaceEnum {
  MODEL_1 = 'MODEL_1',
  MODEL_2 = 'MODEL_2',
  MODEL_3 = 'MODEL_3',
  MODEL_4 = 'MODEL_4',
  MODEL_5 = 'MODEL_5',
  MODEL_6 = 'MODEL_6',
  MODEL_7 = 'MODEL_7',
}

export default AnalysisInterfaceEnum;
