import { gql, QueryHookOptions, useLazyQuery } from '@apollo/client';
import IDropboxFile from '../types/IDropboxFile';

const GET_DROPBOX_FILES = gql`
  query dropboxFiles($path: String) {
    dropboxFiles(path: $path) {
      id
      name
      type
      size
      fileCount
      path
    }
  }
`;

export default (options?: QueryHookOptions) => {
  return useLazyQuery<{ dropboxFiles: IDropboxFile[] }>(GET_DROPBOX_FILES, {
    // fetchPolicy: 'network-only',
    ...options,
  });
};
