import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, Link, useParams } from 'react-router-dom';
import PathEnum from '../../infrastructure/constants/PathEnum';
import useStudyQuery from './graphql/useStudyQuery';
import NewAnalysis from './NewAnalysis';
import StatusChangeInput from './StatusChangeInput';

const StudyHeader: React.FC = () => {
  const { t } = useTranslation();
  const { studyId } = useParams();

  const { data } = useStudyQuery({
    variables: {
      id: studyId,
    },
    skip: !studyId,
  });

  return (
    <Box display="flex" justifyContent="space-between">
      <Typography variant="h4">{data?.study.name || t('studies.studyName')}</Typography>
      <Box display="flex" gap={2}>
        <StatusChangeInput status={data?.study.status} />
        <NewAnalysis isCnv={!!data?.study.cnvModel?.isCNV} />
        <Button
          component={Link}
          variant="contained"
          to={generatePath(PathEnum.Upload, { studyId })}
        >
          {t('study.uploadFiles')}
        </Button>
      </Box>
    </Box>
  );
};

export default StudyHeader;
