import { Box, CircularProgress } from '@mui/material';
import React from 'react';

const FullScreenLoader: React.FC = () => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" height="100%" width="100%">
      <CircularProgress />
    </Box>
  );
};

export default FullScreenLoader;
