import { Box, TextField, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Colors from '../../../infrastructure/constants/Colors';

interface IProps {
  top?: number;
  left?: number;
  value: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
}

const SpotTextInput: React.FC<IProps> = ({ value, onChange, top, left, disabled }) => {
  const [name, setName] = useState<string>('');

  useEffect(() => {
    setName(value);
  }, [value]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const performOnChange = () => {
    if (name && name !== value) {
      onChange?.(name);
    }
  };

  return (
    <Box position="absolute" top={top} left={left} width={32}>
      <Tooltip title={name} arrow>
        <TextField
          disabled={disabled}
          autoComplete="off"
          id="spot"
          name="spot"
          onChange={handleChange}
          onBlur={performOnChange}
          value={name}
          size="small"
          inputMode="text"
          margin="none"
          sx={{
            '& .MuiInputBase-input': {
              padding: '4px',
              textAlign: 'center',
              color: Colors.red,
              fontSize: '18px',
              lineHeight: '24px',
              fontWeight: 600,
            },
            '& fieldset': {
              borderStyle: 'dashed',
            },
            '& .Mui-disabled.MuiInputBase-input': {
              WebkitTextFillColor: 'unset',
            },
          }}
        />
      </Tooltip>
    </Box>
  );
};

export default SpotTextInput;
