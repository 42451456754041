export enum ImageAlignment {
  TOP = 'TOP',
  BOTTOM = 'BOTTOM',
}

interface IAnalysisReorderInput {
  fileId: string;
  index: number;
  alignment: ImageAlignment;
}

export default IAnalysisReorderInput;
