import { Box, Button, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import GenericModal from '../../ui/GenericModal';

interface IProps {
  open: boolean;
  handleClose: () => void;
  handleSubmit: (path: string) => void;
}

const UploadToFolderModal: React.FC<IProps> = ({ open, handleClose, handleSubmit }) => {
  const { t } = useTranslation();
  const [folderName, setFolderName] = useState('');

  const onFolderNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFolderName(e.target.value);
  };

  const onConfirm = (e) => {
    e.preventDefault();
    handleSubmit(folderName);
  };

  return (
    <GenericModal open={open} onClose={handleClose}>
      <Box textAlign="center" component="form" onSubmit={onConfirm}>
        <Typography id="modal-modal-title" variant="h5" component="h2">
          {t('upload.uploadToFolderModal.title')}
        </Typography>
        <Typography sx={{ mt: 1 }}>{t('upload.uploadToFolderModal.description')}</Typography>

        <TextField
          margin="normal"
          fullWidth
          id="folderName"
          type="text"
          label="Folder name"
          name="folderName"
          inputMode="text"
          size="small"
          onChange={onFolderNameChange}
          value={folderName}
        />

        <Box display="flex" justifyContent="center" mt={2}>
          <Button variant="contained" type="submit">
            {t('button.ok')}
          </Button>
        </Box>
      </Box>
    </GenericModal>
  );
};

export default UploadToFolderModal;
