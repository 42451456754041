import cloneDeep from 'lodash/cloneDeep';
import { useCallback, useState } from 'react';
import useStore from '../../../../infrastructure/store/useStore';
import { ImageAlignment } from '../../types/IAnalysisReorderInput';
import IFile from '../../types/IFile';
import reorder from '../helpers/reorder';

const useRearrangeImages = () => {
  const [items, setItems, setRearrangedItems, setHasChanges] = useStore((state) => [
    state.items,
    state.setItems,
    state.setRearrangedItems,
    state.setHasChanges,
  ]);

  const [dragOverId, setDragOverId] = useState();
  const [dropOverId, setDropOverId] = useState();
  const [dragId, setDragId] = useState();
  const [dropId, setDropId] = useState();
  const [dropHoverId, setDropHoverId] = useState<string>();
  const [nextDropHoverId, setNextDropHoverId] = useState<string>();

  const changeDropHover = useCallback(
    (id: string) => {
      let freshDragId;
      let freshDropId;
      setDragId((prevState) => {
        freshDragId = prevState;
        return prevState;
      });
      setDropId((prevState) => {
        freshDropId = prevState;
        return prevState;
      });
      setNextDropHoverId(id);
      if (!freshDragId && !freshDropId) {
        setDropHoverId(id);
      }
    },
    [setDragId, setDropId],
  );

  // was used timeout for running `changeDropHover` always after handleDragStart
  const handleChangeDropHover = useCallback(
    (id: string) => setTimeout(() => changeDropHover(id), 50),
    [changeDropHover],
  );

  const onDragEnd = useCallback(
    (result) => {
      setDragId(undefined);
      setDropId(undefined);
      setDragOverId(undefined);
      setDropOverId(undefined);
      setDropHoverId(nextDropHoverId);
      const { combine, source, destination, type } = result;

      if (combine) {
        const destDropId = combine.droppableId;
        const destDragId = combine.draggableId;
        const sourceDropId = source.droppableId;
        if (destDropId && destDropId !== sourceDropId) {
          setHasChanges?.(true);
          /// do swap
          const sInd = items.findIndex((el) => el?.[0].id === sourceDropId);
          const dInd = items.findIndex((el) => el?.[0].id === destDropId);
          const dItemInd = items[dInd]?.findIndex((el) => el.id === destDragId);
          if (sInd === -1 || dInd === -1 || dItemInd === undefined || dItemInd === -1) {
            return;
          }
          // cloneDeep fixes the shallow effect issue for items and parsedItems nested sub-arrays
          const newItems = cloneDeep(items);
          const aux = newItems[sInd]?.[source.index];
          if (!aux) {
            return;
          }
          const [removed] = newItems[dInd]!.splice(dItemInd, 1, aux);
          newItems[sInd]!.splice(source.index, 1, removed);
          setItems(newItems);
          setRearrangedItems((prevRearrangedItems) => {
            const newRearrangedItems = prevRearrangedItems.filter((item) => {
              return item.fileId !== aux.id && item.fileId !== removed.id;
            });

            return [
              ...newRearrangedItems,
              {
                fileId: aux.id,
                index: dInd + 1,
                alignment: dItemInd === 0 ? ImageAlignment.TOP : ImageAlignment.BOTTOM,
              },
              {
                fileId: removed.id,
                index: sInd + 1,
                alignment: source.index === 0 ? ImageAlignment.TOP : ImageAlignment.BOTTOM,
              },
            ];
          });
        }
        return;
      }

      // dropped outside the list
      if (!destination) {
        return;
      }

      if (source.index !== destination.index) {
        setHasChanges?.(true);
        if (type === 'column') {
          const newItems = reorder([...items], source.index, destination.index) as IFile[][];
          setItems(newItems);
          const rearrangedDestinationItem = newItems?.[destination.index];
          const rearrangedSourceItem = newItems?.[source.index];
          const topItemDestination = rearrangedDestinationItem?.[0];
          const bottomItemDestination = rearrangedDestinationItem?.[1];
          const topItemSource = rearrangedSourceItem?.[0];
          const bottomItemSource = rearrangedSourceItem?.[1];
          if (
            !!topItemSource &&
            !!bottomItemSource &&
            !!topItemDestination &&
            !!bottomItemDestination
          ) {
            setRearrangedItems((prevReaaragenedItems) => {
              let newReaaragenedItems = [...prevReaaragenedItems];
              const foundTopSourceItem = prevReaaragenedItems.find(
                (item) => item.fileId === topItemSource?.id,
              );
              const foundBottomSourceItem = prevReaaragenedItems.find(
                (item) => item.fileId === bottomItemSource?.id,
              );
              const foundTopDestinationItem = prevReaaragenedItems.find(
                (item) => item.fileId === topItemDestination?.id,
              );
              const foundBottomDestinationItem = prevReaaragenedItems.find(
                (item) => item.fileId === bottomItemDestination?.id,
              );

              const topSourceItemToSet = foundTopSourceItem
                ? { ...foundTopSourceItem, index: source.index + 1 }
                : {
                    fileId: topItemSource?.id,
                    index: source.index + 1,
                    alignment: ImageAlignment.TOP,
                  };
              const bottomSourceItemToSet = foundBottomSourceItem
                ? { ...foundBottomSourceItem, index: source.index + 1 }
                : {
                    fileId: bottomItemSource?.id,
                    index: source.index + 1,
                    alignment: ImageAlignment.BOTTOM,
                  };
              const topDestinationItemToSet = foundTopDestinationItem
                ? { ...foundTopDestinationItem, index: destination.index + 1 }
                : {
                    fileId: topItemDestination?.id,
                    index: destination.index + 1,
                    alignment: ImageAlignment.TOP,
                  };
              const bottomDestinationItemToSet = foundBottomDestinationItem
                ? { ...foundBottomDestinationItem, index: destination.index + 1 }
                : {
                    fileId: bottomItemDestination?.id,
                    index: destination.index + 1,
                    alignment: ImageAlignment.BOTTOM,
                  };
              if (
                !!foundBottomDestinationItem ||
                !!foundTopDestinationItem ||
                !!foundBottomSourceItem ||
                !!foundTopSourceItem
              ) {
                newReaaragenedItems = prevReaaragenedItems.filter((item) => {
                  return (
                    item.fileId !== topItemSource?.id &&
                    item.fileId !== bottomItemSource?.id &&
                    item.fileId !== topItemDestination?.id &&
                    item.fileId !== bottomItemDestination?.id
                  );
                });
              }
              return [
                ...newReaaragenedItems,
                topSourceItemToSet,
                bottomSourceItemToSet,
                topDestinationItemToSet,
                bottomDestinationItemToSet,
              ];
            });
          }
        } else if (source.droppableId && source.droppableId === destination.droppableId) {
          const sInd = items.findIndex((el) => el?.[0].id === source.droppableId);
          const newItems = [...items];
          newItems[sInd] = reorder(
            items[sInd],
            result.source.index,
            result.destination.index,
          ) as IFile[];
          setItems(newItems);
          const sourceItem = newItems[sInd]?.[source.index];
          const destinationItem = newItems[sInd]?.[destination.index];
          if (!!sourceItem && !!destinationItem) {
            setRearrangedItems((prevReaaragenedItems) => {
              let newReaaragenedItems = [...prevReaaragenedItems];
              const foundSourceItem = prevReaaragenedItems.find(
                (item) => item.fileId === sourceItem?.id,
              );
              const foundDestinationItem = prevReaaragenedItems.find(
                (item) => item.fileId === destinationItem?.id,
              );
              const newSourceAlignment =
                source.index === 0 ? ImageAlignment.TOP : ImageAlignment.BOTTOM;
              const newDestinationAlignment =
                destination.index === 0 ? ImageAlignment.TOP : ImageAlignment.BOTTOM;
              const sourceItemToSet = foundSourceItem
                ? { ...foundSourceItem, alignment: newSourceAlignment }
                : {
                    fileId: sourceItem?.id,
                    index: sourceItem.index,
                    alignment: newSourceAlignment,
                  };
              const destinationItemToSet = foundDestinationItem
                ? { ...foundDestinationItem, alignment: newDestinationAlignment }
                : {
                    fileId: destinationItem?.id,
                    index: destinationItem.index,
                    alignment: newDestinationAlignment,
                  };
              if (!!foundSourceItem || !!foundDestinationItem) {
                newReaaragenedItems = prevReaaragenedItems.filter((item) => {
                  return (
                    item.fileId !== foundDestinationItem?.fileId &&
                    item.fileId !== foundSourceItem?.fileId
                  );
                });
              }
              return [...newReaaragenedItems, sourceItemToSet, destinationItemToSet];
            });
          }
        }
      }
    },
    [setHasChanges, items, setRearrangedItems, nextDropHoverId],
  );

  const handleDragUpdate = useCallback((result) => {
    const { combine, destination } = result;
    setDragOverId(combine ? combine?.draggableId : combine);
    setDropOverId(combine?.droppableId || destination?.droppableId);
  }, []);

  const handleDragStart = useCallback((result) => {
    setDragId(result.draggableId);
    setDropId(result.source.droppableId);
    setDropOverId(result.source.droppableId);
  }, []);

  return {
    dragOverId,
    setDragOverId,
    dropOverId,
    setDropOverId,
    dragId,
    setDragId,
    dropId,
    setDropId,
    dropHoverId,
    handleChangeDropHover,
    onDragEnd,
    handleDragStart,
    handleDragUpdate,
  };
};

export default useRearrangeImages;
