import ClickAwayListener from '@mui/base/ClickAwayListener';
import { Search } from '@mui/icons-material';
import { Box, Collapse, InputAdornment, TextField } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
  open: boolean;
  value: string;
  onChange: (value: string) => void;
  onClose: () => void;
}

const SearchInput: React.FC<IProps> = ({ open, value, onChange, onClose }) => {
  const { t } = useTranslation();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <Box position="absolute" right={0}>
      <Collapse
        in={open}
        unmountOnExit
        orientation="horizontal"
        timeout={{ enter: 1000, exit: 1000 }}
        collapsedSize={50}
      >
        <ClickAwayListener onClickAway={onClose}>
          <Box width={300}>
            <TextField
              sx={{
                '& .MuiInputBase-root': {
                  borderRadius: '100px',
                },
                '& input': {
                  padding: '6px 10px',
                },
              }}
              autoComplete="off"
              value={value}
              onChange={handleChange}
              onSubmit={onClose}
              autoFocus
              fullWidth
              variant="outlined"
              placeholder={t('search.search')}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </ClickAwayListener>
      </Collapse>
    </Box>
  );
};

export default SearchInput;
