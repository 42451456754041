import { DDALine } from 'dda-line-algorithm';
import { MouseEvent, useCallback, useEffect, useMemo } from 'react';
import useStore from '../../../../infrastructure/store/useStore';
import useMaskChanges from '../../hooks/useMaskChanges';
import useSetRef from '../../hooks/useSetRef';
import useTopView from '../../MaskSelector/hooks/useTopView';
import EditorColors from '../constants/EditorColors';
import ImageEditorConfig from '../constants/ImageEditor.config';
import autoFillShapes from '../helpers/autoFillShapes';
import clearCanvas from '../helpers/clearCanvas';
import clearSquaresInCanvas from '../helpers/clearSquaresInCanvas';
import coordinatesEqual from '../helpers/coordinatesEqual';
import fillCanvas from '../helpers/fillCanvas';
import getSliceIndexByYCoordinate from '../helpers/getSliceIndexByYCoordinate';
import getStartCoordsOfSquare from '../helpers/getStartCoordsOfSquare';
import { getFromToCoordinatesForTopView } from '../helpers/getTopViewFilledCoordinates';
import ICoordinates from '../types/ICoordinates';
import IRowCoordsSet from '../types/IRowCoordsSet';
import IToolsEnum from '../types/IToolsEnum';
import useBoundaries from './useBoundaries';

const useScissors = (canvas: HTMLCanvasElement | null, canvasTemp: HTMLCanvasElement | null) => {
  const [firstCoordsRef, setFirstCoords] = useSetRef<ICoordinates | null>(null);
  const [lastClickCoordsRef, setLastClickCoords] = useSetRef<ICoordinates | null>(null);
  const [lastCoordsRef, setLastCoords] = useSetRef<ICoordinates | null>(null);
  const [lastLineToRef, setLastLineTo] = useSetRef<ICoordinates | null>(null);
  const [drawingRef, setDrawing] = useSetRef(false);
  const { updateTopView, copyToTopView } = useTopView();
  const { boundingRef, updateBounding, clearBounding } = useBoundaries();
  const [
    masks,
    selectedMaskIndex,
    selectedImage,
    brushSize,
    maskRefs,
    selectedTool,
    updateErasedCoordinates,
  ] = useStore((state) => [
    state.masks,
    state.selectedMaskIndex,
    state.selectedImage,
    state.brushSize,
    state.maskRefs,
    state.selectedTool,
    state.updateErasedCoordinates,
  ]);
  const { pushChanges } = useMaskChanges();
  const ctx = canvas?.getContext('2d');
  const ctxTemp = canvasTemp?.getContext('2d');

  const color = useMemo(() => {
    if (selectedTool === IToolsEnum.TOOL_PENCIL) {
      return ImageEditorConfig.COLORS_ORDER[selectedMaskIndex];
    }
    return EditorColors.white;
  }, [selectedTool, selectedMaskIndex]);

  useEffect(() => {
    if (!ctx || !canvas) return;
    ctx.lineCap = 'round';
    ctx.lineJoin = 'round';
    ctx.strokeStyle = color;
    ctx.imageSmoothingQuality = 'high';
    ctx.lineWidth = ImageEditorConfig.DEFAULT_PENCIL_SIZE;
  }, [ctx]);

  useEffect(() => {
    if (!ctxTemp) return;
    ctxTemp.lineCap = 'round';
    ctxTemp.lineJoin = 'round';
    ctxTemp.strokeStyle = EditorColors.white;
    ctxTemp.imageSmoothingQuality = 'high';
    ctxTemp.lineWidth = 4;
  }, [ctxTemp]);

  const onToolChange = useCallback(() => {
    if (!ctx || !canvas) return;
    ctx.strokeStyle = color;
    setLastCoords(null);
    setFirstCoords(null);

    clearBounding();
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctxTemp?.clearRect(0, 0, canvas.width, canvas.height);
    if (selectedTool === IToolsEnum.TOOL_PENCIL) {
      ctx.lineWidth = brushSize;
      ctx.setLineDash([]);
      return;
    }
    ctx.setLineDash([5, 10]);
    ctx.lineWidth = 2;
  }, [color, brushSize, selectedTool, masks[selectedMaskIndex].visible, ctx]);

  useEffect(onToolChange, [onToolChange]);

  if (!canvas || !ctx || !ctxTemp || !canvasTemp) {
    return {
      onMouseDown: () => {},
      onMouseMove: () => {},
      onMouseUp: () => {},
      onDoubleClick: () => {},
      onMouseLeave: () => {},
    };
  }

  const angleDeg = (p1, p2) => (Math.atan2(p2.y - p1.y, p2.x - p1.x) * 180) / Math.PI;

  const drawLine = (context: CanvasRenderingContext2D, start: ICoordinates, end: ICoordinates) => {
    if (selectedImage?.isVIPImage) {
      const coordinates = DDALine(start.x, start.y, end.x, end.y) as ICoordinates[];
      let prevCoord: ICoordinates | null = null;
      if (coordinates.length === 1) {
        coordinates.push(end);
      }
      coordinates.forEach((coordinate, index) => {
        if (index === 0 || !prevCoord) {
          prevCoord = coordinate;
          return;
        }
        const { x, y } = getStartCoordsOfSquare(coordinate, canvas.height);
        context.beginPath();
        const angle = angleDeg(prevCoord, { x, y });
        context.moveTo(prevCoord.x, prevCoord.y);
        if (angle % 90 !== 0) {
          context.lineTo(x, prevCoord.y);
        }
        context.lineTo(x, y);
        context.stroke();
        context.closePath();
        prevCoord = { x, y };
      });
      return;
    }
    context.beginPath();
    context.moveTo(start.x, start.y);
    context.lineTo(end.x, end.y);
    context.stroke();
    context.closePath();
  };

  const clearMasks = (filledCoords: ICoordinates[]) => {
    const refs = selectedTool === IToolsEnum.TOOL_SWORD ? maskRefs : [maskRefs[selectedMaskIndex]];
    const wasCleared = refs.map((maskRef) => {
      if (maskRef && maskRef.current) {
        if (selectedImage?.isVIPImage) {
          return clearSquaresInCanvas(maskRef.current, filledCoords);
        }
        return clearCanvas(maskRef.current, filledCoords);
      }
      return false;
    });
    if (wasCleared.includes(true)) {
      updateTopView(getFromToCoordinatesForTopView(maskRefs));
      pushChanges();
    }
  };

  const fillMask = (filledCoords: ICoordinates[]) => {
    const wasFilled = maskRefs.map((maskRef, index) => {
      if (maskRef && maskRef.current) {
        if (index === selectedMaskIndex) {
          return fillCanvas(maskRef.current, filledCoords, ImageEditorConfig.COLORS_ORDER[index]);
        }
        return clearCanvas(maskRef.current, filledCoords);
      }
      return false;
    });

    if (wasFilled.includes(true)) {
      updateTopView(getFromToCoordinatesForTopView(maskRefs));
      pushChanges();
    }
  };

  const clearCoords = () => {
    setLastCoords(null);
    setFirstCoords(null);
    setLastClickCoords(null);
    setLastLineTo(null);
    clearBounding();
  };

  const autoFill = () => {
    if (!ctx || !canvas) return;
    const [found, filledCoords] = autoFillShapes(canvas, boundingRef.current);
    if (!found) return;
    fillMask(filledCoords);
    clearCoords();
    ctx.clearRect(0, 0, canvas.width, canvas.height);
  };

  const coordinatesToRowSet = (coordinates: ICoordinates[]): IRowCoordsSet => {
    const coords: IRowCoordsSet = {};
    coordinates.forEach((coordinate) => {
      const sliceIndex = getSliceIndexByYCoordinate(coordinate, canvas.height);
      if (!coords[sliceIndex]) {
        coords[sliceIndex] = new Set<number>();
      }
      coords[sliceIndex].add(coordinate.x);
    });
    return coords;
  };

  const cut = () => {
    if (!ctx || !canvas || !ctxTemp || !canvasTemp) return;
    const [found, filledCoords] = autoFillShapes(canvasTemp, boundingRef.current);
    if (!found) return;
    clearMasks(filledCoords);
    clearCoords();
    ctxTemp.clearRect(0, 0, canvasTemp.width, canvasTemp.height);
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    const maskCanvas = maskRefs[selectedMaskIndex].current;
    if (selectedImage?.isVIPImage && maskCanvas) {
      updateErasedCoordinates(coordinatesToRowSet(filledCoords));
      copyToTopView(maskCanvas);
    }
  };

  const centerCoordsForVIP = (coords: ICoordinates) => {
    if (!selectedImage?.isVIPImage) return coords;
    return getStartCoordsOfSquare(coords, canvas.height);
  };

  const setFirstLastCoords = (coords: ICoordinates) => {
    setLastCoords(coords);
    if (!firstCoordsRef.current) {
      setFirstCoords(coords);
    }
  };

  const drawLineFromLastCoords = (coords: ICoordinates) => {
    if (!lastCoordsRef.current) return;
    drawLine(ctx, coords, lastCoordsRef.current);
    if (selectedTool !== IToolsEnum.TOOL_PENCIL) {
      drawLine(ctxTemp, coords, lastCoordsRef.current);
    }
    updateBounding(coords);
    setLastCoords(coords);
  };

  const onMouseDown = (e: MouseEvent<HTMLCanvasElement>) => {
    if (e.button !== 0) return;
    if (selectedMaskIndex !== null && !masks[selectedMaskIndex].visible) return;

    const coords = centerCoordsForVIP({ x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY });
    setLastClickCoords(coords);
    setDrawing(true);
    ctx.beginPath();
    ctx.moveTo(coords.x, coords.y);
    ctx.lineTo(coords.x, coords.y);
    ctx.stroke();
    setLastLineTo(coords);
    if (selectedTool !== IToolsEnum.TOOL_PENCIL) {
      ctxTemp.beginPath();
      ctxTemp.moveTo(coords.x, coords.y);
      ctxTemp.lineTo(coords.x, coords.y);
      ctxTemp.stroke();
    }
    if (e.button === 0 && lastCoordsRef.current) {
      drawLineFromLastCoords(coords);
      return;
    }
    updateBounding(coords);
    setFirstLastCoords(coords);
  };

  const onMouseUp = () => {
    setDrawing(false);
    ctx.closePath();
    if (selectedTool === IToolsEnum.TOOL_PENCIL) {
      autoFill();
      return;
    }
    cut();
  };

  const preventDrawingOnClick = (coords: ICoordinates) => {
    if (coordinatesEqual(lastClickCoordsRef.current, coords, 3)) {
      return true;
    }
    return false;
  };

  const onMouseMove = (e: MouseEvent<HTMLCanvasElement>) => {
    const coords = selectedImage?.isVIPImage
      ? getStartCoordsOfSquare(
          { x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY },
          canvas.height,
        )
      : { x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY };
    if (!drawingRef.current) return;
    if (preventDrawingOnClick({ x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY })) {
      return;
    }
    const lastLineCoords = lastLineToRef.current;
    if (lastLineCoords && selectedImage?.isVIPImage) {
      ctx.beginPath();
      ctxTemp.beginPath();
      const angle = angleDeg(lastLineCoords, coords);
      ctx.moveTo(lastLineCoords.x, lastLineCoords.y);
      ctxTemp.moveTo(lastLineCoords.x, lastLineCoords.y);
      if (angle % 90 !== 0) {
        ctx.lineTo(coords.x, lastLineCoords.y);
        ctxTemp.lineTo(coords.x, lastLineCoords.y);
      }
      ctx.lineTo(coords.x, coords.y);
      ctxTemp.lineTo(coords.x, coords.y);
      ctx.stroke();
      ctxTemp.stroke();
      ctx.closePath();
      ctxTemp.closePath();
    } else {
      ctx.lineTo(coords.x, coords.y);
      ctx.stroke();
      if (selectedTool !== IToolsEnum.TOOL_PENCIL) {
        ctxTemp.lineTo(coords.x, coords.y);
        ctxTemp.stroke();
      }
    }
    setLastLineTo(coords);
    updateBounding(coords);
    setFirstLastCoords(coords);
  };

  const onRightClick = (e: MouseEvent<HTMLCanvasElement>) => {
    e.preventDefault();
    if (firstCoordsRef.current && lastCoordsRef.current) {
      drawLine(ctx, firstCoordsRef.current, lastCoordsRef.current);
      if (selectedTool === IToolsEnum.TOOL_PENCIL) {
        autoFill();
        return;
      }
      drawLine(ctxTemp, firstCoordsRef.current, lastCoordsRef.current);
      setFirstCoords(null);
      setLastCoords(null);
      cut();
    }
  };

  const onMouseEnter = (e: MouseEvent<HTMLCanvasElement>) => {
    if (e.nativeEvent.buttons !== 1) {
      setDrawing(false);
    }
  };

  return {
    onMouseDown,
    onMouseUp,
    onMouseMove,
    onMouseEnter,
    onContextMenu: onRightClick,
  };
};

export default useScissors;
