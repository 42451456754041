import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FileWithPath } from 'react-dropzone';
import { useLocation, useParams } from 'react-router-dom';
import { NotificationType } from '../../../infrastructure/types/INotification';
import API from '../../../infrastructure/utils/API';
import asyncGenerator from '../../../infrastructure/utils/asyncGenerator';
import IDropboxNotificationError from '../../notifications/types/IDropboxNotificationError';
import { GET_STUDY } from '../../study/graphql/useStudyQuery';
import useAddFilesToAnalysisMutation from '../graphql/useAddFilesToAnalysisMutation';
import useAddFilesToStudyFomS3 from '../graphql/useAddFilesToStudyFomS3';
import useCancelDropboxCopy from '../graphql/useCancelDropboxCopy';
import useCopyFilesFromDropboxMutation from '../graphql/useCopyFilesFromDropboxMutation';
import useCreateNotificationFromApp from '../graphql/useCreateNotificationFromApp';
import useDropboxProgressSubscription from '../graphql/useDropboxProgressSubscription';
import useRequestToAddFilesToStudy from '../graphql/useRequestToAddFilesToStudy';
import IFileUploadErrors from '../types/FileUploadErrors';
import IStepEnum from '../types/IStepEnum';
import UploadFileTypeEnum from '../types/UploadFileEnum';
import IProjectFile from '../types/IProjectFile';
import IProjectFolder from '../types/IProjectFolder';
import useStore from '../../../infrastructure/store/useStore';
import getChildrenIds from '../helpers/getChildrenIds';

export enum UploadTypeEnum {
  STUDY = 'STUDY',
  ANALYSIS = 'ANALYSIS',
}

export interface IFile {
  id: string;
  file: FileWithPath;
  selected: boolean;
  progress?: number;
}

export interface IUploadFile {
  fileObject: FileWithPath;
  file: string;
  s3Id: string;
  uploadUrl: string;
  id: string;
}

interface IUploadContext {
  type: UploadTypeEnum;
  step: IStepEnum;
  aborted: boolean;
  startUpload: (path: string) => Promise<void>;
  startAddingFilesToAnalysis: () => Promise<void>;
  files: IFile[];
  filesCount: number;
  uploadedFileCount: number;
  percentage: number;
  uploaded: boolean;
  setFiles: (files: IFile[]) => void;
  toggleFile: (key: number) => void;
  isDragOver: boolean;
  onDragOver: () => void;
  onDragLeave: () => void;
  selectedFilesCount: number;
  addSelectedFileIds: (id: string[], removeIds?: string[]) => void;
  addSelectedFileId: (id: string, removeIds?: string[]) => void;
  setSelectedFileIds: (id: string[]) => void;
  removeSelectedFileIds: (id: string[]) => void;
  removeSelectedFileId: (id: string) => void;
  selectedFilesIds: string[];
  lastSelectedFileId: string | null;
  selectedDropboxFileCount: number;
  selectedDropboxFileIds: string[];
  handleSelectDropboxFile: (id: string | string[]) => void;
  dropboxFileUploaded: number;
  dropboxFileProgress: number;
  setSelectedDropboxFileIds: (ids: string[] | ((prevValue: string[]) => string[])) => void;
  dropboxFilesSize: number;
  setDropboxFilesSize: (size: number) => void;
  abortUpload: () => void;
  dropboxFileErrors: IDropboxNotificationError[];
  fileUploadErrors: IFileUploadErrors[];
  uploadType: UploadFileTypeEnum | null;
  getRangeBetweenSelectedFilesIds: (
    nextId: string,
    prevId: string,
    projectFiles: Record<string, (IProjectFile | IProjectFolder)[]>,
  ) => string[];
}

export const UploadContext = React.createContext<IUploadContext>({
  type: UploadTypeEnum.STUDY,
  step: IStepEnum.SELECT_FILES,
  startUpload: async () => {},
  startAddingFilesToAnalysis: async () => {},
  aborted: false,
  files: [],
  filesCount: 0,
  uploadedFileCount: 0,
  percentage: 0,
  uploaded: false,
  setFiles: () => {},
  toggleFile: () => null,
  isDragOver: false,
  onDragOver: () => null,
  onDragLeave: () => null,
  selectedFilesCount: 0,
  addSelectedFileIds: () => null,
  addSelectedFileId: () => null,
  setSelectedFileIds: () => null,
  removeSelectedFileIds: () => null,
  removeSelectedFileId: () => null,
  selectedFilesIds: [],
  lastSelectedFileId: null,
  selectedDropboxFileCount: 0,
  selectedDropboxFileIds: [],
  handleSelectDropboxFile: () => null,
  dropboxFileUploaded: 0,
  dropboxFileProgress: 0,
  setSelectedDropboxFileIds: () => null,
  dropboxFilesSize: 0,
  setDropboxFilesSize: () => null,
  abortUpload: () => null,
  dropboxFileErrors: [],
  fileUploadErrors: [],
  uploadType: null,
  getRangeBetweenSelectedFilesIds: () => [],
});

interface IFileUploadProgress {
  [key: string]: number;
}

interface ILocationState {
  skipSelectFiles?: boolean;
}

export const UploadProvider: React.FC<{ type: UploadTypeEnum }> = ({ type, children }) => {
  const location = useLocation();
  const locationState = location.state as ILocationState;
  const skipSelectFiles = locationState && locationState?.skipSelectFiles;
  const [step, setStep] = useState<IStepEnum>(
    skipSelectFiles ? IStepEnum.UPLOAD_FILES_PROGRESS : IStepEnum.SELECT_FILES,
  );
  const [isDragOver, setIsDragOver] = useState(false);
  const [selectedFilesIds, setSelectedFilesIds] = useState<string[]>([]);
  const [lastSelectedFileId, setLastSelectedFileId] = useState<string | null>(null);
  const [selectedDropboxFileIds, setSelectedDropboxFileIds] = useState<string[]>([]);
  const [uploadProgress, setUploadProgress] = useState<IFileUploadProgress>({});
  const [totalSize, setTotalSize] = useState<number>(0);
  const [dropboxFileProgressId, setDropboxFileProgressId] = useState<string>();
  const [dropboxFilesSize, setDropboxFilesSize] = useState<number>(0);
  const abortController = useRef(new AbortController());
  const [aborted, setAborted] = useState(false);
  const [uploadedFileCount, setUploadedFileCount] = useState(0);
  const [fileUploadErrors, setFileUploadErrors] = useState<IFileUploadErrors[]>([]);
  const [assigned, setAssigned] = useState(Boolean(locationState?.skipSelectFiles));
  const projectFiles = useStore((state) => state.projectFiles);

  const { studyId, analysisId } = useParams();

  const [files, setFiles] = useState<IFile[]>([]);

  const uploaded = useMemo(() => {
    if (assigned) {
      return true;
    }
    return (
      uploadedFileCount > 0 &&
      fileUploadErrors.length + uploadedFileCount === files.filter((f) => f.selected).length
    );
  }, [uploadedFileCount, files, assigned, fileUploadErrors]);

  const [mutateCreateNotificationFromApp] = useCreateNotificationFromApp({});

  useEffect(() => {
    if (!uploaded || assigned) {
      return;
    }
    mutateCreateNotificationFromApp({
      variables: {
        input: {
          type: NotificationType.STUDY_FILES_UPLOADED,
          successCount: uploadedFileCount,
          unsuccessCount: fileUploadErrors.length,
          studyId,
          errors: fileUploadErrors.map((e) => ({
            message: e.error,
            sentFile: e.fileName,
          })),
        },
      },
    });
  }, [uploaded, fileUploadErrors, uploadedFileCount, assigned]);

  const { data: dropboxFileProgressData } = useDropboxProgressSubscription({
    variables: {
      id: dropboxFileProgressId,
    },
    skip: !dropboxFileProgressId,
    shouldResubscribe: true,
  });

  const dropboxFileProgress = useMemo(
    () => Math.floor(dropboxFileProgressData?.dropboxCopyProgressUpdate?.progress || 0),
    [dropboxFileProgressData],
  );

  const dropboxFileErrors = useMemo(() => {
    return dropboxFileProgressData?.dropboxCopyProgressUpdate?.errors || [];
  }, [dropboxFileProgressData]);

  const dropboxFileUploaded = useMemo(
    () => dropboxFileProgressData?.dropboxCopyProgressUpdate?.uploaded || 0,
    [dropboxFileProgressData],
  );

  const getParent = useCallback(
    (itemId: string) => {
      const parentId = Object.keys(projectFiles).filter((key) => {
        return projectFiles[key].some((file) => file.id === itemId);
      })[0];
      return parentId;
    },
    [projectFiles],
  );

  const getFilteredFileIds = useCallback(
    (ids: string[]) => {
      const newValue = ids.filter((value, index, array) => array.indexOf(value) === index);
      const filteredIds: string[] = newValue.reduce((acc: string[], id: string) => {
        const parentId = getParent(id);
        const arr = [...acc];
        if (arr.includes(parentId)) return arr;
        if (!parentId) {
          arr.push(id);
          return arr;
        }
        const childrenIds = projectFiles[parentId].map((file) => file.id) || [];
        const allChildrenSelected =
          childrenIds.every((childId) => {
            return newValue.includes(childId);
          }) && parentId !== 'root';
        if (allChildrenSelected) {
          arr.push(parentId);
          return arr;
        }
        arr.push(id);
        return arr;
      }, []);

      if (newValue.length === filteredIds.length) return filteredIds;

      const nextFilteredIds = getFilteredFileIds(filteredIds);
      return nextFilteredIds;
    },
    [setSelectedFilesIds, getParent],
  );

  const addSelectedFileIds = useCallback(
    (ids: string[], removeIds: string[] = []) => {
      if (ids.length === 0) return;
      const filteredIds = getFilteredFileIds([...ids, ...selectedFilesIds]) || [];
      setSelectedFilesIds(filteredIds.filter((id) => !removeIds.includes(id)));
    },
    [getFilteredFileIds, setSelectedFilesIds, selectedFilesIds],
  );

  const addSelectedFileId = useCallback(
    (id: string, removeIds: string[] = []) => {
      setLastSelectedFileId(id);
      const filteredIds = getFilteredFileIds([id, ...selectedFilesIds]) || [];
      setSelectedFilesIds(filteredIds.filter((fileId) => !removeIds.includes(fileId)));
    },
    [setLastSelectedFileId, getFilteredFileIds, setSelectedFilesIds, selectedFilesIds],
  );

  const getRemovableParentFolderId = useCallback(
    (id: string) => {
      const parentId = getParent(id);
      if (selectedFilesIds.includes(parentId)) return parentId;
      return getRemovableParentFolderId(parentId);
    },
    [getParent, selectedFilesIds],
  );

  const removeSelectedFileIds = useCallback(
    (ids: string[]) => {
      setSelectedFilesIds((prevValue) => {
        return prevValue.filter((fileId) => !ids.includes(fileId));
      });
      setLastSelectedFileId(null);
    },
    [setSelectedFilesIds, selectedFilesIds],
  );

  const setSelectedFileIds = useCallback(
    (id: string[]) => {
      setSelectedFilesIds(id);
    },
    [setSelectedFilesIds],
  );

  const removeSelectedFileId = useCallback(
    (id: string) => {
      setLastSelectedFileId(null);
      if (selectedFilesIds.includes(id)) {
        setSelectedFilesIds((prevValue: string[]) => prevValue.filter((fileId) => fileId !== id));
        return;
      }
      const parentId = getRemovableParentFolderId(id);
      const childrenIds = getChildrenIds([], projectFiles[parentId], projectFiles, true);
      const filteredFileIds = selectedFilesIds.filter((fileId) => fileId !== parentId);
      const filteredChildrenIds = childrenIds.filter((fileId) => {
        const parent = getParent(fileId);
        return fileId !== id && parent !== id;
      });

      const filteredIds = getFilteredFileIds([...filteredFileIds, ...filteredChildrenIds]) || [];
      setSelectedFilesIds(filteredIds);
    },
    [setSelectedFilesIds, selectedFilesIds, getRemovableParentFolderId],
  );

  const handleSelectDropboxFile = useCallback((id: string | string[]) => {
    if (typeof id === 'string') {
      setSelectedDropboxFileIds((prevFileIds) => {
        const index = prevFileIds.indexOf(id);
        if (index === -1) {
          return [...prevFileIds, id];
        }
        const aux = [...prevFileIds];
        aux.splice(index, 1);
        return aux;
      });
    } else if (id.length > 0) {
      setSelectedDropboxFileIds((prevFileIds) => {
        let aux = [...prevFileIds];
        id.forEach((_id) => {
          const index = aux.indexOf(_id);
          if (index === -1) {
            aux = [...aux, _id];
          } else {
            aux.splice(index, 1);
          }
        });
        return aux;
      });
    }
  }, []);

  const [requestToAddFilesToStudy] = useRequestToAddFilesToStudy({});
  const [addFilesToStudyFomS3] = useAddFilesToStudyFomS3({});

  const performUploadItem = useCallback(
    async (item: IUploadFile, retry: number = 0) => {
      if (!studyId) return;

      return API.put(item.uploadUrl, item.fileObject, {
        signal: abortController.current.signal,
        onUploadProgress: (progressEvent) => {
          setUploadProgress((prevState) => ({
            ...prevState,
            [item.id]: progressEvent.loaded,
          }));
        },
        headers: undefined,
      })
        .then(() => {
          addFilesToStudyFomS3({
            variables: {
              s3Ids: [item.s3Id],
            },
          });
          setUploadedFileCount((prevValue) => prevValue + 1);
        })
        .catch((error) => {
          setUploadProgress((prevState) => ({
            ...prevState,
            [item.id]: 0,
          }));
          if (error.message === 'canceled') {
            setAborted(true);
            return;
          }

          const timeouts = { 1: 5000, 2: 60 * 1000, 3: 5 * 60 * 1000 };

          if (retry < 3) {
            return setTimeout(() => performUploadItem(item, retry + 1), timeouts[retry + 1]);
          }
          setFileUploadErrors((prevValue) => [
            ...prevValue,
            {
              error: error.message,
              fileName: item.file,
              fileId: item.id,
            },
          ]);
        });
    },
    [studyId, setUploadProgress, setAborted, fileUploadErrors, setFileUploadErrors],
  );

  const [mutateCopyFilesFromDropbox] = useCopyFilesFromDropboxMutation({});
  const [mutateCancelDropboxCopy] = useCancelDropboxCopy({});

  const abortUpload = useCallback(() => {
    if (dropboxFileProgressId && selectedDropboxFileIds.length > 0) {
      mutateCancelDropboxCopy({
        variables: {
          id: dropboxFileProgressId,
        },
      }).then(() => {
        setAborted(true);
      });
    }
    abortController.current.abort();
  }, [dropboxFileProgressId]);

  const fileChunks = (selectedFiles: IFile[]) => {
    const chunks: IFile[][] = [];
    const chunkSize = 10;
    for (let i = 0; i < selectedFiles.length; i += chunkSize) {
      chunks.push(selectedFiles.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const chunkUpload = async (chunk) => {
    await Promise.all(
      chunk.map(async (file: IUploadFile) => {
        await performUploadItem(file);
      }),
    );
    return chunk;
  };

  const uploadFiles = useCallback(
    async (selectedFiles, path) => {
      const paths = selectedFiles.map((file: IFile) => {
        if (file.file.path?.startsWith('/')) {
          return `${path}${file.file.path}`;
        }
        return `${path}/${file.file.name}`;
      });
      const { data } = await requestToAddFilesToStudy({
        variables: {
          studyId,
          files: paths,
        },
      });
      const filesToUpload = selectedFiles.map((file: IFile, index: number) => {
        return {
          fileObject: file.file,
          ...data.requestToAddFilesToStudy[index],
          id: file.id,
        };
      });

      const chunks = fileChunks(filesToUpload);

      const chunkIterator = asyncGenerator<IFile[], IFile[]>(chunks, chunkUpload);
      const results: IFile[][] = [];
      // eslint-disable-next-line no-restricted-syntax
      for await (const chunk of chunkIterator) {
        results.push(chunk);
      }
      return results;
    },

    [performUploadItem],
  );

  const startUpload = useCallback(
    async (path) => {
      setStep(IStepEnum.UPLOAD_FILES_PROGRESS);
      if (!studyId) return;
      if (selectedDropboxFileIds.length > 0) {
        await mutateCopyFilesFromDropbox({
          variables: {
            itemIds: selectedDropboxFileIds,
            studyId,
            path,
          },
        }).then(({ data }) => {
          setDropboxFileProgressId(data?.copyFilesFromDropbox?.id);
        });
      }
      const selectedFiles = files.filter((file) => file.selected);
      if (selectedFiles) {
        setTotalSize(selectedFiles.reduce((acc, file) => acc + file.file.size, 0));
        await uploadFiles(selectedFiles, path);
      }
    },
    [studyId, files, performUploadItem, setStep, selectedDropboxFileIds],
  );

  const [mutateFilesToAnalysis] = useAddFilesToAnalysisMutation({});

  const startAddingFilesToAnalysis = useCallback(async () => {
    if (!analysisId || selectedFilesIds.length === 0) {
      return;
    }
    setStep(IStepEnum.UPLOAD_FILES_PROGRESS);

    mutateFilesToAnalysis({
      variables: {
        input: {
          analysisId,
          fileIds: selectedFilesIds,
        },
      },
      refetchQueries: [GET_STUDY],
    }).then(() => {
      setAssigned(true);
    });
  }, [analysisId, selectedFilesIds, mutateFilesToAnalysis]);

  const onDragOver = useCallback(() => {
    if (!isDragOver) {
      setIsDragOver(true);
    }
  }, [isDragOver]);

  const onDragLeave = useCallback(() => {
    setIsDragOver(false);
  }, []);

  const toggleFile = useCallback(
    (key: number) => {
      setFiles((prevFiles) => {
        const copyFiles = [...prevFiles];
        copyFiles[key].selected = !copyFiles[key].selected;
        return copyFiles;
      });
    },
    [setFiles],
  );

  const uploadType = useMemo(() => {
    if (selectedDropboxFileIds.length > 0) {
      return UploadFileTypeEnum.DROPBOX;
    }
    return UploadFileTypeEnum.COMPUTER_FILE;
  }, [selectedDropboxFileIds]);

  const uploadedSize = useMemo(() => {
    return Object.values(uploadProgress).reduce((acc, progress) => acc + progress, 0);
  }, [uploadProgress]);

  const percentage = useMemo(() => {
    if (uploadedFileCount === files.filter((file) => file.selected).length) return 100;
    if (totalSize === 0) return 0;
    return Math.min((uploadedSize / totalSize) * 100, 99.99);
  }, [uploadedFileCount, files, uploadedSize, totalSize]);

  const getChildren = useCallback(
    (parentId: string, filesArray: (IProjectFile | IProjectFolder)[]) => {
      const childrenArr = filesArray.filter((file) => {
        return file.parent === parentId;
      });
      return [...childrenArr.map((child) => [child, ...getChildren(child.id, filesArray)])].flat(1);
    },
    [],
  );

  const getRangeBetweenSelectedFilesIds = useCallback(
    (nextId: string, prevId: string) => {
      const nextParentId = getParent(nextId);
      const prevParentId = getParent(prevId);
      if (!nextParentId || !prevParentId) return [];
      const folderKeys = Object.keys(projectFiles);
      const filesArray = folderKeys
        .map((key) => {
          return [...projectFiles[key]].map((item) => ({
            ...item,
            parent: key,
          }));
        })
        .flat(1)
        .sort((a, b) => {
          if (a.type === b.type) return 0;
          if (a.type === 'FOLDER' && b.type === 'FILE') return -1;
          return 1;
        });

      const flattenedArray = filesArray
        .reduce((acc: any[], item) => {
          const arr = [...acc];
          if ((item.parent && item.parent !== 'root') || !item.name) return arr;
          const childs = getChildren(item.id, filesArray);
          if (childs.length > 0) {
            arr.push(...[item, ...childs]);
            return arr;
          }
          arr.push(item);
          return arr;
        }, [])
        .filter((item) => {
          if (item.type && item.type === 'FOLDER') {
            if (item.id === nextId || item.id === prevId) return true;
            if (item.id === prevParentId || item.id === nextParentId) return false;
            return true;
          }
          return true;
        });

      const nextIndex = flattenedArray.findIndex((file) => file.id === nextId);
      const prevIndex = flattenedArray.findIndex((file) => file.id === prevId);

      const range = flattenedArray
        .filter((_, index) => {
          if (nextIndex < prevIndex) {
            return index >= nextIndex && index <= prevIndex;
          }
          return index <= nextIndex && index >= prevIndex;
        })
        .map((file) => file.id);

      return range;
    },
    [getParent, projectFiles],
  );

  const value = useMemo(
    () => ({
      type,
      step,
      aborted,
      startUpload,
      startAddingFilesToAnalysis,
      files,
      filesCount: files.filter((file) => file.selected).length,
      uploadedFileCount,
      uploaded,
      percentage,
      setFiles,
      toggleFile,
      isDragOver,
      onDragOver,
      onDragLeave,
      selectedFilesCount: selectedFilesIds.length,
      addSelectedFileIds,
      addSelectedFileId,
      setSelectedFileIds,
      removeSelectedFileIds,
      removeSelectedFileId,
      selectedFilesIds,
      lastSelectedFileId,
      selectedDropboxFileIds,
      selectedDropboxFileCount: selectedDropboxFileIds.length,
      handleSelectDropboxFile,
      dropboxFileProgress,
      dropboxFileUploaded,
      setSelectedDropboxFileIds,
      dropboxFilesSize,
      setDropboxFilesSize,
      abortUpload,
      dropboxFileErrors,
      fileUploadErrors,
      uploadType,
      getRangeBetweenSelectedFilesIds,
    }),
    [
      type,
      step,
      aborted,
      startUpload,
      startAddingFilesToAnalysis,
      files,
      uploadedFileCount,
      setFiles,
      uploaded,
      percentage,
      toggleFile,
      isDragOver,
      onDragOver,
      onDragLeave,
      addSelectedFileIds,
      addSelectedFileId,
      setSelectedFileIds,
      removeSelectedFileIds,
      removeSelectedFileId,
      selectedFilesIds,
      lastSelectedFileId,
      selectedDropboxFileIds,
      handleSelectDropboxFile,
      dropboxFileProgress,
      dropboxFileUploaded,
      setSelectedDropboxFileIds,
      dropboxFilesSize,
      setDropboxFilesSize,
      abortUpload,
      dropboxFileErrors,
      fileUploadErrors,
      uploadType,
      getRangeBetweenSelectedFilesIds,
    ],
  );
  return <UploadContext.Provider value={value}>{children}</UploadContext.Provider>;
};

export const useUploadContext = () => React.useContext(UploadContext);
