import { Box } from '@mui/material';
import { grey } from '@mui/material/colors';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useStore from '../../../infrastructure/store/useStore';
import IFile from '../types/IFile';
import LabelsSection from './LabelsSection';

interface IProps {
  files: IFile[];
}

const ImageLabellingLayout: React.FC<IProps> = ({ files }) => {
  const { t } = useTranslation();
  const [selectedImageFile] = useStore((state) => [state.selectedImageFile]);

  if (!selectedImageFile) {
    return (
      <Box
        display="flex"
        flexDirection="row"
        height="100%"
        alignItems="center"
        justifyContent="center"
        flex={1}
      >
        {t('analysis.noImageSelected')}
      </Box>
    );
  }

  return (
    <>
      <Box flex={1}>
        <Box display="flex" flexDirection="column" height="100%">
          <Box
            sx={{
              position: 'relative',
              background: grey[200],
              flex: 1,
              pl: 8,
              pt: 2,
              pb: 1,
              pr: 8,
              textAlign: 'center',
            }}
          >
            <img
              src={selectedImageFile.signedUrl}
              alt={selectedImageFile.name}
              style={{
                width: 500,
              }}
            />
          </Box>
          <Box height={40} display="flex" alignItems="center" px={2}>
            {selectedImageFile.name}
          </Box>
        </Box>
      </Box>
      <LabelsSection files={files} />
    </>
  );
};

export default ImageLabellingLayout;
