import Colors from '../infrastructure/constants/Colors';

export enum BorderStyleEnum {
  NONE = 'none',
  SOLID = 'solid',
  DASHED = 'dashed',
  DOTTED = 'dotted',
}

export const getBorder = (
  color: Colors,
  borderStyle: BorderStyleEnum = BorderStyleEnum.SOLID,
  width = 1,
) => {
  return `${width}px ${borderStyle} ${color}`;
};
