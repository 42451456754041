import { Box, Button } from '@mui/material';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import AnalysisInterfaceEnum from '../../../infrastructure/types/AnalysisInterfaceEnum';
import { shouldShowSliceSelector } from '../helpers/CaseHelpers';
import MaskSelector from '../MaskSelector/MaskSelector';
import TopView from '../MaskSelector/TopView';
import IFile from '../types/IFile';
import IImage from '../types/IImage';
import SliceSelector from '../SliceSelector';

interface IProps {
  interfaceModel: AnalysisInterfaceEnum;
  filesLoading: boolean;
  files: IFile[];
  onSliceSelect: (file: IFile) => ((image: HTMLImageElement) => void) | undefined;
  onVipEdit: () => void;
  selectedImage: IImage | null;
}

const ImageEditorRightSide: React.FC<IProps> = ({
  interfaceModel,
  filesLoading,
  files,
  onSliceSelect,
  onVipEdit,
  selectedImage,
}) => {
  const { t } = useTranslation();

  const showSliceSelector = useMemo(
    () => shouldShowSliceSelector(interfaceModel),
    [interfaceModel],
  );

  return (
    <>
      {showSliceSelector && (
        <SliceSelector filesLoading={filesLoading} files={files} onSliceSelect={onSliceSelect} />
      )}
      <Box width={250}>
        <Box display="flex" flexDirection="column" height="100%">
          {showSliceSelector && (
            <Box display="flex" px={2} justifyContent="center" flexDirection="column">
              <TopView filesLoading={filesLoading} files={files} onSliceSelect={onSliceSelect} />
              <Button
                variant="contained"
                color="secondary"
                sx={{ mt: 1 }}
                onClick={onVipEdit}
                disabled={selectedImage?.isVIPImage}
              >
                {t('analysis.editVIPImage')}
              </Button>
            </Box>
          )}
          <MaskSelector showSliceSelector={showSliceSelector} parentHeight={620} />
        </Box>
      </Box>
    </>
  );
};

export default ImageEditorRightSide;
