import React, { useRef } from 'react';
import useStore from '../../../infrastructure/store/useStore';
import useScissors from './hooks/useScissors';
import IToolsEnum from './types/IToolsEnum';

interface IProps {
  width: number;
  height: number;
}

const ScissorsCanvas: React.FC<IProps> = ({ width, height }) => {
  const scissorsCanvasRef = useRef<HTMLCanvasElement>(null);
  const [selectedTool, offScreenCanvasRef] = useStore((state) => [
    state.selectedTool,
    state.offScreenCanvasRef,
  ]);
  const { ...scissorsCanvasProps } = useScissors(
    scissorsCanvasRef.current,
    offScreenCanvasRef.current,
  );
  const shouldUseScissorsCanvas = [
    IToolsEnum.TOOL_PENCIL,
    IToolsEnum.TOOL_SCISSORS,
    IToolsEnum.TOOL_SWORD,
  ].includes(selectedTool);

  return (
    <>
      <canvas
        id="scissors-canvas"
        ref={scissorsCanvasRef}
        width={width}
        height={height}
        style={{
          position: 'absolute',
          left: 0,
          zIndex: shouldUseScissorsCanvas ? 1 : -1,
        }}
        {...scissorsCanvasProps}
      />
      <canvas
        id="scissors-canvas-temp"
        ref={offScreenCanvasRef}
        width={width}
        height={height}
        style={{ display: 'none' }}
      />
    </>
  );
};

export default ScissorsCanvas;
