import { NavigateBefore } from '@mui/icons-material';
import { Box, Button, Card, Typography } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, Link, useParams } from 'react-router-dom';
import PathEnum from '../../infrastructure/constants/PathEnum';
import useStore from '../../infrastructure/store/useStore';
import AnalysisInterfaceEnum from '../../infrastructure/types/AnalysisInterfaceEnum';
import IFileRoleEnum from '../../infrastructure/types/IFileRoleEnum';
import { FilterTypeEnum } from '../../infrastructure/types/IFilterInput';
import FullScreenLoader from '../../ui/FullScreenLoader';
import FolderSelector from './FolderSelector';
import useAnalysis from './graphql/useAnalysis';
import {
  shouldShowImageEditor,
  shouldShowImageLabelling,
  shouldShowRearrangeImages,
  shouldShowUseCaseSix,
} from './helpers/CaseHelpers';
import ImageEditorLayout from './ImageEditorLayout';
import ImageLabelling from './ImageLabelling/ImageLabelling';
import ImageSelector from './ImageSelector';
import RearrangeImages from './RearangeImages/RearrangeImages';
import UseCaseSix from './UseCaseSix/UseCaseSix';

const AnalysisInner: React.FC = () => {
  const { t } = useTranslation();
  const { analysisId } = useParams();
  const resetAnalysisSlice = useStore((state) => state.resetAnalysisSlice);
  const resetRearrangeItems = useStore((state) => state.resetRearrangeItems);

  const { data: analysisData, loading: analysisLoading } = useAnalysis({
    variables: {
      id: analysisId,
      filters: {
        filters: [
          { field: 'role', type: FilterTypeEnum.IN, args: [IFileRoleEnum.ANALYSIS_RESULT] },
        ],
      },
    },
    fetchPolicy: 'network-only',
  });

  const analysis = useMemo(() => analysisData?.analysis, [analysisData?.analysis]);
  const interfaceModel = analysis?.interface || AnalysisInterfaceEnum.MODEL_1;

  const showImageEditor = useMemo(() => shouldShowImageEditor(interfaceModel), [interfaceModel]);
  const showRearrangeImages = useMemo(
    () => shouldShowRearrangeImages(interfaceModel),
    [interfaceModel],
  );
  const showUseCaseSix = useMemo(() => shouldShowUseCaseSix(interfaceModel), [interfaceModel]);
  const showImageLabelling = useMemo(
    () => shouldShowImageLabelling(interfaceModel),
    [interfaceModel],
  );

  useEffect(() => {
    return () => {
      resetAnalysisSlice();
      resetRearrangeItems();
    };
  }, []);

  if (analysisLoading || !analysis) {
    return <FullScreenLoader />;
  }

  return (
    <>
      <Box display="flex" justifyContent="space-between" pb={5}>
        <Button component={Link} to={generatePath(PathEnum.Study, { studyId: analysis?.studyId })}>
          <NavigateBefore />
          {t('analysis.backToStudy')}
        </Button>
        <Typography variant="h4">{analysis?.name}</Typography>
        <Button
          component={Link}
          to={generatePath(PathEnum.Reports, { studyId: analysis?.studyId, analysisId })}
          variant="contained"
        >
          {t('analysis.generateReport')}
        </Button>
      </Box>
      <Card>
        {showImageLabelling && (
          <ImageLabelling analysisLoading={analysisLoading} analysis={analysis} />
        )}
        {!showImageLabelling && (
          <Box display="flex" justifyContent="space-between" height={620}>
            <Box width={showImageEditor ? 210 : 280}>
              {showImageEditor && <ImageSelector interfaceModel={interfaceModel} />}
              {(showRearrangeImages || showUseCaseSix) && (
                <FolderSelector
                  folderStructure={analysis?.folderStructure}
                  loading={analysisLoading}
                />
              )}
            </Box>
            {showImageEditor && <ImageEditorLayout interfaceModel={interfaceModel} />}
            {showRearrangeImages && (
              <RearrangeImages folderStructure={analysis?.folderStructure || []} />
            )}
            {showUseCaseSix && <UseCaseSix folderStructure={analysis?.folderStructure} />}
          </Box>
        )}
      </Card>
    </>
  );
};

export default AnalysisInner;
