import { Box, Collapse, List } from '@mui/material';
import React, { Fragment, useCallback } from 'react';
import useStore from '../../infrastructure/store/useStore';
import FolderTreeListItem from './FolderTreeListItem';
import IFolderItem from './types/IFolderItem';
import IFolderStructure from './types/IFolderStructure';

interface Props {
  items?: IFolderItem[] | IFolderStructure[];
  onSelection?: (params: {
    id?: string;
    path?: string;
    objectId?: string;
    pathPrev?: string;
    pathNext?: string;
  }) => void;
  parentName?: string;
}

const FolderTree = ({ items, onSelection, parentName }: Props) => {
  const [open, setOpen] = React.useState<boolean[]>([...Array(items?.length ?? 0)].map(() => true));
  const [folderPath] = useStore((state) => [state.folderPath]);
  const handleSelection = useCallback(({ item }: { key: number; item?: IFolderItem }) => {
    const path = !parentName ? item?.name : `${parentName}/${item?.name}`;
    onSelection?.({
      path,
    });
  }, []);

  const handleClick = useCallback(
    ({ key, item }: { key: number; item?: IFolderItem }) => {
      if (item && item.fileCount && !item.subFolders?.length) {
        handleSelection({ key, item });
        return;
      }
      setOpen((prevOpen) => {
        const copyPrev = [...prevOpen];
        copyPrev[key] = !copyPrev[key];
        return copyPrev;
      });
    },
    [setOpen],
  );

  return (
    <List disablePadding>
      {items?.map((item, key) => {
        const path = parentName ? `${parentName}/${item?.name}` : `${item?.name}`;
        const isSelected = path === folderPath;
        return (
          <Fragment key={item.name || key}>
            <FolderTreeListItem
              index={key}
              open={open[key]}
              item={item}
              handleClick={handleClick}
              isSelected={isSelected}
            />
            {item?.subFolders?.map && (
              <Box pl={4}>
                <Collapse in={open[key]} timeout="auto">
                  <FolderTree
                    items={item?.subFolders}
                    parentName={!parentName ? item.name : `${parentName}/${item.name}`}
                    onSelection={onSelection}
                  />
                </Collapse>
              </Box>
            )}
          </Fragment>
        );
      })}
    </List>
  );
};

export default React.memo(FolderTree);
