import { Alert, Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUploadContext } from './context/UploadContext';
import DropboxFilesTree from './DropboxFilesTree';
import useGetDropboxFiles from './graphql/useGetDropboxFiles';
import MaxSizeWarningModal from './MaxSizeWarningModal';
import IDropboxFile from './types/IDropboxFile';

const DropboxFileSelect: React.FC = () => {
  const { t } = useTranslation();
  const { handleSelectDropboxFile, selectedDropboxFileIds } = useUploadContext();

  const [getDropboxFiles, { refetch, error }] = useGetDropboxFiles();

  const [items, setItems] = useState<IDropboxFile[]>([]);

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    getDropboxFiles({
      variables: {
        path: undefined,
        id: undefined,
      },
    }).then((r) => setItems(r.data?.dropboxFiles || []));
  }, []);

  return (
    <Box>
      {error && (
        <Alert severity="error" sx={{ width: '100%' }}>
          {error?.message}
        </Alert>
      )}
      {!error && (
        <>
          <Box pl={5} display="flex" alignItems="center">
            <Typography variant="body2">{t('upload.filesName')}</Typography>
          </Box>
          <DropboxFilesTree
            query={refetch}
            items={items}
            onFileSelect={handleSelectDropboxFile}
            selectedIds={selectedDropboxFileIds}
            onMaxSize={handleOpen}
          />
        </>
      )}
      <MaxSizeWarningModal open={open} handleClose={handleClose} />
    </Box>
  );
};

export default DropboxFileSelect;
