import { CleaningServices, HelpRounded, UndoRounded } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  IconButton,
  Slider,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BrushIcon,
  EraserIcon,
  HandIcon,
  PencilIcon,
  ScissorsIcon,
  SwordIcon,
} from '../../../infrastructure/assets/icons';
import Colors from '../../../infrastructure/constants/Colors';
import useStore from '../../../infrastructure/store/useStore';
import useMaskChanges from '../hooks/useMaskChanges';
import useTopView from '../MaskSelector/hooks/useTopView';
import ImageEditorConfig from './constants/ImageEditor.config';
import EditorToolbarInstructionsModal from './EditorToolbarInstructionsModal';
import { getFromToCoordinatesForTopView } from './helpers/getTopViewFilledCoordinates';
import IToolsEnum from './types/IToolsEnum';

type IToggleButtonCallback = (event: React.MouseEvent<HTMLElement>, value: IToolsEnum) => void;
type ISliderChangeCallback = (event: Event, value: number | number[], activeThumb: number) => void;

const EditorToolbar: React.FC = () => {
  const { t } = useTranslation();

  const [
    masks,
    selectedTool,
    maskRefs,
    selectedMaskIndex,
    selectedImage,
    setSelectedTool,
    brushSize,
    setBrushSize,
    clearCoords,
    updateErasedCoordinates,
  ] = useStore((state) => [
    state.masks,
    state.selectedTool,
    state.maskRefs,
    state.selectedMaskIndex,
    state.selectedImage,
    state.setSelectedTool,
    state.brushSize,
    state.setBrushSize,
    state.clearCoords,
    state.updateErasedCoordinates,
  ]);

  const { undoChanges, pushChanges, getErasedPixelsForRows } = useMaskChanges();
  const { updateTopView, copyToTopView } = useTopView();
  const [showInstructions, setShowInstructions] = useState(false);

  const handleChange: IToggleButtonCallback = (_, nextTool) => {
    if (nextTool !== null) {
      setSelectedTool(nextTool);
    }
  };

  const onSliderChange: ISliderChangeCallback = (_, value) => {
    setBrushSize(value as number);
  };

  const clearCanvas = () => {
    const canvas = maskRefs[selectedMaskIndex]?.current;
    const ctx = canvas?.getContext('2d');
    if (!ctx || !canvas) return;
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    clearCoords();
    if (!selectedImage?.isVIPImage) {
      updateTopView(getFromToCoordinatesForTopView(maskRefs));
      pushChanges();
      return;
    }

    const topViewCanvas = maskRefs[selectedMaskIndex]?.current;
    const bounding = {
      top: 0,
      left: 0,
      right: canvas.width,
      bottom: canvas.height,
    };
    const { foundErased, coords } = getErasedPixelsForRows(masks[0].id, bounding, ctx.lineWidth);
    if (topViewCanvas) {
      copyToTopView(topViewCanvas);
    }
    if (!foundErased) return;
    updateErasedCoordinates(coords);
    pushChanges();
  };

  const openInstructions = () => {
    setShowInstructions(true);
  };

  const closeInstructions = () => {
    setShowInstructions(false);
  };

  return (
    <Box
      component={Card}
      sx={{
        position: 'absolute',
        top: 12,
        left: 16,
        boxShadow: 'none',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <EditorToolbarInstructionsModal open={showInstructions} handleClose={closeInstructions} />
      <Box display="flex" alignItems="center" justifyContent="center" width={40}>
        <Button
          sx={{ pt: 2, pb: 2 }}
          color="primary"
          size="small"
          variant="text"
          onClick={openInstructions}
        >
          <HelpRounded color="primary" fontSize="small" />
        </Button>
      </Box>
      <ToggleButtonGroup
        orientation="vertical"
        value={selectedTool}
        exclusive
        onChange={handleChange}
        color="primary"
      >
        <ToggleButton
          disabled={selectedImage?.isVIPImage}
          value={IToolsEnum.TOOL_PENCIL}
          aria-label="pencil"
        >
          <Tooltip title={t('analysis.toolbar.pencil')}>
            <PencilIcon />
          </Tooltip>
        </ToggleButton>
        <ToggleButton
          disabled={selectedImage?.isVIPImage}
          value={IToolsEnum.TOOL_BRUSH}
          aria-label="brush"
        >
          <Tooltip title={t('analysis.toolbar.brush')}>
            <BrushIcon />
          </Tooltip>
        </ToggleButton>
        <ToggleButton value={IToolsEnum.TOOL_ERASER} aria-label="eraser">
          <Tooltip title={t('analysis.toolbar.eraser')}>
            <EraserIcon />
          </Tooltip>
        </ToggleButton>
        <ToggleButton
          disabled={selectedImage?.isVIPImage}
          value={IToolsEnum.TOOL_SCISSORS}
          aria-label="scissors"
        >
          <Tooltip title={t('analysis.toolbar.scissors')}>
            <ScissorsIcon />
          </Tooltip>
        </ToggleButton>
        <ToggleButton value={IToolsEnum.TOOL_SWORD} aria-label="sword">
          <Tooltip title={t('analysis.toolbar.sword')}>
            <SwordIcon />
          </Tooltip>
        </ToggleButton>
        <ToggleButton value={IToolsEnum.TOOL_HAND} aria-label="hand">
          <Tooltip title={t('analysis.toolbar.move')}>
            <HandIcon />
          </Tooltip>
        </ToggleButton>
      </ToggleButtonGroup>
      <IconButton onClick={undoChanges} sx={{ color: Colors.textPrimary }}>
        <Tooltip title={t('analysis.toolbar.undo')}>
          <UndoRounded />
        </Tooltip>
      </IconButton>
      <IconButton onClick={clearCanvas} sx={{ color: Colors.textPrimary }}>
        <Tooltip title={t('analysis.toolbar.clear')}>
          <CleaningServices />
        </Tooltip>
      </IconButton>
      <Slider
        sx={{ height: 100, my: 3 }}
        valueLabelDisplay="auto"
        min={1}
        max={30}
        disabled={
          selectedImage?.isVIPImage ||
          [IToolsEnum.TOOL_PENCIL, IToolsEnum.TOOL_SWORD, IToolsEnum.TOOL_SCISSORS].includes(
            selectedTool,
          )
        }
        orientation="vertical"
        onChange={onSliderChange}
        value={selectedImage?.isVIPImage ? ImageEditorConfig.VIP_ERASER_SIZE : brushSize}
      />
    </Box>
  );
};

export default EditorToolbar;
