import { Checkbox, FormControl, FormControlLabel, FormGroup } from '@mui/material';
import React from 'react';
import useStore from '../../../infrastructure/store/useStore';
import ILabel from '../types/ILabel';

interface IProps {
  labels: ILabel[];
}

const LabelsSelectionCheckbox: React.FC<IProps> = ({ labels }) => {
  const [selectedLabels, setSelectedLabels] = useStore((state) => [
    state.selectedLabels,
    state.setSelectedLabels,
  ]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentLabels = [...selectedLabels];
    if (event.target.checked) {
      setSelectedLabels([...currentLabels, event.target.name]);
      return;
    }
    setSelectedLabels(currentLabels.filter((label) => label !== event.target.name));
  };

  return (
    <FormControl>
      <FormGroup aria-labelledby="labels-selection-checkbox">
        {labels.map((label) => {
          return (
            <FormControlLabel
              key={label.id}
              control={
                <Checkbox
                  checked={selectedLabels.includes(label.id)}
                  onChange={handleChange}
                  name={label.id}
                />
              }
              name={label.id}
              label={label.name}
            />
          );
        })}
      </FormGroup>
    </FormControl>
  );
};

export default LabelsSelectionCheckbox;
