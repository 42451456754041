import { DDALine } from 'dda-line-algorithm';
import { MouseEvent, RefObject, useCallback, useEffect } from 'react';
import useStore from '../../../../infrastructure/store/useStore';
import useMaskChanges from '../../hooks/useMaskChanges';
import useSetRef from '../../hooks/useSetRef';
import useTopView from '../../MaskSelector/hooks/useTopView';
import ImageEditorConfig from '../constants/ImageEditor.config';
import centerCoords from '../helpers/centerCoords';
import clearCanvasBrush from '../helpers/clearCanvasBrush';
import getFilledPixelCoordinates from '../helpers/getFilledPixelCoordinates';
import { getFromToCoordinatesForTopView } from '../helpers/getTopViewFilledCoordinates';
import ICoordinates from '../types/ICoordinates';
import IToolsEnum from '../types/IToolsEnum';
import useBoundaries from './useBoundaries';

const useCanvasDraw = (
  id: string,
  canvasRef: RefObject<HTMLCanvasElement> | null,
  color: string,
) => {
  const [drawingRef, setDrawing] = useSetRef(false);
  const { boundingRef, clearBounding, updateBounding } = useBoundaries();
  const { pushChanges, checkIfWasErased, getErasedPixelsForRows } = useMaskChanges();
  const { updateTopView, copyToTopView } = useTopView();
  const [lastCoordsRef, setLastCoords] = useSetRef<ICoordinates | null>(null);
  const [
    masks,
    maskRefs,
    selectedMaskIndex,
    selectedTool,
    selectedImage,
    brushSize,
    maskImageDataStack,
    shouldClearCoords,
    setBrushSize,
    updateErasedCoordinates,
  ] = useStore((state) => [
    state.masks,
    state.maskRefs,
    state.selectedMaskIndex,
    state.selectedTool,
    state.selectedImage,
    state.brushSize,
    state.maskImageDataStack,
    state.shouldClearCoords,
    state.setBrushSize,
    state.updateErasedCoordinates,
  ]);
  const [currentTool, setCurrentTool] = useSetRef<IToolsEnum>(selectedTool);

  useEffect(() => {
    const canvas = canvasRef?.current;
    const ctx = canvas?.getContext('2d');
    if (!ctx || !canvas) return;
    if (selectedImage?.isVIPImage) {
      ctx.lineCap = 'square';
      ctx.lineJoin = 'miter';
      ctx.imageSmoothingQuality = 'high';
      ctx.lineWidth = ImageEditorConfig.VIP_ERASER_SIZE;
      return;
    }
    ctx.lineCap = 'round';
    ctx.lineJoin = 'round';
    ctx.imageSmoothingQuality = 'high';
    ctx.lineWidth = brushSize;
    ctx.strokeStyle = color;
  }, [canvasRef?.current, selectedImage?.isVIPImage]);

  useEffect(() => {
    const canvas = canvasRef?.current;
    const ctx = canvas?.getContext('2d');
    if (ctx) {
      ctx.beginPath();
      if (!selectedImage?.isVIPImage) {
        ctx.lineWidth = brushSize;
      }
      ctx.stroke();
    }
  }, [brushSize, selectedImage?.isVIPImage]);

  const clearCoords = () => {
    clearBounding();
  };

  useEffect(() => {
    if (shouldClearCoords) {
      clearCoords();
    }
  }, [shouldClearCoords]);

  const centerCoordsForVIP = useCallback(
    (coords: ICoordinates) => {
      const canvas = canvasRef?.current;
      if (!selectedImage?.isVIPImage || !canvas) return coords;
      return centerCoords(coords, canvas.height);
    },
    [selectedImage],
  );

  const drawOnCenterCoordinates = useCallback(
    (coords: ICoordinates) => {
      const canvas = canvasRef?.current;
      const ctx = canvas?.getContext('2d');
      if (!canvas || !ctx) return;
      if (lastCoordsRef.current === null) {
        const { x, y } = centerCoords(coords, canvas.height);
        ctx.moveTo(x, y);
        ctx.lineTo(x, y);
        ctx.stroke();
        setLastCoords({ x, y });
        updateBounding({ x, y });
        return;
      }
      const coordinates = DDALine(
        lastCoordsRef.current.x,
        lastCoordsRef.current.y,
        coords.x,
        coords.y,
      ) as ICoordinates[];
      coordinates.forEach((coordinate) => {
        const { x, y } = centerCoords(coordinate, canvas.height);
        ctx.moveTo(x, y);
        ctx.lineTo(x, y);
        ctx.stroke();
        updateBounding({ x, y });
      });
      setLastCoords(null);
    },
    [updateBounding],
  );

  const clearMasks = (filledCoords: ICoordinates[]) => {
    maskRefs.forEach((maskRef, index) => {
      if (maskRef && maskRef.current && index !== selectedMaskIndex) {
        clearCanvasBrush(maskRef.current, filledCoords);
      }
    });
  };

  const onToolChange = useCallback(() => {
    const canvas = canvasRef?.current;
    const ctx = canvas?.getContext('2d');
    if (!ctx) return;
    clearCoords();
    if (selectedTool !== currentTool.current) {
      setBrushSize(
        [IToolsEnum.TOOL_PENCIL, IToolsEnum.TOOL_SWORD, IToolsEnum.TOOL_SCISSORS].includes(
          selectedTool,
        )
          ? ImageEditorConfig.DEFAULT_PENCIL_SIZE
          : ImageEditorConfig.DEFAULT_BRUSH_SIZE,
      );
    }
    setCurrentTool(selectedTool);
    if (currentTool.current === IToolsEnum.TOOL_ERASER) {
      ctx.globalCompositeOperation = 'destination-out';
      return;
    }
    ctx.globalCompositeOperation = 'source-over';
  }, [selectedTool]);

  const checkForDiscard = useCallback(() => {
    if (maskImageDataStack.length === 0) {
      clearCoords();
    }
  }, [maskImageDataStack]);

  useEffect(checkForDiscard, [checkForDiscard]);
  useEffect(onToolChange, [onToolChange]);

  const onMouseDown = (e: MouseEvent<HTMLCanvasElement>) => {
    if (e.button !== 0) return;

    if (selectedMaskIndex !== null && !masks[selectedMaskIndex].visible) return;

    const canvas = canvasRef?.current;
    const ctx = canvas?.getContext('2d');
    if (!ctx) return;
    if (currentTool.current === IToolsEnum.TOOL_HAND) return;
    const coords = centerCoordsForVIP({ x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY });
    ctx.beginPath();
    ctx.moveTo(coords.x, coords.y);
    ctx.lineTo(coords.x, coords.y);
    ctx.stroke();
    updateBounding(coords);
    setDrawing(true);
  };

  const clearLayersBehind = (canvas: HTMLCanvasElement) => {
    const coords = getFilledPixelCoordinates(canvas, boundingRef.current, color);
    clearMasks(coords);
  };

  const onMouseUp = () => {
    const canvas = canvasRef?.current;
    const ctx = canvas?.getContext('2d');
    if (!ctx || !canvas || !drawingRef.current) return;
    ctx.closePath();
    setDrawing(false);
    setLastCoords(null);
    if (currentTool.current === IToolsEnum.TOOL_HAND) {
      return;
    }
    if (currentTool.current === IToolsEnum.TOOL_BRUSH) {
      clearLayersBehind(canvas);
    }
    if (!selectedImage?.isVIPImage) {
      updateTopView(getFromToCoordinatesForTopView(maskRefs));
    }
    if (currentTool.current === IToolsEnum.TOOL_ERASER) {
      if (selectedImage?.isVIPImage) {
        const { foundErased, coords } = getErasedPixelsForRows(
          id,
          boundingRef.current,
          ctx.lineWidth,
        );
        if (!foundErased) return;
        updateErasedCoordinates(coords);
        copyToTopView(canvasRef?.current);
      }

      if (!checkIfWasErased(id, boundingRef.current, ctx.lineWidth)) return;
    }
    pushChanges();
  };

  const onMouseMove = (e: MouseEvent<HTMLCanvasElement>) => {
    const canvas = canvasRef?.current;
    const ctx = canvas?.getContext('2d');
    if (!drawingRef.current || !ctx) return;
    const coords = { x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY };

    if (selectedImage?.isVIPImage) {
      drawOnCenterCoordinates({ x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY });
      return;
    }
    ctx.lineTo(coords.x, coords.y);
    ctx.stroke();
    updateBounding(coords);
  };

  const onMouseEnter = (e: MouseEvent<HTMLCanvasElement>) => {
    if (e.nativeEvent.buttons !== 1) {
      setDrawing(false);
      setLastCoords(null);
    }
  };

  return {
    setDrawing,
    onMouseDown,
    onMouseUp,
    onMouseMove,
    onMouseEnter,
    onContextMenu: (e: MouseEvent<HTMLCanvasElement>) => e.preventDefault(),
  };
};

export default useCanvasDraw;
