import { Box, Button, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Colors from '../../infrastructure/constants/Colors';
import GenericModal from '../../ui/GenericModal';
import IAnalysisStatus from '../studies/types/IAnalysisStatus';

interface IProps {
  open: boolean;
  handleClose: () => void;
  status: IAnalysisStatus | null;
}

const AnalysisStatusInfoModal: React.FC<IProps> = ({ open, handleClose, status }) => {
  const { t } = useTranslation();

  const title = useMemo(() => {
    if (status === IAnalysisStatus.TIMED_OUT) {
      return t('analysis.modal.timedOut.title');
    }
    if (status === IAnalysisStatus.IN_PROGRESS) {
      return t('analysis.modal.inProgress.title');
    }
    if (status === IAnalysisStatus.FAILED) {
      return t('analysis.modal.failed.title');
    }
  }, [t, status]);

  const description = useMemo(() => {
    if (status === IAnalysisStatus.TIMED_OUT) {
      return t('analysis.modal.timedOut.description');
    }
    if (status === IAnalysisStatus.IN_PROGRESS) {
      return t('analysis.modal.inProgress.description');
    }
    if (status === IAnalysisStatus.FAILED) {
      return t('analysis.modal.failed.description');
    }
  }, [t, status]);

  return (
    <GenericModal open={open} onClose={handleClose}>
      <Box sx={{ textAlign: 'center' }}>
        <Typography id="modal-modal-title" variant="h5" component="h2">
          {title}
        </Typography>
        <Typography
          id="modal-modal-description"
          sx={{ mt: 1, color: Colors.textSecondary, maxWidth: 350, mx: 'auto' }}
          variant="body2"
        >
          {description}
        </Typography>

        <Box display="flex" justifyContent="center" mt={2}>
          <Button onClick={handleClose} variant="contained">
            {t('button.ok')}
          </Button>
        </Box>
      </Box>
    </GenericModal>
  );
};

export default AnalysisStatusInfoModal;
