import IProjectFile from '../types/IProjectFile';
import IProjectFolder from '../types/IProjectFolder';

const getChildrenIds = (
  ids: string[],
  items: (IProjectFile | IProjectFolder)[] | undefined,
  projectFiles: Record<string, (IProjectFile | IProjectFolder)[]>,
  dontIncludeParentWithChildren = false,
) => {
  if (!items || items.length === 0) {
    return ids;
  }
  const childIds = items.map((child) => child.id);
  return childIds
    .map((childId) => {
      if (dontIncludeParentWithChildren && projectFiles[childId]) {
        return getChildrenIds([...ids], projectFiles[childId], projectFiles);
      }
      return getChildrenIds([...ids, childId], projectFiles[childId], projectFiles);
    })
    .flat();
};

export default getChildrenIds;
