import { gql, OnSubscriptionDataOptions, useSubscription } from '@apollo/client';

export const REPORT_FILES_SUBSCRIPTION = gql`
  subscription filesCreated($reportId: String) {
    filesCreated(reportId: $reportId) {
      id
      name
      path
      signedUrl
    }
  }
`;

export default (options?: OnSubscriptionDataOptions) => {
  return useSubscription(REPORT_FILES_SUBSCRIPTION, options);
};
