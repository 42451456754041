import { gql } from '@apollo/client';

const REFRESH_SESSION = gql`
  mutation refreshSession($refreshSession: RefreshSessionInput!) {
    refreshSession(input: $refreshSession) {
      accessToken
      refreshToken
    }
  }
`;

export default REFRESH_SESSION;
