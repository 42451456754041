import React from 'react';
import AnalysisInner from '../../domain/analysis/AnalysisInner';
import MainContainer from '../../ui/MainContainer';
import Header from '../layout/Header/Header';

const AnalysisPage: React.FC = () => {
  return (
    <>
      <Header />
      <MainContainer>
        <AnalysisInner />
      </MainContainer>
    </>
  );
};

export default AnalysisPage;
