import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import IUser from '../../../infrastructure/types/IUser';

interface IGetUsers {
  users: IUser[];
}

export const GET_USERS = gql`
  query users {
    users {
      id
      email
      firstName
      lastName
      root
      permissions
      status
      emailNotifications
    }
  }
`;

export default (options?: QueryHookOptions) => useQuery<IGetUsers>(GET_USERS, options);
