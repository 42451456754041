import ImageEditorConfig from '../constants/ImageEditor.config';
import ICoordinates from '../types/ICoordinates';

const getSliceIndexByYCoordinate = (coords: ICoordinates, height: number) => {
  const lineHeight = height / ImageEditorConfig.VIP_IMAGES_COUNT;
  const startLineY = coords.y - (coords.y % lineHeight);
  return startLineY / lineHeight;
};

export default getSliceIndexByYCoordinate;
