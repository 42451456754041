import { Box } from '@mui/material';
import React, { useCallback, useEffect, useMemo } from 'react';
import useStore from '../../../infrastructure/store/useStore';
import useMaskChanges from '../hooks/useMaskChanges';
import EditorColors from '../ImageEditor/constants/EditorColors';
import ImageEditorConfig from '../ImageEditor/constants/ImageEditor.config';
import drawImage from '../ImageEditor/helpers/drawImage';
import scaleDownSize from '../ImageEditor/helpers/scaleDownSize';
import IFile from '../types/IFile';

interface Props {
  filesLoading: boolean;
  files: IFile[];
  onSliceSelect: (file: IFile) => ((image: HTMLImageElement) => void) | undefined;
}

const TopView: React.FC<Props> = ({ files, filesLoading, onSliceSelect }) => {
  const [topViewRef, selectedVIPImage, selectedSliceIndex] = useStore((state) => [
    state.topViewRef,
    state.selectedVIPImage,
    state.selectedSliceIndex,
  ]);

  const { setInitialImageData } = useMaskChanges();

  useEffect(() => {
    const canvas = topViewRef?.current;
    const ctx = canvas?.getContext('2d');
    if (!ctx || !canvas || !selectedVIPImage?.mask) return;
    const { signedUrl, id } = selectedVIPImage.mask;
    if (!signedUrl) return;
    drawImage({ ctx, imgSrc: signedUrl, canvas, color: EditorColors.topViewDataColor }).then(
      (imageData) => {
        setInitialImageData(`topView-${id}`, imageData);
      },
    );
  }, [selectedVIPImage]);

  if (!selectedVIPImage) {
    return null;
  }

  const { width, signedUrl, height } = selectedVIPImage;
  const wrapperData = scaleDownSize({
    width: width || 0,
    height: height || 0,
    size: 200,
  });

  const lineHeight = wrapperData.height / ImageEditorConfig.VIP_IMAGES_COUNT;

  const onSliceClick = useCallback(
    (file: IFile) => {
      const image = document.getElementById(file.id)?.firstChild as HTMLImageElement;
      if (!image) return;

      image.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });

      onSliceSelect(file)?.(image);
    },
    [onSliceSelect],
  );

  const slicesMap = useMemo(() => {
    return (
      !filesLoading &&
      files.map((file, key) => (
        <Box
          key={file.id}
          onClick={() => onSliceClick(file)}
          sx={{
            zIndex: 1,
            position: 'absolute',
            top: key * lineHeight,
            left: 0,
            width: '100%',
            height: lineHeight,
            '&:hover': {
              backgroundColor: EditorColors.white,
            },
          }}
        />
      ))
    );
  }, [filesLoading, files, onSliceClick]);

  return (
    <Box
      sx={{
        width: wrapperData.width,
        height: wrapperData.height,
        backgroundImage: `url(${signedUrl})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        position: 'relative',
        marginTop: 1,
      }}
    >
      <Box sx={{ opacity: 0.5 }}>
        <canvas
          ref={topViewRef}
          width={width}
          height={height}
          style={{
            width: '100%',
            height: '100%',
            position: 'relative',
            zIndex: 1,
          }}
        />
        {slicesMap}
        {selectedSliceIndex !== null && (
          <Box
            sx={{
              zIndex: 1,
              position: 'absolute',
              top: selectedSliceIndex * lineHeight,
              left: 0,
              width: '100%',
              height: lineHeight,
              backgroundColor: EditorColors.white,
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default TopView;
