import { gql, MutationHookOptions, useMutation } from '@apollo/client';

const CANCEL_DROPBOX_COPY = gql`
  mutation cancelDropboxCopy($id: String!) {
    cancelDropboxCopy(id: $id) {
      id
    }
  }
`;

export default (options: MutationHookOptions) => useMutation(CANCEL_DROPBOX_COPY, options);
