import { Box, Skeleton } from '@mui/material';
import React from 'react';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import useStore from '../../../infrastructure/store/useStore';
import AnalysisInterfaceEnum from '../../../infrastructure/types/AnalysisInterfaceEnum';
import Canvas from './Canvas';
import EditorColors from './constants/EditorColors';
import ImageEditorConfig from './constants/ImageEditor.config';
import getSizeByInterface from './helpers/getSizeByInterface';
import scaleDownSize from './helpers/scaleDownSize';
import MiniMap from './MiniMap';
import ScissorsCanvas from './ScissorsCanvas';
import IToolsEnum from './types/IToolsEnum';

interface IProps {
  interfaceModel: AnalysisInterfaceEnum;
}

const CanvasWrapper: React.FC<IProps> = ({ interfaceModel }) => {
  const [masks, selectedMaskIndex, selectedTool, selectedImage, selectedImageSize] = useStore(
    (state) => [
      state.masks,
      state.selectedMaskIndex,
      state.selectedTool,
      state.selectedImage,
      state.selectedImageSize,
    ],
  );

  const cursor = (() => {
    if (selectedTool === IToolsEnum.TOOL_HAND) {
      return 'grab';
    }
    if (selectedMaskIndex !== null && !masks[selectedMaskIndex].visible) {
      return 'not-allowed';
    }

    return 'default';
  })();

  const transformWrapperSize = getSizeByInterface(
    ImageEditorConfig.TRANSFORM_WRAPPER_SIZE,
    interfaceModel,
  );
  const minimapSize = getSizeByInterface(ImageEditorConfig.MINIMAP_SIZE, interfaceModel);

  if (!selectedImage || !selectedImageSize) {
    return (
      <Box display="flex" width={transformWrapperSize} height={transformWrapperSize}>
        <Skeleton
          variant="rectangular"
          animation="wave"
          width={minimapSize}
          height={minimapSize}
          sx={{
            position: 'absolute',
            top: 12,
            right: interfaceModel === AnalysisInterfaceEnum.MODEL_4 ? 4 : 16,
          }}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width={transformWrapperSize}
          height={transformWrapperSize}
        />
      </Box>
    );
  }

  const { width, height } = selectedImageSize;
  const { signedUrl } = selectedImage;
  const {
    width: wrapperWidth,
    height: wrapperHeight,
    initialScale,
  } = scaleDownSize({
    width,
    height,
    size: transformWrapperSize,
  });

  return (
    <TransformWrapper
      panning={{
        disabled: selectedTool !== IToolsEnum.TOOL_HAND,
      }}
      doubleClick={{
        disabled: true,
      }}
      minScale={initialScale}
      maxScale={32}
      initialScale={initialScale}
      centerZoomedOut
      wheel={{
        step: 0.075,
      }}
    >
      <MiniMap
        imageSource={signedUrl}
        initialScale={initialScale}
        originalHeight={height}
        originalWidth={width}
        interfaceModel={interfaceModel}
      />
      <Box
        sx={{
          width: transformWrapperSize,
          height: transformWrapperSize,
        }}
      >
        <TransformComponent
          wrapperStyle={{
            height: wrapperHeight,
            width: wrapperWidth,
            cursor,
            margin: '0 auto',
          }}
        >
          <Box
            sx={{
              position: 'relative',
            }}
          >
            <img src={signedUrl} alt={selectedImage.name} />
            {width &&
              height &&
              masks.map((mask, index) => {
                return (
                  <Canvas
                    key={mask.id}
                    id={mask.id}
                    index={index}
                    color={
                      selectedImage.isVIPImage
                        ? EditorColors.topViewDataColor
                        : ImageEditorConfig.COLORS_ORDER[index]
                    }
                    width={width}
                    height={height}
                    imgSrc={mask.signedUrl}
                    visible={mask.visible}
                  />
                );
              })}
            <ScissorsCanvas width={width} height={height} />
          </Box>
        </TransformComponent>
      </Box>
    </TransformWrapper>
  );
};

export default CanvasWrapper;
