import { gql, MutationHookOptions, useMutation } from '@apollo/client';

export const REQUEST_TO_ADD_FILES_TO_STUDY = gql`
  mutation requestToAddFilesToStudy($files: [String!]!, $studyId: String!) {
    requestToAddFilesToStudy(files: $files, studyId: $studyId) {
      file
      s3Id
      uploadUrl
    }
  }
`;

export default (options: MutationHookOptions) => {
  return useMutation(REQUEST_TO_ADD_FILES_TO_STUDY, options);
};
