import { NavigateBefore, NavigateNext } from '@mui/icons-material';
import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import moment from 'moment';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Colors from '../../../infrastructure/constants/Colors';
import useStore from '../../../infrastructure/store/useStore';
import IFileRoleEnum from '../../../infrastructure/types/IFileRoleEnum';
import IFileSubRoleEnum from '../../../infrastructure/types/IFileSubRoleEnum';
import { FilterTypeEnum } from '../../../infrastructure/types/IFilterInput';
import IInternalError from '../../../infrastructure/types/IInternalError';
import isServerError from '../../../infrastructure/utils/isServerError';
import FullScreenLoader from '../../../ui/FullScreenLoader';
import { getBorder } from '../../../utils/Common';
import useFiles from '../graphql/useFiles';
import useUpdateIllnessSpotMutation from '../graphql/useUpdateIllnessSpotMutation';
import IFolderStructure from '../types/IFolderStructure';
import ISpot from '../types/ISpot';
import AntSwitch from './AntSwitch';
import findNextPathFromFolderStructure from './helpers/findNextPathFromFolderStructure';
import findPrevPathFromFolderStructure from './helpers/findPrevPathFromFolderStructure';
import SpotTextInput from './SpotTextInput';

interface IProps {
  folderStructure?: IFolderStructure[];
}

const UseCaseSix: React.FC<IProps> = ({ folderStructure }) => {
  const { t } = useTranslation();
  const { analysisId } = useParams();

  const [folderPath, folderPathPrev, folderPathNext, setFolderPath] = useStore((state) => [
    state.folderPath,
    state.folderPathPrev,
    state.folderPathNext,
    state.setFolderPath,
  ]);

  const mapFoldersWithFiles = useCallback(
    (path: string | null, folders: IFolderStructure[]) => (folder: IFolderStructure) => {
      const nextPath = path ? `${path}/${folder.name}` : folder.name;

      if (folder.fileCount > 0) {
        folders.push({ ...folder, path: nextPath });
      }

      if (folder.subFolders) {
        folder.subFolders.forEach(mapFoldersWithFiles(nextPath, folders));
      }
    },
    [],
  );

  const foldersList = useMemo(() => {
    const folders: IFolderStructure[] = [];
    folderStructure?.forEach(mapFoldersWithFiles(null, folders));
    return folders;
  }, [folderStructure]);

  const handleNextPath = useCallback(() => {
    if (!folderStructure || !folderPath) return;
    const nextEl = findNextPathFromFolderStructure(folderStructure, folderPath);
    if (nextEl) {
      setFolderPath({ path: nextEl.objectId });
    }
  }, [folderPathNext, folderPath, folderStructure, setFolderPath]);

  const handlePrevPath = useCallback(() => {
    if (!folderStructure || !folderPath) return;
    // if folderPathPrev is not defined try to find it from folderStructure
    const prevEl = findPrevPathFromFolderStructure(folderStructure, folderPath);
    if (prevEl) {
      setFolderPath({ path: prevEl.objectId });
    }
  }, [folderPathPrev, folderPath, folderStructure, setFolderPath]);

  const canClickNext = useMemo(() => {
    if (!folderPath) return false;
    const folderIndex = foldersList.findIndex((folder) => folder.path === folderPath);
    if (folderIndex >= 0 && folderIndex < foldersList.length - 1) {
      return true;
    }
    return false;
  }, [folderPath, foldersList]);

  const canClickPrev = useMemo(() => {
    if (!folderPath) return false;
    const folderIndex = foldersList.findIndex((folder) => folder.path === folderPath);
    if (folderIndex > 0) {
      return true;
    }
    return false;
  }, [folderPath, foldersList]);

  const { data: filesData, loading: filesLoading } = useFiles({
    variables: {
      filters: {
        filters: [
          { field: 'path', type: FilterTypeEnum.IN, args: [folderPath] },
          { field: 'analysisId', type: FilterTypeEnum.IN, args: [analysisId] },
          { field: 'role', type: FilterTypeEnum.IN, args: [IFileRoleEnum.ANALYSIS_RESULT] },
        ],
      },
    },
    skip: !folderPath,
  });
  const [error, setError] = useState<null | IInternalError>(null);

  const [mutateSpot, { loading: mutateLoading }] = useUpdateIllnessSpotMutation({
    onError: ({ networkError }) => {
      if (networkError) {
        if (isServerError(networkError)) {
          setError({
            date: moment().format('YYYY-MM-DD HH:mm'),
            statusCode: networkError.statusCode,
          });
          return;
        }
        setError({
          date: moment().format('YYYY-MM-DD HH:mm'),
          context: 'dateOnly',
        });
      }
    },
  });

  const handleUpdateSpotCnvGrade = useCallback(
    (spot: ISpot | undefined) => {
      if (!spot || mutateLoading || filesLoading) {
        return;
      }
      mutateSpot({
        variables: {
          input: {
            id: spot.id,
            name: spot.name,
            cnvGrade: !spot.cnvGrade,
          },
          fileId: spot.fileId,
        },
      });
    },
    [mutateSpot, mutateLoading, filesLoading],
  );

  const handleUpdateSpotName = useCallback(
    (spot: ISpot | undefined, newName: string | number) => {
      if (!spot || !newName || mutateLoading || filesLoading) {
        return;
      }
      mutateSpot({
        variables: {
          input: {
            id: spot.id,
            name: newName,
            cnvGrade: spot.cnvGrade,
          },
          fileId: spot.fileId,
        },
      });
    },
    [mutateSpot, mutateLoading, filesLoading],
  );

  const files = useMemo(() => filesData?.files || [], [filesData]);

  const panoramicImg = useMemo(
    () => files.find((file) => file.subRole === IFileSubRoleEnum.PANORAMIC_IMAGE),
    [files],
  );

  const faImg = useMemo(() => files.find((file) => file.subRole === IFileSubRoleEnum.FA), [files]);

  const octVipImg = useMemo(
    () => files.find((file) => file.subRole === IFileSubRoleEnum.OCT_VIP),
    [files],
  );

  const getCoordinate = (ratio: number, coordinate: number) => {
    return ratio * coordinate - 32 / 2;
  };

  const [aspectRatio, setAspectRatio] =
    useState<Record<string, { horizontal: number; vertical: number }>>();

  const onLoad = (e: React.ChangeEvent<HTMLImageElement>) => {
    const {
      target: { id, height, width, naturalHeight, naturalWidth },
    } = e;

    setAspectRatio({
      ...aspectRatio,
      [id]: { horizontal: width / naturalWidth, vertical: height / naturalHeight },
    });
  };

  if (filesLoading) {
    return <FullScreenLoader />;
  }

  if (!filesLoading && (!files || !files.length)) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        height="100%"
        alignItems="center"
        justifyContent="center"
        p={2}
      >
        <Typography variant="subtitle1" p={2}>
          {t('analysis.noImagesFound')}
        </Typography>
      </Box>
    );
  }

  return (
    <Box width="100%" display="flex" flexDirection="column">
      <Box mx={5} my={3} display="flex" height={240} bgcolor="gray">
        <img alt="panoramic" width="100%" height="100%" src={panoramicImg?.signedUrl} />
      </Box>
      {error && (
        <Typography align="right" variant="body1" color="error.main" mt={1} mr={5}>
          {t('general.unknownError', {
            date: error.date,
            statusCode: error.statusCode,
            context: error.context,
          })}
        </Typography>
      )}
      <Divider />
      <Box height="100%" display="flex" flexGrow={1}>
        <Box px={5} py={3} borderRight={getBorder(Colors.divider)}>
          <Typography pb={2}>{t('analysis.octVip')}</Typography>
          <Box position="relative" width={226} height={228} bgcolor="gray">
            <img
              id={octVipImg?.id}
              alt={`${octVipImg?.name}`}
              width="100%"
              height="100%"
              src={octVipImg?.signedUrl}
              onLoad={onLoad}
            />
            {octVipImg &&
              aspectRatio?.[octVipImg.id] &&
              octVipImg.illnessSpots?.map &&
              octVipImg.illnessSpots?.map((spot) => (
                <SpotTextInput
                  key={spot.id}
                  value={spot.name}
                  onChange={(value) => handleUpdateSpotName(spot, value)}
                  left={getCoordinate(aspectRatio[octVipImg.id].horizontal, spot.coordinateX)}
                  top={getCoordinate(aspectRatio[octVipImg.id].vertical, spot.coordinateY)}
                />
              ))}
          </Box>
        </Box>
        <Box px={5} py={3}>
          <Typography pb={2}>{t('analysis.faImage')}</Typography>
          <Box position="relative" width={226} height={228} bgcolor="gray">
            <img
              id={faImg?.id}
              alt={`${faImg?.name}`}
              width="100%"
              height="100%"
              src={faImg?.thumbnails.s200x200}
              onLoad={onLoad}
            />
            {faImg &&
              aspectRatio?.[faImg.id] &&
              faImg.illnessSpots?.map &&
              faImg.illnessSpots?.map((spot) => (
                <SpotTextInput
                  key={spot.id}
                  value={spot.name}
                  onChange={(value) => handleUpdateSpotName(spot, value)}
                  left={getCoordinate(aspectRatio[faImg.id].horizontal, spot.coordinateX)}
                  top={getCoordinate(aspectRatio[faImg.id].vertical, spot.coordinateY)}
                  disabled
                />
              ))}
          </Box>
        </Box>
        <Box mt={8} height={228} overflow="auto" display="flex" flexDirection="column">
          <Stack direction="row" spacing={3} alignItems="center">
            <Typography variant="body2">{t('analysis.spotId')}</Typography>
            <Typography variant="body2">{t('analysis.cnvGrade')}</Typography>
            <Typography variant="body2">{t('analysis.probability')}</Typography>
          </Stack>
          {faImg?.illnessSpots?.map((spot) => (
            <Stack key={spot?.id} direction="row" spacing={3} alignItems="center" pt={2}>
              <Typography color={Colors.textSecondary} variant="body1">
                {t('analysis.spot')}
                &nbsp;
                {spot?.name || spot?.index}
              </Typography>
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography>0</Typography>
                <AntSwitch
                  checked={!!spot?.cnvGrade}
                  onChange={() => handleUpdateSpotCnvGrade(spot)}
                />
                <Typography>1</Typography>
              </Stack>
              <Typography color={Colors.textSecondary} variant="body1">
                {spot?.probability || 0}
              </Typography>
            </Stack>
          ))}
          <Box mt="auto" display="flex" gap={1} py={1} justifyContent="center" width="100%">
            <Button size="small" onClick={handlePrevPath} disabled={!canClickPrev}>
              <NavigateBefore />
              <Box fontSize="14px">{t('button.previous')}</Box>
            </Button>
            <Button size="small" onClick={handleNextPath} disabled={!canClickNext}>
              <Box fontSize="14px">{t('button.next')}</Box>
              <NavigateNext />
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default UseCaseSix;
