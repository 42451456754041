import FloodFill, { ColorRGBA } from 'q-floodfill';

const colorsMatch = (a: ColorRGBA, b: ColorRGBA, tolerance = 128 * 128) => {
  const dr = a.r - b.r;
  const dg = a.g - b.g;
  const db = a.b - b.b;
  const da = a.a - b.a;
  return dr * dr + dg * dg + db * db + da * da < tolerance;
};

const floodFill = (imgData: ImageData, color: string, x: number, y: number) => {
  const floodFillObj = new FloodFill(imgData);
  floodFillObj.isSameColor = colorsMatch;
  const tolerance = 255 * 255;
  floodFillObj.fill(color, x, y, tolerance);
};

export default floodFill;
