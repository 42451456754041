import { LoadingButton } from '@mui/lab';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Colors from '../../infrastructure/constants/Colors';
import GenericModal from '../../ui/GenericModal';

interface IProps {
  open: boolean;
  saveLoading?: boolean;
  discardLoading?: boolean;
  onSave: () => void;
  onDiscard: () => void;
  handleClose: () => void;
}

const UnsavedChangesModal: React.FC<IProps> = ({
  open,
  saveLoading,
  discardLoading,
  onSave,
  onDiscard,
  handleClose,
}) => {
  const { t } = useTranslation();

  return (
    <GenericModal open={open} onClose={handleClose}>
      <Box sx={{ textAlign: 'center' }}>
        <Typography id="modal-modal-title" variant="h5" component="h2">
          {t('analysis.unsavedChangesTitle')}
        </Typography>
        <Typography
          id="modal-modal-description"
          sx={{ mt: 1, color: Colors.textSecondary, maxWidth: 350, mx: 'auto' }}
          variant="body2"
        >
          {t('analysis.unsavedChangesDescription')}
        </Typography>

        <Box display="flex" justifyContent="space-between" mt={2}>
          <LoadingButton
            disabled={saveLoading || discardLoading}
            loading={discardLoading}
            onClick={onDiscard}
            variant="contained"
            color="secondary"
          >
            {t('button.discard')}
          </LoadingButton>
          <LoadingButton
            disabled={saveLoading || discardLoading}
            loading={saveLoading}
            onClick={onSave}
            variant="contained"
          >
            {t('button.save')}
          </LoadingButton>
        </Box>
      </Box>
    </GenericModal>
  );
};

export default UnsavedChangesModal;
