import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import useStore from '../../../infrastructure/store/useStore';
import useReorderAnalysisImages from '../graphql/useReorderAnalysisImages';

const useRearrangeImagesSaveDiscard = (callback?: () => void) => {
  const { analysisId } = useParams();

  const [items, setItems, parsedFiles, rearrangedItems, setRearrangedItems, setHasChanges] =
    useStore((state) => [
      state.items,
      state.setItems,
      state.parsedFiles,
      state.rearrangedItems,
      state.setRearrangedItems,
      state.setHasChanges,
    ]);

  const [
    doReorderAnalysisImages,
    { loading: reorderAnalysisImagesLoading, error: reorderAnalysisImagesError },
  ] = useReorderAnalysisImages({
    refetchQueries: ['Files'],
  });

  const onDiscard = useCallback(() => {
    setItems(parsedFiles);
    setRearrangedItems(() => []);
    setHasChanges?.(false);
    callback?.();
  }, [parsedFiles, setItems, setRearrangedItems, setHasChanges]);

  const onSave = useCallback(() => {
    const editedItems = items
      .flat()
      .filter((item) => item)
      .map((item, index) => ({
        alignment:
          rearrangedItems.find((rearrangedItem) => rearrangedItem?.fileId === item?.id)
            ?.alignment || item?.alignment,
        fileId: item?.id,
        index: Math.floor(index / 2) + 1,
      }));
    doReorderAnalysisImages({
      variables: {
        input: editedItems,
        analysisId,
      },
    });
    setRearrangedItems(() => []);
    setHasChanges?.(false);
    callback?.();
  }, [doReorderAnalysisImages, rearrangedItems, setRearrangedItems, setHasChanges]);

  return {
    onDiscard,
    onSave,
    reorderAnalysisImagesLoading,
    reorderAnalysisImagesError,
  };
};

export default useRearrangeImagesSaveDiscard;
