import AnalysisInterfaceEnum from '../../../../infrastructure/types/AnalysisInterfaceEnum';

interface ISize {
  small: number;
  default: number;
}

const getSizeByInterface = (size: ISize, model: AnalysisInterfaceEnum) => {
  if (model === AnalysisInterfaceEnum.MODEL_4) {
    return size.small;
  }
  return size.default;
};

export default getSizeByInterface;
