import { ColorRGBA, getColorAtPixel, setColorAtPixel } from 'q-floodfill';
import colorsMatch from './colorsMatch';

interface IReplaceColor {
  colorToReplace: ColorRGBA;
  colorToReplaceWith: ColorRGBA;
}

const replaceColors = (canvas: HTMLCanvasElement, colors: IReplaceColor[]) => {
  const ctx = canvas.getContext('2d', { alpha: false });
  if (!ctx) return;

  const { width, height } = canvas;
  const imgData = ctx.getImageData(0, 0, width, height);
  for (let i = 0; i < width; i += 1) {
    for (let j = 0; j < height; j += 1) {
      const currentColor = getColorAtPixel(imgData, i, j);
      const color = colors.find(({ colorToReplace }) => {
        return colorsMatch(currentColor, colorToReplace);
      });
      if (color) {
        setColorAtPixel(imgData, color.colorToReplaceWith, i, j);
      }
    }
  }
  ctx.putImageData(imgData, 0, 0);
};

export default replaceColors;
