import { Box, Skeleton } from '@mui/material';
import React, { useMemo } from 'react';

interface IProps {
  count: number;
  width: number;
  height: number;
}

const SkeletonsList: React.FC<IProps> = ({ count, width, height }) => {
  const skeletons = useMemo(() => {
    return Array(count)
      .fill(0)
      .map((_, key) => key);
  }, []);

  return (
    <Box>
      {skeletons.map((key) => (
        <Skeleton
          key={key}
          animation="wave"
          variant="rectangular"
          width={width}
          height={height}
          sx={{ marginBottom: '1px' }}
        />
      ))}
    </Box>
  );
};

export default SkeletonsList;
