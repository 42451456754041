import { gql, MutationHookOptions, useMutation } from '@apollo/client';

export const ADD_FILES_TO_STUDY_FROM_S3 = gql`
  mutation addFilesToStudyFomS3($s3Ids: [String!]!) {
    addFilesToStudyFomS3(s3Ids: $s3Ids) {
      id
    }
  }
`;

export default (options: MutationHookOptions) => {
  return useMutation(ADD_FILES_TO_STUDY_FROM_S3, options);
};
