import IFile from '../../analysis/types/IFile';
import IProjectFile from '../../upload/types/IProjectFile';
import IProjectFolder from '../../upload/types/IProjectFolder';

interface IFilesSlice {
  itemFiles: Record<string, IFile[]>;
  projectFiles: Record<string, (IProjectFile | IProjectFolder)[]>;
  setItemFiles: (itemFiles: Record<string, IFile[]>) => void;
  setProjectFiles: (itemFiles: Record<string, (IProjectFile | IProjectFolder)[]>) => void;
}

const initialState = {
  itemFiles: {},
  projectFiles: {},
  setItemFiles: () => {},
  setProjectFiles: () => {},
};

const createFilesSlice = (set): IFilesSlice => ({
  ...initialState,
  setItemFiles: (itemFiles: Record<string, IFile[]>) => {
    set((state) => {
      return { itemFiles: { ...state.itemFiles, ...itemFiles } };
    });
  },
  setProjectFiles: (projectFiles: Record<string, (IProjectFile | IProjectFolder)[]>) => {
    set((state) => {
      return { projectFiles: { ...state.projectFiles, ...projectFiles } };
    });
  },
});

export default createFilesSlice;
