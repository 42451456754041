import { Checkbox, ListItem, ListItemButton, ListItemText } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { FileIcon } from '../../infrastructure/assets/icons';
import useStore from '../../infrastructure/store/useStore';
import { useUploadContext } from './context/UploadContext';

interface IProps {
  id: string;
  name: string;
  parentFolderIds: string[];
}

const ProjectFileListItem: React.FC<IProps> = ({ id, name, parentFolderIds }) => {
  const {
    addSelectedFileIds,
    addSelectedFileId,
    removeSelectedFileId,
    selectedFilesIds,
    lastSelectedFileId,
    getRangeBetweenSelectedFilesIds,
  } = useUploadContext();
  const projectFiles = useStore((state) => state.projectFiles);

  const checked = useMemo(() => {
    const parentSelected =
      parentFolderIds.length > 0 &&
      parentFolderIds.some((parentFolderId) => selectedFilesIds.includes(parentFolderId));
    return parentSelected || selectedFilesIds.includes(id);
  }, [selectedFilesIds, parentFolderIds, id]);

  const handleOnCheck = useCallback(
    (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement | HTMLDivElement, MouseEvent>) => {
      if (!checked) {
        addSelectedFileId(id);
        if (e.shiftKey && lastSelectedFileId) {
          const ids = getRangeBetweenSelectedFilesIds(id, lastSelectedFileId, projectFiles);
          if (ids.length === 0) return;
          addSelectedFileIds(ids);
        }
        return;
      }
      removeSelectedFileId(id);
    },
    [
      checked,
      projectFiles,
      parentFolderIds,
      addSelectedFileIds,
      removeSelectedFileId,
      id,
      lastSelectedFileId,
    ],
  );

  return (
    <ListItem
      disablePadding
      divider
      secondaryAction={<Checkbox checked={checked} onClick={handleOnCheck} />}
    >
      <ListItemButton onClick={handleOnCheck}>
        <FileIcon style={{ marginRight: 8, marginLeft: 4 }} />
        <ListItemText primary={name} />
      </ListItemButton>
    </ListItem>
  );
};

export default React.memo(ProjectFileListItem);
