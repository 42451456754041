import { Grid } from '@mui/material';
import React from 'react';
import StudyHeader from '../../domain/study/StudyHeader';
import StudyTabs from '../../domain/study/StudyTabs';
import MainContainer from '../../ui/MainContainer';
import TableWrapper from '../../ui/TableWrapper';
import Header from '../layout/Header/Header';

const StudyPage: React.FC = () => {
  return (
    <>
      <Header />
      <MainContainer>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <StudyHeader />
          </Grid>
          <Grid item xs={12}>
            <TableWrapper>
              <StudyTabs />
            </TableWrapper>
          </Grid>
        </Grid>
      </MainContainer>
    </>
  );
};

export default StudyPage;
