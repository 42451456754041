import { Box } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import IFileRoleEnum from '../../../infrastructure/types/IFileRoleEnum';
import { FilterTypeEnum } from '../../../infrastructure/types/IFilterInput';
import IAnalysis from '../../studies/types/IAnalysis';
import useFiles from '../graphql/useFiles';
import IFile from '../types/IFile';
import IFolderStructure from '../types/IFolderStructure';
import ImageFolderSelector from './ImageFolderSelector';
import ImageLabellingLayout from './ImageLabellingLayout';

interface IProps {
  analysis: IAnalysis;
  analysisLoading: boolean;
}

const ImageLabelling: React.FC<IProps> = ({ analysis, analysisLoading }) => {
  const { analysisId } = useParams();
  const filters = useMemo(() => {
    return {
      filters: [
        { field: 'analysisId', type: FilterTypeEnum.IN, args: [analysisId] },
        { field: 'role', type: FilterTypeEnum.IN, args: [IFileRoleEnum.ANALYSIS_RESULT] },
      ],
    };
  }, []);

  const { data: filesData, loading: filesLoading } = useFiles({
    variables: {
      filters,
    },
    skip: !analysisId,
  });

  const mapFolders = useCallback(
    (path: string | null, orderMap: { [key: string]: number }) => (folder: IFolderStructure) => {
      const nextPath = path ? `${path}/${folder.name}` : folder.name;
      Object.assign(orderMap, {
        [nextPath]: orderMap.counter,
      });
      // eslint-disable-next-line no-param-reassign
      orderMap.counter += 1;
      if (folder.subFolders) {
        folder.subFolders.forEach(mapFolders(nextPath, orderMap));
      }
    },
    [],
  );

  const folderOrderMap = useMemo(() => {
    const orderMap: { [key: string]: number } = {
      counter: 0,
    };
    analysis.folderStructure.forEach(mapFolders(null, orderMap));
    return orderMap;
  }, [analysis.folderStructure]);

  const sortFiles = useCallback(
    (a: IFile, b: IFile) => {
      const aOrder = folderOrderMap[a.path];
      const bOrder = folderOrderMap[b.path];
      return aOrder - bOrder;
    },
    [folderOrderMap],
  );

  const files = useMemo(() => {
    const unsortedFiles: IFile[] = [...(filesData?.files || [])];
    return unsortedFiles.sort(sortFiles) || [];
  }, [filesData, sortFiles]);

  const filesMap = useMemo(() => {
    const map: { [key: string]: IFile[] } = {};
    files.forEach((file) => {
      if (!map[file.path]) {
        map[file.path] = [];
      }
      map[file.path].push(file);
    });
    return map;
  }, [filesData?.files]);

  return (
    <Box display="flex" justifyContent="space-between" height={620}>
      <Box width={280}>
        <ImageFolderSelector
          loading={analysisLoading || filesLoading}
          filesMap={filesMap}
          folderStructure={analysis.folderStructure}
        />
      </Box>
      <ImageLabellingLayout files={files} />
    </Box>
  );
};

export default ImageLabelling;
