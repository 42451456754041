import { useLocation } from 'react-router-dom';

const useStateParams = <T>(): T | null => {
  const location = useLocation();
  if (!location.state) {
    return null;
  }
  const state = location.state as T;
  return state;
};

export default useStateParams;
