import { gql, useLazyQuery } from '@apollo/client';
import debounce from 'lodash/debounce';
import { useCallback } from 'react';
import ISearchItem from '../types/ISearchItem';

const GET_STUDIES = gql`
  query studies($input: FilterInput) {
    studies(filters: $input) {
      id
      name
    }
  }
`;

interface IGetStudies {
  performSearchStudies: (val: string) => void;
  studies: ISearchItem[];
  loading: boolean;
}

const useGetStudiesLazyQuery = (): IGetStudies => {
  const [getStudies, { data, loading }] = useLazyQuery<{ studies: ISearchItem[] }>(GET_STUDIES);

  const getItems = useCallback(
    (val: string) => {
      if (val) {
        getStudies({
          variables: {
            input: {
              filters: {
                field: 'name',
                type: 'ILIKE',
                args: [val],
              },
              limit: 5,
            },
          },
        });
      }
    },
    [getStudies],
  );

  const performSearchStudies = useCallback(
    debounce((val: string) => getItems(val), 300),
    [],
  );

  return {
    performSearchStudies,
    studies: data?.studies || [],
    loading,
  };
};

export default useGetStudiesLazyQuery;
