import { MpColumn, MpColumnRender } from '@mp-react/table';
import { useTranslation } from 'react-i18next';

const useUsersColumns = (): MpColumn[] => {
  const { t } = useTranslation();

  return [
    {
      label: t('profile.firstName'),
      key: 'firstName',
    },
    {
      label: t('profile.lastName'),
      key: 'lastName',
    },
    {
      label: t('profile.email'),
      key: 'email',
    },
    {
      label: t('studies.status'),
      key: 'isActive',
      render: MpColumnRender.switch,
      width: 70,
      action: {
        slug: 'toggleStatus',
        color: 'primary',
      },
    },
    {
      label: '',
      key: 'id',
      sort: false,
      render: MpColumnRender.button,
      action: {
        slug: 'resendEmail',
        label: t('profile.resetPassword'),
      },
    },
  ];
};

export default useUsersColumns;
