import { gql, useLazyQuery } from '@apollo/client';
import debounce from 'lodash/debounce';
import { useCallback } from 'react';
import ISearchItem from '../types/ISearchItem';

const GET_ANALYSES = gql`
  query analyses($input: FilterInput) {
    analyses(filters: $input) {
      id
      name
      study {
        name
      }
    }
  }
`;

interface IGetAnalyses {
  performSearchAnalyses: (val: string) => void;
  analyses: ISearchItem[];
  loading: boolean;
}

const useGetAnalysesLazyQuery = (): IGetAnalyses => {
  const [getAnalyses, { data, loading }] = useLazyQuery<{ analyses: ISearchItem[] }>(GET_ANALYSES);

  const getItems = useCallback(
    (val: string) => {
      if (val) {
        getAnalyses({
          variables: {
            input: {
              filters: {
                field: 'name',
                type: 'ILIKE',
                args: [val],
              },
              limit: 5,
            },
          },
        });
      }
    },
    [getAnalyses],
  );

  const performSearchAnalyses = useCallback(
    debounce((val: string) => getItems(val), 300),
    [],
  );

  return {
    performSearchAnalyses,
    analyses: data?.analyses || [],
    loading,
  };
};

export default useGetAnalysesLazyQuery;
