import { gql } from '@apollo/client';

export const NOTIFICATION_CREATED_SUBSCRIPTION = gql`
  subscription notificationCreated {
    notificationCreated {
      id
      type
      message
      analysisId
      studyId
      creationDate
      read
      errors {
        code
        message
        sentFile
      }
    }
  }
`;

export const NOTIFICATION_UPDATED_SUBSCRIPTION = gql`
  subscription notificationUpdated {
    notificationUpdated {
      id
      type
      message
      analysisId
      studyId
      creationDate
      currentProgress
      expectedProgress
      read
      errors {
        code
        message
        sentFile
      }
    }
  }
`;
