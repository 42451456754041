import { getColorAtPixel, setColorAtPixel } from 'q-floodfill';

const convertToBlack = (canvas: HTMLCanvasElement) => {
  const ctx = canvas.getContext('2d', { alpha: false });
  if (!ctx) return;

  const { width, height } = canvas;
  const imgData = ctx.getImageData(0, 0, width, height);
  for (let i = 0; i < width; i += 1) {
    for (let j = 0; j < height; j += 1) {
      const currentColor = getColorAtPixel(imgData, i, j);
      if (currentColor.a !== 0) {
        setColorAtPixel(imgData, { r: 1, g: 1, b: 1, a: 255 }, i, j);
      }
      if (currentColor.a === 0) {
        setColorAtPixel(imgData, { r: 0, g: 0, b: 0, a: 255 }, i, j);
      }
    }
  }
  ctx.putImageData(imgData, 0, 0);
};

export default convertToBlack;
