import { Checkbox, CircularProgress, Divider } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useStore from '../../infrastructure/store/useStore';
import IFileRoleEnum from '../../infrastructure/types/IFileRoleEnum';
import { FilterTypeEnum } from '../../infrastructure/types/IFilterInput';
import { IFolderStructure } from '../studies/types/IStudy';
import useStudyFolderStructure from '../study/graphql/useStudyFolderStructure';
import { useUploadContext } from './context/UploadContext';
import ProjectFilesTree from './ProjectFilesTree';
import FileTypeEnum from './types/FileTypeEnum';
import IProjectFolder from './types/IProjectFolder';

const ProjectSelectFiles: React.FC = () => {
  const { t } = useTranslation();
  const { studyId } = useParams();
  const { setSelectedFileIds, selectedFilesIds } = useUploadContext();
  const projectFiles = useStore((state) => state.projectFiles);

  const { data, loading } = useStudyFolderStructure({
    variables: {
      id: studyId,
      filters: {
        filters: [{ field: 'role', type: FilterTypeEnum.IN, args: [IFileRoleEnum.RAW] }],
      },
    },
    fetchPolicy: 'network-only',
  });

  const mapFolders = useCallback((el: IFolderStructure) => {
    const folder: IProjectFolder = {
      id: el.id,
      name: el.name,
      fileCount: el.fileCount,
      subFolders: el.subFolders?.map(mapFolders) || [],
      type: FileTypeEnum.FOLDER,
    };
    return folder;
  }, []);

  const items = useMemo(() => {
    return data?.study?.folderStructure?.map(mapFolders) || [];
  }, [data]);

  const checked = useMemo(() => {
    const childrenIds = projectFiles.root?.filter((el) => el.name !== '').map((el) => el.id) || [];
    return childrenIds.length > 0 && childrenIds.every((id) => selectedFilesIds.includes(id));
  }, [projectFiles, selectedFilesIds]);

  const handleOnCheck = useCallback(() => {
    const childrenIds = projectFiles.root?.map((el) => el.id) || [];
    if (childrenIds.length === 0) return;
    if (!checked) {
      setSelectedFileIds(childrenIds);
      return;
    }
    setSelectedFileIds([]);
  }, [checked, projectFiles, setSelectedFileIds]);

  return (
    <Box>
      <Typography>{t('upload.selectFilesForAnalysis')}</Typography>

      <Box mt={5}>
        {loading && <CircularProgress />}
        {data && (
          <>
            <Box py={1} pl={4} pr={2} display="flex" justifyContent="space-between">
              <Box display="flex" alignItems="center">
                <Typography variant="body1">{t('upload.filesName')}</Typography>
              </Box>
              <Checkbox checked={checked} onChange={handleOnCheck} />
            </Box>
            <Divider />
            <ProjectFilesTree path="" folders={items} parentFolderIds={['root']} />
          </>
        )}
      </Box>
    </Box>
  );
};

export default ProjectSelectFiles;
