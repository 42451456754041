import axios, { AxiosRequestConfig } from 'axios';
import LocalStorageEnum from '../constants/LocalStorageEnum';

const appendUrl = (url: string): string => {
  if (url.startsWith('http')) {
    return url;
  }
  const baseUrl = process.env.REACT_APP_REST_API_URL;
  return `${baseUrl}/${url}`;
};

const API = {
  get: async (url: string, config: AxiosRequestConfig = {}) => {
    return axios.get(appendUrl(url), {
      ...config,
      headers: {
        Authorization: `Bearer ${localStorage.getItem(LocalStorageEnum.JWT_TOKEN_KEY)}`,
      },
    });
  },

  delete: async (url: string, config: AxiosRequestConfig = {}) => {
    return axios.delete(appendUrl(url), {
      ...config,
      headers: {
        Authorization: `Bearer ${localStorage.getItem(LocalStorageEnum.JWT_TOKEN_KEY)}`,
      },
    });
  },

  post: async (url: string, body: unknown, config: AxiosRequestConfig = {}) => {
    return axios.post(appendUrl(url), body, {
      ...config,
      headers: {
        Authorization: `Bearer ${localStorage.getItem(LocalStorageEnum.JWT_TOKEN_KEY)}`,
      },
    });
  },

  put: async (url: string, body: unknown, config: AxiosRequestConfig = {}) => {
    return axios.put(appendUrl(url), body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(LocalStorageEnum.JWT_TOKEN_KEY)}`,
      },
      ...config,
    });
  },
};

export default API;
