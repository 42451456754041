enum IToolsEnum {
  TOOL_PENCIL,
  TOOL_BRUSH,
  TOOL_ERASER,
  TOOL_SCISSORS,
  TOOL_SWORD,
  TOOL_HAND,
}

export default IToolsEnum;
