import { useCallback } from 'react';
import useSetRef from '../../hooks/useSetRef';
import IBounding from '../types/IBounding';
import ICoordinates from '../types/ICoordinates';

const useBoundaries = () => {
  const [boundingRef, setBounding, resetBounding] = useSetRef<IBounding>({
    left: Infinity,
    top: Infinity,
    right: 0,
    bottom: 0,
  });
  const [coordsRef, setCoords, clearCoords] = useSetRef<ICoordinates[]>([]);

  const clearBounding = useCallback(() => {
    clearCoords();
    resetBounding();
  }, [resetBounding, clearCoords]);

  const pushCoords = useCallback(
    (coords: ICoordinates) => {
      setCoords([...coordsRef.current, coords]);
    },
    [setCoords, coordsRef],
  );

  const updateBounding = useCallback(
    (coords: ICoordinates) => {
      const { left, top, right, bottom } = boundingRef.current;
      const newLeft = Math.min(left, coords.x);
      const newTop = Math.min(top, coords.y);
      const newRight = Math.max(right, coords.x);
      const newBottom = Math.max(bottom, coords.y);
      setBounding({ left: newLeft, top: newTop, right: newRight, bottom: newBottom });
      pushCoords(coords);
    },
    [setBounding, pushCoords],
  );

  return {
    boundingRef,
    coordsRef,
    setBounding,
    clearBounding,
    updateBounding,
  };
};

export default useBoundaries;
