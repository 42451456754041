import { AccountCircle } from '@mui/icons-material';
import { IconButton, Menu, MenuItem } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PathEnum from '../../../infrastructure/constants/PathEnum';
import { useAuth } from '../../../infrastructure/context/AuthContext';
import useCurrentUser from '../../../infrastructure/hooks/useCurrentUser';
import RouterLink from '../../../ui/RouterLink';

const ProfileMenu: React.FC = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const { t } = useTranslation();
  const { logout } = useAuth();

  const { user } = useCurrentUser();

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        size="large"
        aria-label="account controls of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      >
        <AccountCircle />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem component={RouterLink} to={PathEnum.UserProfile}>
          {t('header.profile')}
        </MenuItem>
        {user.root && (
          <MenuItem component={RouterLink} to={PathEnum.Users}>
            {t('users.manageUsers')}
          </MenuItem>
        )}
        <MenuItem onClick={logout}>{t('header.logout')}</MenuItem>
      </Menu>
    </>
  );
};

export default ProfileMenu;
