import { colorToRGBA, getColorAtPixel, setColorAtPixel } from 'q-floodfill';
import ImageEditorConfig from '../constants/ImageEditor.config';
import IBounding from '../types/IBounding';
import ICoordinates from '../types/ICoordinates';
import addBoundariesPadding from './addBoundariesPadding';

const backgroundColor = 'rgba(0, 0, 0, 0)';

const getFilledPixelCoordinates = (
  canvas: HTMLCanvasElement,
  boundaries: IBounding,
  color: string,
) => {
  const ctx = canvas.getContext('2d');
  if (!ctx) return [];
  const { left, top, right, bottom } = addBoundariesPadding(
    boundaries,
    ImageEditorConfig.BOUNDARIES_PADDING,
    canvas.width,
    canvas.height,
  );
  const width = right - left - 1;
  const height = bottom - top - 1;
  const coordinates: ICoordinates[] = [];
  const imgData = ctx.getImageData(left, top, width, height);
  for (let i = 0; i < width; i += 1) {
    for (let j = 0; j < height; j += 1) {
      const { a } = getColorAtPixel(imgData, i, j);
      const isBlank = a === 0;
      const nextColor = isBlank ? colorToRGBA(backgroundColor) : colorToRGBA(color);
      if (!isBlank) {
        coordinates.push({ x: i + left, y: j + top });
      }
      setColorAtPixel(imgData, nextColor, i, j);
    }
  }
  ctx.putImageData(imgData, left, top, 0, 0, width, height);
  return coordinates;
};

export default getFilledPixelCoordinates;
