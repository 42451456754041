import { AxiosResponse } from 'axios';
import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import useStore from '../../../infrastructure/store/useStore';
import IFileSubRoleEnum from '../../../infrastructure/types/IFileSubRoleEnum';
import { FilterTypeEnum } from '../../../infrastructure/types/IFilterInput';
import API from '../../../infrastructure/utils/API';
import asyncGenerator from '../../../infrastructure/utils/asyncGenerator';
import useFiles from '../graphql/useFiles';
import removePixelsFromImage from '../ImageEditor/helpers/removePixelsFromImage';
import IFile from '../types/IFile';
import IMask from '../types/IMask';

interface IMaskToProcess extends IMask {
  coordinatesToErase: number[];
}

interface IMaskBlob {
  id: string;
  blob: Blob;
}

const useVIPScansSaver = () => {
  const [selectedVIPImage, erasedCoordinates, offScreenCanvasRef] = useStore((state) => [
    state.selectedVIPImage,
    state.erasedCoordinates,
    state.offScreenCanvasRef,
  ]);
  const { analysisId } = useParams();
  const { data: filesData } = useFiles({
    variables: {
      filters: {
        filters: [
          { field: 'analysisId', type: FilterTypeEnum.IN, args: [analysisId] },
          { field: 'subRole', type: FilterTypeEnum.IN, args: [IFileSubRoleEnum.IMAGE] },
          selectedVIPImage && {
            field: 'associatedFileId',
            type: FilterTypeEnum.IN,
            args: [selectedVIPImage.associatedFileId],
          },
        ],
      },
    },
    skip: !selectedVIPImage,
  });

  const files = useMemo(() => {
    if (!filesData?.files) return [];
    return [...filesData.files].sort((a, b) => a.index - b.index);
  }, [filesData?.files]);

  const masksToProcess = useMemo(() => {
    if (!files || Object.keys(erasedCoordinates).length === 0 || files.length === 0) return [];
    const masks: IMaskToProcess[] = [];
    Object.keys(erasedCoordinates).forEach((sliceIndex) => {
      const slice = files[sliceIndex] as IFile;
      if (!slice) return;
      slice.masks?.forEach((mask) => {
        masks.push({
          ...mask,
          coordinatesToErase: erasedCoordinates[sliceIndex],
        });
      });
    });
    return masks;
  }, [files, erasedCoordinates]);

  const processSlice = useCallback(
    async (mask: IMaskToProcess): Promise<IMaskBlob> => {
      return new Promise((resolve) => {
        const canvas = offScreenCanvasRef.current;
        const ctx = canvas?.getContext('2d');
        if (!canvas || !ctx || !mask.signedUrl) return;
        removePixelsFromImage({
          canvas,
          imgSrc: mask.signedUrl,
          coordinatesToErase: mask.coordinatesToErase,
        }).then((blob) => resolve({ id: mask.id, blob }));
      });
    },
    [offScreenCanvasRef],
  );

  const uploadImage = useCallback((id: string, blob: Blob) => {
    const formData = new FormData();
    formData.append('mask', blob);
    return API.put(`masks/${id}/updateMask`, formData);
  }, []);

  const saveVIPScans = useCallback(async () => {
    const uploadPromises: Promise<AxiosResponse>[] = [];
    const iterator = asyncGenerator<IMaskToProcess, IMaskBlob>(masksToProcess, processSlice);
    // eslint-disable-next-line no-restricted-syntax
    for await (const mask of iterator) {
      const { id, blob } = mask;
      uploadPromises.push(uploadImage(id, blob));
    }
    return uploadPromises;
  }, [offScreenCanvasRef, masksToProcess, processSlice, uploadImage]);

  return {
    saveVIPScans,
  };
};

export default useVIPScansSaver;
