import { LoadingButton } from '@mui/lab';
import { Box, MenuItem, TextField, Typography } from '@mui/material';
import moment from 'moment';
import React, { FormEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import PathEnum from '../../infrastructure/constants/PathEnum';
import AnalysisInterfaceEnum from '../../infrastructure/types/AnalysisInterfaceEnum';
import { FilterTypeEnum } from '../../infrastructure/types/IFilterInput';
import IInternalError from '../../infrastructure/types/IInternalError';
import isServerError from '../../infrastructure/utils/isServerError';
import FullScreenLoader from '../../ui/FullScreenLoader';
import GenericModal from '../../ui/GenericModal';
import useAiModelsQuery from './graphql/useAiModelsQuery';
import useCreateAnalysisMutation from './graphql/useCreateAnalysisMutation';
import useStudyQuery, { GET_STUDY } from './graphql/useStudyQuery';

interface IProps {
  handleClose: () => void;
  isCnv: boolean;
}

const NewAnalysisModal: React.FC<IProps> = ({ handleClose, isCnv }) => {
  const { studyId } = useParams<{ studyId: string }>();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const [submitted, setSubmitted] = useState(false);
  const [commonObjectIdsError, setCommonObjectIdsError] = useState(false);

  const [name, setName] = useState<string>('');
  const [model, setModel] = useState<string>('');
  const [linkedAnalysisIdModel5, setLinkedAnalysisIdModel5] = useState<string>('');
  const [linkedAnalysisIdModel1, setLinkedAnalysisIdModel1] = useState<string>('');
  const [linkedAnalysisIdModel4, setLinkedAnalysisIdModel4] = useState<string>('');
  const [error, setError] = useState<null | IInternalError>(null);

  const { data: studyQueryData, loading: loadingStudy } = useStudyQuery({
    variables: {
      id: studyId,
    },
    skip: !studyId,
  });

  const { data, loading: loadingAiModels } = useAiModelsQuery({
    variables: {
      filters: {
        filters: [
          {
            field: 'cnvModelId',
            type: FilterTypeEnum.IN,
            args: [studyQueryData?.study?.cnvModelId || ''],
          },
        ],
      },
    },
    skip: !studyQueryData,
  });

  const [mutateAnalysis, { loading }] = useCreateAnalysisMutation({
    onCompleted: (response) => {
      if (
        (isCnv && response.createAnalysis.interface === AnalysisInterfaceEnum.MODEL_1) ||
        response.createAnalysis.interface === AnalysisInterfaceEnum.MODEL_6
      ) {
        navigate(
          generatePath(PathEnum.AnalysisUpload, {
            studyId,
            analysisId: response.createAnalysis.id,
          }),
          {
            state: {
              skipSelectFiles: true,
            },
          },
        );
        return;
      }
      navigate(
        generatePath(PathEnum.AnalysisUpload, { studyId, analysisId: response.createAnalysis.id }),
      );
    },
    onError: ({ graphQLErrors, networkError }) => {
      const objectIdsError = graphQLErrors.find((err) => err.subCode === 5005);
      if (objectIdsError) {
        setCommonObjectIdsError(true);
      }
      if (networkError) {
        if (isServerError(networkError)) {
          setError({
            date: moment().format('YYYY-MM-DD HH:mm'),
            statusCode: networkError.statusCode,
          });
          return;
        }
        setError({
          date: moment().format('YYYY-MM-DD HH:mm'),
          context: 'dateOnly',
        });
      }
    },
  });

  const selectedInterface = useMemo(() => {
    if (model) {
      const aiModel = data?.aiModels.find((item) => item.id === model);
      return aiModel?.interface;
    }
    return null;
  }, [model, data]);

  const shouldSelectLinkedAnalysis = useMemo(() => {
    if (selectedInterface) {
      return selectedInterface === AnalysisInterfaceEnum.MODEL_1 && isCnv;
    }
    return false;
  }, [selectedInterface, isCnv]);

  const shouldSelectLinkedAnalysisModel6 = useMemo(() => {
    if (selectedInterface) {
      return selectedInterface === AnalysisInterfaceEnum.MODEL_6;
    }
    return false;
  }, [selectedInterface]);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    setCommonObjectIdsError(false);
    setSubmitted(true);
    setError(null);
    if (shouldSelectLinkedAnalysis && !linkedAnalysisIdModel5) {
      return;
    }
    if (shouldSelectLinkedAnalysisModel6 && (!linkedAnalysisIdModel5 || !linkedAnalysisIdModel1)) {
      return;
    }
    if (!name || !model || !studyId) {
      return false;
    }
    const input = {
      name,
      aiModelId: model,
      studyId,
    };
    if (shouldSelectLinkedAnalysis) {
      Object.assign(input, { linkedAnalysesIds: [linkedAnalysisIdModel5] });
    }
    if (shouldSelectLinkedAnalysisModel6) {
      Object.assign(input, {
        linkedAnalysesIds: linkedAnalysisIdModel4
          ? [linkedAnalysisIdModel5, linkedAnalysisIdModel1, linkedAnalysisIdModel4]
          : [linkedAnalysisIdModel5, linkedAnalysisIdModel1],
      });
    }
    mutateAnalysis({
      variables: {
        input,
      },
      refetchQueries: [GET_STUDY],
    });
  };

  const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const onModelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setModel(e.target.value);
  };

  const onLinkedAnalysisChangeModel5 = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLinkedAnalysisIdModel5(e.target.value);
  };
  const onLinkedAnalysisChangeModel1 = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLinkedAnalysisIdModel1(e.target.value);
  };
  const onLinkedAnalysisChangeModel4 = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLinkedAnalysisIdModel4(e.target.value);
  };

  const linkedAnalysesModel1 = useMemo(() => {
    return (
      studyQueryData?.study?.analyses?.filter((analysis) => {
        return (
          analysis.interface === AnalysisInterfaceEnum.MODEL_1 && analysis.status === 'COMPLETED'
        );
      }) || []
    );
  }, [selectedInterface, studyQueryData]);

  const linkedAnalysesModel4 = useMemo(() => {
    return (
      studyQueryData?.study?.analyses?.filter((analysis) => {
        return (
          analysis.interface === AnalysisInterfaceEnum.MODEL_4 && analysis.status === 'COMPLETED'
        );
      }) || []
    );
  }, [selectedInterface, studyQueryData]);

  const linkedAnalysesModel5 = useMemo(() => {
    return (
      studyQueryData?.study?.analyses?.filter((analysis) => {
        return (
          analysis.interface === AnalysisInterfaceEnum.MODEL_5 && analysis.status === 'COMPLETED'
        );
      }) || []
    );
  }, [selectedInterface, studyQueryData]);

  const filteredModels = useMemo(() => {
    if (!studyQueryData?.study?.cnvModel?.isCNV) {
      return data?.aiModels || [];
    }

    return (
      data?.aiModels.filter((aiModel) => {
        if (aiModel.interface === AnalysisInterfaceEnum.MODEL_1) {
          if (!linkedAnalysesModel5.length) {
            return false;
          }
        }
        if (aiModel.interface === AnalysisInterfaceEnum.MODEL_6) {
          if (!linkedAnalysesModel1.length || !linkedAnalysesModel5.length) {
            return false;
          }
        }
        return true;
      }) || []
    );
  }, [
    studyQueryData?.study,
    data?.aiModels,
    linkedAnalysesModel1,
    linkedAnalysesModel4,
    linkedAnalysesModel5,
  ]);

  const model1Label = useMemo(() => {
    if (selectedInterface !== AnalysisInterfaceEnum.MODEL_6) {
      return '';
    }
    return data?.aiModels.find((item) => item.interface === AnalysisInterfaceEnum.MODEL_1)?.model;
  }, [selectedInterface, data?.aiModels]);

  const model4Label = useMemo(() => {
    if (selectedInterface !== AnalysisInterfaceEnum.MODEL_6) {
      return '';
    }
    return data?.aiModels.find((item) => item.interface === AnalysisInterfaceEnum.MODEL_4)?.model;
  }, [selectedInterface, data?.aiModels]);

  const model5Label = useMemo(() => {
    if (selectedInterface !== AnalysisInterfaceEnum.MODEL_6) {
      return '';
    }
    return data?.aiModels.find((item) => item.interface === AnalysisInterfaceEnum.MODEL_5)?.model;
  }, [selectedInterface, data?.aiModels]);

  if (loadingStudy || loadingAiModels) {
    return <FullScreenLoader />;
  }

  return (
    <GenericModal open onClose={handleClose}>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography id="modal-modal-title" variant="h5" component="h2">
          {t('study.nameYourAnalysis')}
        </Typography>
        <Box width="100%" component="form" onSubmit={handleSubmit} noValidate sx={{ mt: '14px' }}>
          <TextField
            autoFocus
            required
            margin="normal"
            fullWidth
            id="name"
            type="text"
            label={t('studies.createAnalysis.nameLabel')}
            name="name"
            inputMode="text"
            size="small"
            placeholder={t('studies.createAnalysis.namePlaceholder')}
            onChange={onNameChange}
            value={name}
            error={submitted && !name}
            autoComplete="off"
          />
          <TextField
            required
            margin="normal"
            fullWidth
            select
            id="model"
            name="model"
            size="small"
            label={t('studies.createAnalysis.modelLabel')}
            placeholder={t('studies.createAnalysis.modelPlaceholder')}
            onChange={onModelChange}
            value={model}
            error={submitted && !model}
          >
            {filteredModels.map((aiModel) => (
              <MenuItem key={aiModel.id} value={aiModel.id}>
                {aiModel.model}
              </MenuItem>
            ))}
          </TextField>
          {shouldSelectLinkedAnalysis && (
            <TextField
              required
              margin="normal"
              fullWidth
              select
              id="model"
              name="model"
              size="small"
              label={t('studies.createAnalysis.linkedAnalysisLabel')}
              placeholder={t('studies.createAnalysis.linkedAnalysisPlaceholder')}
              onChange={onLinkedAnalysisChangeModel5}
              value={linkedAnalysisIdModel5}
              error={submitted && !linkedAnalysisIdModel5}
            >
              {linkedAnalysesModel5.map((analysis) => (
                <MenuItem key={analysis.id} value={analysis.id}>
                  {analysis.name}
                </MenuItem>
              ))}
            </TextField>
          )}
          {shouldSelectLinkedAnalysisModel6 && (
            <>
              <TextField
                required
                margin="normal"
                fullWidth
                select
                id="model"
                name="model"
                size="small"
                label={t('studies.createAnalysis.linkedAnalysisCustomLabel', {
                  model: model5Label,
                })}
                placeholder={t('studies.createAnalysis.linkedAnalysisPlaceholder')}
                onChange={onLinkedAnalysisChangeModel5}
                value={linkedAnalysisIdModel5}
                error={submitted && !linkedAnalysisIdModel5}
              >
                {linkedAnalysesModel5.map((analysis) => (
                  <MenuItem key={analysis.id} value={analysis.id}>
                    {analysis.name}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                required
                margin="normal"
                fullWidth
                select
                id="model"
                name="model"
                size="small"
                label={t('studies.createAnalysis.linkedAnalysisCustomLabel', {
                  model: model1Label,
                })}
                placeholder={t('studies.createAnalysis.linkedAnalysisPlaceholder')}
                onChange={onLinkedAnalysisChangeModel1}
                value={linkedAnalysisIdModel1}
                error={submitted && !linkedAnalysisIdModel1}
              >
                {linkedAnalysesModel1.map((analysis) => (
                  <MenuItem key={analysis.id} value={analysis.id}>
                    {analysis.name}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                margin="normal"
                fullWidth
                select
                id="model"
                name="model"
                size="small"
                label={t('studies.createAnalysis.linkedAnalysisCustomLabel', {
                  model: model4Label,
                })}
                placeholder={t('studies.createAnalysis.linkedAnalysisPlaceholder')}
                onChange={onLinkedAnalysisChangeModel4}
                value={linkedAnalysisIdModel4}
              >
                {linkedAnalysesModel4.map((analysis) => (
                  <MenuItem key={analysis.id} value={analysis.id}>
                    {analysis.name}
                  </MenuItem>
                ))}
              </TextField>
            </>
          )}
          {error &&
            (commonObjectIdsError ? (
              <Typography variant="body1" color="error.main" mt={1}>
                {t('studies.createAnalysis.linkedAnalysesError')}
              </Typography>
            ) : (
              <Typography variant="body1" color="error.main" mt={1}>
                {t('general.unknownError', {
                  date: error.date,
                  statusCode: error.statusCode,
                  context: error.context,
                })}
              </Typography>
            ))}
          <Box mt={3} textAlign="center">
            <LoadingButton loading={loading} type="submit" variant="contained">
              {t('button.create')}
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </GenericModal>
  );
};

export default NewAnalysisModal;
