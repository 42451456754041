export enum UserStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export default interface IUser {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  emailNotifications: boolean;
  root?: boolean;
  status?: UserStatusEnum;
}
