import { FilterFunction, getRawValue, MpFilterProps, MpFilterTypes } from '@mp-react/table';
import { ComponentType } from 'react';
import SearchIncludeExclude from './SearchIncludeExclude/SearchIncludeExclude';

const functionSearchIncludeExclude: FilterFunction = (rows, _, filterValue) => {
  if (filterValue == null || typeof filterValue !== 'object') {
    return rows;
  }

  return rows.filter((row) => {
    if (filterValue.exclude) {
      if (getRawValue(row.original, 'name').includes(filterValue.exclude)) {
        return false;
      }
    }
    if (filterValue.include) {
      if (!getRawValue(row.original, 'name').includes(filterValue.include)) {
        return false;
      }
    }
    return true;
  });
};

export const customFilterMap: Record<string, ComponentType<MpFilterProps>> = {
  includeExclude: SearchIncludeExclude,
};

export const customFilterFunctionMap: Record<string, MpFilterTypes | FilterFunction> = {
  includeExclude: functionSearchIncludeExclude,
};
