import { Box, Divider, ListItem, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import xss from 'xss';
import { ClockIcon } from '../../../infrastructure/assets/icons';
import Colors from '../../../infrastructure/constants/Colors';
import PathEnum from '../../../infrastructure/constants/PathEnum';
import { useNotifications } from '../../../infrastructure/context/NotificationsContext';
import INotification, { NotificationType } from '../../../infrastructure/types/INotification';

const NotificationItem: React.FC<{ notification: INotification }> = ({ notification }) => {
  const navigate = useNavigate();

  const { markAsRead } = useNotifications();

  const onClick = () => {
    markAsRead([notification.id]);
    if (
      notification.type === NotificationType.STUDY_FILES_UPLOADED ||
      notification.type === NotificationType.ANALYSIS_PROCESSED
    ) {
      navigate(generatePath(PathEnum.Study, { studyId: notification.studyId }));
    }
    if (
      notification.type === NotificationType.ANALYSIS_FILES_UPLOADED ||
      notification.type === NotificationType.ANALYSIS_REPORT_GENERATED
    ) {
      navigate(generatePath(PathEnum.Analysis, { analysisId: notification.analysisId }));
    }
  };

  return (
    <ListItem button onClick={onClick} disablePadding sx={{ py: 1 }}>
      <Box display="flex" flexDirection="column" pl={3}>
        <Typography
          variant="body2"
          pr={3}
          sx={{
            fontSize: '12px',
            lineHeight: '18px',
            color: Colors.textPrimary,
          }}
          dangerouslySetInnerHTML={{
            __html: xss(notification.message),
          }}
        />
        <Typography
          sx={{
            marginTop: '3px',
            fontSize: '10px',
            lineHeight: '12px',
            color: Colors.textSecondary,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <ClockIcon />
          <Box component="span" sx={{ ml: '5px' }}>
            {moment(notification.creationDate).fromNow()}
          </Box>
        </Typography>
        <Divider sx={{ mt: 1 }} />
      </Box>
    </ListItem>
  );
};

export default NotificationItem;
