import { ExpandMore } from '@mui/icons-material';
import FolderIcon from '@mui/icons-material/Folder';
import { Checkbox, Collapse, ListItem, ListItemButton, ListItemText } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import useStore from '../../infrastructure/store/useStore';
import { useUploadContext } from './context/UploadContext';
import getChildrenIds from './helpers/getChildrenIds';
import IProjectFolder from './types/IProjectFolder';

interface IProps {
  id: string;
  name: string;
  parentFolderIds: string[];
  children: JSX.Element | JSX.Element[];
  folder?: IProjectFolder;
}

enum CollapseState {
  COLLAPSED = 'COLLAPSED',
  EXPANDED = 'EXPANDED',
  NOT_RENDERED = 'NOT_RENDERED',
  RENDERING = 'RENDERING',
}

const ProjectFolderListItem: React.FC<IProps> = ({
  id,
  name,
  children,
  parentFolderIds,
  folder,
}) => {
  const [collapseState, setCollapseState] = useState(CollapseState.NOT_RENDERED);
  const {
    addSelectedFileIds,
    removeSelectedFileIds,
    addSelectedFileId,
    lastSelectedFileId,
    selectedFilesIds,
    getRangeBetweenSelectedFilesIds,
    removeSelectedFileId,
  } = useUploadContext();
  const projectFiles = useStore((state) => state.projectFiles);

  useEffect(() => {
    if (collapseState === CollapseState.RENDERING) {
      setCollapseState(CollapseState.EXPANDED);
    }
  }, [collapseState]);

  const handleOnClick = useCallback(() => {
    if (collapseState === CollapseState.EXPANDED) {
      setCollapseState(CollapseState.COLLAPSED);
      return;
    }
    if (collapseState === CollapseState.NOT_RENDERED) {
      setCollapseState(CollapseState.RENDERING);
      return;
    }
    setCollapseState(CollapseState.EXPANDED);
  }, [collapseState, setCollapseState]);

  const checked = useMemo(() => {
    const parentSelected =
      parentFolderIds.length > 0 &&
      parentFolderIds.some((parentFolderId) => selectedFilesIds.includes(parentFolderId));
    return parentSelected || selectedFilesIds.includes(id);
  }, [selectedFilesIds, parentFolderIds, id]);

  const handleOnCheck = useCallback(
    (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement | HTMLDivElement, MouseEvent>) => {
      if (!checked) {
        const removeIds: string[] = [];
        if (projectFiles[id]) {
          removeIds.push(...getChildrenIds([], projectFiles[id], projectFiles));
        }
        addSelectedFileId(id, removeIds);
        if (e.shiftKey && lastSelectedFileId) {
          const ids = getRangeBetweenSelectedFilesIds(id, lastSelectedFileId, projectFiles);
          if (ids.length === 0) return;
          addSelectedFileIds(ids, removeIds);
        }
        return;
      }
      removeSelectedFileId(id);
    },
    [
      checked,
      projectFiles,
      parentFolderIds,
      addSelectedFileIds,
      removeSelectedFileIds,
      removeSelectedFileId,
      id,
    ],
  );

  const getTotalCount = useCallback((item: IProjectFolder) => {
    const totalCount =
      item.subFolders?.reduce((count, current) => count + getTotalCount(current), 0) || 0;
    return item.fileCount + totalCount;
  }, []);

  const fileName = useMemo(() => {
    if (!folder) {
      return name;
    }
    if (folder.fileCount === undefined) {
      return name;
    }

    return `${name} (${getTotalCount(folder)})`;
  }, [folder, name]);

  return (
    <>
      <ListItem
        disablePadding
        divider
        secondaryAction={<Checkbox checked={checked} onClick={handleOnCheck} />}
      >
        <ListItemButton onClick={handleOnClick}>
          <ExpandMore />
          <FolderIcon sx={{ mx: 1 }} />
          <ListItemText primary={fileName} />
        </ListItemButton>
      </ListItem>
      {collapseState !== CollapseState.NOT_RENDERED && (
        <Collapse in={collapseState === CollapseState.EXPANDED} timeout={100}>
          {children}
        </Collapse>
      )}
    </>
  );
};

export default React.memo(ProjectFolderListItem);
