import { colorToRGBA, getColorAtPixel, setColorAtPixel } from 'q-floodfill';
import ICoordinates from '../types/ICoordinates';
import colorsMatch from './colorsMatch';

const backgroundColor = 'rgba(0, 0, 0, 0)';

const clearCanvasBrush = (canvas: HTMLCanvasElement, coordinates: ICoordinates[]) => {
  const ctx = canvas.getContext('2d');
  if (!ctx) return false;
  const { width, height } = canvas;
  const imgData = ctx.getImageData(0, 0, width, height);

  coordinates.forEach(({ x, y }) => {
    if (!colorsMatch(getColorAtPixel(imgData, x, y), colorToRGBA(backgroundColor))) {
      setColorAtPixel(imgData, colorToRGBA(backgroundColor), x, y);
    }
  });
  ctx.putImageData(imgData, 0, 0);
};

export default clearCanvasBrush;
