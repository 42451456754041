import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import React from 'react';
import useStore from '../../../infrastructure/store/useStore';
import ILabel from '../types/ILabel';

interface IProps {
  labels: ILabel[];
}

const LabelsSelectionRadio: React.FC<IProps> = ({ labels }) => {
  const [selectedLabels, setSelectedLabels] = useStore((state) => [
    state.selectedLabels,
    state.setSelectedLabels,
  ]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedLabels([event.target.value]);
  };

  return (
    <FormControl>
      <RadioGroup
        aria-labelledby="labels-selection-radio"
        name="labels-selection-radio"
        onChange={handleChange}
        value={selectedLabels[0]}
      >
        {labels.map((label) => {
          return (
            <FormControlLabel
              key={label.id}
              value={label.id}
              control={<Radio />}
              label={label.name}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};

export default LabelsSelectionRadio;
