import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import IUser from '../../../infrastructure/types/IUser';

interface ICreateUserInput {
  input: {
    firstName: string;
    lastName: string;
    email: string;
  };
}

interface ICreateUserResponse {
  createUser: IUser;
}

const CREATE_USER = gql`
  mutation createUser($input: CreateUserInput!) {
    createUser(createUserInput: $input) {
      id
      firstName
      lastName
      creationDate
      status
      email
      root
    }
  }
`;

export default (options: MutationHookOptions<ICreateUserResponse, ICreateUserInput>) => {
  return useMutation<ICreateUserResponse, ICreateUserInput>(CREATE_USER, options);
};
