import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import IUser from '../../../infrastructure/types/IUser';

interface IGetUserProfile {
  userProfile: IUser;
}

export const GET_USER_PROFILE = gql`
  query userProfile {
    userProfile {
      id
      email
      firstName
      lastName
      emailNotifications
    }
  }
`;

export default (options?: QueryHookOptions) => useQuery<IGetUserProfile>(GET_USER_PROFILE, options);
