import { MpTable } from '@mp-react/table';
import { Typography } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import Colors from '../../infrastructure/constants/Colors';
import PathEnum from '../../infrastructure/constants/PathEnum';
import TableLimit from '../../infrastructure/constants/TableLimit';
import IAnalysis from '../studies/types/IAnalysis';
import IAnalysisStatus from '../studies/types/IAnalysisStatus';
import AnalysisStatusInfoModal from './AnalysisStatusInfoModal';
import { ANALYSIS_SUBSCRIPTION } from './graphql/useAnalysisSubscription';
import useStudyQuery from './graphql/useStudyQuery';
import useAnalysisColumns from './hooks/useAnalysisColumns';
import DeleteAnalysisModal from './DeleteAnalysisModal';
import useDeleteAnalysis from './graphql/useDeleteAnalysis';

const AnalysisTable: React.FC = () => {
  const { studyId } = useParams<{ studyId: string }>();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [modalStatus, setModalStatus] = useState<IAnalysisStatus | null>(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState<string | null>(null);

  const columns = useAnalysisColumns();

  const { loading, data, subscribeToMore, refetch } = useStudyQuery({
    variables: {
      id: studyId,
    },
    fetchPolicy: 'cache-and-network',
    skip: !studyId,
  });

  const [deleteAnalysis, { loading: deleteLoading, client }] = useDeleteAnalysis({
    onCompleted: () => {
      client.resetStore();
      setDeleteModalOpen(null);
      refetch();
    },
    onError: () => {},
  });

  useEffect(() => {
    if (studyId) {
      subscribeToMore({
        document: ANALYSIS_SUBSCRIPTION,
        variables: {
          studyId,
        },
      });
    }
  }, [studyId]);

  const onRowClick = (row) => {
    const { status } = row.original;
    if (
      status === IAnalysisStatus.TIMED_OUT ||
      status === IAnalysisStatus.IN_PROGRESS ||
      status === IAnalysisStatus.FAILED
    ) {
      setModalStatus(status);
      return;
    }
    if (status === IAnalysisStatus.CREATED) {
      navigate(generatePath(PathEnum.AnalysisUpload, { studyId, analysisId: row.id }));
      return;
    }
    navigate(generatePath(PathEnum.Analysis, { analysisId: row.id }));
  };

  const mapAnalysisStatusTrans = useCallback(
    (analysis: IAnalysis) => {
      return {
        ...analysis,
        statusTranslation: t(`analysis.${analysis.status}`),
      };
    },
    [t],
  );

  const analyses = useMemo(() => {
    return data?.study.analyses?.map(mapAnalysisStatusTrans) || [];
  }, [data]);

  const closeDeleteModal = useCallback(() => {
    setDeleteModalOpen(null);
  }, [setDeleteModalOpen]);

  const openDeleteModal = useCallback(
    (id: string) => {
      setDeleteModalOpen(id);
    },
    [setDeleteModalOpen],
  );

  const onDelete = useCallback(() => {
    if (!deleteModalOpen) {
      return;
    }
    deleteAnalysis({ variables: { id: deleteModalOpen } });
  }, [deleteModalOpen, deleteAnalysis]);

  return (
    <>
      <MpTable
        loading={loading}
        data={analyses}
        dataCount={analyses.length}
        columns={columns}
        rowIdKey="id"
        onGetData={() => {}}
        pageSize={TableLimit.analysesLimit}
        localPagination
        localSorting
        localFiltering
        onRowClick={onRowClick}
        rowMethods={{
          deleteAnalysis: ({ row }) => {
            openDeleteModal(row.id);
          },
        }}
        emptyChildren={
          <Typography color={Colors.gray} sx={{ mt: 10 }} textAlign="center">
            {t('study.noAnalysisFound')}
          </Typography>
        }
      />
      <DeleteAnalysisModal
        open={Boolean(deleteModalOpen)}
        loading={deleteLoading}
        handleClose={closeDeleteModal}
        onDelete={onDelete}
      />
      <AnalysisStatusInfoModal
        open={modalStatus !== null}
        status={modalStatus}
        handleClose={() => setModalStatus(null)}
      />
    </>
  );
};

export default AnalysisTable;
