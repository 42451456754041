interface IProps {
  width: number;
  height: number;
  size: number;
}

const scaleDownSize = ({ width, height, size }: IProps) => {
  if (width === 0 || height === 0) {
    return { width: size, height: size, initialScale: 1 };
  }
  const ratio = height / width;

  if (ratio > 1) {
    return {
      width: size / ratio,
      height: size,
      initialScale: size / height,
    };
  }
  return {
    width: size,
    height: size * ratio,
    initialScale: size / width,
  };
};

export default scaleDownSize;
