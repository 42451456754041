import { LoadingButton } from '@mui/lab';
import { Box, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import GenericModal from '../../ui/GenericModal';
import useSendResetPasswordEmail from './graphql/useSendResetPasswordEmail';
import Colors from '../../infrastructure/constants/Colors';

interface IProps {
  handleClose: () => void;
}

const ForgotPasswordModal: React.FC<IProps> = ({ handleClose }) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [send, { loading, error }] = useSendResetPasswordEmail({
    onCompleted: () => {
      setCompleted(true);
    },
    onError: () => {},
  });

  const onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setSubmitted(true);
    if (!email) {
      return false;
    }
    send({ variables: { input: { email } } });
  };

  return (
    <GenericModal open onClose={handleClose}>
      <Box sx={{ textAlign: 'center' }}>
        <Typography id="modal-modal-title" variant="h5" component="h2">
          {t(completed ? 'auth.resetPasswordSentTitle' : 'auth.forgotPasswordTitle')}
        </Typography>
        <Typography
          id="modal-modal-description"
          sx={{ mt: 1, color: Colors.textSecondary, maxWidth: 350, mx: 'auto' }}
          variant="body2"
        >
          {t(completed ? 'auth.resetPasswordSentDescription' : 'auth.forgotPasswordDescription')}
        </Typography>
        {!completed && (
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: '14px' }} autoComplete="on">
            <TextField
              margin="normal"
              fullWidth
              id="email"
              type="email"
              label={t('auth.emailLabel')}
              name="email"
              autoComplete="email"
              inputMode="email"
              size="small"
              placeholder={t('auth.emailPlaceholder')}
              onChange={onEmailChange}
              value={email}
              error={!!error || (submitted && !email)}
            />
            {submitted && !email && (
              <Typography variant="body1" color="error.main" mt={1}>
                {t('auth.emptyEmail')}
              </Typography>
            )}
            {error && (
              <Typography variant="body1" color="error.main" mt={1}>
                {t('auth.invalidEmail')}
              </Typography>
            )}
            <LoadingButton
              loading={loading}
              type="submit"
              variant="contained"
              sx={{ mt: '22px' }}
            >
              {t('auth.sendResetLinkButtonLabel')}
            </LoadingButton>
          </Box>
        )}
      </Box>
    </GenericModal>
  );
};

export default ForgotPasswordModal;
