import { Box, Button, Checkbox, Divider, Typography } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useStore from '../../infrastructure/store/useStore';
import IFolderStructure from '../analysis/types/IFolderStructure';
import FileTypeEnum from '../upload/types/FileTypeEnum';
import IProjectFolder from '../upload/types/IProjectFolder';
import ProjectFilesTree from '../upload/ProjectFilesTree';
import { useUploadContext } from '../upload/context/UploadContext';
import IFileRoleEnum from '../../infrastructure/types/IFileRoleEnum';

interface IProps {
  folderStructure?: IFolderStructure[];
}

const FolderSelector: React.FC<IProps> = ({ folderStructure }) => {
  const { t } = useTranslation();
  const { setSelectedFileIds, selectedFilesIds } = useUploadContext();
  const projectFiles = useStore((state) => state.projectFiles);

  const discard = useCallback(() => {
    setSelectedFileIds([]);
  }, [setSelectedFileIds]);

  const selectAll = useCallback(() => {
    if (!folderStructure) return;
    const folderIds = folderStructure.map((folder) => folder.id);
    setSelectedFileIds(folderIds);
  }, []);

  const mapFolders = useCallback((el: IFolderStructure) => {
    const folder: IProjectFolder = {
      id: el.id,
      name: el.name,
      fileCount: el.fileCount,
      subFolders: el.subFolders?.map(mapFolders) || [],
      type: FileTypeEnum.FOLDER,
    };
    return folder;
  }, []);

  const items = useMemo(() => {
    return folderStructure?.map(mapFolders) || [];
  }, [folderStructure]);

  const checked = useMemo(() => {
    const childrenIds = projectFiles.root?.filter((el) => el.name !== '').map((el) => el.id) || [];
    return childrenIds.length > 0 && childrenIds.every((id) => selectedFilesIds.includes(id));
  }, [projectFiles, selectedFilesIds]);

  const handleOnCheck = useCallback(() => {
    const childrenIds = projectFiles.root?.map((el) => el.id) || [];
    if (childrenIds.length === 0) return;
    if (!checked) {
      setSelectedFileIds(childrenIds);
      return;
    }
    setSelectedFileIds([]);
  }, [checked, projectFiles, setSelectedFileIds]);

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Toolbar>
        <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h5">{t('reports.selectObjectsToAnalyse')}</Typography>
          <Box>
            <Button onClick={selectAll}>{t('reports.selectAllObjects')}</Button>
            <Button onClick={discard}>{t('button.discard')}</Button>
          </Box>
        </Box>
      </Toolbar>
      <Divider />
      <Box p={3} sx={{ flexGrow: 1, overflowY: 'auto' }}>
        <Box py={1} pl={4} pr={2} display="flex" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <Typography variant="body1">{t('analysis.folders')}</Typography>
          </Box>
          <Checkbox checked={checked} onChange={handleOnCheck} />
        </Box>
        <Divider />
        <ProjectFilesTree
          path=""
          folders={items}
          parentFolderIds={['root']}
          roleType={IFileRoleEnum.ANALYSIS_RESULT}
        />
      </Box>
    </Box>
  );
};

export default FolderSelector;
