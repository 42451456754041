import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import IDepartment from '../types/IDepartment';

interface IGetDepartments {
  departments: IDepartment[];
}

const GET_DEPARTMENTS = gql`
  query departments {
    departments {
      id
      name
    }
  }
`;

export default (options?: QueryHookOptions) => useQuery<IGetDepartments>(GET_DEPARTMENTS, options);
