import { Box, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useStore from '../../infrastructure/store/useStore';
import Image from '../../ui/Image';
import SkeletonsList from '../../ui/SkeletonsList';
import IFile from './types/IFile';

interface Props {
  filesLoading: boolean;
  files: IFile[];
  onSliceSelect: (file: IFile) => ((image: HTMLImageElement) => void) | undefined;
}

const SliceSelector: React.FC<Props> = ({ filesLoading, files, onSliceSelect }) => {
  const { t } = useTranslation();

  const imageRef = React.useRef<HTMLImageElement>(null);

  const [selectedImage, selectedVIPImage, selectedSliceIndex] = useStore((state) => [
    state.selectedImage,
    state.selectedVIPImage,
    state.selectedSliceIndex,
  ]);

  useEffect(() => {
    if (selectedSliceIndex !== null && !filesLoading) {
      const file = files[selectedSliceIndex];
      const image = document.getElementById(file.id)?.firstChild as HTMLImageElement;
      if (!image) return;

      image.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [selectedVIPImage, filesLoading]);

  return (
    <Box width={150} display="flex" flexDirection="column" sx={{ minWidth: 150 }}>
      <Typography variant="body1" py={1.5} px={1.5} fontWeight={500}>
        {t('analysis.slices')}
      </Typography>
      <Box ref={imageRef} sx={{ overflowY: 'auto', overflowX: 'hidden', mb: 5 }}>
        {filesLoading && <SkeletonsList count={10} width={150} height={180} />}
        {!filesLoading &&
          files.map((file, i) => (
            <Image
              key={file.id}
              id={file.id}
              src={file.thumbnails.s150x150}
              name={file.name}
              width={150}
              onImageClick={onSliceSelect(file)}
              showName
              fullWidth
              selected={file.id === selectedImage?.id}
              fallbackSrc={file?.signedUrl}
              shouldSelectOnLoad={selectedSliceIndex === i}
            />
          ))}
      </Box>
    </Box>
  );
};

export default SliceSelector;
