import { gql, MutationHookOptions, useMutation } from '@apollo/client';

const RESET_PASSWORD = gql`
  mutation resetPassword($input: ResetPasswordInput!) {
    resetPassword(input: $input)
  }
`;

export default (options: MutationHookOptions) => {
  return useMutation<boolean>(RESET_PASSWORD, options);
};
