import { NavigateBefore, NavigateNext } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button } from '@mui/material';
import { grey } from '@mui/material/colors';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useStore from '../../../infrastructure/store/useStore';
import AnalysisInterfaceEnum from '../../../infrastructure/types/AnalysisInterfaceEnum';
import useMaskSaveDiscard from '../hooks/useMaskSaveDiscard';

interface Props {
  interfaceModel: AnalysisInterfaceEnum;
  hasChanges: boolean;
  onNext: () => void;
  onPrev: () => void;
  disabledNext: boolean;
  disabledPrev: boolean;
}

const ActionsContainer: React.FC<Props> = ({
  interfaceModel,
  hasChanges,
  onNext,
  onPrev,
  disabledNext,
  disabledPrev,
}) => {
  const { t } = useTranslation();

  const [selectedSliceIndex] = useStore((state) => [state.selectedSliceIndex]);

  const { onSave, onDiscard, saveLoading } = useMaskSaveDiscard();

  return (
    <Box bgcolor={grey[200]} display="flex" flexDirection="column" alignItems="center" pb={1}>
      {interfaceModel === AnalysisInterfaceEnum.MODEL_4 && (
        <Box display="flex" gap={1} mb={1}>
          <Button size="small" disabled={disabledPrev} onClick={onPrev}>
            <NavigateBefore />
            <Box fontSize="14px">{t('button.previous')}</Box>
          </Button>
          <Button
            size="small"
            disabled={selectedSliceIndex === null || disabledNext}
            onClick={onNext}
          >
            <Box fontSize="14px">{t('button.next')}</Box>
            <NavigateNext />
          </Button>
        </Box>
      )}
      <Box display="flex" gap={1}>
        <LoadingButton
          disabled={!hasChanges || saveLoading}
          onClick={onDiscard}
          variant="contained"
          color="secondary"
          sx={{ minWidth: 102 }}
        >
          {t('button.discard')}
        </LoadingButton>
        <LoadingButton
          disabled={!hasChanges || saveLoading}
          loading={saveLoading}
          onClick={onSave}
          variant="contained"
          sx={{ minWidth: 102 }}
        >
          {t('button.save')}
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default ActionsContainer;
