import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import IStudy from '../types/IStudy';

interface IGetStudies {
  studies: IStudy[];
  studiesCount: number;
}

export const GET_STUDIES = gql`
  query studies($filters: FilterInput) {
    studies(filters: $filters) {
      id
      name
      status
      creationDate
      lastModificationDate
      dueDate
      department
      studyDirectorId
      studyDirectorFullName
      analysesCount
      cnvModelId
      cnvModelPathology
    }
    studiesCount(filters: $filters)
  }
`;

export default (options?: QueryHookOptions) => useQuery<IGetStudies>(GET_STUDIES, options);
