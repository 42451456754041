import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import NewUser from '../../domain/users/NewUser';
import UsersTable from '../../domain/users/UsersTable';
import MainContainer from '../../ui/MainContainer';
import TableWrapper from '../../ui/TableWrapper';
import Header from '../layout/Header/Header';

const UsersPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Header />
      <MainContainer>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="h4">{t('users.users')}</Typography>
              <NewUser />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <TableWrapper>
              <UsersTable />
            </TableWrapper>
          </Grid>
        </Grid>
      </MainContainer>
    </>
  );
};

export default UsersPage;
