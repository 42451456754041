import {
  ApolloClient,
  from,
  fromPromise,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  split,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { ErrorResponse, onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLError } from 'graphql';
import { createClient } from 'graphql-ws';
import LocalStorageEnum from '../constants/LocalStorageEnum';
import ISessionToken from '../types/ISessionToken';
import REFRESH_SESSION from './mutations/refreshSessionMutation';

// eslint-disable-next-line import/no-mutable-exports
let apolloClient: ApolloClient<NormalizedCacheObject>;

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_API_URL,
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: process.env.REACT_APP_SUBSCRIPTION_URL || '',
    connectionParams: async () => {
      const token = localStorage.getItem(LocalStorageEnum.JWT_TOKEN_KEY);
      return {
        Authorization: token ? `Bearer ${token}` : '',
      };
    },
    shouldRetry: () => true,
    retryWait: async () => {
      await new Promise((resolve) => {
        setTimeout(resolve, 7500);
      });
    },
  }),
);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  httpLink,
);

interface IRefreshSession {
  refreshSession: ISessionToken;
}

const refreshSession = async () => {
  return apolloClient
    .mutate<IRefreshSession>({
      mutation: REFRESH_SESSION,
      variables: {
        refreshSession: { refreshToken: localStorage.getItem(LocalStorageEnum.REFRESH_TOKEN_KEY) },
      },
    })
    .then(({ data }) => {
      if (!data) return null;
      const { accessToken, refreshToken } = data.refreshSession;
      localStorage.setItem(LocalStorageEnum.JWT_TOKEN_KEY, accessToken);
      localStorage.setItem(LocalStorageEnum.REFRESH_TOKEN_KEY, refreshToken);
      return accessToken;
    })
    .catch(() => {
      localStorage.removeItem(LocalStorageEnum.JWT_TOKEN_KEY);
      localStorage.removeItem(LocalStorageEnum.REFRESH_TOKEN_KEY);
      return null;
    });
};

interface IGraphQLError extends GraphQLError {
  subCode?: number;
}

interface IErrorResponse extends ErrorResponse {
  graphQLErrors?: ReadonlyArray<IGraphQLError>;
}

const errorLink = onError(({ graphQLErrors, forward, operation }: IErrorResponse) => {
  if (graphQLErrors && graphQLErrors[0].subCode === 1007) {
    return fromPromise(refreshSession()).flatMap(() => {
      return forward(operation);
    });
  }
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(LocalStorageEnum.JWT_TOKEN_KEY);

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

apolloClient = new ApolloClient({
  link: from([errorLink, authLink, splitLink]),
  cache: new InMemoryCache({
    typePolicies: {
      CnvModel: {
        keyFields: false,
      },
    },
  }),
});

export default apolloClient;
