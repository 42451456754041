import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import ISessionToken from '../../../infrastructure/types/ISessionToken';

interface ILoginUser {
  login: ISessionToken;
}

const LOGIN = gql`
  mutation Login($input: LoginInput!) {
    login(input: $input) {
      accessToken
      refreshToken
    }
  }
`;

export default (options: MutationHookOptions) => useMutation<ILoginUser>(LOGIN, options);
