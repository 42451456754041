import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import NewStudy from '../../domain/studies/NewStudy';
import StudiesTable from '../../domain/studies/StudiesTable';
import MainContainer from '../../ui/MainContainer';
import TableWrapper from '../../ui/TableWrapper';
import Header from '../layout/Header/Header';

const StudiesPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Header />
      <MainContainer>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="h4">{t('studies.studies')}</Typography>
              <NewStudy />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <TableWrapper>
              <StudiesTable />
            </TableWrapper>
          </Grid>
        </Grid>
      </MainContainer>
    </>
  );
};

export default StudiesPage;
