import { RefObject, useCallback, useState } from 'react';
import useStore from '../../../infrastructure/store/useStore';
import API from '../../../infrastructure/utils/API';
import convertToBlackAndWhiteBlob from '../helpers/convertToBlackAndWhiteBlob';
import EditorColors from '../ImageEditor/constants/EditorColors';
import ImageEditorConfig from '../ImageEditor/constants/ImageEditor.config';
import useMaskChanges from './useMaskChanges';
import useVIPScansSaver from './useVIPScansSaver';

const useMaskSaveDiscard = (callback?: () => void) => {
  const [saveLoading, setSaveLoading] = useState(false);
  const { saveVIPScans } = useVIPScansSaver();

  const [masks, selectedVIPImage, selectedImage, maskRefs, topViewRef, clearErasedCoordinates] =
    useStore((state) => [
      state.masks,
      state.selectedVIPImage,
      state.selectedImage,
      state.maskRefs,
      state.topViewRef,
      state.clearErasedCoordinates,
    ]);

  const { setLastChangeAsInitialImageData, resetToInitialChanges } = useMaskChanges();

  const saveMask = useCallback(
    (id: string, canvasRef: RefObject<HTMLCanvasElement>, color: EditorColors) => {
      return new Promise((resolve) => {
        const canvas = canvasRef.current;
        if (!canvas) return;
        const blob = convertToBlackAndWhiteBlob(canvas, color);
        const formData = new FormData();
        formData.append('mask', blob);
        API.put(`masks/${id}/updateMask`, formData).then(resolve);
      });
    },
    [],
  );

  const onDiscard = useCallback(async () => {
    resetToInitialChanges();
    clearErasedCoordinates();
    callback?.();
  }, [resetToInitialChanges]);

  const onSave = useCallback(async () => {
    setSaveLoading(true);
    const promises = masks.map((mask, maskIndex) => {
      if (selectedVIPImage && selectedVIPImage.mask && selectedImage?.isVIPImage) {
        return saveMask(mask.id, maskRefs[maskIndex], EditorColors.topViewDataColor);
      }

      const hiddenMaskCanvasRef = maskRefs[maskIndex].current;
      const hiddenMaskCanvasCtx = hiddenMaskCanvasRef?.getContext('2d');
      if (!mask.visible && hiddenMaskCanvasRef && hiddenMaskCanvasCtx) {
        hiddenMaskCanvasCtx.clearRect(
          0,
          0,
          hiddenMaskCanvasRef.height || 0,
          hiddenMaskCanvasRef.width || 0,
        );
      }

      return saveMask(mask.id, maskRefs[maskIndex], ImageEditorConfig.COLORS_ORDER[maskIndex]);
    });
    if (selectedVIPImage && selectedVIPImage.mask && !selectedImage?.isVIPImage) {
      promises.push(saveMask(selectedVIPImage.mask.id, topViewRef, EditorColors.topViewDataColor));
    }
    const vipScanPromises = await saveVIPScans();
    await Promise.all([...promises, ...vipScanPromises]);
    clearErasedCoordinates();
    setLastChangeAsInitialImageData();
    setSaveLoading(false);
    callback?.();
  }, [saveVIPScans, masks, selectedVIPImage, selectedImage, topViewRef, saveMask]);

  return {
    saveLoading,
    onSave,
    onDiscard,
  };
};

export default useMaskSaveDiscard;
