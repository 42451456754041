import { gql, useMutation } from '@apollo/client';

interface IDeleteReportInput {
  id: string;
}

interface IDeleteReportResponse {
  deleteReport: {
    id: string;
  };
}

const DELETE_REPORT = gql`
  mutation deleteReport($id: String!) {
    deleteReport(id: $id) {
      id
    }
  }
`;

export default (options: { onError: (apolloError) => void; onCompleted: (response) => void }) => {
  return useMutation<IDeleteReportResponse, IDeleteReportInput>(DELETE_REPORT, options);
};
