import { Box, Divider, Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Colors from '../../infrastructure/constants/Colors';
import useStore from '../../infrastructure/store/useStore';
import FullScreenLoader from '../../ui/FullScreenLoader';
import { getBorder } from '../../utils/Common';
import FolderTree from './FolderTree';
import useRearrangeImagesSaveDiscard from './hooks/useRearrangeImagesSaveDiscard';
import IFolderStructure from './types/IFolderStructure';
import UnsavedChangesModal from './UnsavedChangesModal';

interface IProps {
  folderStructure?: IFolderStructure[];
  loading?: boolean;
}

const FolderSelector: React.FC<IProps> = ({ folderStructure, loading }) => {
  const { t } = useTranslation();

  const [folderPath, setFolderPath, hasChanges] = useStore((state) => [
    state.folderPath,
    state.setFolderPath,
    state.hasChanges,
  ]);

  const [open, setOpen] = useState(false);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const { onSave, onDiscard } = useRearrangeImagesSaveDiscard(handleClose);

  const onSelection = useCallback(
    (params: { path?: string; pathPrev?: string; pathNext?: string }) => {
      if (hasChanges && params.path !== folderPath) {
        setOpen(true);
        return;
      }
      setFolderPath(params);
    },
    [setFolderPath, hasChanges, folderPath],
  );

  if (loading) return <FullScreenLoader />;

  return (
    <Box
      height="100%"
      borderRight={getBorder(Colors.divider)}
      pb={2}
      sx={{ flexGrow: 1, overflowY: 'auto' }}
    >
      <Typography px={2} pb="16px" pt="18px">
        {t('analysis.folders')}
      </Typography>
      <Divider />
      <FolderTree items={folderStructure} onSelection={onSelection} />
      <UnsavedChangesModal
        open={open}
        onSave={onSave}
        onDiscard={onDiscard}
        handleClose={handleClose}
      />
    </Box>
  );
};

export default FolderSelector;
