import { gql, MutationHookOptions, useMutation } from '@apollo/client';

const EDIT_IMAGE_LABELS = gql`
  mutation editLabelSelection($input: [EditImageLabelSelectionInput!]!, $fileId: String!) {
    editLabelSelection(input: $input, fileId: $fileId) {
      id
      name
      selected
      fileId
    }
  }
`;

export default (options: MutationHookOptions) => useMutation(EDIT_IMAGE_LABELS, options);
