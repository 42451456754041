import { Box, Button, List, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Colors from '../../infrastructure/constants/Colors';
import { useNotifications } from '../../infrastructure/context/NotificationsContext';
import NotificationsItem from './NotificationItem';

const NotificationsList: React.FC = () => {
  const { notifications, notificationsCount, seeMore } = useNotifications();
  const { t } = useTranslation();

  return (
    <Box ml={3} mt={4}>
      <List>
        {notifications.map((notification, key) => (
          <NotificationsItem
            key={notification.id}
            notification={notification}
            divide={key < notificationsCount - 1}
          />
        ))}
      </List>

      {notifications.length < notificationsCount && (
        <Box display="flex" justifyContent="center">
          <Button onClick={seeMore}>{t('button.seeMore')}</Button>
        </Box>
      )}

      {notifications.length < 1 && (
        <Box py={6}>
          <Typography color={Colors.gray2} textAlign="center">
            {t('notifications.noNotificationsYet')}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default NotificationsList;
