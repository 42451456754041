import { TabList, TabPanel } from '@mui/lab';
import TabContext from '@mui/lab/TabContext';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import React from 'react';
import { useTranslation } from 'react-i18next';
import AnalysisTable from './AnalysisTable';
import FilesList from './FilesList';
import ReportsTable from './ReportsTable';
import StudySettings from './StudySettings';
import StudyTabEnum from './types/StudyTabEnum';
import { UploadProvider, UploadTypeEnum } from '../upload/context/UploadContext';

const StudyTabs: React.FC = () => {
  const [selectedTab, setSelectedTab] = React.useState<StudyTabEnum>(StudyTabEnum.ANALYSIS);

  const handleChange = (_: React.SyntheticEvent, newValue: StudyTabEnum) => {
    setSelectedTab(newValue);
  };

  const { t } = useTranslation();

  return (
    <Box sx={{ width: '100%', minHeight: 'calc(100vh - 400px)' }}>
      <TabContext value={selectedTab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="tabs study">
            <Tab label={t('study.analyses')} value={StudyTabEnum.ANALYSIS} />
            <Tab label={t('study.files')} value={StudyTabEnum.FILES} />
            <Tab label={t('study.reports')} value={StudyTabEnum.REPORTS} />
            <Tab label={t('study.settings')} value={StudyTabEnum.SETTINGS} />
          </TabList>
        </Box>
        <TabPanel value={StudyTabEnum.ANALYSIS} sx={{ p: 0 }}>
          <AnalysisTable />
        </TabPanel>
        <TabPanel value={StudyTabEnum.FILES} sx={{ p: 0 }}>
          <UploadProvider type={UploadTypeEnum.STUDY}>
            <FilesList />
          </UploadProvider>
        </TabPanel>
        <TabPanel value={StudyTabEnum.REPORTS} sx={{ p: 0 }}>
          <ReportsTable />
        </TabPanel>
        <TabPanel value={StudyTabEnum.SETTINGS} sx={{ p: 0 }}>
          <StudySettings />
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default StudyTabs;
