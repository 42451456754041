import { gql, MutationHookOptions, useMutation } from '@apollo/client';

const MARK_ALL_NOTIFICATIONS_AS_READ = gql`
  mutation markAllNotificationsAsRead {
    markAllNotificationsAsRead {
      id
      read
    }
  }
`;

// eslint-disable-next-line max-len
export default (options: MutationHookOptions) => useMutation(MARK_ALL_NOTIFICATIONS_AS_READ, options);
