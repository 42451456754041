import LinearProgress, {
  linearProgressClasses,
  LinearProgressProps,
} from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import * as React from 'react';
import Colors from '../../infrastructure/constants/Colors';

const BorderLinearProgress: React.FC<LinearProgressProps> = styled(LinearProgress)(() => ({
  height: 20,
  borderRadius: '300px',
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: Colors.disable,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 0,
    backgroundColor: Colors.green2,
  },
}));

export default BorderLinearProgress;
