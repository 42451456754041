import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import FolderIcon from '@mui/icons-material/Folder';
import { Box, Collapse, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import useStore from '../../../infrastructure/store/useStore';
import Image from '../../../ui/Image';
import IFile from '../types/IFile';
import IFolderStructure from '../types/IFolderStructure';
import UnsavedChangesModal from '../UnsavedChangesModal';
import useLabelsSaveDiscard from './hooks/useLabelsSaveDiscard';

interface IProps {
  path: string | null;
  folderStructure: IFolderStructure[];
  filesMap: { [key: string]: IFile[] };
}

const ImageFolderTree: React.FC<IProps> = ({ folderStructure, filesMap, path }) => {
  const [open, setOpen] = useState(false);

  const handleClose = React.useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const { hasChanges, onDiscard, onSave, saveLoading } = useLabelsSaveDiscard(handleClose);
  const [openFolders, setOpenFolders] = useStore((state) => [
    state.openFolders,
    state.setOpenFolders,
  ]);
  const [selectedImageFile, setSelectedImageFile] = useStore((state) => [
    state.selectedImageFile,
    state.setSelectedImageFile,
  ]);

  const currentFolderPath = (name: string) => {
    return path ? `${path}/${name}` : name;
  };

  const onImageClick = (file: IFile) => () => {
    if (hasChanges) {
      setOpen(true);
      return;
    }
    setSelectedImageFile(file);
  };

  const handleClick = (name: string) => {
    const folderPath = currentFolderPath(name);
    const isOpen = openFolders[folderPath] !== false;
    setOpenFolders({ ...openFolders, [folderPath]: !isOpen });
  };

  const files = (path && filesMap[path]) || [];

  return (
    <>
      <List disablePadding sx={{ width: '100%', position: 'relative' }}>
        {folderStructure.map((item) => {
          return (
            <React.Fragment key={item.id}>
              <ListItem disablePadding divider>
                <ListItemButton onClick={() => handleClick(item.name)}>
                  {openFolders[currentFolderPath(item.name)] !== false ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )}
                  <FolderIcon sx={{ mx: 1, width: 20 }} />
                  <ListItemText primary={item.name} />
                </ListItemButton>
              </ListItem>

              <Box pl={1}>
                <Collapse in={openFolders[currentFolderPath(item.name)] !== false} timeout="auto">
                  <ImageFolderTree
                    folderStructure={item.subFolders || []}
                    filesMap={filesMap}
                    path={currentFolderPath(item.name)}
                  />
                </Collapse>
              </Box>
            </React.Fragment>
          );
        })}
        <Box display="flex" flexWrap="wrap" overflow="auto" maxHeight="100%">
          {files.map((file) => {
            return (
              <Image
                key={file.id}
                id={file.id}
                name={file.name}
                onImageClick={onImageClick(file)}
                src={file.thumbnails.s150x150}
                width="100%"
                selected={selectedImageFile?.id === file.id}
                showName
                fallbackSrc={file?.signedUrl}
              />
            );
          })}
        </Box>
      </List>

      <UnsavedChangesModal
        handleClose={handleClose}
        open={open}
        onDiscard={onDiscard}
        onSave={onSave}
        saveLoading={saveLoading}
      />
    </>
  );
};

export default ImageFolderTree;
