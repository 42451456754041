import { getColorAtPixel } from 'q-floodfill';
import { RefObject, useCallback, useMemo } from 'react';
import useStore from '../../../infrastructure/store/useStore';
import addPaddingToBoundaries from '../ImageEditor/helpers/addBoundariesPadding';
import colorsMatch from '../ImageEditor/helpers/colorsMatch';
import getSliceIndexByYCoordinate from '../ImageEditor/helpers/getSliceIndexByYCoordinate';
import IBounding from '../ImageEditor/types/IBounding';
import IRowCoordsSet from '../ImageEditor/types/IRowCoordsSet';
import IMaskImageData from '../types/IMaskImageData';

const useMaskChanges = () => {
  const [
    masks,
    maskRefs,
    topViewRef,
    maskImageDataStack,
    initialImageData,
    selectedVIPImage,
    clearMaskImageData,
    setInitialImageData,
    pushMaskImageData,
    popMaskImageData,
    setLastChangeAsInitialImageData,
  ] = useStore((state) => [
    state.masks,
    state.maskRefs,
    state.topViewRef,
    state.maskImageDataStack,
    state.initialImageData,
    state.selectedVIPImage,
    state.clearMaskImageData,
    state.setInitialImageData,
    state.pushMaskImageData,
    state.popMaskImageData,
    state.setLastChangeAsInitialImageData,
  ]);

  const getImageData = useCallback((canvasRef: RefObject<HTMLCanvasElement>) => {
    const canvas = canvasRef?.current;
    const ctx = canvas?.getContext('2d');
    if (!ctx || !canvas) return;
    const imgData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    return imgData;
  }, []);

  const pushChanges = useCallback(() => {
    const maskImageData: IMaskImageData = {};
    maskRefs.forEach((canvasRef, index) => {
      const imgData = getImageData(canvasRef);
      if (imgData) {
        const { id } = masks[index];
        maskImageData[id] = imgData;
      }
    });
    if (selectedVIPImage && selectedVIPImage.mask) {
      // this is to separate top view VIP mask from VIP mask which is being edited
      const id = `topView-${selectedVIPImage.mask.id}`;
      const imgData = getImageData(topViewRef);
      if (imgData) {
        maskImageData[id] = imgData;
      }
    }
    pushMaskImageData(maskImageData);
  }, [pushMaskImageData, selectedVIPImage]);

  const getRefById = useCallback(
    (id: string) => {
      const vipMaskId = `topView-${selectedVIPImage?.mask?.id}`;
      if (vipMaskId === id) {
        return topViewRef;
      }
      const index = masks.findIndex((mask) => mask.id === id);

      return maskRefs[index];
    },
    [maskRefs, masks, selectedVIPImage, topViewRef],
  );

  const undoChanges = useCallback(() => {
    if (maskImageDataStack.length === 0) return;
    const lastChange =
      maskImageDataStack.length === 1
        ? initialImageData
        : maskImageDataStack[maskImageDataStack.length - 2];
    popMaskImageData();
    Object.keys(lastChange).forEach((id) => {
      const canvas = getRefById(id)?.current;
      const ctx = canvas?.getContext('2d');
      if (!canvas || !ctx) return;
      ctx.putImageData(lastChange[id], 0, 0);
    });
  }, [popMaskImageData, maskImageDataStack, initialImageData]);

  const resetToInitialChanges = useCallback(() => {
    const lastChange = initialImageData;
    clearMaskImageData();
    Object.keys(lastChange).forEach((id) => {
      const canvas = getRefById(id)?.current;
      const ctx = canvas?.getContext('2d');
      if (!canvas || !ctx) return;
      ctx.putImageData(lastChange[id], 0, 0);
    });
  }, [getRefById, clearMaskImageData, maskImageDataStack, initialImageData]);

  const getImageDataById = useCallback(
    (id: string) => {
      const canvas = getRefById(id)?.current;
      const ctx = canvas?.getContext('2d');
      if (!ctx || !canvas) return;
      const imgData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      return imgData;
    },
    [getRefById],
  );

  const checkIfWasErased = useCallback(
    (id: string, bounding: IBounding, padding: number) => {
      const currentImgData = getImageDataById(id);
      const lastChange =
        maskImageDataStack.length === 0
          ? initialImageData
          : maskImageDataStack[maskImageDataStack.length - 1];
      const initialImgData = lastChange[id];
      if (!currentImgData || !initialImgData) return false;
      const { left, top, bottom, right } = addPaddingToBoundaries(
        bounding,
        padding,
        currentImgData.width,
        currentImgData.height,
      );
      let foundErased = false;
      for (let i = left; i <= right; i += 1) {
        for (let j = top; j <= bottom; j += 1) {
          const currentPixelColor = getColorAtPixel(currentImgData, i, j);
          const initialPixelColor = getColorAtPixel(initialImgData, i, j);
          if (!colorsMatch(currentPixelColor, initialPixelColor)) {
            foundErased = true;
            break;
          }
        }
      }
      return foundErased;
    },
    [maskImageDataStack, getImageDataById, initialImageData],
  );

  const getErasedPixelsForRows = useCallback(
    (id: string, bounding: IBounding, padding: number) => {
      const currentImgData = getImageDataById(id);
      const lastChange =
        maskImageDataStack.length === 0
          ? initialImageData
          : maskImageDataStack[maskImageDataStack.length - 1];
      const initialImgData = lastChange[id];

      if (!currentImgData || !initialImgData) return { foundErased: false, coords: {} };
      const { left, top, bottom, right } = addPaddingToBoundaries(
        bounding,
        padding,
        currentImgData.width,
        currentImgData.height,
      );
      const coords: IRowCoordsSet = {};
      for (let i = left; i <= right; i += 1) {
        for (let j = top; j <= bottom; j += 1) {
          const currentPixelColor = getColorAtPixel(currentImgData, i, j);
          const initialPixelColor = getColorAtPixel(initialImgData, i, j);
          if (!colorsMatch(currentPixelColor, initialPixelColor)) {
            const sliceIndex = getSliceIndexByYCoordinate({ x: i, y: j }, currentImgData.height);
            if (!coords[sliceIndex]) {
              coords[sliceIndex] = new Set<number>();
            }
            coords[sliceIndex].add(i);
          }
        }
      }
      const foundErased = Object.keys(coords).length > 0;
      return { foundErased, coords };
    },
    [maskImageDataStack, getImageDataById, initialImageData],
  );

  const hasChanges = useMemo(() => {
    return maskImageDataStack.length > 0;
  }, [maskImageDataStack]);

  return {
    hasChanges,
    resetToInitialChanges,
    checkIfWasErased,
    undoChanges,
    pushChanges,
    setInitialImageData,
    setLastChangeAsInitialImageData,
    getErasedPixelsForRows,
  };
};

export default useMaskChanges;
