import ImageEditorConfig from '../constants/ImageEditor.config';
import ICoordinates from '../types/ICoordinates';

const centerCoords = (coords: ICoordinates, height: number) => {
  const lineHeight = height / ImageEditorConfig.VIP_IMAGES_COUNT;
  const dy = coords.y % lineHeight;
  const startLineY = coords.y - (coords.y % lineHeight) + 1;
  const startLineX = coords.x - (coords.x % lineHeight);
  const bottomY = startLineY + lineHeight;
  const y = dy >= lineHeight / 2 ? bottomY : startLineY;
  return { x: startLineX, y };
};

export default centerCoords;
