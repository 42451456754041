import IAnalysis from './IAnalysis';

export enum StudyStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}
interface IStudyFile {
  id: string;
  name: string;
  path: string;
}
export interface IFolderStructure {
  id: string;
  name: string;
  objectId?: string;
  subFolders: IFolderStructure[];
  fileCount: number;
}
export default interface IStudy {
  id: string;
  name: string;
  status: StudyStatusEnum;
  creationDate: string;
  lastModificationDate: string;
  dueDate: string;
  department: string;
  cnvModelId: string;
  studyDirectorId: string;
  studyDirectorFullName?: string;
  analysesCount: number;
  analyses?: IAnalysis[];
  files?: Array<IStudyFile>;
  folderStructure?: IFolderStructure[];
  cnvModel?: {
    id: string;
    pathology: string;
    isCNV: boolean;
  };
}
