import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import React, { MouseEvent } from 'react';
import EditorColors from '../ImageEditor/constants/EditorColors';

interface IProps {
  children: string;
  color: EditorColors;
  visible: boolean;
  onToggle: (e: MouseEvent<HTMLButtonElement>) => void;
}

const MaskToggleButton: React.FC<IProps> = ({ children, color, visible, onToggle }) => {
  return (
    <>
      <Box
        sx={{
          width: 20,
          height: 20,
          borderRadius: 0.5,
          marginRight: 1.5,
          backgroundColor: color,
        }}
      />
      <Box textAlign="left" flex={1}>
        {children}
      </Box>
      <IconButton size="small" onClick={onToggle}>
        {visible ? <Visibility fontSize="small" /> : <VisibilityOff fontSize="small" />}
      </IconButton>
    </>
  );
};

export default MaskToggleButton;
