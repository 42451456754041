import { MpTablePaginatorProps } from '@mp-react/table';
import { Box, CircularProgress, Typography } from '@mui/material';
import React, { useMemo } from 'react';

const CustomPaginatorWithoutButtons: React.FC<MpTablePaginatorProps> = ({
  itemCount,
  loading,
  translations,
  selectedCount,
  showOnlyFilteredTotals,
  filteredCount,
}) => {
  const infoText = useMemo(() => {
    const totalCount = showOnlyFilteredTotals ? filteredCount : itemCount;
    return [
      totalCount != null ? `${totalCount} ${translations?.total ?? 'total'}` : null,
      selectedCount != null && selectedCount > 0
        ? `${selectedCount} ${translations?.selected ?? 'selected'}`
        : null,
    ]
      .filter((item) => item != null)
      .join(' • ');
  }, [showOnlyFilteredTotals, filteredCount, itemCount, selectedCount, translations]);

  return (
    <Box width="100%" display="flex" minHeight={48} alignItems="center">
      <Typography color="textSecondary" variant="body2">
        {infoText}
      </Typography>
      {loading && (
        <Box paddingX={1} display="flex" alignItems="center" justifyContent="center">
          <CircularProgress size="1.5em" data-qa="TableProgress" />
        </Box>
      )}
    </Box>
  );
};

export default CustomPaginatorWithoutButtons;
