import { Box, Container, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ResetPasswordForm from '../../domain/auth/ResetPasswordForm';
import { Logo } from '../../infrastructure/assets/images';

interface Props {
  isNewUser?: boolean;
}
const ResetPasswordPage: React.FC<Props> = ({ isNewUser }) => {
  const { t } = useTranslation();

  return (
    <Container
      maxWidth="xs"
      sx={{
        height: '100%',
        flexDirection: 'column',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box component="img" src={Logo} alt="Logo" display="block" mx="auto" width={200} />
      <Box
        display="flex"
        mt={2}
        maxWidth="sm"
        width={520}
        flexDirection="column"
        alignItems="center"
        py={8}
        px={10}
        borderRadius={2}
        sx={{ backgroundColor: 'common.white' }}
      >
        {isNewUser ? (
          <Typography component="h1" variant="h5">
            {t('auth.createPasswordTitle')}
          </Typography>
        ) : (
          <Typography component="h1" variant="h5">
            {t('auth.resetPasswordTitle')}
          </Typography>
        )}
        <ResetPasswordForm isNewUser={isNewUser} />
      </Box>
    </Container>
  );
};

export default ResetPasswordPage;
