import { MpColumn, MpFilterType } from '@mp-react/table';
import { useTranslation } from 'react-i18next';
import { CustomRendererType } from '../customRenderers/CustomRenderers';

const useDropboxColumns = ({ loading }: { loading: boolean }): MpColumn[] => {
  const { t } = useTranslation();

  return [
    {
      label: t('upload.filesName'),
      key: 'nameAndPath',
      render: CustomRendererType.nameAndPath as any,
      filter: !loading
        ? {
            label: t('upload.filesName'),
            type: 'includeExclude' as any,
          }
        : undefined,
      sort: !loading,
    },
    {
      label: t('upload.type'),
      key: 'type',
      filter: !loading
        ? {
            type: MpFilterType.select,
            label: t('upload.type'),
          }
        : undefined,
      sort: !loading,
    },
    {
      label: t('upload.extension'),
      key: 'fileType',
      filter: !loading
        ? {
            type: MpFilterType.select,
            label: t('upload.extension'),
          }
        : undefined,
      sort: !loading,
    },
    {
      label: t('upload.size'),
      key: 'sizeNumber',
      render: CustomRendererType.prettyBytes as any,
      sort: !loading,
    },
  ];
};

export default useDropboxColumns;
