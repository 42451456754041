import { ApolloQueryResult } from '@apollo/client';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import FolderIcon from '@mui/icons-material/Folder';
import {
  Box,
  Checkbox,
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { FileIcon } from '../../infrastructure/assets/icons';
import DropboxLimit from './constants/DropboxLimit';
import FileTypeEnum from './types/FileTypeEnum';
import IDropboxFile from './types/IDropboxFile';

interface IProps {
  items: IDropboxFile[];
  onFileSelect?: (id: string | string[]) => void;
  onFileDeselect?: (id: string, root?: boolean) => void;
  selectedIds: string[];
  query: (variables?: {
    path: string;
  }) => Promise<ApolloQueryResult<{ dropboxFiles: IDropboxFile[] }>>;
  selected?: boolean;
  onMaxSize?: () => void;
}

const DropboxFilesTree: React.FC<IProps> = ({
  query,
  items,
  onFileSelect,
  selectedIds,
  selected,
  onFileDeselect,
  onMaxSize,
}) => {
  const [open, setOpen] = React.useState([...Array(items.length)].map(() => false));

  const [itemFiles, setItemFiles] = useState<Record<string, IDropboxFile[]>>({});

  useEffect(() => {
    if (selected) {
      onFileSelect?.(items.filter((el) => selectedIds.includes(el.id)).map((el) => el.id));
    }
  }, [selected]);

  const fetchFiles = useCallback(
    ({ id, select }: { id: string; select?: boolean }) => {
      query({ path: id }).then((r) => {
        setItemFiles((prevItemFiles) => ({ ...prevItemFiles, [id]: r.data?.dropboxFiles || [] }));
        if (select) {
          onFileSelect?.(r.data?.dropboxFiles.map((el) => el.id));
        }
      });
    },
    [query, onFileSelect],
  );

  const handleClick = useCallback(
    ({ key, id, select }: { key: number; id: string; select?: boolean }) => {
      const item = items?.[key];

      if (item?.type === FileTypeEnum.FOLDER) {
        if (itemFiles[id] === undefined) {
          fetchFiles({ id, select });
        }
      }

      setOpen((prevOpen) => {
        const copyPrev = [...prevOpen];
        copyPrev[key] = !copyPrev[key];
        return copyPrev;
      });
    },
    [items, fetchFiles],
  );

  const itemsMap = items.map((item: IDropboxFile, key) => {
    const isSelected = selectedIds.includes(item.id);

    return (
      <Fragment key={item.name || key}>
        <ListItem
          disablePadding
          divider
          secondaryAction={
            <Checkbox
              checked={selected || isSelected}
              onChange={() => {
                if (selected) {
                  onFileDeselect?.(item.id);
                } else {
                  if (item.size > DropboxLimit.dropboxFileMaxSize) {
                    onMaxSize?.();
                    return;
                  }
                  onFileSelect?.(item.id);
                }
              }}
            />
          }
        >
          <ListItemButton onClick={() => handleClick({ key, id: item.id })}>
            {item.type === FileTypeEnum.FOLDER && (open[key] ? <ExpandLess /> : <ExpandMore />)}
            {item.type === FileTypeEnum.FOLDER ? (
              <FolderIcon sx={{ mx: 1 }} />
            ) : (
              <FileIcon style={{ marginRight: 8, marginLeft: 4 }} />
            )}
            <ListItemText primary={item.name} />
          </ListItemButton>
        </ListItem>
        {itemFiles[item.id] && (
          <Box pl={4}>
            <Collapse in={open[key]} timeout={100}>
              <DropboxFilesTree
                query={query}
                items={itemFiles[item.id]}
                onFileSelect={onFileSelect}
                onFileDeselect={(id: string, root?: boolean) => {
                  if (selected) {
                    onFileDeselect?.(item.id, true);
                  }
                  if (root) {
                    if (isSelected) {
                      onFileSelect?.([
                        item.id,
                        ...itemFiles[item.id].map((el) => el.id).filter((_id) => _id !== id),
                      ]);
                    }
                  } else {
                    onFileSelect?.([
                      ...(isSelected ? [item.id] : []),
                      ...itemFiles[item.id].map((el) => el.id).filter((_id) => _id !== id),
                    ]);
                  }
                }}
                selectedIds={selectedIds}
                selected={selected || isSelected}
                onMaxSize={onMaxSize}
              />
            </Collapse>
          </Box>
        )}
      </Fragment>
    );
  });

  return <List>{itemsMap}</List>;
};

export default DropboxFilesTree;
