enum EditorColors {
  pink = '#FF00D4',
  yellow = '#FFD400',
  darkCyan = '#216939',
  orange = '#64C7F9',
  purple = '#D28A39',
  green = '#C2FF9B',
  blue = '#0029B7',
  red = '#F4511E',
  darkBlue = '#3F51B5',
  brown = '#C2713F',
  topViewDataColor = '#FE1717',
  minimapBorderColor = '#0077C8',
  white = '#FFFFFF',
  transparent = 'rgba(0,0,0,0)',
  black = '#000000',
}

export default EditorColors;
