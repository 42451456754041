import { Search } from '@mui/icons-material';
import { Fade, IconButton } from '@mui/material';
import React from 'react';

interface IProps {
  show: boolean;
  handleClick: () => void;
}

const SearchIcon: React.FC<IProps> = ({ show, handleClick }) => {
  return (
    <Fade
      in={show}
      appear={false}
      timeout={{ enter: 200, exit: 0 }}
      style={{ transitionDelay: show ? '.9s' : '0s' }}
    >
      <IconButton
        size="large"
        aria-label="search"
        aria-controls="search-input"
        aria-haspopup="true"
        onClick={handleClick}
        color="inherit"
        sx={{
          position: 'absolute',
          right: 0,
        }}
      >
        <Search />
      </IconButton>
    </Fade>
  );
};

export default SearchIcon;
