import { NavigateBefore, NavigateNext } from '@mui/icons-material';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import SwapHorizontalCircleIcon from '@mui/icons-material/SwapHorizontalCircle';
import { Box, Button, Divider, Tooltip, Typography } from '@mui/material';
import React, { useCallback, useEffect, useMemo } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Colors from '../../../infrastructure/constants/Colors';
import useStore from '../../../infrastructure/store/useStore';
import IFileRoleEnum from '../../../infrastructure/types/IFileRoleEnum';
import { FilterTypeEnum } from '../../../infrastructure/types/IFilterInput';
import FullScreenLoader from '../../../ui/FullScreenLoader';
import Image from '../../../ui/Image';
import useFiles from '../graphql/useFiles';
import useRearrangeImagesSaveDiscard from '../hooks/useRearrangeImagesSaveDiscard';
import IFolderStructure from '../types/IFolderStructure';
import CollageSeparatorLine from './CollageSeparatorLine';
import getColumnListStyle from './helpers/getColumnListStyle';
import getColumnStyle from './helpers/getColumnStyle';
import getItemListStyle from './helpers/getItemListStyle';
import getItemStyle from './helpers/getItemStyle';
import useRearrangeImages from './hooks/useRearrangeImages';

interface Props {
  folderStructure: IFolderStructure[];
}

const RearrangeImages: React.FC<Props> = ({ folderStructure }) => {
  const { analysisId } = useParams();

  const { t } = useTranslation();

  const { onSave, onDiscard, reorderAnalysisImagesLoading, reorderAnalysisImagesError } =
    useRearrangeImagesSaveDiscard();

  const [items, setItems, setParsedFiles, folderPath, hasChanges, setFolderPath] = useStore(
    (state) => [
      state.items,
      state.setItems,
      state.setParsedFiles,
      state.folderPath,
      state.hasChanges,
      state.setFolderPath,
    ],
  );

  const { data: filesData, loading: filesLoading } = useFiles({
    variables: {
      filters: {
        filters: [
          { field: 'path', type: FilterTypeEnum.IN, args: [folderPath] },
          { field: 'analysisId', type: FilterTypeEnum.IN, args: [analysisId] },
          { field: 'role', type: FilterTypeEnum.IN, args: [IFileRoleEnum.ANALYSIS_RESULT] },
        ],
      },
    },
    skip: !folderPath,
  });

  const files = useMemo(() => filesData?.files || [], [filesData]);

  const filesWithAlignments = useMemo(() => {
    return (
      files?.map((file) => {
        const parsedMetadata = JSON.parse(file?.metadata ?? '');
        const alignment = parsedMetadata?.allegement ?? parsedMetadata?.alignment ?? null;
        return {
          ...file,
          alignment,
        };
      }) ?? []
    );
  }, [files]);

  const sortedFiles = useMemo(() => {
    return filesWithAlignments.sort((a, b) => (a.index > b.index ? 1 : -1));
  }, [filesWithAlignments]);

  useEffect(() => {
    const parsedFiles =
      sortedFiles
        ?.map((file, index) => {
          if (index % 2 === 0) {
            const fileOne = file;
            const fileTwo = sortedFiles?.[index + 1];
            let topFile = fileOne;
            let bottomFile = fileTwo;
            if (fileOne.alignment === 'BOTTOM') {
              topFile = fileTwo;
              bottomFile = fileOne;
            }
            return [topFile, bottomFile];
          }
          return undefined;
        })
        ?.filter((file) => !!file) ?? [];
    setParsedFiles(parsedFiles);
    setItems(parsedFiles);
  }, [sortedFiles]);

  const {
    handleDragStart,
    handleDragUpdate,
    onDragEnd,
    handleChangeDropHover,
    dragId,
    dragOverId,
    dropId,
    dropOverId,
    dropHoverId,
  } = useRearrangeImages();

  const mapFoldersWithFiles = useCallback(
    (path: string | null, folders: IFolderStructure[]) => (folder: IFolderStructure) => {
      const nextPath = path ? `${path}/${folder.name}` : folder.name;

      if (folder.fileCount > 0) {
        folders.push({ ...folder, path: nextPath });
      }

      if (folder.subFolders) {
        folder.subFolders.forEach(mapFoldersWithFiles(nextPath, folders));
      }
    },
    [],
  );

  const foldersList = useMemo(() => {
    const folders: IFolderStructure[] = [];
    folderStructure.forEach(mapFoldersWithFiles(null, folders));
    return folders;
  }, [folderStructure]);

  const handleOnNext = useCallback(() => {
    if (!folderPath) return false;
    const folderIndex = foldersList.findIndex((folder) => folder.path === folderPath);
    if (folderIndex >= 0 && folderIndex < foldersList.length - 1) {
      setFolderPath({
        path: foldersList[folderIndex + 1].path,
      });
    }
  }, [setFolderPath, folderPath, foldersList]);

  const handleOnPrev = useCallback(() => {
    if (!folderPath) return false;
    const folderIndex = foldersList.findIndex((folder) => folder.path === folderPath);
    if (folderIndex > 0) {
      setFolderPath({
        path: foldersList[folderIndex - 1].path,
      });
    }
  }, [setFolderPath, folderPath, foldersList]);

  const canClickNext = useMemo(() => {
    if (!folderPath) return false;
    const folderIndex = foldersList.findIndex((folder) => folder.path === folderPath);
    if (folderIndex >= 0 && folderIndex < foldersList.length - 1) {
      return true;
    }
    return false;
  }, [folderPath, foldersList]);

  const canClickPrev = useMemo(() => {
    if (!folderPath) return false;
    const folderIndex = foldersList.findIndex((folder) => folder.path === folderPath);
    if (folderIndex > 0) {
      return true;
    }
    return false;
  }, [folderPath, foldersList]);

  return (
    <Box width="calc(100% - 280px)" display="flex" flexDirection="column">
      <Typography px={2} pb="16px" pt="18px">
        {t('analysis.rearrangeImagesToCorrectOrder')}
      </Typography>
      <Divider />
      {(!files || !files.length) && !filesLoading && (
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          alignItems="center"
          justifyContent="center"
          p={2}
        >
          <Typography variant="subtitle1" p={2}>
            {t('analysis.noImagesFound')}
          </Typography>
        </Box>
      )}
      {(filesLoading || reorderAnalysisImagesError || reorderAnalysisImagesLoading) && (
        <FullScreenLoader />
      )}
      {!(filesLoading || reorderAnalysisImagesError || reorderAnalysisImagesLoading) && (
        <DragDropContext
          onDragStart={handleDragStart}
          onDragEnd={onDragEnd}
          onDragUpdate={handleDragUpdate}
        >
          <Droppable direction="horizontal" droppableId="droppable" type="column">
            {(prov, snap) => (
              <Box
                ref={prov.innerRef}
                style={getColumnListStyle(snap.isDraggingOver)}
                position="relative"
              >
                {items.length > 7 && <CollageSeparatorLine />}
                {items.map &&
                  items.map((el, ind) => (
                    <Draggable
                      index={ind}
                      draggableId={`level0-${el?.[0].id}`}
                      key={`draggable-column-${el?.[0].id}`}
                    >
                      {(_prov, _snap) => (
                        <div onMouseEnter={() => handleChangeDropHover(`${ind}`)}>
                          <Box
                            ref={_prov.innerRef}
                            {..._prov.draggableProps}
                            style={getColumnStyle(_snap.isDragging, _prov.draggableProps.style)}
                          >
                            <Box display="flex" alignItems="center" flexDirection="column">
                              <Box
                                {..._prov.dragHandleProps}
                                width="100%"
                                display="flex"
                                alignitems="center"
                                justifyContent="center"
                                py="2px"
                              >
                                <SwapHorizIcon />
                              </Box>
                              <Droppable
                                isCombineEnabled
                                droppableId={el?.[0].id}
                                direction="vertical"
                                // type={`${ind}`}
                              >
                                {(provided, snapshot) => (
                                  <Box
                                    ref={provided.innerRef}
                                    style={getItemListStyle(snapshot.isDraggingOver)}
                                    {...provided.droppableProps}
                                  >
                                    {!!el?.map &&
                                      el?.map((item, index) => {
                                        if (!item) return null;
                                        return (
                                          <Draggable
                                            key={item.id}
                                            draggableId={item.id}
                                            index={index}
                                          >
                                            {(_provided, _snapshot) => (
                                              <Box
                                                display="flex"
                                                justifyContent="center"
                                                height={
                                                  dragId && dropOverId !== el?.[0].id ? 120 : 'auto'
                                                }
                                              >
                                                <Box
                                                  ref={_provided.innerRef}
                                                  {..._provided.draggableProps}
                                                  {..._provided.dragHandleProps}
                                                  style={getItemStyle(
                                                    _snapshot.isDragging,
                                                    _provided.draggableProps.style,
                                                    dropId !== undefined && dropId !== el?.[0].id,
                                                    dropHoverId !== `${ind}`,
                                                    dragId !== item.id && dropOverId !== el?.[0].id,
                                                  )}
                                                >
                                                  <Box
                                                    position="relative"
                                                    display="flex"
                                                    justifyContent="center"
                                                    borderRadius="4px"
                                                    boxShadow={
                                                      dragOverId === item.id &&
                                                      dropId !== dropOverId
                                                        ? Colors.draggableBoxShadow
                                                        : 'unset'
                                                    }
                                                  >
                                                    {dragOverId === item.id &&
                                                      dropId !== dropOverId && (
                                                        <Box
                                                          position="absolute"
                                                          top={5}
                                                          color={Colors.white}
                                                        >
                                                          <SwapHorizontalCircleIcon />
                                                        </Box>
                                                      )}
                                                    <Image
                                                      height={115}
                                                      width={115}
                                                      src={item.thumbnails?.s100x100}
                                                      fallbackSrc={item.signedUrl}
                                                      alt={item.name}
                                                      style={{
                                                        borderRadius: 4,
                                                      }}
                                                    />
                                                    <Box
                                                      position="absolute"
                                                      bottom={0}
                                                      width={115}
                                                      bgcolor="rgba(255,255,255,0.6)"
                                                      px={1}
                                                      py={0.25}
                                                      style={{
                                                        whiteSpace: 'nowrap',
                                                        textOverflow: 'ellipsis',
                                                        overflow: 'hidden',
                                                      }}
                                                    >
                                                      <Tooltip title={item.name}>
                                                        <Typography
                                                          variant="body2"
                                                          color="textPrimary"
                                                          style={{
                                                            whiteSpace: 'nowrap',
                                                            textOverflow: 'ellipsis',
                                                            overflow: 'hidden',
                                                          }}
                                                        >
                                                          {item.name}
                                                        </Typography>
                                                      </Tooltip>
                                                    </Box>
                                                  </Box>
                                                </Box>
                                                {_provided.placeholder}
                                              </Box>
                                            )}
                                          </Draggable>
                                        );
                                      })}
                                    {provided.placeholder}
                                  </Box>
                                )}
                              </Droppable>
                            </Box>
                          </Box>
                          {_prov.placeholder}
                        </div>
                      )}
                    </Draggable>
                  ))}
                {prov.placeholder}
              </Box>
            )}
          </Droppable>
        </DragDropContext>
      )}
      <Box display="flex" justifyContent="space-between" mb={5} mt="auto" px={3}>
        <Button disabled={hasChanges || !canClickPrev} onClick={handleOnPrev}>
          <NavigateBefore />
          {t('button.previous')}
        </Button>
        <Box display="flex" gap={2}>
          <Button disabled={!hasChanges} onClick={onDiscard} variant="contained" color="secondary">
            {t('button.discard')}
          </Button>
          <Button disabled={!hasChanges} onClick={onSave} variant="contained">
            {t('button.save')}
          </Button>
        </Box>
        <Button disabled={hasChanges || !canClickNext} onClick={handleOnNext}>
          {t('button.next')}
          <NavigateNext />
        </Button>
      </Box>
    </Box>
  );
};

export default RearrangeImages;
