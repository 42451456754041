import { Box, Skeleton, Tooltip, Typography } from '@mui/material';
import React, { createRef, useState } from 'react';
import Colors from '../infrastructure/constants/Colors';
import { BorderStyleEnum, getBorder } from '../utils/Common';

interface IProps
  extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  name?: string;
  showName?: boolean;
  fullWidth?: boolean;
  selected?: boolean;
  id?: string;
  width: number | string;
  fallbackSrc?: string;
  onImageClick?: (image: HTMLImageElement) => void;
  shouldSelectOnLoad?: boolean;
}

const Image: React.FC<IProps> = ({
  showName,
  name,
  onImageClick,
  selected,
  width,
  fullWidth,
  id,
  fallbackSrc,
  src,
  shouldSelectOnLoad,
  ...rest
}) => {
  const imgRef = createRef<HTMLImageElement>();
  const [loaded, setLoaded] = useState(false);
  const [source, setSource] = useState(src);

  const onLoadEnd = () => {
    setLoaded(true);
    if (shouldSelectOnLoad && imgRef.current && !selected) {
      onImageClick?.(imgRef.current);
    }
  };

  const onClick = () => {
    if (onImageClick && imgRef.current) {
      onImageClick(imgRef.current);
    }
  };

  const onError = () => {
    if (fallbackSrc) {
      setSource(fallbackSrc);
    }
  };

  if (showName) {
    return (
      <Box
        onClick={onClick}
        id={id}
        sx={{
          width: fullWidth ? '100%' : '50%',
          background: Colors.disable,
          cursor: 'pointer',
          position: 'relative',
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          border: getBorder(
            selected ? Colors.lightPrimary : Colors.disable,
            BorderStyleEnum.SOLID,
            2,
          ),
        }}
      >
        <img
          alt="lazy-loaded-alt"
          {...rest}
          width={width}
          loading="lazy"
          ref={imgRef}
          src={source}
          style={
            !loaded
              ? { width: 1, height: 1, display: 'flex', opacity: 0.1 }
              : { display: 'flex', paddingBottom: 1, maxWidth: '100%' }
          }
          onLoad={onLoadEnd}
          onError={onError}
        />
        {!loaded && (
          <Skeleton
            animation="wave"
            variant="rectangular"
            width={width}
            sx={{ paddingBottom: '100%' }}
          />
        )}
        {name && (
          <Tooltip title={name}>
            <Typography
              variant="body2"
              my={0.5}
              px={0.5}
              whiteSpace="nowrap"
              fontSize={12}
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {name}
            </Typography>
          </Tooltip>
        )}
      </Box>
    );
  }
  return (
    <Box display="flex" onClick={onClick}>
      <img
        alt="lazy-loaded-alt"
        {...rest}
        width={width}
        loading="lazy"
        ref={imgRef}
        onLoad={onLoadEnd}
        onError={onError}
        src={source}
      />
    </Box>
  );
};

export default Image;
