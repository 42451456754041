import React from 'react';
import { useUploadContext } from './context/UploadContext';
import SelectFilesDrawer from './SelectFilesDrawer';
import IStepEnum from './types/IStepEnum';
import UploadFilesDrawer from './UploadFilesDrawer';

const UploadInner: React.FC = () => {
  const { step } = useUploadContext();
  if (step === IStepEnum.UPLOAD_FILES_PROGRESS) {
    return <UploadFilesDrawer />;
  }
  return <SelectFilesDrawer />;
};

export default React.memo(UploadInner);
