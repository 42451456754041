import { HelpRounded } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Alert, Box, Button, TextField, Typography } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TableLimit from '../../infrastructure/constants/TableLimit';
import { useUploadContext } from './context/UploadContext';
import useSearchDropboxFiles from './graphql/useSearchDropboxFiles';
import SearchDropboxFilesTable from './SearchDropboxFilesTable';
import SearchInstructionsModal from './SearchInstructionsModal';

const SearchDropboxFiles: React.FC = () => {
  const { t } = useTranslation();

  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const { setSelectedDropboxFileIds, setDropboxFilesSize } = useUploadContext();

  const [search, setSearch] = useState<string>();
  const [showInstructions, setShowInstructions] = useState(false); // Issue the query
  // const abortController = useRef(new AbortController());

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const [isLoading, setIsLoading] = useState(false);
  const cancelledRef = useRef(false);
  const cancelledFilesRef = useRef<any[]>([]);
  const loadingSearch = false;

  const [searchDropboxFiles, { data: searchData, fetchMore, error }] = useSearchDropboxFiles({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  const files = useMemo(() => {
    if (cancelledRef.current) {
      return cancelledFilesRef.current;
    }
    return (
      searchData?.searchDropboxFiles.items.map((file) => ({
        ...file,
        fileType: file.fileType ?? '', // fix local filtering (undefined error)
        sizeNumber: Number(file.size),
        nameAndPath: {
          name: file.name,
          path: file.path,
          toString: () => file.name,
        },
      })) || []
    );
  }, [searchData?.searchDropboxFiles]);

  const performFetchMore = (cursor: string) => {
    if (cancelledRef.current) return;
    fetchMore({
      variables: {
        input: {
          query: search,
          limit: TableLimit.dropboxFilesRequestLimit,
          cursor,
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (cancelledRef.current) return prev;
        if (!mounted.current) return prev;

        if (!fetchMoreResult) return prev;

        return {
          ...prev,
          searchDropboxFiles: {
            ...prev.searchDropboxFiles,
            items: [...prev.searchDropboxFiles.items, ...fetchMoreResult.searchDropboxFiles.items],
          },
        };
      },
    }).then((r) => {
      if (!mounted.current) {
        setIsLoading(false);
        return;
      }

      if (!r.data?.searchDropboxFiles.cursor) {
        setIsLoading(false);
        return;
      }
      if (r.data?.searchDropboxFiles.cursor) {
        performFetchMore(r?.data?.searchDropboxFiles.cursor);
        return;
      }

      setIsLoading(false);
    });
  };

  const performSearchFiles = () => {
    setIsLoading(true);
    cancelledRef.current = false;
    cancelledFilesRef.current = [];
    searchDropboxFiles({
      variables: {
        input: {
          query: search,
          limit: TableLimit.dropboxFilesRequestLimit,
        },
      },
    })
      .then((r) => {
        if (!mounted.current) {
          setIsLoading(false);
          return;
        }

        if (r.data?.searchDropboxFiles.cursor) {
          performFetchMore(r.data?.searchDropboxFiles.cursor);
          return;
        }

        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  };

  const cancelSearch = () => {
    setIsLoading(false);
    cancelledRef.current = true;
    cancelledFilesRef.current = files;
    // abortController.current.abort();
  };

  const handleSearchFiles = (e) => {
    if (isLoading) {
      return;
    }
    e.preventDefault();
    if (!search) {
      return;
    }
    performSearchFiles();
  };

  const onRowsSelected = useCallback(
    (selectedRows) => {
      const size = selectedRows.reduce((acc, row) => acc + row.values.sizeNumber, 0);
      setDropboxFilesSize(size);

      const selectedRowsIds = selectedRows.map((el) => el.id);
      setSelectedDropboxFileIds((prevState) => {
        if (prevState.length !== selectedRowsIds.length) {
          return selectedRowsIds;
        }
        return prevState;
      });
    },
    [setSelectedDropboxFileIds, setDropboxFilesSize],
  );

  const openInstructions = () => {
    setShowInstructions(true);
  };

  const closeInstructions = () => {
    setShowInstructions(false);
  };

  return (
    <Box>
      <SearchInstructionsModal open={showInstructions} handleClose={closeInstructions} />
      {!loadingSearch &&
        !isLoading &&
        files.length === TableLimit.dropboxFilesRequestLimit * TableLimit.dropboxPagesLimit && (
          <Alert severity="warning">{t('upload.exceededLimit')}</Alert>
        )}

      <Box display="flex" alignItems="center" component="form" onSubmit={handleSearchFiles}>
        <TextField
          margin="normal"
          fullWidth
          id="dropboxFileLink"
          name="dropboxFileLink"
          type="text"
          inputMode="text"
          size="small"
          value={search || ''}
          onChange={handleChangeSearch}
          sx={{
            '& fieldset': {
              borderStyle: 'dashed',
            },
          }}
          placeholder={t('upload.searchInSearchFile')}
        />
        <LoadingButton
          loading={loadingSearch || isLoading}
          sx={{ ml: 3, mt: 1 }}
          variant="contained"
          type="submit"
          disabled={!search?.length}
        >
          {t('search.search')}
        </LoadingButton>
        {isLoading && (
          <Button
            sx={{ mt: 1, ml: 1, height: 44 }}
            onClick={cancelSearch}
            variant="outlined"
            color="error"
          >
            {t('upload.cancel')}
          </Button>
        )}
      </Box>
      <Box display="flex" alignItems="center" mb={2}>
        <Button color="primary" size="small" variant="text" onClick={openInstructions}>
          <HelpRounded color="primary" fontSize="small" />
          <Typography fontSize="small" ml={0.5}>
            {t('upload.searchInSearchFileHelp')}
          </Typography>
        </Button>
      </Box>
      {error && (
        <Alert severity="error" sx={{ width: '100%' }}>
          {error?.message}
        </Alert>
      )}
      {!error && (
        <SearchDropboxFilesTable
          files={files}
          onRowsSelected={onRowsSelected}
          loading={loadingSearch || isLoading}
        />
      )}
    </Box>
  );
};

export default SearchDropboxFiles;
