import { createRef, RefObject } from 'react';
import ImageEditorConfig from '../ImageEditor/constants/ImageEditor.config';
import IRowCoordsSet from '../ImageEditor/types/IRowCoordsSet';
import IToolsEnum from '../ImageEditor/types/IToolsEnum';
import IFile from '../types/IFile';
import IImage, { IImageSize } from '../types/IImage';
import IMask from '../types/IMask';
import IMaskImageData from '../types/IMaskImageData';
import IVIPImage from '../types/IVIPImage';

interface IAnalysisSlice {
  masks: IMask[];
  maskRefs: RefObject<HTMLCanvasElement>[];
  topViewRef: RefObject<HTMLCanvasElement>;
  offScreenCanvasRef: RefObject<HTMLCanvasElement>;
  selectedMaskIndex: number;
  selectedTool: IToolsEnum;
  brushSize: number;
  selectedSliceIndex: number;
  selectedImageSize: IImageSize | null;
  selectedImage: IImage | null;
  selectedVIPImage: IVIPImage | null;
  initialImageData: IMaskImageData;
  maskImageDataStack: IMaskImageData[];
  shouldClearCoords: null | number;
  erasedCoordinates: { [y: number]: number[] };
  selectedImageFile: IFile | null;
  openFolders: { [key: string]: boolean };
  selectedLabels: string[];
  setSelectedLabels: (labels: string[]) => void;
  setSelectedImageFile: (file: IFile) => void;
  updateErasedCoordinates: (erasedCoordinates: IRowCoordsSet) => void;
  clearErasedCoordinates: () => void;
  setLastChangeAsInitialImageData: () => void;
  setInitialImageData: (id: string, imageData: ImageData) => void;
  pushMaskImageData: (maskImageData: IMaskImageData) => void;
  popMaskImageData: () => void;
  clearMaskImageData: () => void;
  setSelectedImage: (image: IImage, masks: IMask[], index?: number) => void;
  setSelectedVIPImage: (image: IVIPImage) => void;
  setBrushSize: (size: number) => void;
  setSelectedTool: (tool: IToolsEnum) => void;
  setSelectedSliceIndex: (index: number) => void;
  setSelectedImageSize: (size: IImageSize) => void;
  setSelectedMaskIndex: (index: number) => void;
  setVisibility: (maskIndex: number, visible: boolean) => void;
  resetAnalysisSlice: () => void;
  clearCoords: () => void;
  setOpenFolders: (openFolders: { [key: string]: boolean }) => void;
}

const initialState = {
  masks: [],
  maskRefs: [],
  topViewRef: createRef<HTMLCanvasElement>(),
  offScreenCanvasRef: createRef<HTMLCanvasElement>(),
  selectedMaskIndex: 0,
  selectedTool: IToolsEnum.TOOL_PENCIL,
  brushSize: ImageEditorConfig.DEFAULT_PENCIL_SIZE,
  selectedSliceIndex: 0,
  selectedVIPImage: null,
  selectedImageSize: null,
  selectedImage: null,
  initialImageData: {},
  maskImageDataStack: [],
  shouldClearCoords: null,
  erasedCoordinates: {},
  selectedImageFile: null,
  openFolders: {},
  selectedLabels: [],
};

const createAnalysisSlice = (set): IAnalysisSlice => ({
  ...initialState,
  setSelectedLabels: (labels: string[]) => set((state) => ({ ...state, selectedLabels: labels })),
  updateErasedCoordinates: (erasedCoordinates: IRowCoordsSet) => {
    set((state) => {
      const newErasedCoordinates = { ...state.erasedCoordinates };
      Object.keys(erasedCoordinates).forEach((sliceIndex) => {
        if (!newErasedCoordinates[sliceIndex]) {
          newErasedCoordinates[sliceIndex] = [...erasedCoordinates[sliceIndex]];
          return;
        }
        const uniqueCoordinates = new Set<number>([
          ...newErasedCoordinates[sliceIndex],
          ...erasedCoordinates[sliceIndex],
        ]);
        newErasedCoordinates[sliceIndex] = Array.from(uniqueCoordinates);
      });
      return {
        erasedCoordinates: newErasedCoordinates,
      };
    });
  },
  clearErasedCoordinates: () => {
    set({ erasedCoordinates: {} });
  },
  setLastChangeAsInitialImageData: () => {
    set((state) => ({
      initialImageData: state.maskImageDataStack[state.maskImageDataStack.length - 1],
      maskImageDataStack: [],
    }));
  },
  setInitialImageData: (id: string, imageData: ImageData) => {
    set((state) => ({
      initialImageData: {
        ...state.initialImageData,
        [id]: imageData,
      },
      maskImageDataStack: [],
    }));
  },
  pushMaskImageData: (maskImageData: IMaskImageData) => {
    set((state) => {
      return { maskImageDataStack: [...state.maskImageDataStack, maskImageData] };
    });
  },
  popMaskImageData: () => {
    set((state) => {
      return { maskImageDataStack: [...state.maskImageDataStack.slice(0, -1)] };
    });
  },
  clearMaskImageData: () => {
    set({ maskImageDataStack: [] });
  },
  setSelectedImageSize: (size: IImageSize) => {
    set({ selectedImageSize: size });
  },
  setSelectedImage: (image: IImage, masks: IMask[], index: number | null = null) => {
    set((state) => ({
      selectedImage: image,
      selectedMaskIndex: 0,
      selectedImageSize:
        state.selectedImageSize?.width === image.width &&
        state.selectedImageSize?.height === image.height
          ? state.selectedImageSize
          : null,
      masks: masks.map((mask) => {
        return {
          ...mask,
          visible: true,
        };
      }),
      maskRefs: masks.map(() => createRef<HTMLCanvasElement>()),
      selectedSliceIndex: index,
      selectedTool: image.isVIPImage ? IToolsEnum.TOOL_ERASER : state.selectedTool,
      isVIPImage: image.isVIPImage,
    }));
  },
  setSelectedVIPImage: (image: IVIPImage) => {
    set({
      selectedVIPImage: image,
      selectedImage: null,
    });
  },
  setBrushSize: (size) => set({ brushSize: size }),
  setSelectedTool: (tool: IToolsEnum) => set({ selectedTool: tool }),
  setSelectedSliceIndex: (index) => set({ selectedSliceIndex: index }),
  setSelectedMaskIndex: (index: number) => {
    set({
      selectedMaskIndex: index,
    });
  },
  setVisibility: (maskIndex, visible) => {
    set((state) => ({
      masks: state.masks.map((mask, index) => {
        if (index === maskIndex) {
          return {
            ...mask,
            visible,
          };
        }
        return mask;
      }),
    }));
  },
  resetAnalysisSlice: () => {
    set(initialState);
  },
  clearCoords: () => {
    set({ shouldClearCoords: Date.now() });
  },
  setSelectedImageFile: (file: IFile) => {
    const selectedLabels = file.labels.filter((label) => label.selected).map((label) => label.id);
    set({ selectedImageFile: file, selectedLabels });
  },
  setOpenFolders: (openFolders: { [key: string]: boolean }) => {
    set({ openFolders });
  },
});

export default createAnalysisSlice;
