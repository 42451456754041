import { Box, Button, Typography } from '@mui/material';
import prettyBytes from 'pretty-bytes';
import React from 'react';
import { useTranslation } from 'react-i18next';
import GenericModal from '../../ui/GenericModal';
import DropboxLimit from './constants/DropboxLimit';

interface IProps {
  open: boolean;
  handleClose: () => void;
}

const MaxSizeWarningModal: React.FC<IProps> = ({ open, handleClose }) => {
  const { t } = useTranslation();

  return (
    <GenericModal open={open} onClose={handleClose}>
      <Box textAlign="center">
        <Typography id="modal-modal-title" variant="h5" component="h2">
          {t('upload.tooBigFile')}
        </Typography>
        <Typography>
          {t('upload.cannotSelectFileBiggerThan', {
            size: prettyBytes(DropboxLimit.dropboxFileMaxSize),
          })}
        </Typography>
        <Typography>{t('upload.pleaseChooseAnotherFile')}</Typography>

        <Box display="flex" justifyContent="center" mt={2}>
          <Button onClick={handleClose} variant="contained">
            {t('button.ok')}
          </Button>
        </Box>
      </Box>
    </GenericModal>
  );
};

export default MaxSizeWarningModal;
