import React from 'react';
import { Navigate } from 'react-router-dom';
import PathEnum from '../../infrastructure/constants/PathEnum';
import { useAuth } from '../../infrastructure/context/AuthContext';

interface IProps {
  children: React.ReactElement;
}

const ProtectedRoute = ({ children }: IProps) => {
  const { isAuthenticated } = useAuth();
  if (!isAuthenticated) {
    return <Navigate to={PathEnum.Login} />;
  }

  return children;
};

export default ProtectedRoute;
