import { gql, MutationHookOptions, useMutation } from '@apollo/client';

const CREATE_NOTIFICATION_FROM_APP = gql`
  mutation createNotificationFromApp($input: CreateNotificationFromAppInput!) {
    createNotificationFromApp(input: $input) {
      type
      successCount
      unsuccessCount
      studyId
      errors {
        message
        sentFile
      }
    }
  }
`;

export default (options: MutationHookOptions) => {
  return useMutation(CREATE_NOTIFICATION_FROM_APP, options);
};
