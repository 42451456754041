import React from 'react';
import { Navigate } from 'react-router-dom';
import PathEnum from '../../infrastructure/constants/PathEnum';
import { useAuth } from '../../infrastructure/context/AuthContext';
import useCurrentUser from '../../infrastructure/hooks/useCurrentUser';

interface IProps {
  children: React.ReactElement;
}

const ProtectedRootRoute = ({ children }: IProps) => {
  const { isAuthenticated } = useAuth();
  const { user } = useCurrentUser();

  if (!isAuthenticated && user.root) {
    return <Navigate to={PathEnum.Login} />;
  }

  return children;
};

export default ProtectedRootRoute;
