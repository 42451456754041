import { gql, MutationHookOptions, useMutation } from '@apollo/client';

const UPDATE_STUDY = gql`
  mutation updateStudy(
    $id: String!
    $input: UpdateStudyInput!
  ) {
    updateStudy(
      id: $id
      input: $input
    ) {
      id
      name
      status
      creationDate
      lastModificationDate
      dueDate
      department
      studyDirectorId
    }
  }
`;

export default (options: MutationHookOptions) => useMutation(UPDATE_STUDY, options);
