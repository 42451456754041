import { colorToRGBA } from 'q-floodfill';
import replaceColors from './replaceColors';

interface IProps {
  imgSrc: string;
  canvas: HTMLCanvasElement;
  ctx: CanvasRenderingContext2D;
  color: string;
}

const drawImage = ({ canvas, ctx, imgSrc, color }: IProps): Promise<ImageData> => {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => {
      const { globalCompositeOperation } = ctx;
      // this is preventing to draw "empty image" when eraser is selected
      ctx.globalCompositeOperation = 'source-over';
      ctx.drawImage(img, 0, 0);
      replaceColors(canvas, [
        {
          colorToReplace: { r: 1, g: 1, b: 1, a: 255 },
          colorToReplaceWith: colorToRGBA(color),
        },
        {
          colorToReplace: colorToRGBA('#000000'),
          colorToReplaceWith: colorToRGBA('rgba(0, 0, 0, 0)'),
        },
      ]);
      const imgData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      ctx.globalCompositeOperation = globalCompositeOperation;
      resolve(imgData);
    };
    img.src = imgSrc;
    img.crossOrigin = 'anonymous';
  });
};

export default drawImage;
