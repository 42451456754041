import { Box, Button, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import React, { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import Colors from '../../../infrastructure/constants/Colors';
import useStore from '../../../infrastructure/store/useStore';
import { BorderStyleEnum, getBorder } from '../../../utils/Common';
import ImageEditorConfig from '../ImageEditor/constants/ImageEditor.config';
import MaskToggleButton from './MaskToggleButton';

interface IProps {
  showSliceSelector: boolean;
  parentHeight: number;
}

const MaskSelector: React.FC<IProps> = ({ showSliceSelector, parentHeight }) => {
  const { t } = useTranslation();

  const [masks, selectedMaskIndex, setSelectedMaskIndex, setVisibility] = useStore((state) => [
    state.masks,
    state.selectedMaskIndex,
    state.setSelectedMaskIndex,
    state.setVisibility,
  ]);

  const handleChange = (_, nextMask) => {
    if (nextMask !== null) {
      setSelectedMaskIndex(nextMask);
    }
  };

  const onVisibilityToggle = (maskIndex: number) => (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setVisibility(maskIndex, !masks[maskIndex].visible);
  };

  const hidden = masks.every((mask) => !mask.visible);

  const toggleAllMasks = () => {
    masks.forEach((_, index) => {
      setVisibility(index, hidden);
    });
  };

  console.log(masks, showSliceSelector);

  return (
    <Box sx={{ maxHeight: '100%' }}>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Typography variant="h6" py={1.5} px={1.5} fontWeight={400}>
          {t('analysis.objects')}
        </Typography>
        <Button onClick={toggleAllMasks}>
          {hidden ? t('analysis.showAll') : t('analysis.hideAll')}
        </Button>
      </Box>
      <ToggleButtonGroup
        orientation="vertical"
        value={selectedMaskIndex}
        exclusive
        onChange={handleChange}
        color="primary"
        variant="border"
        fullWidth
        sx={{
          overflow: 'auto',
          height: showSliceSelector ? parentHeight - 316 : parentHeight - 56,
        }}
      >
        {masks.map((mask, index) => {
          return (
            <ToggleButton
              value={index}
              key={mask.id}
              disableRipple
              component="div"
              sx={{
                borderRadius: 0,
                justifyContent: 'start',
                borderLeft: getBorder(Colors.transparent, BorderStyleEnum.SOLID, 2),
                backgroundColor: Colors.default,
                '&.Mui-selected': {
                  backgroundColor: Colors.secondary,
                  borderLeft: getBorder(Colors.lightPrimary, BorderStyleEnum.SOLID, 2),
                },
              }}
            >
              <MaskToggleButton
                color={ImageEditorConfig.COLORS_ORDER[index]}
                visible={mask.visible}
                onToggle={onVisibilityToggle(index)}
              >
                {mask.label === '-' ? `${t('analysis.VIPMaskLabel')}` : mask.label}
              </MaskToggleButton>
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>
    </Box>
  );
};

export default MaskSelector;
