import { gql, MutationHookOptions, useMutation } from '@apollo/client';

const COPY_FILES_FROM_DROPBOX = gql`
  mutation copyFilesFromDropbox($itemIds: [String!]!, $studyId: String!, $path: String) {
    copyFilesFromDropbox(itemIds: $itemIds, studyId: $studyId, path: $path) {
      id
      scanned
      uploaded
      failed
      progress
      errors {
        fileId
        fileName
        error
      }
    }
  }
`;

export default (options: MutationHookOptions) => useMutation(COPY_FILES_FROM_DROPBOX, options);
