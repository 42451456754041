import EditorColors from './EditorColors';

export default {
  DEFAULT_BRUSH_SIZE: 15,
  DEFAULT_PENCIL_SIZE: 2,
  VIP_ERASER_SIZE: 10,
  COLORS_ORDER: [
    EditorColors.pink,
    EditorColors.yellow,
    EditorColors.darkCyan,
    EditorColors.orange,
    EditorColors.purple,
    EditorColors.green,
    EditorColors.blue,
    EditorColors.red,
    EditorColors.darkBlue,
    EditorColors.brown,
  ],
  CANVAS_WIDTH: 1000,
  CANVAS_HEIGHT: 1024,
  BOUNDARIES_PADDING: 30,
  VIP_IMAGES_COUNT: 100,
  MINIMAP_SIZE: {
    default: 100,
    small: 72,
  },
  TRANSFORM_WRAPPER_SIZE: {
    default: 500,
    small: 400,
  },
};
