import { gql, MutationHookOptions, useMutation } from '@apollo/client';

const UPDATE_SPOT = gql`
  mutation updateIllnessSpots($input: [UpdateIllnessSpotInput!]!, $fileId: String!) {
    updateIllnessSpots(input: $input, fileId: $fileId) {
      id
      name
      index
      cnvGrade
      fileId
    }
  }
`;

export default (options: MutationHookOptions) => useMutation(UPDATE_SPOT, options);
