import { Box, Button, List, ListItem, ListItemText, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Colors from '../../infrastructure/constants/Colors';
import GenericModal from '../../ui/GenericModal';
import INotificationError from './types/INotificationError';

interface IProps {
  open: boolean;
  handleClose: () => void;
  errors: INotificationError[];
}

const NotificationErrorModal: React.FC<IProps> = ({ open, handleClose, errors }) => {
  const { t } = useTranslation();

  return (
    <GenericModal open={open} onClose={handleClose}>
      <Box sx={{ textAlign: 'center', maxHeight: 450, overflow: 'auto' }}>
        <Typography id="modal-modal-title" variant="h5" component="h2" pb={2}>
          {t('analysis.errors')}
        </Typography>
        <Typography
          component="div"
          id="modal-modal-description"
          sx={{ mt: 0, color: Colors.textSecondary, maxWidth: 450, mx: 'auto' }}
          variant="body2"
        >
          {errors.map((error, index) => (
            <List key={error.sentFile + [index]} disablePadding>
              <ListItem disablePadding>
                <ListItemText primary={error.message} secondary={error.sentFile} />
              </ListItem>
            </List>
          ))}
        </Typography>

        <Box display="flex" justifyContent="center" mt={2}>
          <Button onClick={handleClose} variant="contained">
            {t('button.ok')}
          </Button>
        </Box>
      </Box>
    </GenericModal>
  );
};

export default NotificationErrorModal;
