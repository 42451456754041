import IBounding from '../types/IBounding';

const addPaddingToBoundaries = (
  bounding: IBounding,
  padding: number,
  canvasWidth: number,
  canvasHeight: number,
): IBounding => {
  const top = Math.max(0, bounding.top - padding);
  const left = Math.max(0, bounding.left - padding);
  const right = Math.min(canvasWidth - 1, bounding.right + padding);
  const bottom = Math.min(canvasHeight - 1, bounding.bottom + padding);
  return { top, left, right, bottom };
};

export default addPaddingToBoundaries;
