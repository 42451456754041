import { Box, Divider, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Colors from '../../../infrastructure/constants/Colors';
import FullScreenLoader from '../../../ui/FullScreenLoader';
import { getBorder } from '../../../utils/Common';
import IFile from '../types/IFile';
import IFolderStructure from '../types/IFolderStructure';
import ImageFolderTree from './ImageFolderTree';

interface IProps {
  loading?: boolean;
  filesMap: { [key: string]: IFile[] };
  folderStructure: IFolderStructure[];
}

const ImageFolderSelector: React.FC<IProps> = ({ loading, folderStructure, filesMap }) => {
  const { t } = useTranslation();

  if (loading) return <FullScreenLoader />;

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      position="relative"
      borderRight={getBorder(Colors.divider)}
      sx={{ flexGrow: 1, overflowY: 'auto' }}
    >
      <Typography px={2} pb={2} pt={2}>
        {t('analysis.images')}
      </Typography>
      <Divider />

      <Box display="flex" flexWrap="wrap" pb={2} sx={{ overflowY: 'auto' }}>
        <ImageFolderTree folderStructure={folderStructure} filesMap={filesMap} path={null} />
      </Box>
    </Box>
  );
};

export default ImageFolderSelector;
