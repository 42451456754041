import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import ICNVModel from '../types/ICNVModel';

interface IGetCNVModelsQueryData {
  cnvModels: ICNVModel[];
}

export const GET_STUDIES = gql`
  query cnvModels {
    cnvModels {
      id
      pathology
    }
  }
`;

export default (options?: QueryHookOptions) => {
  return useQuery<IGetCNVModelsQueryData>(GET_STUDIES, options);
};
