import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import IStudy from '../../studies/types/IStudy';

interface IGetStudy {
  study: IStudy;
}

export const GET_STUDY = gql`
  query study($id: String!) {
    study(id: $id) {
      id
      name
      status
      creationDate
      lastModificationDate
      dueDate
      department
      studyDirectorId
      analysesCount
      analyses {
        id
        name
        aiModelId
        interface
        status
        creationDate
        aiModel {
          model
        }
      }
      cnvModelId
      cnvModel {
        id
        pathology
        isCNV
      }
    }
  }
`;

export default (options?: QueryHookOptions) => useQuery<IGetStudy>(GET_STUDY, options);
