import { NavigateBefore, NavigateNext } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Typography } from '@mui/material';
import { t } from 'i18next';
import React, { useCallback, useMemo, useState } from 'react';
import useStore from '../../../infrastructure/store/useStore';
import IFile from '../types/IFile';
import UnsavedChangesModal from '../UnsavedChangesModal';
import useLabelsSaveDiscard from './hooks/useLabelsSaveDiscard';
import LabelsSelectionCheckbox from './LabelsSelectionCheckbox';
import LabelsSelectionRadio from './LabelsSelectionRadio';

interface IProps {
  files: IFile[];
}

const LabelsSection: React.FC<IProps> = ({ files }) => {
  const [open, setOpen] = useState(false);
  const [selectedImageFile, setSelectedImageFile, openFolders, setOpenFolders] = useStore(
    (state) => [
      state.selectedImageFile,
      state.setSelectedImageFile,
      state.openFolders,
      state.setOpenFolders,
    ],
  );

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const { onSave, onDiscard, hasChanges, saveLoading } = useLabelsSaveDiscard(handleClose);

  if (!selectedImageFile) return null;

  const multiSelectable = useMemo(() => {
    const metadata = JSON.parse(selectedImageFile.metadata);
    return metadata.labelsMultiSelectable;
  }, [selectedImageFile.metadata]);

  const onPrev = useCallback(() => {
    if (!selectedImageFile) return;

    if (hasChanges) {
      setOpen(true);
      return;
    }
    const index = files.findIndex((file) => file.id === selectedImageFile.id);
    if (index > 0) {
      const prevImage = files[index - 1];
      setSelectedImageFile(prevImage);

      const image = document.getElementById(prevImage.id) as HTMLImageElement;
      image.scrollIntoView({ block: 'start' });

      const paths = prevImage.path.split('/');
      const pathsMap: { [key: string]: boolean } = {};
      let prevPath: string | null = null;
      paths.forEach((path) => {
        prevPath = prevPath ? `${prevPath}/${path}` : path;
        pathsMap[prevPath] = true;
      });
      setOpenFolders({ ...openFolders, ...pathsMap });
    }
  }, [setSelectedImageFile, setOpenFolders, openFolders, files, selectedImageFile, hasChanges]);

  const onNext = useCallback(() => {
    if (!selectedImageFile) return;

    if (hasChanges) {
      setOpen(true);
      return;
    }
    const index = files.findIndex((file) => file.id === selectedImageFile.id);
    if (index > -1 && index < files.length - 1) {
      const nextImage = files[index + 1];
      setSelectedImageFile(nextImage);

      const image = document.getElementById(nextImage.id) as HTMLImageElement;
      image.scrollIntoView({ block: 'start' });

      const paths = nextImage.path.split('/');
      const pathsMap: { [key: string]: boolean } = {};
      let nextPath: string | null = null;
      paths.forEach((path) => {
        nextPath = nextPath ? `${nextPath}/${path}` : path;
        pathsMap[nextPath] = true;
      });
      setOpenFolders({ ...openFolders, ...pathsMap });
    }
  }, [setSelectedImageFile, setOpenFolders, openFolders, files, selectedImageFile, hasChanges]);

  const disablePrev = useMemo(() => {
    const index = files.findIndex((file) => file.id === selectedImageFile.id);
    return index === 0;
  }, [files, selectedImageFile]);

  const disableNext = useMemo(() => {
    const index = files.findIndex((file) => file.id === selectedImageFile.id);
    return index === files.length - 1;
  }, [files, selectedImageFile]);

  return (
    <>
      <Box width={250}>
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          px={1.5}
          py={1.5}
          justifyContent="space-between"
        >
          <Box>
            <Typography variant="h6" pb={1.5} fontWeight={400}>
              {t('analysis.labels')}
            </Typography>
            {!multiSelectable && <LabelsSelectionRadio labels={selectedImageFile.labels} />}
            {multiSelectable && <LabelsSelectionCheckbox labels={selectedImageFile.labels} />}
          </Box>
          <Box display="flex" flexDirection="column">
            <Box justifyContent="space-between" display="flex">
              <LoadingButton
                color="secondary"
                onClick={onDiscard}
                variant="contained"
                disabled={!hasChanges || saveLoading}
                sx={{ minWidth: 102 }}
              >
                {t('button.discard')}
              </LoadingButton>
              <LoadingButton
                onClick={onSave}
                variant="contained"
                sx={{ minWidth: 102 }}
                disabled={!hasChanges || saveLoading}
                loading={saveLoading}
              >
                {t('button.save')}
              </LoadingButton>
            </Box>

            <Box mt={1} display="flex" justifyContent="space-between" width="100%">
              <Button size="small" sx={{ minWidth: 102 }} onClick={onPrev} disabled={disablePrev}>
                <NavigateBefore />
                <Box pr={2} fontSize="14px">
                  {t('button.previous')}
                </Box>
              </Button>
              <Button
                size="small"
                sx={{ minWidth: 102, textAlign: 'center' }}
                onClick={onNext}
                disabled={disableNext}
              >
                <Box fontSize="14px" pl={2}>
                  {t('button.next')}
                </Box>
                <NavigateNext />
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      <UnsavedChangesModal
        handleClose={handleClose}
        open={open}
        onDiscard={onDiscard}
        onSave={onSave}
        saveLoading={saveLoading}
      />
    </>
  );
};

export default LabelsSection;
