interface OrderFilterInput {
  field: string;
  order: OrderEnum;
}

interface FieldFilterInput {
  field: string;
  type: FilterTypeEnum;
  args: string[];
}

interface IFilterInput {
  offset?: number;
  cursor?: string;
  limit?: number;
  order?: OrderFilterInput;
  filters: FieldFilterInput[];
}

export enum OrderEnum {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum FilterTypeEnum {
  IN = 'IN',
  BETWEEN = 'BETWEEN',
  GTE = 'GTE',
  LTE = 'LTE',
  ILIKE = 'ILIKE',
  STARTS_WITH = 'STARTS_WITH',
}

export default IFilterInput;
