import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import IFile from '../types/IFile';

const REORDER_ANALYSIS_IMAGES = gql`
  mutation ReorderAnalysisImages(
    $input: [AnalysisInterface5ImageReorderInput!]!
    $analysisId: String!
  ) {
    reorderAnalysisImages(input: $input, analysisId: $analysisId) {
      id
      name
      signedUrl
    }
  }
`;

export default (options: MutationHookOptions) => {
  return useMutation<IFile[]>(REORDER_ANALYSIS_IMAGES, options);
};
