import React, { useMemo, useState } from 'react';
import FullScreenLoader from '../../ui/FullScreenLoader';
import LocalStorageEnum from '../constants/LocalStorageEnum';
import apolloClient from '../persistence/graphql';
import LOGOUT from '../persistence/mutations/logoutMutation';
import useUserQuery from '../persistence/queries/useUserQuery';
import ISessionToken from '../types/ISessionToken';

interface IAuthContext {
  isAuthenticated: boolean;
  setToken: (session: ISessionToken) => void;
  logout: () => void;
}

export const AuthContext = React.createContext<IAuthContext>({
  isAuthenticated: false,
  setToken: () => {},
  logout: () => {},
});

export const AuthProvider: React.FC = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(
    Boolean(localStorage.getItem(LocalStorageEnum.JWT_TOKEN_KEY)),
  );

  const { client, loading, error } = useUserQuery({
    skip: !isAuthenticated,
    onError: () => {
      setIsAuthenticated(false);
      localStorage.removeItem(LocalStorageEnum.JWT_TOKEN_KEY);
      localStorage.removeItem(LocalStorageEnum.REFRESH_TOKEN_KEY);
    },
  });

  const setToken = ({ accessToken, refreshToken }: ISessionToken) => {
    localStorage.setItem(LocalStorageEnum.JWT_TOKEN_KEY, accessToken);
    localStorage.setItem(LocalStorageEnum.REFRESH_TOKEN_KEY, refreshToken);
    setIsAuthenticated(true);
  };

  const logout = () => {
    setIsAuthenticated(false);
    client.resetStore();
    apolloClient
      .mutate({
        mutation: LOGOUT,
      })
      .finally(() => {
        localStorage.removeItem(LocalStorageEnum.JWT_TOKEN_KEY);
        localStorage.removeItem(LocalStorageEnum.REFRESH_TOKEN_KEY);
      });
  };

  const value = useMemo(
    () => ({ isAuthenticated, setToken, logout }),
    [isAuthenticated, setToken, logout],
  );

  if (loading || error) {
    return <FullScreenLoader />;
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => React.useContext(AuthContext);
