// eslint-disable-next-line import/prefer-default-export
import DueDate from './DueDate/DueDate';

export enum CustomRendererType {
  dueDate = 'dueDate',
}

export const customRenderersMap = {
  [CustomRendererType.dueDate]: DueDate,
};
