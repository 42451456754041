import { styled, Switch, SwitchProps } from '@mui/material';
import React from 'react';
import Colors from '../../../infrastructure/constants/Colors';

const AntSwitch: React.FC<SwitchProps> = styled(Switch)(({ theme }) => ({
  width: 50,
  height: 20,
  padding: 0,
  display: 'flex',
  '& .MuiSwitch-switchBase': {
    padding: 1,
    '&.Mui-checked': {
      transform: 'translateX(30px)',
      color: Colors.white,
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: Colors.switchTrackChecked,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 18,
    height: 18,
    borderRadius: 9,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: '66.666px',
    opacity: 1,
    backgroundColor: Colors.switchTrack,
    boxSizing: 'border-box',
  },
}));

export default AntSwitch;
