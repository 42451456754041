import IFile from '../../analysis/types/IFile';

export enum ReportStatusEnum {
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  ERROR = 'ERROR',
}

interface IReport {
  id: string;
  name: string;
  status: ReportStatusEnum,
  error: string;
  creationDate: string;
  files?: IFile[];
}

export default IReport;
