import { Box } from '@mui/material';
import { grey } from '@mui/material/colors';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useStore from '../../../infrastructure/store/useStore';
import AnalysisInterfaceEnum from '../../../infrastructure/types/AnalysisInterfaceEnum';
import ActionsContainer from '../ImageEditorRightSide/ActionsContainer';
import CanvasWrapper from './CanvasWrapper';
import EditorToolbar from './EditorToolbar';

interface IProps {
  interfaceModel: AnalysisInterfaceEnum;
  onPrevSlice: () => void;
  onNextSlice: () => void;
  hasChanges: boolean;
  disabledNext: boolean;
  disabledPrev: boolean;
}

const ImageEditor: React.FC<IProps> = ({
  interfaceModel,
  hasChanges,
  onPrevSlice,
  onNextSlice,
  disabledNext,
  disabledPrev,
}) => {
  const { t } = useTranslation();
  const [selectedImage, setSelectedImageSize] = useStore((state) => [
    state.selectedImage,
    state.setSelectedImageSize,
  ]);

  if (!selectedImage) {
    return (
      <Box
        display="flex"
        flexDirection="row"
        height="100%"
        alignItems="center"
        justifyContent="center"
        minWidth={560}
        flex={1}
      >
        {t('analysis.noSliceSelected')}
      </Box>
    );
  }

  const { signedUrl } = selectedImage;

  const onLoad = (e: React.ChangeEvent<HTMLImageElement>) => {
    setSelectedImageSize({
      width: e.target.naturalWidth,
      height: e.target.naturalHeight,
    });
  };

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <img
        onLoad={onLoad}
        src={signedUrl}
        alt={selectedImage.name}
        style={{
          visibility: 'hidden',
          position: 'absolute',
          width: 1,
          height: 1,
        }}
      />
      <Box
        sx={{
          position: 'relative',
          background: grey[200],
          flex: 1,
          pl: 10,
          ...(interfaceModel === AnalysisInterfaceEnum.MODEL_4
            ? { pr: 10, py: 5 }
            : {
                pt: 2,
                pb: 1,
                pr: 15,
              }),
        }}
      >
        <EditorToolbar />
        <CanvasWrapper interfaceModel={interfaceModel} />
      </Box>
      <ActionsContainer
        interfaceModel={interfaceModel}
        hasChanges={hasChanges}
        onNext={onNextSlice}
        onPrev={onPrevSlice}
        disabledNext={disabledNext}
        disabledPrev={disabledPrev}
      />
      <Box height={40} display="flex" alignItems="center" px={2}>
        {selectedImage.name}
      </Box>
    </Box>
  );
};

export default React.memo(ImageEditor);
