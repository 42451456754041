import { Box, Card, Container } from '@mui/material';
import React from 'react';
import ProfileForm from '../../domain/profile/ProfileForm';
import MainContainer from '../../ui/MainContainer';
import Header from '../layout/Header/Header';

const UserProfilePage: React.FC = () => {
  return (
    <>
      <Header />
      <MainContainer>
        <Container
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            pt: 2,
          }}
        >
          <Card>
            <Box
              width={590}
              py={12}
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              borderRadius={3}
              sx={{ backgroundColor: 'common.white' }}
            >
              <ProfileForm />
            </Box>
          </Card>
        </Container>
      </MainContainer>
    </>
  );
};

export default UserProfilePage;
