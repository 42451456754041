export default {
  Dashboard: '/',
  Login: '/login',
  ResetPassword: '/reset-password',
  CreatePassword: '/create-password',
  Admin: '/admin',
  UserProfile: '/profile',
  Analysis: '/analysis/:analysisId',
  Studies: '/studies',
  Study: '/studies/:studyId',
  Upload: '/studies/:studyId/upload',
  AnalysisUpload: '/studies/:studyId/select/:analysisId',
  Reports: '/studies/:studyId/reports/:analysisId',
  Notifications: '/notifications',
  Users: '/users',
};
