import { ApolloProvider } from '@apollo/client';
import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import { AuthProvider } from '../infrastructure/context/AuthContext';
import { NotificationsProvider } from '../infrastructure/context/NotificationsContext';
import apolloClient from '../infrastructure/persistence/graphql';
import theme from '../infrastructure/theme';
import Router from './routing/Router';

const App: React.FC = () => {
  return (
    <ApolloProvider client={apolloClient}>
      <AuthProvider>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <SnackbarProvider maxSnack={5}>
              <NotificationsProvider>
                <CssBaseline />
                <Router />
              </NotificationsProvider>
            </SnackbarProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </AuthProvider>
    </ApolloProvider>
  );
};

export default App;
