import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import IUser, { UserStatusEnum } from '../../../infrastructure/types/IUser';

interface IUpdateUserInput {
  input: {
    firstName?: string;
    lastName?: string;
    status?: UserStatusEnum;
  };
  id: string;
}

interface IUpdateUserResponse {
  updateUser: IUser;
}

const UPDATE_USER = gql`
  mutation updateUser($input: UpdateUserInput!, $id: String!) {
    updateUser(input: $input, id: $id) {
      id
      firstName
      lastName
      creationDate
      status
      email
      root
    }
  }
`;

export default (options: MutationHookOptions<IUpdateUserResponse, IUpdateUserInput>) => {
  return useMutation<IUpdateUserResponse, IUpdateUserInput>(UPDATE_USER, options);
};
