import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  FormControl,
  TextField,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Colors from '../../infrastructure/constants/Colors';
import AnalysisInterfaceEnum from '../../infrastructure/types/AnalysisInterfaceEnum';
import IFileRoleEnum from '../../infrastructure/types/IFileRoleEnum';
import { FilterTypeEnum } from '../../infrastructure/types/IFilterInput';
import FullScreenLoader from '../../ui/FullScreenLoader';
import { getBorder } from '../../utils/Common';
import useAnalysis from '../analysis/graphql/useAnalysis';
import { shouldShowImageEditor } from '../analysis/helpers/CaseHelpers';
import IReportCategory from '../analysis/types/IReportCategory';
import FolderSelector from './FolderSelector';
import useCreateReportMutation from './graphql/useCreateReportMutation';
import { REPORTS } from './graphql/useReports';
import ImagesSelector from './ImagesSelector';
import ReportsList from './ReportsList';
import { useUploadContext } from '../upload/context/UploadContext';

const ReportsInner: React.FC = () => {
  const { t } = useTranslation();
  const { analysisId } = useParams();

  const [name, setName] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const [nameValidator, setNameValidator] = useState<string>();
  const { selectedFilesIds } = useUploadContext();

  const [mutateReport] = useCreateReportMutation({
    onError: ({ graphQLErrors }) => {
      const nameError = graphQLErrors.find((error) => error.message[0].includes(`"${name}"`));
      if (nameError) {
        setNameValidator(nameError.message);
      }
    },
  });

  const { data: analysisData, loading: analysisLoading } = useAnalysis({
    variables: {
      id: analysisId,
      filters: {
        filters: [
          { field: 'role', type: FilterTypeEnum.IN, args: [IFileRoleEnum.ANALYSIS_RESULT] },
        ],
      },
    },
    skip: !analysisId,
  });

  const [submitted, setSubmitted] = useState(false);
  const [selectedImagesIds, setSelectedImagesIds] = useState<string[]>([]);
  const [script, setScript] = useState<string | string[]>([]);

  const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const onChangeScript = (_, value: IReportCategory[]) => {
    setScript(value.map((category) => category.id));
    // );
  };

  const analysis = useMemo(() => analysisData?.analysis, [analysisData?.analysis]);

  const interfaceModel = analysis?.interface || AnalysisInterfaceEnum.MODEL_1;

  const showImagesSelector = useMemo(() => shouldShowImageEditor(interfaceModel), [interfaceModel]);
  const showFolderSelector = useMemo(() => {
    return (
      interfaceModel === AnalysisInterfaceEnum.MODEL_5 ||
      interfaceModel === AnalysisInterfaceEnum.MODEL_6 ||
      interfaceModel === AnalysisInterfaceEnum.MODEL_7
    );
  }, [interfaceModel]);

  const reportCategories = analysis?.reportCategories || [];

  const openAutocomplete = useCallback(() => {
    setOpen(true);
  }, []);

  const closeAutocomplete = useCallback(() => {
    setOpen(false);
  }, []);

  const renderOption = useCallback(
    (props, option, { selected }) => {
      const isLastOption = option.id === reportCategories[reportCategories.length - 1]?.id;
      return (
        <React.Fragment key={option.id}>
          <li {...props}>
            <Checkbox style={{ marginRight: 8 }} checked={selected} />
            {option.type}
          </li>
          {isLastOption && (
            <Box sx={{ mt: 1, px: 2 }}>
              <Button fullWidth type="button" variant="contained" onClick={closeAutocomplete}>
                {t('button.ok')}
              </Button>
            </Box>
          )}
        </React.Fragment>
      );
    },
    [reportCategories, closeAutocomplete],
  );

  const handleGenerate = useCallback(() => {
    setNameValidator(undefined);
    setSubmitted(true);
    if (!name || !script || script.length === 0) {
      return false;
    }
    if (showImagesSelector && selectedImagesIds.length === 0) {
      return false;
    }
    if (showFolderSelector && selectedFilesIds.length === 0) {
      return false;
    }
    const imageIds = (() => {
      if (showImagesSelector) {
        return selectedImagesIds;
      }
      if (showFolderSelector) {
        return selectedFilesIds;
      }
      return undefined;
    })();

    mutateReport({
      variables: {
        input: {
          analysisId,
          name,
          categoryIds: script,
          imageIds,
        },
      },
      refetchQueries: [REPORTS],
    });
  }, [
    analysisId,
    mutateReport,
    name,
    script,
    selectedImagesIds,
    showImagesSelector,
    selectedFilesIds,
    showFolderSelector,
  ]);

  if (analysisLoading || !analysis) {
    return <FullScreenLoader />;
  }

  return (
    <Card>
      <Box width="100%" height={600} display="flex">
        <Box
          sx={{ borderRight: getBorder(Colors.divider), flexGrow: 1 }}
          height="100%"
          display="flex"
          flexDirection="column"
        >
          {showImagesSelector && (
            <ImagesSelector
              interfaceModel={interfaceModel}
              selectedImagesIds={selectedImagesIds}
              setSelectedImagesIds={setSelectedImagesIds}
            />
          )}
          {showFolderSelector && <FolderSelector folderStructure={analysis.folderStructure} />}
        </Box>
        <Box py={2} px={3} width={388} sx={{ overflowY: 'auto' }}>
          <Typography variant="h5">{t('analysis.generateReport')}</Typography>
          <Box component="form" noValidate mt={1} mb={6}>
            <TextField
              margin="normal"
              size="small"
              fullWidth
              label={t('reports.reportName')}
              name="reportName"
              value={name}
              onChange={onNameChange}
              error={submitted && (!name || !!nameValidator)}
              helperText={nameValidator}
              autoComplete="off"
            />
            <FormControl
              fullWidth
              size="small"
              margin="normal"
              error={submitted && (!script || script.length === 0)}
            >
              <Autocomplete
                multiple
                id="script"
                options={reportCategories}
                disableCloseOnSelect
                onChange={onChangeScript}
                getOptionLabel={(option) => option.type}
                open={open}
                onOpen={openAutocomplete}
                onClose={closeAutocomplete}
                renderOption={renderOption}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('reports.selectScript')}
                    placeholder={t('reports.selectScript')}
                  />
                )}
              />
            </FormControl>
            <LoadingButton
              onClick={handleGenerate}
              // disabled={selectedImagesIds.length === 0}
              fullWidth
              type="button"
              loading={false}
              sx={{ mt: 3 }}
              variant="contained"
            >
              {t('reports.generate')}
            </LoadingButton>
            {submitted && showImagesSelector && selectedImagesIds.length === 0 && (
              <Alert severity="warning" sx={{ mt: 2 }}>
                {t('reports.pleaseSelectImages')}
              </Alert>
            )}
            {submitted && showFolderSelector && selectedFilesIds.length === 0 && (
              <Alert severity="warning" sx={{ mt: 2 }}>
                {t('reports.pleaseSelectObjects')}
              </Alert>
            )}
          </Box>
          <ReportsList />
        </Box>
      </Box>
    </Card>
  );
};

export default ReportsInner;
