import { Box, Button, ListItem, ListItemButton, Typography } from '@mui/material';
import moment from 'moment';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import xss from 'xss';
import { ClockIcon } from '../../infrastructure/assets/icons';
import Colors from '../../infrastructure/constants/Colors';
import PathEnum from '../../infrastructure/constants/PathEnum';
import INotification, { NotificationType } from '../../infrastructure/types/INotification';
import NotificationErrorModal from './NotificationErrorModal';

interface IProps {
  notification: INotification;
  divide: boolean;
}

const NotificationsItem: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const { notification, divide } = props;
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();

  const onClick = () => {
    if (
      notification.type === NotificationType.STUDY_FILES_UPLOADED ||
      notification.type === NotificationType.ANALYSIS_PROCESSED
    ) {
      navigate(generatePath(PathEnum.Study, { studyId: notification.studyId }));
    }
    if (
      notification.type === NotificationType.ANALYSIS_FILES_UPLOADED ||
      notification.type === NotificationType.ANALYSIS_REPORT_GENERATED
    ) {
      navigate(generatePath(PathEnum.Analysis, { analysisId: notification.analysisId }));
    }
  };

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <ListItem divider={divide}>
      <ListItemButton onClick={onClick}>
        <Box>
          <Typography
            variant="body1"
            dangerouslySetInnerHTML={{
              __html: xss(notification.message) || '',
            }}
          />
          <Typography
            sx={{
              marginTop: '10px',
              color: Colors.textSecondary,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <ClockIcon width="20px" height="20px" />
            <Box component="span" sx={{ ml: '5px' }}>
              {/* 11 hours ago */}
              {moment(notification.creationDate).fromNow()}
              {notification.errors.length !== 0 ? (
                <Button
                  sx={{ ml: 1 }}
                  variant="text"
                  color="error"
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpen(true);
                  }}
                >
                  {t('notifications.seeErrors')}
                </Button>
              ) : null}
            </Box>
          </Typography>
        </Box>
      </ListItemButton>
      <NotificationErrorModal open={open} handleClose={handleClose} errors={notification.errors} />
    </ListItem>
  );
};

export default NotificationsItem;
