import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import useStore from '../../../infrastructure/store/useStore';
import useMaskChanges from '../hooks/useMaskChanges';
import drawImage from './helpers/drawImage';
import useCanvasDraw from './hooks/useCanvasDraw';

interface IProps {
  id: string;
  index: number;
  color: string;
  width: number;
  height: number;
  visible: boolean;
  imgSrc?: string;
}

const Canvas: React.FC<IProps> = ({ id, index, color, width, height, imgSrc, visible }) => {
  const [selectedMaskIndex, selectedImage, maskRefs] = useStore((state) => [
    state.selectedMaskIndex,
    state.selectedImage,
    state.maskRefs,
  ]);
  const isSelected = index === selectedMaskIndex;
  const maskRef = maskRefs[index];
  const { setDrawing, ...canvasProps } = useCanvasDraw(id, maskRef, color);
  const { setInitialImageData } = useMaskChanges();

  useEffect(() => {
    setDrawing(false);
  }, [isSelected]);

  useEffect(() => {
    const canvas = maskRef?.current;
    const ctx = canvas?.getContext('2d');
    if (!ctx || !canvas) return;
    if (imgSrc) {
      drawImage({ ctx, imgSrc, canvas, color }).then((imageData) => {
        setInitialImageData(id, imageData);
      });
    }
  }, [maskRef]);

  return (
    <>
      {selectedImage?.isVIPImage && (
        <Box
          sx={{
            position: 'absolute',
            background: 'conic-gradient(from 90deg at 1px 1px,#0000 90deg,#333 0) 0 0/10px 10px;',
            width,
            height,
            opacity: 0.1,
            zIndex: 0,
            top: 0,
            left: 0,
          }}
        />
      )}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          opacity: visible ? 0.2 : 0,
          zIndex: isSelected ? 1 : 0,
        }}
      >
        <canvas ref={maskRef} id="canvas" width={width} height={height} {...canvasProps} />
      </Box>
    </>
  );
};

export default React.memo(Canvas);
