import { gql, OnSubscriptionDataOptions, useSubscription } from '@apollo/client';

export const ANALYSIS_SUBSCRIPTION = gql`
  subscription analysisUpdated($studyId: String!) {
    analysisUpdated(studyId: $studyId) {
      id
      name
      status
      studyId
    }
  }
`;

export default (options?: OnSubscriptionDataOptions) => {
  return useSubscription(ANALYSIS_SUBSCRIPTION, options);
};
