enum Colors {
  white = '#FFFFFF',
  black = '#333333',
  default = '#F7F8F8',
  primary = '#0077C8',
  lightPrimary = '#3392D3',
  secondary = '#E6F2FA',
  lightSecondary = '#CCE4F4',
  gray = '#666666',
  gray2 = '#999999',
  disable = '#E6E6E6',
  error = '#E0004D',
  backdropBg = 'rgba(51,51,51, 0.9)',
  textPrimary = '#333333',
  textSecondary = '#B3B3B3',
  green = '#33AB7E',
  green2 = '#5CBC98',
  alert = '#E63371',
  divider = '#F2F2F2',
  draggableTransparentBackground = 'rgba(0, 119, 200, .1)',
  boxShadow = '0px 4px 4px rgba(0, 0, 0, 0.04), 0px 1px 8px rgba(0, 0, 0, 0.04), 0px 2px 4px rgba(0, 0, 0, 0.04)',
  draggableBoxShadow = '0px 0px 0px 2px #3392D3',
  transparent = 'transparent',
  red = '#FF1E1E',
  switchTrackChecked = '#66ADDE',
  switchTrack = '#E6E6E6',
}

export default Colors;
