import { CloseSharp } from '@mui/icons-material';
import { Box, Card, IconButton, Modal } from '@mui/material';
import React, { ReactElement } from 'react';

interface IProps {
  children: ReactElement | ReactElement[];
  open: boolean;
  onClose: () => void;
}

const GenericModal: React.FC<IProps> = ({ children, open, onClose }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <Card
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 520,
          p: 2,
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 11,
            right: 11,
          }}
        >
          <IconButton onClick={onClose}>
            <CloseSharp />
          </IconButton>
        </Box>
        <Box p={8}>{children}</Box>
      </Card>
    </Modal>
  );
};

export default GenericModal;
