import { Box } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { useTransformContext } from 'react-zoom-pan-pinch';
import AnalysisInterfaceEnum from '../../../infrastructure/types/AnalysisInterfaceEnum';
import EditorColors from './constants/EditorColors';
import ImageEditorConfig from './constants/ImageEditor.config';
import getSizeByInterface from './helpers/getSizeByInterface';
import scaleDownSize from './helpers/scaleDownSize';

interface IProps {
  imageSource: string;
  initialScale: number;
  originalWidth: number;
  originalHeight: number;
  interfaceModel: AnalysisInterfaceEnum;
}

const MiniMap: React.FC<IProps> = ({
  imageSource,
  initialScale,
  interfaceModel,
  originalWidth,
  originalHeight,
}) => {
  const ref = useTransformContext();
  const minimapRef = useRef<HTMLCanvasElement>(null);
  const size = getSizeByInterface(ImageEditorConfig.MINIMAP_SIZE, interfaceModel);
  const { width, height } = scaleDownSize({
    width: originalWidth,
    height: originalHeight,
    size,
  });

  useEffect(() => {
    const ctx = minimapRef.current?.getContext('2d');
    const { state } = ref;
    if (!ctx) return;
    ctx.beginPath();
    ctx.strokeStyle = EditorColors.minimapBorderColor;
    ctx.lineWidth = 2;
    const zoomLevel = state.scale / initialScale;
    const diffX = originalWidth / width;
    const diffY = originalHeight / height;
    const rectangleWidth = width / zoomLevel;
    const rectangleHeight = height / zoomLevel;
    const x = Math.abs(state.positionX / state.scale) / diffX;
    const y = Math.abs(state.positionY / state.scale) / diffY;
    ctx.clearRect(0, 0, width, height);
    ctx.rect(x, y, rectangleWidth, rectangleHeight);
    ctx.stroke();
  }, [ref]);

  return (
    <Box
      sx={{
        position: 'absolute',
        width,
        height,
        backgroundImage: `url(${imageSource})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        right: interfaceModel === AnalysisInterfaceEnum.MODEL_4 ? 4 : 16,
        top: 12,
      }}
    >
      <canvas id="minimap" ref={minimapRef} width={width} height={height} />
    </Box>
  );
};

export default MiniMap;
