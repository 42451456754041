import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Breadcrumbs, Button, Card } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Colors from '../../infrastructure/constants/Colors';
import { getBorder } from '../../utils/Common';
import ComputerSelectFiles from './ComputerSelectFiles';
import DropboxLimit from './constants/DropboxLimit';
import { UploadTypeEnum, useUploadContext } from './context/UploadContext';
import DropboxFileSelect from './DropboxFileSelect';
import MaxSizeWarningModal from './MaxSizeWarningModal';
import ProjectSelectFiles from './ProjectSelectFiles';
import SearchDropboxFiles from './SearchDropboxFiles';
import IUploadTabEnum from './types/IUploadTabEnum';
import UploadToFolderModal from './UploadToFolderModal';

const SelectFilesDrawer: React.FC = () => {
  const { t } = useTranslation();

  const {
    type,
    startUpload,
    startAddingFilesToAnalysis,
    filesCount,
    selectedDropboxFileCount,
    selectedFilesCount,
    onDragOver,
    dropboxFilesSize,
  } = useUploadContext();

  const [tab, setTab] = useState<IUploadTabEnum>(
    type === UploadTypeEnum.STUDY ? IUploadTabEnum.LOCAL : IUploadTabEnum.PROJECT,
  );

  const [open, setOpen] = useState(false);
  const [uploadModalOpen, setUploadModalOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUploadModalClose = () => {
    setUploadModalOpen(false);
  };

  const handleStartUploadSubmit = useCallback(
    (path: string) => {
      startUpload(path);
      setUploadModalOpen(false);
    },
    [startUpload],
  );

  const handleStartUpload = useCallback(() => {
    if (dropboxFilesSize > DropboxLimit.dropboxFileMaxSize) {
      handleOpen();
      return;
    }
    setUploadModalOpen(true);
  }, [dropboxFilesSize, handleOpen]);

  return (
    <Card onDragOver={onDragOver}>
      <Box position="relative" width="100%" sx={{ height: 'calc(100vh - 212px)' }}>
        <Box sx={{ display: 'flex' }} height="100%">
          <Box
            sx={{
              width: 280,
              flexShrink: 0,
              borderRight: getBorder(Colors.divider),
            }}
          >
            <Toolbar>
              <Typography variant="h5">{t('upload.source')}</Typography>
            </Toolbar>
            <Divider />
            <List sx={{ pt: 0 }}>
              {type === UploadTypeEnum.STUDY && (
                <>
                  <ListItem
                    button
                    sx={{ px: 3 }}
                    selected={tab === IUploadTabEnum.DROPBOX}
                    onClick={() => setTab(IUploadTabEnum.DROPBOX)}
                  >
                    <ListItemText primary={t('upload.uploadFromDropbox')} />
                  </ListItem>
                  <Divider />
                  <ListItem
                    button
                    sx={{ px: 3 }}
                    selected={tab === IUploadTabEnum.DROPBOXSEARCH}
                    onClick={() => setTab(IUploadTabEnum.DROPBOXSEARCH)}
                  >
                    <ListItemText primary={t('upload.searchInSearchFile')} />
                  </ListItem>
                  <Divider />
                  <ListItem
                    button
                    sx={{ px: 3 }}
                    selected={tab === IUploadTabEnum.LOCAL}
                    onClick={() => setTab(IUploadTabEnum.LOCAL)}
                  >
                    <ListItemText primary={t('upload.uploadFromComputer')} />
                  </ListItem>
                  <Divider />
                </>
              )}
              {type === UploadTypeEnum.ANALYSIS && (
                <ListItem
                  button
                  sx={{ px: 3 }}
                  selected={tab === IUploadTabEnum.PROJECT}
                  onClick={() => setTab(IUploadTabEnum.PROJECT)}
                >
                  <ListItemText primary={t('upload.projectFiles')} />
                </ListItem>
              )}
              <Divider />
            </List>
          </Box>

          <Box display="flex" flexDirection="column" width="100%">
            <Toolbar sx={{ background: 'white' }}>
              <Box px={2}>
                <Breadcrumbs
                  separator={<NavigateNextIcon fontSize="small" />}
                  aria-label="breadcrumb"
                >
                  <Typography key="1" color="text.primary">
                    {t('upload.imageSelect')}
                  </Typography>
                  <Typography key="2" color="text.secondary">
                    {t('upload.imageUpload')}
                  </Typography>
                </Breadcrumbs>
              </Box>
            </Toolbar>
            <Divider />

            <Box component="div" sx={{ flexGrow: 1, p: 5, overflowY: 'auto' }}>
              {tab === IUploadTabEnum.DROPBOX && <DropboxFileSelect />}
              {tab === IUploadTabEnum.DROPBOXSEARCH && <SearchDropboxFiles />}
              {tab === IUploadTabEnum.LOCAL && <ComputerSelectFiles />}
              {tab === IUploadTabEnum.PROJECT && <ProjectSelectFiles />}
            </Box>
            <Box
              component="div"
              width="100%"
              bottom={0}
              sx={{
                zIndex: 11,
                backgroundColor: 'common.white',
                boxShadow: '0px -3px 14px rgba(0, 0, 0, 0.08)',
              }}
            >
              <Toolbar>
                <Box ml="auto">
                  {type === UploadTypeEnum.STUDY ? (
                    <>
                      <Button
                        disabled={!filesCount && !selectedDropboxFileCount}
                        variant="contained"
                        onClick={handleStartUpload}
                      >
                        {t('upload.startUpload')}
                      </Button>
                      <MaxSizeWarningModal open={open} handleClose={handleClose} />
                      <UploadToFolderModal
                        open={uploadModalOpen}
                        handleSubmit={handleStartUploadSubmit}
                        handleClose={handleUploadModalClose}
                      />
                    </>
                  ) : (
                    <Button
                      disabled={!selectedFilesCount}
                      variant="contained"
                      onClick={startAddingFilesToAnalysis}
                    >
                      {t('upload.proceed')}
                    </Button>
                  )}
                </Box>
              </Toolbar>
            </Box>
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

export default SelectFilesDrawer;
