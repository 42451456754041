import React, { useCallback } from 'react';

type IUseSetRef<T> = [React.MutableRefObject<T>, (value: T) => void, () => void];

const useSetRef = <T>(initialValue: T): IUseSetRef<T> => {
  const ref = React.useRef<T>(initialValue);

  const setValue = useCallback(
    (value: T) => {
      ref.current = value;
    },
    [ref],
  );

  const resetValue = useCallback(() => {
    ref.current = initialValue;
  }, [ref, initialValue]);

  return [ref, setValue, resetValue];
};

export default useSetRef;
