import { Box } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useStore from '../../infrastructure/store/useStore';
import AnalysisInterfaceEnum from '../../infrastructure/types/AnalysisInterfaceEnum';
import IFileSubRoleEnum from '../../infrastructure/types/IFileSubRoleEnum';
import { FilterTypeEnum } from '../../infrastructure/types/IFilterInput';
import useFiles from './graphql/useFiles';
import useMaskChanges from './hooks/useMaskChanges';
import useMaskSaveDiscard from './hooks/useMaskSaveDiscard';
import ImageEditor from './ImageEditor/ImageEditor';
import ImageEditorRightSide from './ImageEditorRightSide/ImageEditorRightSide';
import IFile from './types/IFile';
import UnsavedChangesModal from './UnsavedChangesModal';

interface IProps {
  interfaceModel: AnalysisInterfaceEnum;
}

const ImageEditorLayout: React.FC<IProps> = ({ interfaceModel }) => {
  const { t } = useTranslation();

  const { analysisId } = useParams();

  const [open, setOpen] = useState(false);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const { onSave, saveLoading, onDiscard } = useMaskSaveDiscard(handleClose);
  const { hasChanges } = useMaskChanges();
  const [selectedImage, setSelectedImage, selectedSliceIndex, selectedVIPImage] = useStore(
    (state) => [
      state.selectedImage,
      state.setSelectedImage,
      state.selectedSliceIndex,
      state.selectedVIPImage,
    ],
  );

  const { data: filesData, loading: filesLoading } = useFiles({
    variables: {
      filters: {
        filters: [
          { field: 'analysisId', type: FilterTypeEnum.IN, args: [analysisId] },
          { field: 'subRole', type: FilterTypeEnum.IN, args: [IFileSubRoleEnum.IMAGE] },
          selectedVIPImage && {
            field: 'associatedFileId',
            type: FilterTypeEnum.IN,
            args: [selectedVIPImage.associatedFileId],
          },
        ],
      },
    },
    skip: !selectedVIPImage,
  });

  const files = useMemo(() => {
    if (!filesData?.files) return [];
    return [...filesData.files].sort((a, b) => a.index - b.index);
  }, [filesData?.files]);

  const onSliceSelect = useCallback(
    (file: IFile) => (image: HTMLImageElement | null) => {
      if (hasChanges) {
        setOpen(true);
        return;
      }
      const width = image?.naturalWidth;
      const height = image?.naturalHeight;
      if (!file.signedUrl) return;
      setSelectedImage(
        {
          id: file.id,
          index: file.index,
          signedUrl: file.signedUrl,
          objectId: file.objectId,
          name: file.name,
          width,
          height,
          isVIPImage: false,
        },
        file.masks || [],
        file.index - 1,
      );
    },
    [hasChanges, setSelectedImage],
  );

  const onNextSlice = useCallback(() => {
    const nextIndex = (selectedSliceIndex || 0) + 1;
    if (files && files[nextIndex]) {
      const file = files[nextIndex];
      const image = document.getElementById(file.id)?.firstChild as HTMLImageElement;
      if (!image) return;

      image.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });

      onSliceSelect(file)(image);
    }
  }, [selectedSliceIndex, files, onSliceSelect]);

  const onPrevSlice = useCallback(() => {
    if (!selectedSliceIndex || selectedSliceIndex < 1) return;
    const prevIndex = selectedSliceIndex - 1;
    if (files && files[prevIndex]) {
      const file = files[prevIndex];
      const image = document.getElementById(file.id)?.firstChild as HTMLImageElement;
      if (!image) return;

      image.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });

      onSliceSelect(file)(image);
    }
  }, [selectedSliceIndex, files, onSliceSelect]);

  const disableNext = useMemo(() => {
    if (selectedSliceIndex && selectedSliceIndex + 1 === files.length) {
      return true;
    }
    return false;
  }, [selectedSliceIndex, files]);

  const disablePrev = useMemo(() => {
    if (!selectedSliceIndex) {
      return true;
    }
    return false;
  }, [selectedSliceIndex]);

  const onVipEdit = useCallback(() => {
    if (!selectedVIPImage || !selectedVIPImage.mask) return;
    if (hasChanges) {
      setOpen(true);
      return;
    }
    setSelectedImage(
      {
        id: selectedVIPImage.id,
        index: selectedVIPImage.index,
        signedUrl: selectedVIPImage.signedUrl,
        objectId: selectedVIPImage.objectId,
        name: selectedVIPImage.name,
        width: selectedVIPImage.width,
        height: selectedVIPImage.height,
        isVIPImage: true,
      },
      [selectedVIPImage.mask],
    );
  }, [hasChanges, selectedVIPImage, setSelectedImage]);

  if (!selectedImage && !selectedVIPImage) {
    return (
      <Box
        display="flex"
        flexDirection="row"
        height="100%"
        alignItems="center"
        justifyContent="center"
        flex={1}
      >
        {t('analysis.noImageSelected')}
      </Box>
    );
  }

  return (
    <>
      <Box flex={1}>
        <ImageEditor
          interfaceModel={interfaceModel}
          hasChanges={hasChanges}
          onNextSlice={onNextSlice}
          onPrevSlice={onPrevSlice}
          disabledNext={disableNext}
          disabledPrev={disablePrev}
        />
      </Box>
      <ImageEditorRightSide
        interfaceModel={interfaceModel}
        files={files}
        filesLoading={filesLoading}
        onSliceSelect={onSliceSelect}
        onVipEdit={onVipEdit}
        selectedImage={selectedImage}
      />
      <UnsavedChangesModal
        handleClose={handleClose}
        open={open}
        onDiscard={onDiscard}
        onSave={onSave}
        saveLoading={saveLoading}
      />
    </>
  );
};

export default ImageEditorLayout;
