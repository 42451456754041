import { gql, MutationHookOptions, useMutation } from '@apollo/client';

const UPDATE_USER_PROFILE = gql`
  mutation updateUserProfile(
    $firstName: String!
    $lastName: String!
    $emailNotifications: Boolean!
  ) {
    updateUserProfile(input: {
      firstName: $firstName
      lastName: $lastName
      emailNotifications: $emailNotifications
    }) {
      id
      firstName
      lastName
      email
      emailNotifications
    }
  }
`;

export default (options: MutationHookOptions) => useMutation(UPDATE_USER_PROFILE, options);
