import { ReportStatusEnum } from '../types/IReport';

const updateReportsQuery = (lastAddedInProgressReportId) => {
  return (prev, { subscriptionData }) => {
    const newFiles = subscriptionData.data?.filesCreated;
    if (!newFiles?.length) return prev;
    const index = prev.analysisReports.findIndex((el) => el.id === lastAddedInProgressReportId);
    if (index === -1) return prev;
    const auxReports = [...prev.analysisReports];
    auxReports[index] = {
      ...auxReports[index],
      status: ReportStatusEnum.COMPLETED,
      files: [...newFiles, ...(auxReports[index].files || [])],
    };
    return {
      ...prev,
      analysisReports: auxReports,
    };
  };
};

export default updateReportsQuery;
