import { ServerError, ServerParseError } from '@apollo/client';
import { DatePicker, LoadingButton, LocalizationProvider } from '@mui/lab';
import AdapterMoment from '@mui/lab/AdapterMoment';
import { Box, MenuItem, TextField, Typography } from '@mui/material';
import { GraphQLError } from 'graphql';
import moment, { Moment } from 'moment';
import React, { FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterTypeEnum } from '../../infrastructure/types/IFilterInput';
import IInternalError from '../../infrastructure/types/IInternalError';
import { UserStatusEnum } from '../../infrastructure/types/IUser';
import isServerError from '../../infrastructure/utils/isServerError';
import FullScreenLoader from '../../ui/FullScreenLoader';
import GenericModal from '../../ui/GenericModal';
import useCnvModelsQuery from './graphql/useCnvModelsQuery';
import useCreateStudyMutation from './graphql/useCreateStudyMutation';
import useDepartmentsQuery from './graphql/useDepartmentsQuery';
import { GET_STUDIES } from './graphql/useStudiesQuery';
import useUsersQuery from './graphql/useUsersQuery';

interface IProps {
  handleClose: () => void;
}

interface IGraphQLError extends GraphQLError {
  subCode?: number;
}

const NewStudyModal: React.FC<IProps> = ({ handleClose }) => {
  const [error, setError] = useState<null | string>(null);
  const { t } = useTranslation();
  const [submitted, setSubmitted] = useState(false);

  const [name, setName] = useState<string>('');
  const [department, setDepartment] = useState<string>('');
  const [manager, setManager] = useState<string>('');
  const [cnvModelId, setCnvModelId] = useState<string>('');
  const [dueDate, setDueDate] = useState<Moment | null>(null);
  const [internalError, setInternalError] = useState<null | IInternalError>(null);

  const [mutateStudy, { loading: studyLoading }] = useCreateStudyMutation({
    onCompleted: () => {
      handleClose();
    },
    onError: ({
      networkError,
      graphQLErrors,
    }: {
      graphQLErrors: ReadonlyArray<IGraphQLError>;
      networkError: Error | ServerParseError | ServerError | null;
    }) => {
      const isSameUser = graphQLErrors.find((err) => err.subCode === 4003);
      if (isSameUser) {
        setError(isSameUser.message);
      }
      if (networkError) {
        if (isServerError(networkError)) {
          setInternalError({
            date: moment().format('YYYY-MM-DD HH:mm'),
            statusCode: networkError.statusCode,
          });
          return;
        }
        setInternalError({
          date: moment().format('YYYY-MM-DD HH:mm'),
          context: 'dateOnly',
        });
      }
    },
  });

  const { data, loading } = useUsersQuery({
    variables: {
      filters: {
        filters: { field: 'status', type: FilterTypeEnum.IN, args: [UserStatusEnum.ACTIVE] },
      },
    },
  });
  const { data: cnvModelsData, loading: loadingModels } = useCnvModelsQuery();
  const { data: departmentsData, loading: loadingDepartments } = useDepartmentsQuery();

  const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const onDepartmentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDepartment(e.target.value);
  };

  const onModelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCnvModelId(e.target.value);
  };

  const onManagerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setManager(e.target.value);
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    setSubmitted(true);
    setInternalError(null);
    setError(null);
    if (!name || !manager || !cnvModelId || !department || !dueDate) {
      return false;
    }
    mutateStudy({
      variables: {
        input: {
          name,
          studyDirectorId: manager,
          cnvModelId,
          dueDate: dueDate.toISOString(),
          department,
        },
      },
      refetchQueries: [GET_STUDIES],
    });
  };

  if (loading || loadingDepartments || loadingModels) {
    return (
      <GenericModal open onClose={handleClose}>
        <FullScreenLoader />
      </GenericModal>
    );
  }

  const sortedPathology = cnvModelsData?.cnvModels
    .slice()
    .sort((a, b) => a.pathology.localeCompare(b.pathology));

  const sortedStudyDirectors = data?.users
    .slice()
    .sort((a, b) => a.firstName.localeCompare(b.firstName));

  return (
    <GenericModal open onClose={handleClose}>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography id="modal-modal-title" variant="h5" component="h2">
          {t('studies.createStudy')}
        </Typography>
        <Box width="100%" component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 2 }}>
          <TextField
            autoFocus
            margin="normal"
            fullWidth
            id="name"
            type="text"
            label={t('studies.nameLabel')}
            name="name"
            inputMode="text"
            size="small"
            placeholder={t('studies.namePlaceholder')}
            onChange={onNameChange}
            value={name}
            error={submitted && !name}
            autoComplete="off"
          />

          {departmentsData?.departments.length ? (
            <TextField
              margin="normal"
              fullWidth
              select
              id="department"
              name="department"
              size="small"
              label={t('studies.departmentLabel')}
              placeholder={t('studies.departmentPlaceholder')}
              onChange={onDepartmentChange}
              value={department}
              error={submitted && !department}
            >
              {departmentsData?.departments.map((departmentObj) => (
                <MenuItem key={departmentObj.id} value={departmentObj.name}>
                  {departmentObj.name}
                </MenuItem>
              ))}
            </TextField>
          ) : (
            // Use free text input if there aren't any departments
            <TextField
              margin="normal"
              fullWidth
              id="department"
              type="text"
              label={t('studies.departmentLabel')}
              name="department"
              inputMode="text"
              size="small"
              placeholder={t('studies.departmentPlaceholder')}
              onChange={onDepartmentChange}
              value={department}
              error={submitted && !department}
            />
          )}

          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label={t('studies.dueDateLabel')}
              value={dueDate}
              onChange={(newValue) => {
                setDueDate(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={submitted && !dueDate}
                  fullWidth
                  name="dueDate"
                  size="small"
                  inputMode="text"
                  margin="normal"
                  autoComplete="off"
                />
              )}
            />
          </LocalizationProvider>
          <TextField
            select
            margin="normal"
            fullWidth
            id="manager"
            label={t('studies.managerLabel')}
            name="manager"
            size="small"
            placeholder={t('studies.managerPlaceholder')}
            onChange={onManagerChange}
            value={manager}
            error={submitted && !manager}
          >
            {sortedStudyDirectors?.map((user) => (
              <MenuItem key={user.id} value={user.id}>
                {`${user.firstName} ${user.lastName}`}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            margin="normal"
            fullWidth
            select
            id="model"
            name="model"
            size="small"
            label={t('studies.modelLabel')}
            placeholder={t('studies.modelPlaceholder')}
            onChange={onModelChange}
            value={cnvModelId}
            error={submitted && !cnvModelId}
          >
            {sortedPathology?.map((item) => {
              return (
                <MenuItem key={item.id} value={item.id}>
                  {item.pathology}
                </MenuItem>
              );
            })}
          </TextField>
          {internalError && (
            <Typography variant="body1" color="error.main" mt={1}>
              {t('general.unknownError', {
                date: internalError.date,
                statusCode: internalError.statusCode,
                context: internalError.context,
              })}
            </Typography>
          )}
          {error && (
            <Typography variant="body1" color="error.main" mt={1}>
              {error}
            </Typography>
          )}
          <Box mt={3} textAlign="center">
            <LoadingButton loading={studyLoading} type="submit" variant="contained">
              {t('button.create')}
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </GenericModal>
  );
};

export default NewStudyModal;
