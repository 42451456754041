import { gql, useMutation } from '@apollo/client';
import IFile from '../../analysis/types/IFile';

interface IDeleteRawFilesInput {
  ids: string[];
}

interface IDeleteRawFilesResponse {
  deleteRawFiles: IFile[];
}

const DELETE_RAW_FILES = gql`
  mutation deleteRawFiles($ids: [String!]!) {
    deleteRawFiles(ids: $ids) {
      id
    }
  }
`;

export default (options: { onError: (apolloError) => void; onCompleted: (response) => void }) => {
  return useMutation<IDeleteRawFilesResponse, IDeleteRawFilesInput>(DELETE_RAW_FILES, options);
};
