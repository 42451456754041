import { colorToRGBA, getColorAtPixel } from 'q-floodfill';
import ICoordinates from '../types/ICoordinates';
import centerCoords from './centerCoords';
import colorsMatch from './colorsMatch';

const backgroundColor = 'rgba(0, 0, 0, 0)';

const clearSquaresInCanvas = (canvas: HTMLCanvasElement, coordinates: ICoordinates[]) => {
  const ctx = canvas.getContext('2d');
  if (!ctx) return false;
  const { width, height } = canvas;
  const imgData = ctx.getImageData(0, 0, width, height);
  let cleared = false;
  const { lineWidth } = ctx;
  ctx.lineWidth = 10;
  ctx.lineCap = 'square';
  ctx.lineJoin = 'miter';
  ctx.globalCompositeOperation = 'destination-out';
  coordinates.forEach((coords) => {
    if (!colorsMatch(getColorAtPixel(imgData, coords.x, coords.y), colorToRGBA(backgroundColor))) {
      const { x, y } = centerCoords(coords, height);
      cleared = true;
      ctx.beginPath();
      ctx.moveTo(x, y);
      ctx.lineTo(x, y);
      ctx.stroke();
    }
  });
  ctx.lineWidth = lineWidth;
  ctx.globalCompositeOperation = 'source-over';
  return cleared;
};

export default clearSquaresInCanvas;
